import { millisecondsToSeconds } from 'date-fns';

import { average, countItems, getNodes, group } from '../../../utils/dataUtils';

export const mergeAnalytics = ({ data, prop }) => {
	const mergedAnalytics = {
		timeInInterface: {},
		userFirstAction: {},
	};
	if (!data) {
		return mergedAnalytics;
	}

	data.forEach((object) => {
		const analytics = object[prop];

		for (const key in analytics.timeInInterface) {
			if (mergedAnalytics.timeInInterface[key] === undefined) {
				mergedAnalytics.timeInInterface[key] = [];
			}

			mergedAnalytics.timeInInterface[key].push(
				...analytics.timeInInterface[key],
			);
		}

		for (const key in analytics.userFirstAction) {
			if (mergedAnalytics.userFirstAction[key] === undefined) {
				mergedAnalytics.userFirstAction[key] = [];
			}

			mergedAnalytics.userFirstAction[key].push(
				...analytics.userFirstAction[key],
			);
		}
	});

	return mergedAnalytics;
};

export const getUsageStats = (groupedData) => {
	const userFirstAction = {};
	const timeInInterface = {};

	for (const key in groupedData) {
		if (userFirstAction[key] === undefined) {
			userFirstAction[key] = [];
		}

		if (timeInInterface[key] === undefined) {
			timeInInterface[key] = [];
		}

		let load = null;
		let actions = [];

		groupedData[key].forEach((item, index, arr) => {
			if (item.type === 'load') {
				load = item.date;
				actions = [];
			}
			actions.push(item.type);

			if (
				item.type === 'load' &&
				arr[index + 1]?.type &&
				arr[index + 1]?.type !== 'load'
			) {
				userFirstAction[key] = [
					...userFirstAction[key],
					{
						time:
							new Date(arr[index + 1]?.date) -
							new Date(item.date),
						type: arr[index + 1]?.type,
					},
				];
			}

			if (
				load &&
				item.type !== 'load' &&
				(!arr[index + 1]?.type || arr[index + 1]?.type === 'load')
			) {
				timeInInterface[key] = [
					...timeInInterface[key],
					{
						time: new Date(item.date) - new Date(load),
						actions: actions,
					},
				];
			}
		});
	}

	return {
		userFirstAction: userFirstAction,
		timeInInterface: timeInInterface,
	};
};

export const getUsersAnalytics = ({ users }) => {
	return getNodes(users).map((value) => {
		const groupedUserData = groupUserData(value.analytics);

		return {
			username: value.username,
			data: groupedUserData,
			firstAction: getUsageStats(groupedUserData),
		};
	});
};

export const groupUserData = (userData) => {
	const combinedData = {};

	if (!userData || !userData.data) {
		return {};
	}

	userData.data.forEach(({ dataByRange }) =>
		dataByRange.forEach(({ selector, data }) => {
			if (!combinedData[selector]) {
				combinedData[selector] = [];
			}

			combinedData[selector] = [...combinedData[selector], ...data];
		}),
	);

	for (const key in combinedData) {
		combinedData[key] = combinedData[key]
			.map((object) => ({
				date: object.data.date,
				type: object?.data?.payload?.analyticsKey || object.type,
			}))
			.sort((a, b) => new Date(a.date) - new Date(b.date));
	}

	return combinedData;
};

export const mergeAnalyticsByDate = ({ timeInInterface, userFirstAction }) => {
	const mergedSessionTime = {};
	const mergedFirstAction = {};

	for (const selector in timeInInterface) {
		if (mergedSessionTime[selector] === undefined) {
			mergedSessionTime[selector] = { time: [], actions: [] };
		}

		timeInInterface[selector].forEach(({ time, actions }) => {
			mergedSessionTime[selector] = {
				time: [...mergedSessionTime[selector].time, time],
				actions: [...mergedSessionTime[selector].actions, ...actions],
			};
		});
	}

	for (const selector in userFirstAction) {
		if (mergedFirstAction[selector] === undefined) {
			mergedFirstAction[selector] = { time: [], actions: [] };
		}

		userFirstAction[selector].forEach((item) => {
			mergedFirstAction[selector] = {
				time: [...mergedFirstAction[selector].time, item.time],
				actions: [...mergedFirstAction[selector].actions, item],
			};
		});
	}

	const getSessionStats = (object) => {
		const sessionStats = {};

		for (const key in object) {
			if (sessionStats[key] === undefined) {
				sessionStats[key] = {};
			}

			sessionStats[key] = {
				time: average(object[key].time),
				actions: countItems(object[key].actions),
			};
		}

		return sessionStats;
	};

	const countFirstActions = (items) => {
		const data = {};

		items.forEach(({ type, time }) => {
			if (data[type] === undefined) {
				data[type] = { time: [], count: 0 };
			}

			data[type] = {
				time: [...data[type].time, time],
				count: data[type].count + 1,
			};
		});

		return Object.keys(data).map((key) => ({
			action: key,
			value: average(data[key].time),
			count: data[key].count,
		}));
	};

	const getFirstActionStats = (object) => {
		const firstActionStats = {};

		for (const key in object) {
			if (firstActionStats[key] === undefined) {
				firstActionStats[key] = {};
			}

			const actions = countFirstActions(object[key].actions);

			firstActionStats[key] = {
				time: Math.round(average(object[key].time) * 1000) / 1000,
				totalActions: actions.reduce((acc, item) => {
					return acc + item.count;
				}, 0),
				actions: actions,
			};
		}

		return firstActionStats;
	};

	return {
		timeInInterface: getSessionStats(mergedSessionTime),
		userFirstAction: getFirstActionStats(mergedFirstAction),
	};
};

export const getTimeInInterface = ({ data, selector, prevSelector }) => {
	return data.map(({ _id, name, partnerStats }) => {
		const currentTime =
			partnerStats?.timeInInterface?.[selector]?.time || 0;
		const prevTime =
			partnerStats?.timeInInterface?.[prevSelector]?.time || 0;
		return {
			id: _id,
			name: name,
			time: millisecondsToSeconds(currentTime),
			diff: millisecondsToSeconds(prevTime - currentTime),
		};
	});
};

export const getFirstAction = ({ data, selector, prevSelector }) => {
	const tableData = {};
	let totalFirstActions = 0;

	data.forEach(({ partnerStats }) => {
		const currentStats = group(
			partnerStats.userFirstAction?.[selector]?.actions,
			'action',
		);
		const prevStats = group(
			partnerStats.userFirstAction?.[prevSelector]?.actions,
			'action',
		);

		for (const key in currentStats) {
			totalFirstActions = totalFirstActions + currentStats[key].count;
			if (tableData[key] === undefined) {
				tableData[key] = {
					current: [],
					prev: [],
					count: 0,
				};
			}
			tableData[key] = {
				current: [...tableData[key].current, currentStats[key].value],
				prev: [...tableData[key].prev, prevStats?.[key]?.value || 0],
				count: tableData[key].count + currentStats[key].count,
			};
		}
	});

	return Object.keys(tableData).map((key, index) => {
		const currentAvg = average(tableData[key].current);
		const prevAvg = average(tableData[key].prev);

		return {
			id: `${index}-firstAction`,
			event: key,
			time: millisecondsToSeconds(currentAvg),
			percentage:
				Math.round((tableData[key].count / totalFirstActions) * 100) /
				100,
			diff: millisecondsToSeconds(prevAvg - currentAvg),
		};
	});
};

export const getGeneralUsage = ({ data, selector, prevSelector }) => {
	const tableData = {};

	data.forEach(({ partnerStats }) => {
		const currentStats = group(
			partnerStats.timeInInterface?.[selector]?.actions,
			'action',
		);
		const prevStats = group(
			partnerStats.timeInInterface?.[prevSelector]?.actions,
			'action',
		);

		for (const key in currentStats) {
			if (tableData[key] === undefined) {
				tableData[key] = {
					current: 0,
					prev: 0,
				};
			}

			tableData[key] = {
				current: tableData[key].current + currentStats[key].value,
				prev: tableData[key].prev + prevStats?.[key]?.value || 0,
			};
		}
	});

	return Object.keys(tableData).map((key, index) => ({
		id: `${index}-general`,
		event: key,
		clicks: tableData[key].current,
		diff: tableData[key].current - tableData[key].prev,
	}));
};

export const getAvgTime = ({ data, type, selector, prevSelector }) => {
	const current = data
		.map(({ partnerStats }) => partnerStats?.[type]?.[selector]?.time)
		.filter((item) => item);

	const prev = data
		.map(({ partnerStats }) => partnerStats?.[type]?.[prevSelector]?.time)
		.filter((item) => item);

	return {
		value: millisecondsToSeconds(average(current)),
		diff: millisecondsToSeconds(average(prev) - average(current)),
	};
};

export const getTooltipData = ({
	data,
	selector,
	prevSelector,
	selectedStats,
	timeInInterface,
	firstAction,
}) => {
	switch (selectedStats) {
		case 'timeInInterface':
			return data.map(({ name, _id, partnerStats }) => {
				const time =
					partnerStats?.timeInInterface?.[selector]?.time || 0;
				const diff =
					partnerStats?.timeInInterface?.[prevSelector]?.time || 0;

				return {
					id: _id,
					name: name,
					time: millisecondsToSeconds(time),
					diff: millisecondsToSeconds(diff - time),
				};
			});

		case 'firstAction':
			return data.map(({ name, _id, partnerStats }) => {
				const time =
					partnerStats?.userFirstAction?.[selector]?.time || 0;
				const diff =
					partnerStats?.userFirstAction?.[prevSelector]?.time || 0;

				return {
					id: _id,
					name: name,
					time: millisecondsToSeconds(time),
					diff: millisecondsToSeconds(diff - time),
				};
			});

		default:
			return [
				{
					id: 'id-1',
					name: 'Time in interface',
					time: timeInInterface.value,
					diff: timeInInterface.diff,
				},
				{
					id: 'id-2',
					name: 'Time to first action',
					time: firstAction.value,
					diff: firstAction.diff,
				},
			];
	}
};
