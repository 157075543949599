import { FeatureService } from '@asteria/backend-utils-services';

const FIELDS = `
pageInfo {
  hasNextPage
  hasPreviousPage
  count
  currentCursor
  startCursor
  endCursor
}
edges {
  node {
    id
    key
    name
    description
    version
    createdAt
    updatedAt
    groups {
      featureId
      feature {
        id
        key
        name
      }
      enabled
    }
  }
}
`;

FeatureService.feature.extend({
	key: 'fetch',
	getQuery: (fields = FIELDS) => `
		query Features($pageFilters: PageInput = {}) {
			features(pageFilters: $pageFilters) {
        ${fields}
			}
		}
	`,
	loggerMethod: `services.featureService.fetch`,
});
