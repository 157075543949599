import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import {
	Autocomplete,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	Grid,
	Input,
	RadioGroup,
	Select,
	Slider,
	Stack,
	TextField,
} from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';

export const LookUpAndRead = (props) => {
	const { name, operation } = props;
	const { register, setValue } = useFormContext();

	const { layoutData } = React.useContext(FormContext);

	const texts = React.useMemo(() => {
		const texts = (layoutData?.pdfData?.Pages ?? []).flatMap(
			({ Texts }) => Texts,
		);

		return Array.from(new Set(texts.map((item) => item.text).sort()));
	}, [layoutData]);

	const { onChangeDir, ...directionInput } = register(`${name}.direction`);

	const { onChange, ...patternInputProps } = register(`${name}.pattern`);
	const onChangePattern = React.useCallback(
		(e, value) => {
			setValue(`${name}.pattern`, value);
		},
		[setValue, name],
	);

	return (
		<Stack>
			<Autocomplete
				disablePortal
				freeSolo
				id={name}
				options={texts}
				size="small"
				value={operation.pattern ?? ''}
				{...patternInputProps}
				onChange={onChangePattern}
				onInputChange={onChangePattern}
				renderInput={(params) => (
					<TextField {...params} label="Pattern" />
				)}
			/>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">Pages</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={operation.pages ?? 'all'}
					label="Operation"
					{...register(`${name}.pages`)}
				>
					<MenuItem value="first">First</MenuItem>
					<MenuItem value="last">Last</MenuItem>
					<MenuItem value="all">All</MenuItem>
				</Select>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label2">
					Direction
				</InputLabel>
				<Select
					labelId="demo-simple-select-label2"
					id="demo-simple-select2"
					value={operation.direction ?? 'both'}
					label="Operation"
					{...register(`${name}.direction`)}
				>
					<MenuItem value="before">Before</MenuItem>
					<MenuItem value="after">After</MenuItem>
					<MenuItem value="both">Both</MenuItem>
				</Select>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">Direction</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={operation.dir}
					label="Operation"
					{...register(`${name}.dir`)}
				>
					<MenuItem value="">Current</MenuItem>
					<MenuItem value="DOWN">Down</MenuItem>
					<MenuItem value="UP">Up</MenuItem>
					<MenuItem value="LEFT">Left</MenuItem>
					<MenuItem value="RIGHT">Right</MenuItem>
					<MenuItem value="START">Start</MenuItem>
					<MenuItem value="END">End</MenuItem>
				</Select>
			</FormControl>
			<Slider
				value={operation.threshold ?? 100}
				max={100}
				min={1}
				aria-label="Threshold"
				valueLabelDisplay="on"
				{...register(`${name}.threshold`, { value: 3 })}
			/>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs>
					<Slider
						value={operation.thresholdWord ?? 0}
						max={100}
						min={1}
						aria-label="Threshold"
						valueLabelDisplay="on"
						{...register(`${name}.thresholdWord`, {
							value: 0,
						})}
					/>
				</Grid>
				<Grid item>
					<Input
						value={operation.thresholdWord ?? 0}
						size="small"
						{...register(`${name}.thresholdWord`, {
							value: 0,
						})}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
};
