import AsteriaCore from '@asteria/core';

import { envURL, printerURL, widgetURL } from './constants';

let API_URL = envURL.PROD_API_URL;
let WIDGET_URL = widgetURL.PROD_API_URL;
let PRINTER_URL = printerURL.PROD_API_URL;

if (
	window.location.href.includes('dev.') ||
	window.location.href.includes('dev-')
) {
	API_URL = envURL.DEV_API_URL;
	WIDGET_URL = widgetURL.DEV_API_URL;
	PRINTER_URL = printerURL.DEV_API_URL;
} else if (
	window.location.href.includes('stage.') ||
	window.location.href.includes('stage-')
) {
	API_URL = envURL.STAGE_API_URL;
	WIDGET_URL = widgetURL.STAGE_API_URL;
	PRINTER_URL = printerURL.STAGE_API_URL;
} else if (window.location.href.includes('localhost')) {
	API_URL = envURL.DEV_API_URL;
	WIDGET_URL = widgetURL.DEV_API_URL;
	PRINTER_URL = printerURL.DEV_API_URL;

	// For local api development
	// API_URL = envURL.LOCAL_API_URL;
	// WIDGET_URL = widgetURL.LOCAL_API_URL;
} else if (window.location.href.includes('local.')) {
	API_URL = envURL.DEV_API_URL;
	WIDGET_URL = widgetURL.DEV_API_URL;
	PRINTER_URL = printerURL.PROD_API_URL;
}

// API_URL = envURL.PROD_API_URL;
// WIDGET_URL = widgetURL.PROD_API_URL;
// PRINTER_URL = printerURL.PROD_API_URL;

const getValueAsync = (options) => {
	const { key } = options;

	if (/services\..+?\.graphqlUri/.test(key)) {
		return API_URL;
	}

	if (key === 'widgetUrl') {
		return WIDGET_URL;
	}

	if (key === 'printerURL') {
		return PRINTER_URL;
	}

	return null;
};

AsteriaCore.Configuration.getValueAsync = getValueAsync;

export default AsteriaCore.Configuration;

export { API_URL, WIDGET_URL, PRINTER_URL };
