import React from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
	Button,
	ButtonGroup,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	Menu,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';

import { FormContext } from '../context';
import { detectValue, formatField } from '../utils';

import '../styles.scss';

const FieldNumber = (props) => {
	const { first, last, onMoveUp, onMoveDown, onRemove } = props;

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs>
				Number
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldDate = (props) => {
	const { type, first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				{type[0].toUpperCase() + type.slice(1)}
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="format"
					label="Format"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'format'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldCompare = (props) => {
	const { type, first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				{type[0].toUpperCase() + type.slice(1)}
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="value"
					label="Value"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'value'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="set"
					label="Set"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'set'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldCondition = (props) => {
	const { type, first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				{type[0].toUpperCase() + type.slice(1)}
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="value"
					label="Value"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'value'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="set"
					label="Set"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'set'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldAppend = (props) => {
	const { type, first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				{type[0].toUpperCase() + type.slice(1)}
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="value"
					label="Value"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'value'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldTrim = (props) => {
	const { first, last, onMoveUp, onMoveDown, onRemove } = props;

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs>
				Trim
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldReplace = (props) => {
	const { first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				Replace
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="source"
					label="Source"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'source'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="target"
					label="Target"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'target'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldRegex = (props) => {
	const { first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				Regex
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="source"
					label="Source"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'source'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="target"
					label="Target"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'target'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="default"
					label="Default"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'default'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
			<Grid item>
				{
					'(Total amount|Insgesamt zu zahlen|Er tillgodo|Summa att betala|Sum å betale|Amount to pay|Total to pay|Total amount due).*?([\\d ,\\.]+(?:,\\d2)?)$'
				}
			</Grid>
			<Grid item>
				{
					'(Mehrwertsteuer|Mervärdesskatt|Mervårdesskatt|Mervardesskat|^VAT|^V.A.T).*?([\\d ,\\.]+(?:,\\d2)?)$'
				}
			</Grid>
			<Grid item>
				{
					'(Total amount|Insgesamt zu zahlen|Er tillgodo|Summa att betala|Sum å betale|Amount to pay|Total to pay|Total amount due).*?[\\(\\{](.*)[\\)\\}]'
				}
			</Grid>
			<Grid item>{'(\\d{4}-\\d{2}-\\d{2})'}</Grid>
		</Grid>
	);
};

const FieldSubstring = (props) => {
	const { first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				Substring
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="start"
					label="Start"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'start'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="end"
					label="Start"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'end'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldAdd = (props) => {
	const { type, first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				{type[0].toUpperCase() + type.slice(1)}
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="splitter"
					label="Split On"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'splitter'].join('.'))}
				/>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldPlus = (props) => {
	const { type, first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				{type[0].toUpperCase() + type.slice(1)}
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="selector"
					label="Selector"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'selector'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<TextField
					margin="dense"
					id="target"
					label="Target"
					fullWidth
					variant="outlined"
					InputLabelProps={{ shrink: true }}
					size="small"
					{...register([name, 'target'].join('.'))}
				/>
			</Grid>
			<Grid item xs>
				<Select
					labelId="datatype"
					id="datatype"
					label="Type"
					{...register([name, 'datatype'].join('.'))}
				>
					<MenuItem value="date">Date</MenuItem>
				</Select>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const FieldAddress = (props) => {
	const { type, first, last, onMoveUp, onMoveDown, onRemove, name } = props;

	const { register } = useFormContext();

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs="auto">
				<ButtonGroup variant="outlined">
					<Button disabled={first} onClick={onMoveUp}>
						<KeyboardArrowUpIcon />
					</Button>
					<Button disabled={last} onClick={onMoveDown}>
						<KeyboardArrowDownIcon />
					</Button>
				</ButtonGroup>
			</Grid>

			<Grid item xs="auto">
				{type[0].toUpperCase() + type.slice(1)}
			</Grid>
			<Grid item xs>
				<Select
					labelId="field"
					id="field"
					label="Age"
					{...register([name, 'field'].join('.'))}
				>
					<MenuItem value="name">Company Name</MenuItem>
					<MenuItem value="street">Street</MenuItem>
					<MenuItem value="coAddress">CO</MenuItem>
					<MenuItem value="zipCode">ZipCode</MenuItem>
					<MenuItem value="city">City</MenuItem>
					<MenuItem value="country">Country</MenuItem>
				</Select>
			</Grid>
			<Grid item xs="auto">
				<Button color="error" onClick={onRemove}>
					<RemoveCircleIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

const Field = (props) => {
	if (props?.type === 'number') {
		return <FieldNumber {...props} />;
	}

	if (
		props?.type === 'date' ||
		props?.type === 'time' ||
		props?.type === 'datetime'
	) {
		return <FieldDate {...props} />;
	}

	if (props?.type === 'trim') {
		return <FieldTrim {...props} />;
	}

	if (props?.type === 'replace') {
		return <FieldReplace {...props} />;
	}

	if (props?.type === 'substring') {
		return <FieldSubstring {...props} />;
	}

	if (props?.type === 'add') {
		return <FieldAdd {...props} />;
	}

	if (props?.type === 'address') {
		return <FieldAddress {...props} />;
	}

	if (props?.type === 'addressWithName') {
		return <FieldAddress {...props} />;
	}

	if (props?.type === 'regex') {
		return <FieldRegex {...props} />;
	}

	if (props?.type === 'compare') {
		return <FieldCompare {...props} />;
	}

	if (props?.type === 'append') {
		return <FieldAppend {...props} />;
	}

	if (props?.type === 'plus') {
		return <FieldPlus {...props} />;
	}

	if (props?.type === 'condition') {
		return <FieldCondition {...props} />;
	}

	return null;
};

const InputSettings = (props) => {
	const { open, onSubmit, name } = props;

	const { canvas, scheduler } = React.useContext(FormContext);
	const { register } = useFormContext();
	const saved = useWatch({ name: ['layout.$settings', name].join('.') });
	const area = useWatch({ name: ['layout.$area', name].join('.') });
	const [source, setSource] = React.useState(null);

	const { getValues } = useFormContext();
	const { fields, append, move, remove, replace, update } = useFieldArray({
		name: ['modal.settings', name].join('.'),
	});

	React.useEffect(() => {
		if (saved) {
			replace(saved);
		}
	}, [replace, saved]);

	const rules = useWatch({ name: ['modal.settings', name].join('.') });

	// const source = useWatch({ name: name });
	React.useEffect(() => {
		const fetchValue = async () => {
			const {text: value} = await detectValue(area, canvas.current, scheduler);
			setSource(value);
		};

		if (open && area) {
			fetchValue();
		}
	}, [area, open, name, canvas, scheduler]);

	const target = formatField(source, rules);

	const [rulesMenuRef, setRulesMenuRef] = React.useState(null);
	const isDropdownOpen = Boolean(rulesMenuRef);

	const onDropdownOpen = (event) => {
		setRulesMenuRef(event.currentTarget);
	};

	const onDropdownClose = () => {
		setRulesMenuRef(null);
	};

	const handleSubmit = React.useCallback(() => {
		const rules = getValues(['modal.settings', name].join('.'));
		const area = getValues(['modal.area', name].join('.'));

		return onSubmit?.({ rules, area });
	}, [getValues, name, onSubmit]);

	const handleClose = React.useCallback(
		(event) => {
			return onSubmit?.(saved ?? []);
		},
		[onSubmit, saved],
	);

	const onRuleAdd = React.useCallback(
		(rule) => {
			return () => {
				append(rule);
				setRulesMenuRef(null);
			};
		},
		[append],
	);

	const onRuleMoveUp = React.useCallback(
		(index) => {
			return () => {
				move(index, index - 1);
			};
		},
		[move],
	);

	const onRuleMoveDown = React.useCallback(
		(index) => {
			return () => {
				move(index, index + 1);
			};
		},
		[move],
	);

	const onRuleRemove = React.useCallback(
		(index) => {
			return () => {
				remove(index);
			};
		},
		[remove],
	);

	const onRuleChange = React.useCallback(
		(index) => {
			return (value) => {
				update(index, value);
			};
		},
		[update],
	);

	if (!open) {
		return null;
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Configuration</DialogTitle>
			<DialogContent dividers>
				<DialogContentText sx={{ marginBottom: 1 }}>
					You can easily configure how field should be passed in the
					invoice
				</DialogContentText>
				<Grid container spacing={2} sx={{ marginBottom: 4 }}>
					<Grid item xs>
						<TextField
							margin="dense"
							id="source"
							label="Source"
							fullWidth
							variant="outlined"
							multiline
							value={source}
							InputProps={{ readOnly: true }}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item xs>
						<TextField
							margin="dense"
							id="target"
							label="Target"
							fullWidth
							variant="outlined"
							value={target}
							InputProps={{ readOnly: true }}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>
				<FormControlLabel
					control={
						<Checkbox
							{...register(
								['modal.area', name, 'lastPage'].join('.'),
							)}
							defaultChecked={area?.lastPage}
						/>
					}
					label="Last Page"
				/>
				<Button
					id="rules-action"
					aria-controls={isDropdownOpen ? 'rules-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={isDropdownOpen ? 'true' : undefined}
					onClick={onDropdownOpen}
				>
					Add new rule
				</Button>
				<Menu
					id="rules-menu"
					anchorEl={rulesMenuRef}
					open={isDropdownOpen}
					onClose={onDropdownClose}
					MenuListProps={{
						'aria-labelledby': 'rules-action',
					}}
				>
					<MenuItem onClick={onRuleAdd({ type: 'number' })}>
						Number
					</MenuItem>
					<Divider />
					<MenuItem onClick={onRuleAdd({ type: 'date' })}>
						Date
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'time' })}>
						Time
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'datetime' })}>
						Datetime
					</MenuItem>
					<Divider />
					<MenuItem onClick={onRuleAdd({ type: 'trim' })}>
						Trim
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'replace' })}>
						Replace
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'substring' })}>
						Substring
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'regex' })}>
						Regex
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'add' })}>
						Add (Add together numbers)
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'address' })}>
						Address
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'addressWithName' })}>
						Address With Name
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'compare' })}>
						Compare
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'append' })}>
						Append
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'plus' })}>
						Plus
					</MenuItem>
					<MenuItem onClick={onRuleAdd({ type: 'condition' })}>
						Condition
					</MenuItem>
				</Menu>
				<Grid container spacing={2}>
					{fields.map((object, index, arr) => (
						<Grid item>
							<Field
								key={object.id}
								{...object}
								rule={object}
								first={index === 0}
								last={index === arr.length - 1}
								onMoveUp={onRuleMoveUp(index)}
								onMoveDown={onRuleMoveDown(index)}
								onRemove={onRuleRemove(index)}
								onChange={onRuleChange(index)}
								name={['modal.settings', name, index].join('.')}
							/>
						</Grid>
					))}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleSubmit}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InputSettings;
