import * as React from 'react';

import { useSelector } from 'react-redux';

import * as Ducks from '../ducks';
import { selectToken } from '../store/auth';

export const useRequest = (props) => {
	const [state, dispatch] = React.useReducer(
		Ducks.request.reducer,
		Ducks.request.initialState,
	);

	const { handler, options, context, skip = false } = props;

	React.useEffect(() => {
		if (!skip) {
			dispatch(Ducks.request.requestInflight());

			handler(options, context)
				.then((data) => dispatch(Ducks.request.requestSuccess(data)))
				.catch((err) => dispatch(Ducks.request.requestFailure(err)));
		}
	}, [options]);

	return state;
};

export const usePagination = (props) => {
	const [state, dispatch] = React.useReducer(
		Ducks.request.reducer,
		Ducks.request.initialState,
	);

	const { handler, options, context, skip = false } = props;

	React.useEffect(() => {
		const fetch = async () => {
			if (!skip) {
				dispatch(Ducks.request.requestInflight());

				let data = [];

				const { pageFilters = {} } = options;

				let cursor = undefined;
				while (cursor !== null) {
					if (pageFilters.last !== undefined) {
						pageFilters.before = cursor;
					} else {
						pageFilters.after = cursor;
					}

					const response = await handler(
						{ ...options, pageFilters: pageFilters },
						context,
					).catch((err) => {
						dispatch(Ducks.request.requestFailure(err));
						return err;
					});

					if (response instanceof Error) {
						return;
					}

					if (
						pageFilters.last !== undefined &&
						response?.pageInfo?.hasPreviousPage
					) {
						cursor = response?.pageInfo?.startCursor;
					} else if (
						pageFilters.first !== undefined &&
						response?.pageInfo?.hasNextPage
					) {
						cursor = response?.pageInfo?.endCursor;
					} else {
						cursor = null;
					}

					const edges = response?.edges ?? [];
					data = data.concat(edges.map(({ node }) => node));

					if (!cursor) {
						cursor = null;
					}
				}

				dispatch(Ducks.request.requestSuccess(data));
			}
		};

		fetch();
	}, [options]);

	return state;
};

export const useMultipleRequests = (props) => {
	const [state, dispatch] = React.useReducer(
		Ducks.request.reducer,
		Ducks.request.initialState,
	);
	React.useEffect(() => {
		dispatch(Ducks.request.requestInflight());

		Promise.all(
			Object.values(props).map(({ handler, options, context }) =>
				handler(options, context),
			),
		)
			.then((data) =>
				Object.keys(props).reduce(
					(acc, key, index) => ({ ...acc, [key]: data[index] }),
					{},
				),
			)
			.then((data) => dispatch(Ducks.request.requestSuccess(data)))
			.catch((err) => dispatch(Ducks.request.requestFailure(err)));
	}, []);

	return state;
};

export const usePagination2 = (props) => {
	const [state, dispatch] = React.useReducer(
		Ducks.request.reducer,
		Ducks.request.initialState,
	);

	const [cursor, setCursor] = React.useState(null);
	const [count, setCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const { handler, options, context } = props;

	const accessToken = useSelector(selectToken);

	const fetch = React.useCallback(async () => {
		dispatch(Ducks.request.requestInflight());
		const { pageFilters = {} } = options;

		const response = await handler(
			{ ...options, pageFilters: pageFilters },
			{ accessToken, ...(context ?? {}) },
		).catch((err) => {
			dispatch(Ducks.request.requestFailure(err));
			return err;
		});

		if (response instanceof Error) {
			return;
		}

		const edges = response?.edges ?? [];
		const items = edges.map(({ node }) => node);
		const count = response?.pageInfo?.count || 0;

		setCount(count);
		dispatch(
			Ducks.request.requestSuccess({
				items,
				page: 0,
				pages: Math.ceil(
					count / (pageFilters.last ?? pageFilters.first ?? count),
				),
			}),
		);

		if (
			pageFilters.last !== undefined &&
			response?.pageInfo?.hasPreviousPage
		) {
			setCursor(response?.pageInfo?.startCursor);
		} else if (
			pageFilters.first !== undefined &&
			response?.pageInfo?.hasNextPage
		) {
			setCursor(response?.pageInfo?.endCursor);
		} else {
			setCursor(null);
		}
	}, [options, context, handler, accessToken]);

	const nextPage = React.useCallback(async () => {
		dispatch(Ducks.request.requestInflight());
		const { pageFilters = {} } = options;

		if (pageFilters.last !== undefined) {
			pageFilters.before = cursor;
		} else {
			pageFilters.after = cursor;
		}

		const response = await handler(
			{ ...options, pageFilters: pageFilters },
			{ accessToken, ...(context ?? {}) },
		).catch((err) => {
			dispatch(Ducks.request.requestFailure(err));
			return err;
		});

		if (response instanceof Error) {
			return;
		}

		const edges = response?.edges ?? [];
		const items = edges.map(({ node }) => node);

		dispatch(
			Ducks.request.requestSuccess({
				items,
				page: page + 1,
				pages: Math.ceil(
					count / (pageFilters.last ?? pageFilters.first ?? count),
				),
			}),
		);

		setPage(page + 1);

		if (
			pageFilters.last !== undefined &&
			response?.pageInfo?.hasPreviousPage
		) {
			setCursor(response?.pageInfo?.startCursor);
		} else if (
			pageFilters.first !== undefined &&
			response?.pageInfo?.hasNextPage
		) {
			setCursor(response?.pageInfo?.endCursor);
		} else {
			setCursor(null);
		}
	}, [options, context, handler, accessToken, cursor, page, count]);

	return { fetch, nextPage, count, ...state };
};
