import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import {
	Badge,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';

import { useBadge } from './hooks';

const NavItem = ({ item, active }) => {
	const navigate = useNavigate();

	const redirectTo = useCallback(
		(e) => {
			e.preventDefault();
			navigate(item.path);
		},
		[item, navigate],
	);

	const badge = useBadge(item);

	return (
		<ListItemButton key={item.id} selected={active} onClick={redirectTo}>
			<ListItemIcon>
				<Badge
					badgeContent={badge}
					color="primary"
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					{item.icon}
				</Badge>
			</ListItemIcon>
			<ListItemText primary={item.title} />
		</ListItemButton>
	);
};

export default NavItem;
