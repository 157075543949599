import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AuthService } from '@asteria/backend-utils-services';

import AuthenticatedLayout from './Layout/Authenticated';
import NonAuthenticatedLayout from './Layout/NonAuthenticated';
import * as Context from './context';
import {
	logout,
	selectIsAuthenticated,
	selectToken,
	selectUser,
	setAdminUser,
} from './store/auth';

import '@asteria/themes/base/main.scss';

import '@asteria/themes/asteria/main.scss';

const App = () => {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const user = useSelector(selectUser);
	const accessToken = useSelector(selectToken);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isAuthenticated && accessToken && !user) {
			AuthService.auth
				.me({}, { token: accessToken })
				.then((user) => dispatch(setAdminUser(user)))
				.catch(() => dispatch(logout()));
		}
	}, [isAuthenticated, user, accessToken, dispatch]);

	if (isAuthenticated && !user) {
		return null;
	}

	if (!isAuthenticated) {
		return <NonAuthenticatedLayout />;
	}

	return <AuthenticatedLayout />;
};

export default Context.auth.withContext(App);
