import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';
import Wrapper, { Footer } from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form/form';
import Input from '@asteria/component-form/input';

const ProfileSettings = () => {
	const navigate = useNavigate();

	const handleCancel = useCallback(() => {
		navigate('/');
	}, []);

	const handleSave = useCallback((form) => {
		console.log('profileData', form);
	}, []);

	return (
		<Form onSubmit={handleSave}>
			<Wrapper className="h-screen">
				<div className="my-2">
					<Title size="title-5" type="title">
						Profile settings
					</Title>
				</div>
				<Wrapper scroll>
					<Input id="name" name="name" label="Name" required />
					<Input id="email" name="email" label="Email" required />
					<Input
						id="password"
						name="password"
						label="Password"
						required
					/>
				</Wrapper>
				<Footer className="self-end">
					<Button label="Cancel" onClick={handleCancel} />
					<Button type="submit" variant="primary" label="Save" />
				</Footer>
			</Wrapper>
		</Form>
	);
};

export default ProfileSettings;
