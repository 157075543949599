import React, { useCallback, useMemo, useRef, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import {
	Box,
	IconButton,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { format, startOfDay } from 'date-fns';

import {
	CompanyService,
	PartnerService,
} from '@asteria/backend-utils-services';

import InvoiceFilters from '../../components/Filters';
import InvoiceSearch from '../../components/Search';

const CompanyRow = (props) => {
	const { company } = props;

	return (
		<TableRow>
			<TableCell>
				<Link to={`/invoices/${company?.id}/1/2`}>{company?.id}</Link>
			</TableCell>
			<TableCell component="th" scope="row">
				<Link to={`/invoices/${company?.id}/1/2`}>{company?.name}</Link>
			</TableCell>
			<TableCell component="th" scope="row">
				{company?.agreementNumbers?.join?.(', ')}
			</TableCell>
		</TableRow>
	);
};

const CompaniesLayoutTab = () => {
	const [search, setSearch] = React.useState('');
	const [filter, setFilter] = React.useState({
		status: 'PENDING',
		startDate: localStorage.invoiceFilerDate
			? new Date(localStorage.invoiceFilerDate)
			: startOfDay(new Date()),
		clientStage: 'APPROVED',
	});

	const { data: partner } = useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['partners'],
		queryFn: () =>
			PartnerService.partner
				.fetch({
					fields: `edges { node { id name } }`,
					pageFilters: { first: 0 },
				})
				.then(({ edges }) => edges.map(({ node }) => node))
				.then((partners) =>
					partners.find(({ name }) => name.toLowerCase() === 'payex'),
				),
	});

	const { data: companies = [], isLoading } = useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['companies', partner?.id],
		enabled: !!partner?.id,
		queryFn: () =>
			CompanyService.company
				.fetch({
					fields: `edges { node { id name services { id serviceId data} } }`,
					filters: { partnerId: partner?.id },
					pageFilters: { first: 10 },
				})
				.then(({ edges }) => edges.map(({ node }) => node))
				.then((companies) => companies.filter(({ name }) => name))
				.then((companies) =>
					companies.map((company) => ({
						...company,
						service: company.services.find(
							({ serviceId }) => serviceId === partner?.id,
						),
						agreementNumbers: company.services
							.find(({ serviceId }) => serviceId === partner?.id)
							?.data?.agreements?.map?.(({ number }) => number),
					})),
				)
				.then((companies) =>
					companies.toSorted(({ name: a }, { name: b }) =>
						(a ?? '').localeCompare(b ?? ''),
					),
				),
	});

	const displayCompanies = useMemo(() => {
		if (!search) {
			return companies;
		}

		return companies.filter((company) => {
			if (company.name.includes(search)) {
				return true;
			}

			if (company?.agreementNumbers?.includes?.(search)) {
				return true;
			}

			return false;
		});
	}, [search, companies]);

	return (
		<Paper
			elevation={0}
			sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<InvoiceSearch setSearch={setSearch} />
			</Box>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Agreeemnts</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan="4">
									<Skeleton />
								</TableCell>
							</TableRow>
						) : null}
						{displayCompanies?.map?.((company) => (
							<CompanyRow key={company.id} company={company} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default CompaniesLayoutTab;
