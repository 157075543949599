import React from 'react';

import { useFieldArray } from 'react-hook-form';

import { Button, Divider, List, ListItem, ListSubheader } from '@mui/material';

import { AddressField } from './address';
import { Field } from './field';
import { Input } from './input';

const Fields = [
	{ name: 'invoice.delivery.method', label: 'Delivery Method' },
	{ name: 'invoice.delivery.terms', label: 'Delivery terms' },
	{ name: 'invoice.meta.termsOfPayment', label: 'Terms of payment' },
	{ name: 'invoice.meta.sellerOrderId', label: 'Seller Order ID' },
	{ name: 'invoice.meta.buyerOrderId', label: 'Buyer Order ID' },
	{ name: 'invoice.meta.buyerReference', label: 'Buyer reference' },
	{ name: 'invoice.meta.sellerReference', label: 'Seller reference' },
];

const ExtraFields = (props) => {
	const { fields, append, remove } = useFieldArray({
		name: 'layout.configuration.invoice.delivery.extra',
	});

	const onAdd = React.useCallback(() => {
		append({ key: '' });
	}, [append]);

	return (
		<>
			{fields.map((field, index) => (
				<ListItem key={field.id}>
					<Field
						name={`invoice.delivery.extra.${index}`}
						label={`Extra Field ${index + 1}`}
						{...props}
					/>
				</ListItem>
			))}

			<ListItem>
				<Button
					variant="outlined"
					color="success"
					fullWidth
					onClick={onAdd}
				>
					Add new extra field
				</Button>
			</ListItem>
		</>
	);
};

const ExtraSummaryFields = (props) => {
	const { fields, append, remove } = useFieldArray({
		name: 'layout.configuration.invoice.print.summary',
	});

	const onAdd = React.useCallback(() => {
		append({ label: '', value: '' });
	}, [append]);

	return (
		<>
			{fields.map((field, index) => (
				<ListItem key={field.id}>
					<Field
						name={`invoice.print.summary.${index}.label`}
						label="Label"
						{...props}
					/>
					<Field
						name={`invoice.print.summary.${index}.value`}
						label="value"
						fieldType="signednumber"
						edit
						{...props}
					/>
				</ListItem>
			))}

			<ListItem>
				<Button
					variant="outlined"
					color="success"
					fullWidth
					onClick={onAdd}
				>
					Add new extra summary field
				</Button>
			</ListItem>
		</>
	);
};

export const Delivery = (props) => {
	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			{Fields.map(({ name, label }, index) => {
				if (!label) {
					return <Divider key={index} />;
				}

				if (!name) {
					return <ListSubheader key={index}>{label}</ListSubheader>;
				}

				return (
					<ListItem key={name}>
						<Field name={name} label={label} edit {...props} />
					</ListItem>
				);
			})}
			<Divider />
			<ListSubheader>Extra Fields</ListSubheader>
			{<ExtraFields {...props} />}
			<Divider />

			{<ExtraSummaryFields {...props} />}
			<Divider />

			<ListSubheader>Shipping Address</ListSubheader>
			<ListItem>
				<AddressField name="invoice.contact.shipping" {...props} />
			</ListItem>
		</List>
	);
};
