import React from 'react';

import { ArrowForwardIos } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	Button,
	Grid,
	IconButton,
} from '@mui/material';

import TemplateSelector from '../../../../../components/pdf/TemplateSelector';
import ChatModal from '../../../../../components/pdf/chatModal';
import { InvoiceSelector } from '../../../../../components/pdf/invoiceSelector';
import { AutolayoutSwitch } from '../../../../../components/pdf/switches/autolayoutSwitch';
import { ClientSwitch } from '../../../../../components/pdf/switches/clientSwitch';
import { CreditSwitch } from '../../../../../components/pdf/switches/creditSwitch';

export const Controls = (props) => {
	const {
		id,
		selectedInvoice,
		setInvoice,
		invoices,
		invoicePages,
		loadClient,
		setLoadClient,
		autoRefreshData,
		setAutoRefreshData,
		selectedTemplate,
		onAction,
		onSubmit,
	} = props;

	const [showChatModal, setShowChatModal] = React.useState(false);

	const openChat = React.useCallback(() => {
		setShowChatModal(true);
	}, []);

	const closeChat = React.useCallback(() => {
		setShowChatModal(false);
	}, []);

	const onNext = React.useCallback(() => {
		const index = invoicePages.findIndex(
			(invoice) =>
				invoice?.invoiceNumber === selectedInvoice?.invoiceNumber,
		);

		if (index + 1 !== invoicePages.length) {
			setInvoice({
				target: { value: invoicePages[index + 1]?.invoiceNumber },
			});
		}
	}, [invoicePages, setInvoice, selectedInvoice]);

	return (
		<Accordion defaultExpanded>
			<AccordionDetails>
				<Grid container spacing={2} direction="column">
					<Grid item xs>
						<InvoiceSelector
							value={
								selectedInvoice?.invoice?.id ??
								selectedInvoice?.invoiceNumber ??
								'new'
							}
							onChange={setInvoice}
							objects={invoices}
							invoicePages={invoicePages}
						/>
						<IconButton
							variant="outlined"
							onClick={onNext}
							disabled={
								invoicePages?.[invoices.length - 1]
									?.invoiceNumber ===
								selectedInvoice?.invoiceNumber
							}
						>
							<ArrowForwardIos />
						</IconButton>
					</Grid>
					<Grid container item xs>
						<Grid item xs="auto">
							<CreditSwitch />
						</Grid>
						<Grid item xs="auto">
							<ClientSwitch
								checked={loadClient}
								onChange={() => setLoadClient(!loadClient)}
							/>
						</Grid>
						<Grid item xs="auto">
							<AutolayoutSwitch
								checked={autoRefreshData}
								onChange={() =>
									setAutoRefreshData(!autoRefreshData)
								}
							/>
						</Grid>
					</Grid>
					<Grid container item xs spacing={2} alignItems="center">
						<Grid item xs={4}>
							<Button fullWidth onClick={openChat}>
								Open chat
							</Button>
						</Grid>
						<Grid container item xs={8}>
							<TemplateSelector
								onAction={onAction}
								onSubmit={onSubmit}
								selectedTemplate={selectedTemplate}
							/>
						</Grid>
					</Grid>
				</Grid>
				<ChatModal
					id={id}
					open={showChatModal}
					onClose={closeChat}
					onSubmit={onSubmit}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
