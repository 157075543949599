import React from 'react';

import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import './index.css';
import './services/adminService';
import './services/authService';
import './services/clientService';
import './services/companyService';
import './services/featureService';
import './services/integrationGateway';
import './services/invoiceService';
import './services/languageService';
import { store } from './store';
import './utils/cache';
import './utils/configuration';
import './utils/http';

const queryClient = new QueryClient();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const theme = createTheme({
	palette: {
		primary: {
			main: '#e7645e',
			light: '#fd8988',
			dark: '#ba000d',
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</QueryClientProvider>
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
);
