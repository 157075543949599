import { addDays, formatISO, parse, parseISO } from 'date-fns';

import { FIELDS } from './constants';
import { Countries } from './countries';

const ZIPCODE_Regex = /^(([a-zA-Z]{1,2})?[- ]*([0-9 -]{4,8}(?![0-9 ])))/;

const cleanNumber = (str, { allowNegative = false } = {}) => {
	let clean = str.toString();
	clean = clean.toString().replace(/ ‚/g, ',');
	clean = clean.toString().replace(/[^0-9,.]/g, '');

	// clean = clean.toString().replace(/ ‚/g, ',');

	if (allowNegative) {
		clean = str.toString().replace(/ ‚/g, ',');
		clean = clean.toString().replace(/[^0-9,.\-]/g, '');
		clean = clean.replace('--', '-');
	}

	let factionPlacementIndex =
		Math.max(clean.lastIndexOf('.'), clean.lastIndexOf(',')) -
		clean.length +
		1;

	if (factionPlacementIndex === -clean.length) {
		// Injext ,
		factionPlacementIndex = null;
	}

	clean = clean.replace(/[.,]/g, '');

	if (factionPlacementIndex !== null) {
		clean =
			clean.slice(0, factionPlacementIndex) +
			'.' +
			clean.slice(factionPlacementIndex);
	}

	return clean;
};

export { cleanNumber };

export async function detectValue(
	{ x1: $x1, x2: $x2, y1: $y1, y2: $y2 } = {},
	srcCanvas,
	scheduler,
	options = { preserve_interword_spaces: '0' },
) {
	const x1 = $x1 * window.devicePixelRatio;
	const x2 = $x2 * window.devicePixelRatio;

	const y1 = $y1 * window.devicePixelRatio;
	const y2 = $y2 * window.devicePixelRatio;

	const width = x2 - x1;
	const height = y2 - y1;

	if (height <= 0 || width <= 0) {
		return '';
	}

	if (!scheduler) {
		return '';
	}

	const resp = await scheduler.addJob('recognize', srcCanvas, {
		rectangle: { top: y1, left: x1, height, width },
		...options,
	});

	return resp?.data;
}

export async function readValue(
	{ name, area, rules = [] },
	{ canvas, scheduler, options },
) {
	// read value from canvas
	const { text } = await detectValue(area, canvas, scheduler, options);

	// apply rules
	return formatField(text, rules, name);
}

export async function readValueWithRules(
	{ name, area },
	{ canvas, getValues, scheduler, options },
) {
	const rules = getValues(`layout.$settings.${name}`);
	// read value from canvas
	const { text } = await detectValue(area, canvas, scheduler, options);

	// apply rules
	return formatField(text, rules, name);
}

export function formatField(value, customRules = [], fieldName) {
	const staticRules =
		FIELDS.find(({ name }) => name === fieldName)?.rules ?? [];
	const rules = [...(customRules ?? []), ...(staticRules ?? [])];

	const originalValue = value ?? '';
	const val = (rules ?? []).reduce((acc, rule) => {
		if (acc === null) {
			return acc;
		}

		try {
			if (rule?.type === 'number') {
				const val = Number.parseFloat(cleanNumber(acc));

				if (Number.isNaN(val)) {
					return acc;
				}

				return val;
			}

			if (rule?.type === 'replace') {
				const source = new RegExp(rule?.source, 'g');
				const target = rule?.target ?? '';

				if (source) {
					return acc.toString().replaceAll(source, target);
				}
			}

			// Betalningsvillkor[ ]?(\d*)
			if (rule?.type === 'plus') {
				const source = new RegExp(rule?.selector, 'gm');
				let target = rule?.target || '$1';
				let value = 0;

				if (source) {
					// const matches = acc.toString().match(source);
					const matches = source.exec(originalValue.toString());
					if (matches && matches.length > 0) {
						for (let i = 0; i < matches.length; i += 1) {
							target = target.replaceAll(`$${i}`, matches[i]);
						}

						value = target;
					} else {
						value = 0;
					}
				}

				if (rule?.datatype === 'date') {
					let days = 30;
					if (!value) {
						days = 30;
					} else {
						days = parseInt(value.replace(/[^0-9]/, ''));
					}

					return formatISO(addDays(parseISO(acc), parseInt(days)), {
						representation: 'date',
					});
				}

				return acc;
			}

			if (rule?.type === 'compare') {
				return new RegExp(rule?.value).test(acc);
			}

			if (rule?.type === 'condition') {
				const source = new RegExp(rule?.value, 'gms');
				let target = rule?.set;
				if (source) {
					// const matches = acc.toString().match(source);
					const matches = source.exec(acc.toString());
					if (matches && matches.length > 0) {
						if (target) {
							for (let i = 0; i < matches.length; i += 1) {
								target = target.replaceAll(`$${i}`, matches[i]);
							}

							return target;
						}

						return acc;
					}

					return null;
				}
			}

			if (rule?.type === 'append') {
				return `${acc}${rule?.value || ''}`;
			}

			if (rule?.type === 'regex') {
				const source = new RegExp(rule?.source, 'gm');
				let target = rule?.target || '$1';

				if (source) {
					// const matches = acc.toString().match(source);
					const matches = source.exec(acc.toString());
					if (matches && matches.length > 0) {
						for (let i = 0; i < matches.length; i += 1) {
							target = target.replaceAll(`$${i}`, matches[i]);
						}

						return target;
					}

					return rule?.default ?? acc;
				}
			}

			if (rule?.type === 'date') {
				const fmt = rule?.format;
				let value = new Date(acc);
				if (fmt) {
					try {
						value = parse(acc, fmt, new Date());
					} catch (err) {
						return acc;
					}
				}

				if (value instanceof Date && !isNaN(value)) {
					return formatISO(value, { representation: 'date' });
				}

				return acc;
			}

			if (rule?.type === 'time') {
				const fmt = rule?.format;
				let value = new Date(acc);

				if (fmt) {
					try {
						value = parse(acc, fmt, new Date());
					} catch (err) {
						return Number.NaN;
					}
				}

				if (value instanceof Date && !isNaN(value)) {
					return formatISO(value, { representation: 'time' });
				}

				return Number.NaN;
			}

			if (rule?.type === 'datetime') {
				const fmt = rule?.format;
				let value = new Date(acc);

				if (fmt) {
					try {
						value = parse(acc, fmt, new Date());
					} catch (err) {
						return Number.NaN;
					}
				}

				return formatISO(value);
			}

			if (rule?.type === 'trim') {
				if (acc?.trim) {
					return acc.trim();
				}
				return acc;
			}

			if (rule?.type === 'substring') {
				const start = !!rule?.start;
				if (start) {
					const newStr = acc.toString();
					return newStr.slice(rule?.start, rule.end || undefined);
				}
				return acc;
			}

			if (rule?.type === 'add') {
				let split = rule?.splitter;
				if (split) {
					split = new RegExp(split, 'gm');
					return acc
						.toString()
						.split(split)
						.reduce((acc, item) => {
							var val = Number.parseFloat(cleanNumber(item));
							if (Number.isNaN(val)) {
								return acc;
							}

							return acc + val;
						}, 0)
						.toString();
				}
			}

			if (rule?.type === 'address' || rule?.type === 'addressWithName') {
				// find zipcode
				let field = rule?.field;
				const parts = acc.split('\n').filter((part) => part);

				const reverseParts = [...parts].reverse();
				let zipCodeIndex = reverseParts.findIndex((part) =>
					ZIPCODE_Regex.test(part),
				);

				if (zipCodeIndex < 0) {
					return acc;
				}

				zipCodeIndex = parts.length - zipCodeIndex - 1;

				if (zipCodeIndex === -1) {
					// Unable to find zipCode, what to do ?
					return acc;
				}

				let street =
					zipCodeIndex === 0
						? '.'
						: parts[Math.max(0, zipCodeIndex - 1)].trim();

				if (rule?.type === 'addressWithName') {
					street =
						zipCodeIndex === 1
							? '.'
							: parts[Math.max(1, zipCodeIndex - 1)].trim();
				}

				let coAddress = '';
				let country = '';
				let name = parts?.[0] ?? '';

				if (zipCodeIndex > 1 && rule?.type === 'address') {
					coAddress = parts
						.slice(0, Math.max(0, zipCodeIndex - 1))
						.join(' ')
						.trim();
				} else if (
					zipCodeIndex > 2 &&
					rule?.type === 'addressWithName'
				) {
					coAddress = parts
						.slice(1, Math.max(1, zipCodeIndex - 1))
						.join(' ')
						.trim();
				}

				const zipMatch = ZIPCODE_Regex.exec(parts[zipCodeIndex]);

				let [zipCode] = zipMatch;
				const city = parts[zipCodeIndex].substring(zipCode.length);
				if (zipCodeIndex < parts.length - 1) {
					country = parts[zipCodeIndex + 1].trim();
				} else {
					country = 'SE';
				}

				zipCode = zipCode.trim();

				if (
					zipCode.startsWith('SE') ||
					zipCode.startsWith('FI') ||
					zipCode.startsWith('NO') ||
					zipCode.startsWith('N-') ||
					zipCode.startsWith('DK')
				) {
					zipCode = zipCode.replace(/[^0-9]/g, '');
				}

				const data = {
					coAddress,
					street,
					zipCode,
					city,
					country,
					name,
				};

				let value = data[field] || '';

				if (field === 'country') {
					value = Countries[value.toLowerCase()] || '';
				}

				return value;
			}
		} catch (e) {
			console.log(e);
			// Do nothing
		}

		return acc;
	}, value ?? '');

	return val ?? '';
}

export function getInvoiceRows(baseline, lines) {
	let invoiceRows = lines.reduce((acc, item) => {
		if (Math.abs(item.baseline.x0 - baseline) < 5) {
			acc.push({ row: item, extra: [] });
		} else {
			acc[acc.length - 1].extra.push(item.text);
		}

		return acc;
	}, []);

	return invoiceRows;
}
