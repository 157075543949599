import React from 'react';

import { Box } from '@mui/material';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function TabContent(props) {
	const { active, name, children } = props;

	return (
		<div
			role="tabpanel"
			hidden={!active}
			id={`batch-tabpanel-${name}`}
			aria-labelledby={`batch-tab-${name}`}
			className="flex-1"
		>
			{active ? (
				<Box sx={{ p: 2 }} className="h-full flex flex-col">
					{children}
				</Box>
			) : null}
		</div>
	);
}

export { TabContent, Transition };
