import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Tab,
	Tabs,
	Toolbar,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { CompanyService } from '@asteria/backend-utils-services';

import { selectToken } from '../../../store/auth';

import GeneralTabContent from './GeneralTab';
import IntegrationTabContent from './IntegrationTab';
import PartnerTabContent from './PartnerTab';
import UserTabContent from './UserTab';

const TabContent = (props) => {
	const { value, index, name, children } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${name}`}
			aria-labelledby={`simple-tab-${name}`}
		>
			{value === index && <Box sx={{ p: 2 }}>{children}</Box>}
		</div>
	);
};

const CompanyDetailsModalContent = (props) => {
	const { id, onClose } = props;

	const [tabIndex, setTabIndex] = React.useState(0);
	const navigate = useNavigate();

	const onChangeTab = React.useCallback(
		(event, newValue) => {
			// Invoice tab
			if (newValue === 4) {
				navigate(`/invoices/${id}/1/2`);
				return;
			}

			// Batch tab
			if (newValue === 5) {
				navigate(`/batches/${id}`);
				return;
			}

			setTabIndex(newValue);
		},
		[id, navigate],
	);

	const accessToken = useSelector(selectToken);

	const { data } = useQuery({
		queryKey: ['companies', id, 'short'],
		queryFn: () =>
			CompanyService.company.fetchOne(
				{
					fields: `
						_id
						name
					`,
					id: id,
				},
				{ token: accessToken },
			),
	});

	return (
		<>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="div"
					>
						{data?.name ?? id}
					</Typography>
					<IconButton
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<Close />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={tabIndex}
						onChange={onChangeTab}
						aria-label="Company tabs"
					>
						<Tab
							label="General"
							id="company-tab-general"
							aria-controls="company-tabpanel-general"
						/>
						<Tab
							label="Users"
							id="company-tab-users"
							aria-controls="company-tabpanel-users"
						/>
						<Tab
							label="Integrations"
							id="company-tab-integrations"
							aria-controls="company-tabpanel-integrations"
						/>
						<Tab
							label="Partners"
							id="company-tab-partners"
							aria-controls="company-tabpanel-partners"
						/>
						<Tab
							label="Invoices"
							id="company-tab-invoices"
							aria-controls="company-tabpanel-invoices"
						/>
						<Tab
							label="Batches"
							id="company-tab-batches"
							aria-controls="company-tabpanel-batches"
						/>
					</Tabs>
				</Box>
				<TabContent value={tabIndex} index={0} name="general">
					<GeneralTabContent id={id} />
				</TabContent>
				<TabContent value={tabIndex} index={1} name="users">
					<UserTabContent id={id} />
				</TabContent>
				<TabContent value={tabIndex} index={2} name="integrations">
					<IntegrationTabContent id={id} />
				</TabContent>
				<TabContent value={tabIndex} index={3} name="partners">
					<PartnerTabContent id={id} />
				</TabContent>
			</DialogContent>
		</>
	);
};

const CompanyDetailsModal = (props) => {
	const { open, onClose } = props;

	return (
		<Dialog open={open} onClose={onClose} fullScreen>
			<CompanyDetailsModalContent {...props} />
		</Dialog>
	);
};

export default CompanyDetailsModal;
