import React from 'react';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Grid,
	Link,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import TemplateSelector from './TemplateSelector';
import ChatModal from './chatModal';
import Wrapper from './fields';
import { InvoiceSelector } from './invoiceSelector';
import { AutolayoutSwitch } from './switches/autolayoutSwitch';
import { ClientSwitch } from './switches/clientSwitch';
import { CreditSwitch } from './switches/creditSwitch';

const SidePane = (props) => {
	const {
		id,
		selectedInvoice,
		setInvoice,
		invoices,
		loadClient,
		setLoadClient,
		autoRefreshData,
		setAutoRefreshData,
		onAction,
		onSubmit,
		url,
		showOCR,
	} = props;

	const [showChatModal, setShowChatModal] = React.useState(false);

	const openChat = React.useCallback(() => {
		setShowChatModal(true);
	}, []);

	const closeChat = React.useCallback(() => {
		setShowChatModal(false);
	}, []);

	return (
		<Grid container px={2} spacing={2} direction="column">
			<Grid item xs>
				<Accordion defaultExpanded>
					<AccordionDetails>
						<Grid container spacing={2} direction="column">
							<Grid item xs>
								<InvoiceSelector
									value={
										selectedInvoice?.invoice?.id ?? 'new'
									}
									onChange={setInvoice}
									objects={invoices}
								/>
							</Grid>
							<Grid container item xs>
								<Grid item xs="auto">
									<CreditSwitch />
								</Grid>
								<Grid item xs="auto">
									<ClientSwitch
										checked={loadClient}
										onChange={() =>
											setLoadClient(!loadClient)
										}
									/>
								</Grid>
								<Grid item xs="auto">
									<AutolayoutSwitch
										checked={autoRefreshData}
										onChange={() =>
											setAutoRefreshData(!autoRefreshData)
										}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs
								spacing={2}
								alignItems="center"
							>
								<Grid item xs={4}>
									<Button fullWidth onClick={openChat}>
										Open chat
									</Button>
								</Grid>
								<Grid container item xs={8}>
									<TemplateSelector
										onAction={onAction}
										onSubmit={onSubmit}
									/>
								</Grid>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs>
				<Accordion defaultExpanded>
					<AccordionDetails>
						<Wrapper />
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs>
				<Accordion>
					<AccordionSummary>
						<Typography>Useful links</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<List>
							<ListItem divider>
								<Link
									href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes"
									target="__blank"
								>
									Country Codes
								</Link>
							</ListItem>
							<ListItem divider>
								<Link
									href={url.replace('_ocr', '')}
									target="__blank"
								>
									Download PDF
								</Link>
							</ListItem>
							<ListItem divider>
								<Link onClick={() => (showOCR.value = false)}>
									Use Selectors
								</Link>
							</ListItem>
						</List>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<ChatModal
				id={id}
				open={showChatModal}
				onClose={closeChat}
				onSubmit={onSubmit}
			/>
		</Grid>
	);
};

SidePane.displayName = 'SidePane';

SidePane.propTypes = { className: PropTypes.string };

export default SidePane;
