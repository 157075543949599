import * as React from 'react';

import * as Ducks from '../ducks';

export const Context = React.createContext({});

export const withContext = (WrappedComponent) => {
	const AuthContextComponent = (props) => {
		const [state, dispatch] = React.useReducer(
			Ducks.auth.reducer,
			Ducks.auth.initialState,
		);

		return (
			<Context.Provider value={{ state: state, dispatch: dispatch }}>
				<WrappedComponent {...props} />
			</Context.Provider>
		);
	};

	return AuthContextComponent;
};
