export const bgPallette = [
	'#6366f1',
	'#22c55e',
	'#f43f5e',
	'#d946ef',
	'#eab308',
	'#f97316',
	'#84cc16',
	'#06b6d4',
	'#3b82f6',
	'#14b8a6',
	'#78716c',
];
