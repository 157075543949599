import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const Form = React.forwardRef((props, ref) => {
	const { children, defaultValues, values, onSubmit, ...form } = props;

	const methods = useForm({ defaultValues: defaultValues, values: values });
	const { handleSubmit } = methods;

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} ref={ref} {...form}>
				{children}
			</form>
		</FormProvider>
	);
});

export default Form;
