import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createClient } from 'graphql-ws';

import AsteriaCore from '@asteria/core';

import { SupportService } from '@asteria/backend-utils-services';

import { selectToken } from '../../../../store/auth';
import { QUERY_CHAT } from '../../constants';
import { formatChat } from '../../utils';

/**
 * @typedef { import('@tanstack/react-query').UndefinedInitialDataOptions } UndefinedInitialDataOptions
 */

/**
 * @param { UndefinedInitialDataOptions & { id: string } } options
 */
export function useChat({ id, ...args }) {
	return useQuery({
		queryKey: ['chats', id],
		queryFn: async () =>
			SupportService.custom({
				query: QUERY_CHAT,
				variables: {
					chatId: id,
					// chatCompanyId: null,
					messagePageFilters: { last: 0, orderField: 'createdAt' },
					// messageFilters: null,
					// messageCompanyId: null,
				},
			}),

		select: (data) => formatChat(data?.response),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		...args,
	});
}

export function useSubscription({ id }) {
	const accessToken = useSelector(selectToken);

	const queryClient = useQueryClient();

	useEffect(() => {
		const url = AsteriaCore.Configuration.getValueAsync({
			key: 'services.supportService.graphqlUri',
		});

		const client = createClient({
			url: url.replace('http://', 'ws://').replace('https://', 'wss://'),
			connectionParams: { Authorization: `Bearer ${accessToken}` },
		});

		const unsubscribe = client.subscribe(
			{
				query: `
					subscription chatMessageCreated(
						$filters: [ChatMessageFilters!]
					) {
						chatMessageCreated(filters: $filters) {
							_id
							companyId
							chatId
							content
							read
							createdBy {
								id
								type
							}
							createdAt
							updatedAt
							deletedAt
						}
					}
				`,
				variables: { filters: { chatId: id } },
			},
			{
				next: () =>
					queryClient.invalidateQueries({
						predicate: (query) =>
							query.queryKey[0] === 'chats' &&
							query.queryKey[1] === id,
					}),
				// eslint-disable-next-line no-console
				complete: console.info,
				// eslint-disable-next-line no-console
				error: console.error,
			},
		);

		return unsubscribe;
	}, [accessToken, id, queryClient]);
}

export function useCreateMutation({ id }) {
	const queryClient = useQueryClient();

	const details = useChat({ id });

	return useMutation({
		mutationFn: async (form) =>
			SupportService.custom({
				query: `
					mutation Request(
						$input: [ChatMessageCreateInput!]!
						$companyId: ObjectId
					) {
						response: createChatMessages(
							input: $input
							companyId: $companyId
						) {
							ok
							error
						}
					}
				`,
				variables: {
					input: { chatId: id, content: form?.message },
					companyId: details?.data?.companyId,
				},
			}),

		onSuccess: () =>
			queryClient.invalidateQueries({
				predicate: (query) =>
					query.queryKey[0] === 'chats' && query.queryKey[1] === id,
			}),
	});
}

export function useUpdateMutation({ id }) {
	const queryClient = useQueryClient();

	const details = useChat({ id });

	return useMutation({
		mutationFn: async (input) =>
			SupportService.custom({
				query: `
					mutation UpdateChatMessages(
						$input: [ChatMessageUpdateInput!]!
						$companyId: ObjectId
					) {
						updateChatMessages(
							input: $input
							companyId: $companyId
						) {
							ok
							error
							data {
								id
								_id
								chatId
							}
						}
					}
				`,
				variables: {
					input: input,
					companyId: details?.data?.companyId,
				},
			}),
		onSuccess: () =>
			queryClient.invalidateQueries({
				predicate: (query) =>
					query.queryKey[0] === 'chats' && query.queryKey[1] === id,
			}),
	});
}
