import React from 'react';

import { useFieldArray, useWatch } from 'react-hook-form';

import { Divider, List, ListItem, ListSubheader } from '@mui/material';

import { Field } from './field';
import { Input } from './input';

const Fields = [
	{
		name: 'invoice.vats.rate',
		label: 'Vat Rates',
		fieldType: 'number',
		isArrayObject: true,
	},
	{
		name: 'invoice.vats.total',
		label: 'Total Ex.Vat',
		fieldType: 'number',
		isArrayObject: true,
	},
	{
		name: 'invoice.vats.vat',
		label: 'Vat Total',
		fieldType: 'number',
		isArrayObject: true,
	},
	{
		name: 'invoice.vats.adjustment',
		label: 'Vat Adjustment',
		fieldType: 'number',
		isArrayObject: true,
	},
];

export const Vats = (props) => {
	useFieldArray({
		name: 'invoice.vats',
	});

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			{Fields.map(({ name, label, fieldType, isArrayObject }) => {
				if (!label) {
					return <Divider />;
				}

				if (!name) {
					return <ListSubheader>{label}</ListSubheader>;
				}

				return (
					<ListItem key={name}>
						<Field
							name={name}
							label={label}
							fieldType={fieldType}
							isArrayObject={isArrayObject}
							{...props}
						/>
					</ListItem>
				);
			})}
		</List>
	);
};
