import React from 'react';

const ListItem = ({ row, rowProps = () => {} }) => {
	return (
		<tr className="hover:bg-gray-100" {...row.getRowProps(rowProps(row))}>
			{row.cells.map((cell) => {
				return (
					<td
						key={cell.id}
						className={cell.column.class}
						{...cell.getCellProps()}
					>
						{cell.value && cell?.value?.type
							? ''
							: cell.render('Cell')}
					</td>
				);
			})}
		</tr>
	);
};

export default ListItem;
