import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { AdminService, PartnerService } from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form/form';
import Input from '@asteria/component-form/input';
import Select, { Option } from '@asteria/component-form/select';
import Modal from '@asteria/component-modal';

import * as Context from '../../context';
import * as Hooks from '../../hooks';

const NewPartnerDialog = ({ isOpen, onSave, onCancel, partnerId }) => {
	const { state } = useContext(Context.auth.Context);
	const [partner, setPartner] = useState(undefined);

	const requestOptions = useMemo(() => {
		return {
			fields: `id name`,
		};
	}, []);

	const { data: themes } = Hooks.request.useRequest({
		handler: AdminService.theme.fetch,
		options: requestOptions,
		context: { token: state.accessToken },
	});

	useEffect(() => {
		if (!partnerId) {
			setPartner(undefined);
			return;
		}

		const fetch = async () => {
			const response = await PartnerService.partner.fetchOne(
				{
					fields: `_id name settings { hosting { domain } themeId }`,
					id: partnerId,
				},
				{ token: state.accessToken },
			);

			setPartner(response);
		};

		fetch();
	}, [partnerId, isOpen]);

	const themeOptions = useMemo(() => {
		if (!themes) {
			return null;
		}

		return themes.map((theme) => (
			<Option value={theme.id} key={theme.id}>
				{theme.name}
			</Option>
		));
	}, [themes]);

	const handleCancel = useCallback(() => {
		onCancel();
	}, [onCancel]);

	const handleSave = useCallback(
		(form) => {
			onSave(form);
		},
		[onSave],
	);

	if (partnerId && !partner) {
		return null;
	}

	return (
		<Modal onClose={handleCancel} open={isOpen}>
			{isOpen && (
				<Form onSubmit={handleSave} defaultValues={partner}>
					<Wrapper className="asteria-component__add-client">
						<Header>
							<Title size="title-5" type="title">
								New partner
							</Title>
						</Header>
						<Content>
							<Input
								id="name"
								name="name"
								label="Name"
								initialfocus
							/>
							<Input
								id="settings.hosting.domain"
								name="settings.hosting.domain"
								label="Domain"
							/>
							<Text>Theme</Text>
							<Select
								className="mt-2"
								name="settings.themeId"
								size="small"
							>
								<Option>Nothing</Option>
								{themeOptions}
							</Select>
						</Content>
						<Footer className="self-end">
							<Button label="Cancel" onClick={handleCancel} />
							<Button
								type="submit"
								variant="primary"
								label="Save"
							/>
						</Footer>
					</Wrapper>
				</Form>
			)}
		</Modal>
	);
};

export default NewPartnerDialog;
