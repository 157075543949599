import React from 'react';

import Message from './message';

const MessageGroup = React.memo(function MessageGroup({ date, messages }) {
	return (
		<div className="flex flex-col gap-8">
			<h3 className="text-center font-medium">{date}</h3>
			<div className="flex flex-col gap-4">
				{messages.map((object) => (
					<Message key={object?._id} {...object} />
				))}
			</div>
		</div>
	);
});

export default MessageGroup;
