export const STAGE_API_URL = 'https://stage-api.asteria.ai/api/v3/';
export const DEV_API_URL = 'https://dev-api.asteria.ai/api/v3/';
export const PROD_API_URL = 'https://prod.api.asteria.ai/api/v3/';
export const LOCAL_API_URL = 'http://localhost:4004/';

export const envURL = {
	STAGE_API_URL: 'https://stage-api.asteria.ai/api/v3/',
	DEV_API_URL: 'https://dev-api.asteria.ai/api/v3/',
	PROD_API_URL: 'https://prod.api.asteria.ai/api/v3/',
	LOCAL_API_URL: 'http://localhost:4004/',
};

export const widgetURL = {
	STAGE_API_URL: 'https://stage-demo.asteria.ai/',
	DEV_API_URL: 'https://dev-demo.asteria.ai/',
	PROD_API_URL: 'https://demo.asteria.ai/',
	LOCAL_API_URL: 'http://localhost:3000/',
};

export const printerURL = {
	STAGE_API_URL: 'https://stage-printer.asteria.ai/',
	DEV_API_URL: 'https://dev-printer.asteria.ai/',
	PROD_API_URL: 'https://printer.asteria.ai/',
	LOCAL_API_URL: 'http://localhost:8000/',
};

export const env = {
	STAGE: 'STAGE',
	DEV: 'DEV',
	PROD: 'PROD',
	LOCAL: 'LOCAL',
};
