import React from 'react';

import { useSelector } from 'react-redux';

import { Person } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { AuthService, PartnerService } from '@asteria/backend-utils-services';

import { selectToken } from '../store/auth';
import Config from '../utils/configuration';

const CashflowModal = (props) => {
	const { user, companyId, onClose, open } = props;

	const accessToken = useSelector(selectToken);

	const { data } = useQuery({
		queryKey: ['partners', 'cashflow'],
		queryFn: () =>
			PartnerService.partner
				.fetch(
					{
						pageFilters: { first: 0 },
						fields: `edges { node { _id name settings { hosting { domain widget } } } }`,
					},
					{ token: accessToken },
				)
				.then((response) =>
					(response?.edges ?? []).map(({ node }) => node),
				),
	});

	const onCashflowOpen = React.useCallback(
		(partner) => {
			return async (event) => {
				const { data: { accessToken: cashflowAccessToken } = {} } =
					await AuthService.auth
						.sendRequest(
							{
								query: `
							mutation Request(
								$username: String!
								$password: String!
								$partnerId: ID
								$code: String
								$companyId: ID
							) {
								login(
									username: $username
									password: $password
									partnerId: $partnerId
									code: $code
									companyId: $companyId
								) {
									ok
									error
									data {
										userId
										accessToken
										refreshToken
										accessTokenExpires
										refreshTokenExpires
										disabled
										revoked
										hasTwoFactor
									}
								}
							}
						`,
								variables: {
									username: user?.username || user?._id,
									password: 'dummy',
									companyId: companyId,
								},
							},
							{ token: accessToken },
						)
						.then(({ login }) => login);

				const widgetEnvUrl = await Config.getValueAsync({
					key: 'widgetUrl',
				});

				const site = partner?.settings?.hosting?.domain ?? 'asteria';
				const widget = partner?.settings?.hosting?.widget ?? 'full';

				const widgetUrl = `${widgetEnvUrl}?token=${cashflowAccessToken}&site=${site}&widget=${widget}`;

				// eslint-disable-next-line no-console
				console.log(widgetUrl);

				window.open(widgetUrl);

				return onClose?.(event);
			};
		},
		[accessToken, companyId, onClose, user?._id, user?.username],
	);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Select partner</DialogTitle>
			<DialogContent>
				<List>
					{(data ?? []).map((object) => (
						<ListItem key={object?._id} disablePadding>
							<ListItemButton onClick={onCashflowOpen(object)}>
								<ListItemIcon>
									<Person />
								</ListItemIcon>
								<ListItemText
									primary={object?.name}
									secondary={[
										object?.settings?.hosting?.domain,
										object?.settings?.hosting?.widget,
									]
										.filter(Boolean)
										.join(' | ')}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CashflowModal;
