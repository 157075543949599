export const dialogTypes = {
	SAVE_TRANSLATIONS: 'saveTranslations',
	SYNC_TRANSLATIONS: 'syncTranslations',
	COMPARE_TRANSLATIONS: 'compareTranslations',
};

export const syncTypes = {
	SAVE: 'save',
	SYNC: 'sync',
};
