import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { blue } from '@mui/material/colors';

import * as Context from '../../context';

const PartnerSelectorDialog = (props) => {
	const { onClose, selectedValue, open } = props;

	const {
		state: { partners },
	} = React.useContext(Context.partner.Context);

	const handleClose = () => {
		onClose?.(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose?.(value);
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle>Select Partner to view User as</DialogTitle>
			<List sx={{ pt: 0 }}>
				{partners.map((partner) => (
					<ListItem disableGutters key={partner._id}>
						<ListItemButton
							onClick={() => handleListItemClick(partner)}
						>
							<ListItemAvatar>
								<Avatar
									sx={{
										bgcolor: blue[100],
										color: blue[600],
									}}
								>
									<PersonIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={partner.name} />
						</ListItemButton>
					</ListItem>
				))}
				<ListItem disableGutters>
					<ListItemButton
						autoFocus
						onClick={() => handleListItemClick('addAccount')}
					>
						<ListItemAvatar>
							<Avatar>
								<AddIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="Add account" />
					</ListItemButton>
				</ListItem>
			</List>
		</Dialog>
	);
};

export default PartnerSelectorDialog;
