import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Diff from 'deep-diff';

import { LanguageService } from '@asteria/backend-utils-services';

import { Title } from '@asteria/component-core/typography';
import Wrapper, { Footer } from '@asteria/component-core/wrapper';

import Error from '../../components/form/Error';
import * as Context from '../../context';
import * as Ducks from '../../ducks';
import * as Hooks from '../../hooks';
import { syncTypes } from '../translations/utils/constants';

import SyncDialogs from './components/SyncDialogs';
import SyncTable from './components/SyncTable';
import TableSettings from './components/TableSettings';
import { showTypes } from './utils/constans';

const Sync = () => {
	const { state: authState } = useContext(Context.auth.Context);
	const { state: translationsState, dispatch: dispatchTranslations } =
		useContext(Context.translations.Context);
	const { state: linkState } = useLocation();

	const [updatedData, setUpdatedData] = useState({});
	const [updatedRows, setUpdatedRows] = useState({});
	const [removedRows, setRemovedRows] = useState({});

	const [tableSettings, setTableSettings] = useState({
		changes: showTypes.SHOW_CHANGES,
	});
	const [dialogSettings, setDialogSettings] = useState({ type: null });
	const navigate = useNavigate();

	const requestOptions = React.useMemo(() => {
		const request = linkState?.request;

		return {
			themeId: request?.themeId,
			partnerId: request?.partnerId,
			language: translationsState?.settings?.code,
			fields: '_id code translations',
			customURI: request?.env,
		};
	}, [linkState?.request, translationsState?.settings?.code]);

	const { data: translation, error: translationError } =
		Hooks.request.useRequest({
			handler: LanguageService.translation.extension.fetchOne,
			options: requestOptions,
			context: { token: authState.accessToken },
		});

	const difference = useMemo(() => {
		const code = translationsState?.settings?.code;
		const translations =
			translationsState?.translations?.[code]?.translations ?? {};

		const storedValue =
			linkState.type === syncTypes.SAVE
				? translations
				: translation?.translations;

		const comparedValue =
			linkState.type === syncTypes.SAVE
				? translation?.translations
				: translations;

		if (!storedValue || !comparedValue || !linkState?.type) {
			return null;
		}

		const diffData = {};
		for (const key in storedValue) {
			diffData[key] = {
				id: key,
				new: storedValue?.[key],
				original: comparedValue?.[key],
			};
		}

		const diferense = Diff.diff(comparedValue ?? {}, storedValue);

		if (!diferense) {
			return diffData;
		}

		diferense
			.filter((item) => item && item.path && item.path[0])
			.filter((item) => !(item.rhs === null && !item.lhs))
			.forEach((item) => {
				diffData[item.path[0]] = {
					id: item.path[0],
					new: item.rhs,
					original: item.lhs,
					kind:
						item.rhs === null || item.rhs === '' ? 'D' : item.kind,
				};
			});

		return diffData;
	}, [
		linkState.type,
		translation?.translations,
		translationsState?.settings?.code,
		translationsState?.translations,
	]);

	const getSelectedRows = useCallback(
		(data) => {
			const updatedRows = [];
			const removedRows = [];

			data.filter((item) => item.kind).forEach((item) => {
				const target = item?.new ?? null;

				if (item.kind === 'D' && target !== null) {
					removedRows.push({
						key: item.id,
						language: translationsState?.settings?.code,
					});
				} else {
					updatedRows.push({
						key: item.id,
						language: translationsState?.settings?.code,
						content: target,
					});
				}
			});

			setUpdatedRows(updatedRows);
			setRemovedRows(removedRows);
		},
		[translationsState?.settings],
	);

	const updateSelected = useCallback(() => {
		const settings = translationsState?.settings;

		const source = translation?.translations ?? {};
		const changes =
			translationsState?.translations?.[settings?.code]?.translations ??
			{};

		for (const item of updatedRows ?? []) {
			source[item.key] = item.content;
			changes[item.key] = item.content;
		}

		for (const item of removedRows ?? []) {
			source[item.key] = null;
			changes[item.key] = null;
		}

		translation.translations = source;

		dispatchTranslations(
			Ducks.translations.updateTranslations({
				[settings?.code]: {
					...translationsState?.translations?.[settings?.code],
					translations: changes,
				},
			}),
		);
	}, [
		dispatchTranslations,
		translation,
		translationsState?.settings,
		translationsState?.translations,
		updatedRows,
		removedRows,
	]);

	const saveData = useCallback(async () => {
		const settings = translationsState?.settings;

		if (updatedRows.length > 0) {
			await LanguageService.translation.extension
				.update(
					{
						input: updatedRows,
					},
					{ token: authState.accessToken },
				)
				.catch((err) => {
					console.log('LanguageService.translation.update', err);
				});
		}

		if (removedRows.length > 0) {
			await LanguageService.translation.extension
				.delete(
					{
						input: removedRows,
					},
					{ token: authState.accessToken },
				)
				.catch((err) => {
					console.log('LanguageService.translation.update', err);
				});
		}

		updateSelected();

		setDialogSettings({ type: null });
	}, [
		translationsState?.settings,
		updatedRows,
		removedRows,
		authState.accessToken,
		updateSelected,
	]);

	const cancel = useCallback(() => {
		navigate('/translations');
		setDialogSettings({ type: null });
	}, [navigate]);

	const discardAll = useCallback(() => {
		dispatchTranslations(
			Ducks.translations.updateTranslations({
				[translationsState?.settings?.code]: {},
			}),
		);
		navigate('/translations');
		setDialogSettings({ type: null });
	}, [dispatchTranslations, navigate, translationsState?.settings?.code]);

	const discardSelected = useCallback(() => {
		const settings = translationsState?.settings;

		const source = translation?.translations ?? {};
		const changes =
			translationsState?.translations?.[settings?.code]?.translations ??
			{};

		for (const key in updatedData ?? {}) {
			if (source[key]) {
				changes[key] = source[key];
			} else {
				delete changes[key];
			}
		}

		translation.translations = source;

		dispatchTranslations(
			Ducks.translations.updateTranslations({
				[settings?.code]: {
					...translationsState?.translations?.[settings?.code],
					translations: changes,
				},
			}),
		);

		setDialogSettings({ type: null });
	}, [
		dispatchTranslations,
		translation,
		translationsState?.settings,
		translationsState?.translations,
		updatedData,
	]);

	const handleAction = useCallback(
		(action, data) => {
			if (action === 'sync:copy') {
				navigator.clipboard
					.writeText(Object.keys(updatedData).join('\n'))
					.then(() => {
						// Alert the user that the action took place.
						// Nobody likes hidden stuff being done under the hood!
						alert('Copied to clipboard');
					});
			}
		},
		[updatedData],
	);

	if (translationError) {
		return <Error error={translationError} />;
	}

	return (
		<>
			<Wrapper className="h-screen">
				<div className="my-2">
					<Title size="title-5" type="title">
						Sync / Save translations
					</Title>
				</div>
				<Wrapper className="my-2" scroll>
					<SyncTable
						data={difference}
						settings={tableSettings}
						getSelectedRows={getSelectedRows}
					/>
				</Wrapper>
				<Footer>
					<TableSettings
						linkState={linkState}
						translationSettings={translationsState?.settings}
						disableSave={!(updatedRows.length + removedRows.length)}
						tableSettings={tableSettings}
						setDialogSettings={setDialogSettings}
						setTableSettings={setTableSettings}
						saveData={saveData}
						onAction={handleAction}
					/>
				</Footer>
			</Wrapper>
			<SyncDialogs
				settings={dialogSettings}
				setDialogSettings={setDialogSettings}
				saveData={saveData}
				discardAll={discardAll}
				discardSelected={discardSelected}
				cancel={cancel}
			/>
		</>
	);
};

export default Sync;
