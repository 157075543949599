import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
	Alert,
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';

import Form from '../../../components/form';

import { useBatch, useBatchUpdate } from './hooks';

function BatchTableRow({ name, label, value, editable, options, loading }) {
	const { register, watch } = useFormContext();

	const formValue = watch(name);

	return (
		<TableRow>
			<TableCell sx={{ height: 80 }}>{label}</TableCell>
			<TableCell sx={{ height: 80 }}>
				{loading ? (
					<Skeleton />
				) : editable ? (
					<TextField
						variant="outlined"
						size="small"
						select={!!options?.length}
						value={formValue}
						fullWidth
						{...register(name)}
					>
						{(options ?? []).map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				) : (
					value
				)}
			</TableCell>
		</TableRow>
	);
}

function BatchTabDetails({ id, companyId }) {
	const query = useBatch({ id, companyId, select: (data) => data?.response });
	const mutation = useBatchUpdate({ id, companyId });

	const rows = [
		{ name: 'id', label: 'ID', value: query?.data?.id },
		{
			name: 'status',
			label: 'Status',
			value: query?.data?.status,
			editable: true,
			options: ['SENT', 'ERROR', 'COMPLETED', 'REMOVED'],
		},
		{
			name: 'serviceId',
			label: 'Service ID',
			value: query?.data?.serviceId,
		},
		{
			name: 'data.batch',
			label: 'Batch No.',
			value: query?.data?.data?.batch,
			editable: true,
		},
		{
			name: 'createdAt',
			label: 'Created at',
			value: query?.data?.createdAt,
		},
		{
			name: 'updatedAt',
			label: 'Updated at',
			value: query?.data?.updatedAt,
		},
	];

	const onSubmit = (form) =>
		mutation.mutate({
			status: form?.status,
			data: { batch: Number.parseInt(form?.data?.batch) },
		});

	if (query.error) {
		return <Alert severity="error">{query.error?.message}</Alert>;
	}

	if (query.isFetching && !query.isRefetching) {
		return <CircularProgress />;
	}

	return (
		<Form values={query.data} onSubmit={onSubmit}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: 300 }}>Field</TableCell>
							<TableCell>Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((args) => (
							<BatchTableRow
								{...args}
								loading={query.isFetching}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				alignItems="center"
				justifyContent="flex-end"
				display="flex"
				py={2}
				gap={2}
			>
				<Button variant="contained" type="submit">
					Save
				</Button>
			</Box>
		</Form>
	);
}

export default BatchTabDetails;
