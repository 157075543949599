import React, { useContext, useEffect, useMemo, useState } from 'react';

import { format, formatISO } from 'date-fns';

import { CompanyService } from '@asteria/backend-utils-services';

import { Circle } from '@asteria/component-core/progress';
import { Title } from '@asteria/component-core/typography';
import Wrapper from '@asteria/component-core/wrapper';

// import PartnerDialogs from './components/dialogs/PartnerDialogs';
import Error from '../../components/form/Error';
import PartnerSelector from '../../components/partner/PartnerSelector';
import * as Context from '../../context';
import * as Hooks from '../../hooks';
import { getNodes } from '../../utils/dataUtils';
import { getStartDate, moveDate } from '../../utils/dateUtils';

import UsageAnalyticsGraph from './components/UsageAnalyticsGraph';
import UsageAnalyticsSettings from './components/UsageAnalyticsSettings';
import UsageAnalyticsStats from './components/UsageAnalyticsStats';
import UsageAnalyticsTable from './components/UsageAnalyticsTable';
import {
	getUsersAnalytics,
	mergeAnalytics,
	mergeAnalyticsByDate,
} from './utils/usageUtils';

const UsageAnalytics = () => {
	const { state } = useContext(Context.auth.Context);
	const [dialogState, setDialogState] = useState({ data: null, type: null });
	const {
		state: { partner },
	} = React.useContext(Context.partner.Context);

	const [settings, setSettings] = useState({
		range: 'WEEK',
		date: new Date(),
	});

	const [pageOptions, setPageOptions] = useState({
		selectedStats: null,
		selector: format(new Date(), 'yyyy-MM-dd'),
	});

	useEffect(() => {
		const date = getStartDate({
			date: settings.date,
			range: settings.range,
		});

		setPageOptions({
			...pageOptions,
			selector: format(date, 'yyyy-MM-dd'),
		});
	}, [settings]);

	const analyticsOptions = useMemo(() => {
		const startDate = getStartDate({
			date: moveDate({
				date: settings.date,
				range: settings.range,
				diff: -5,
			}),
			range: settings.range,
		});

		const endDate = getStartDate({
			date: moveDate({
				date: settings.date,
				range: settings.range,
				diff: 5,
			}),
			range: settings.range,
		});

		return {
			pageFilters: { first: 100, orderField: 'createdAt' },
			filters: { partnerId: partner?._id },
			userPageFilters: { first: 0 },
			userAnalyticsFilter: {
				type: [
					'form.checkbox.change',
					'search',
					'load',
					'button.click',
				],
				createdAt: {
					gte: formatISO(startDate, { representation: 'date' }),
					lte: formatISO(endDate, { representation: 'date' }),
				},
			},
			analyticsRange: settings.range,
		};
	}, [settings.date, settings.range, dialogState]);

	const { data: analytics, error: analyticsError } =
		Hooks.request.usePagination({
			handler: CompanyService.company.extension.fetchUIAnalytics,
			options: analyticsOptions,
			context: { token: state.accessToken },
		});

	const groupedData = useMemo(() => {
		if (!analytics) {
			return [];
		}

		const partnerData = (analytics || []).reduce((acc, object) => {
			const partners = getNodes(object.partners);

			for (const partner of partners) {
				if (acc[partner._id] === undefined) {
					acc[partner._id] = {
						_id: partner._id,
						name: partner.name,
						companies: [],
					};
				}

				acc[partner._id].companies.push(object);
			}

			return acc;
		}, {});

		return Object.values(partnerData).map((object) => {
			const companies = object.companies.map((item) => ({
				...item,
				companyAnalytics: mergeAnalytics({
					data: getUsersAnalytics(item),
					prop: 'firstAction',
				}),
			}));

			return {
				...object,
				partnerAnalytics: mergeAnalytics({
					data: companies,
					prop: 'companyAnalytics',
				}),
				partnerStats: mergeAnalyticsByDate(
					mergeAnalytics({
						data: companies,
						prop: 'companyAnalytics',
					}),
				),
				companies: companies,
			};
		});
	}, [analytics]);

	const filteredAnalytics = useMemo(() => {
		if (!groupedData.length) {
			return [];
		}

		if (!partner?._id) {
			return groupedData;
		}

		return groupedData.filter((item) => item._id === partner?._id);
	}, [groupedData, partner?._id]);

	if (analyticsError) {
		return <Error error={analyticsError} />;
	}

	return (
		<>
			<Wrapper>
				<div className="my-2">
					<Title size="title-5" type="title">
						Usage Analytics
					</Title>
					<div className="flex flex-row gap-2 my-2">
						<PartnerSelector />
						<UsageAnalyticsSettings
							settings={settings}
							setSettings={setSettings}
						/>
					</div>
				</div>
				{analytics === null && analyticsError === null ? (
					<Circle progress={-1} animateOnRender={false} />
				) : (
					<Wrapper>
						<UsageAnalyticsGraph
							data={filteredAnalytics}
							settings={settings}
							setSettings={setSettings}
							pageOptions={pageOptions}
							setPageOptions={setPageOptions}
						/>
						<UsageAnalyticsStats
							data={filteredAnalytics}
							settings={settings}
							pageOptions={pageOptions}
							setPageOptions={setPageOptions}
						/>
						<UsageAnalyticsTable
							settings={settings}
							listData={filteredAnalytics}
							pageOptions={pageOptions}
							setDialogState={setDialogState}
						/>
					</Wrapper>
				)}
			</Wrapper>
			{/* <PartnerDialogs
				dialogState={dialogState}
				setDialogState={setDialogState}
			/> */}
		</>
	);
};

export default UsageAnalytics;
