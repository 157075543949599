import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { TextFields } from '@mui/icons-material';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

export const FormatDate = (props) => {
	const { name, operation } = props;
	const { register } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		name: `${name}.formats`,
	});

	const onAdd = React.useCallback(() => {
		append({ format: '', matcher: '' });
	});

	return (
		<Stack direction="column">
			{fields.map((item, index) => (
				<Stack direction="row">
					<TextField
						id={name}
						label="Static Field Value"
						variant="outlined"
						fullWidth
						size="small"
						{...register(`${name}.formats.${index}.matcher`)}
					/>
					<TextField
						id={name}
						label="Static Field Value"
						variant="outlined"
						fullWidth
						size="small"
						{...register(`${name}.formats.${index}.format`)}
					/>
				</Stack>
			))}
			<Button onClick={onAdd}>Add Format</Button>
		</Stack>
	);
};
