/* eslint-disable indent */
import React from 'react';

import { useSelector } from 'react-redux';

import { ExpandMore, Settings } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Checkbox,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { parseISO, subDays, subMonths, subYears } from 'date-fns';
import * as jose from 'jose';
import { groupBy } from 'lodash';

import {
	AdminService,
	CompanyService,
	IntegrationGateway,
} from '@asteria/backend-utils-services';

import { selectToken } from '../../../store/auth';

async function sendLastSyncRequest({ accessToken, id, lastSync }) {
	return IntegrationGateway.integration.sendRequest(
		{
			query: `
				mutation UpdateIntegrationLastSync(
					$id: ObjectId!
					$lastSync: Date
					$shouldUpdateEntities: Boolean
				) {
					updateIntegrationLastSync(
						id: $id
						lastSync: $lastSync
						shouldUpdateEntities: $shouldUpdateEntities
					) {
						ok
						error
					}
				}
			`,
			variables: {
				id: id,
				lastSync: lastSync,
				shouldUpdateEntities: true,
			},
			headers: { skipSessionToken: true },
		},
		{ token: accessToken },
	);
}

async function updateIntegration({ accessToken, id, input }) {
	return IntegrationGateway.integration.sendRequest(
		{
			query: `
				mutation UpdateIntegration(
					$id: ObjectId!
					$input: IntegrationUpdateInputType!
				) {
					updateIntegration(
						id: $id
						input: $input
					) {
						ok
						error
					}
				}
			`,
			variables: { id: id, input: input },
			headers: { skipSessionToken: true },
		},
		{ token: accessToken },
	);
}

const LastSyncOptions = (props) => {
	const { lastSync, id, companyId, token } = props;

	const client = useQueryClient();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onReset = async () => {
		const date = null;

		await sendLastSyncRequest({
			accessToken: token,
			id: id,
			lastSync: date,
		});

		client.invalidateQueries({
			queryKey: ['companies', companyId, 'integrations'],
			exact: true,
		});

		return handleClose();
	};

	const onDayReset = async () => {
		const date = subDays(parseISO(lastSync), 1).toISOString();

		await sendLastSyncRequest({
			accessToken: token,
			id: id,
			lastSync: date,
		});

		client.invalidateQueries({
			queryKey: ['companies', companyId, 'integrations'],
			exact: true,
		});

		return handleClose();
	};

	const onMonthReset = async () => {
		const date = subMonths(parseISO(lastSync), 1).toISOString();

		await sendLastSyncRequest({
			accessToken: token,
			id: id,
			lastSync: date,
		});

		client.invalidateQueries({
			queryKey: ['companies', companyId, 'integrations'],
			exact: true,
		});

		return handleClose();
	};

	const onYearReset = async () => {
		const date = subYears(parseISO(lastSync), 1).toISOString();

		await sendLastSyncRequest({
			accessToken: token,
			id: id,
			lastSync: date,
		});

		client.invalidateQueries({
			queryKey: ['companies', companyId, 'integrations'],
			exact: true,
		});

		return handleClose();
	};

	return (
		<>
			<IconButton
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				size="small"
			>
				<Settings fontSize="small" />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem onClick={onReset}>Reset</MenuItem>
				<MenuItem onClick={onDayReset}>-1 day</MenuItem>
				<MenuItem onClick={onMonthReset}>-1 month</MenuItem>
				<MenuItem onClick={onYearReset}>-1 year</MenuItem>
			</Menu>
		</>
	);
};

const IntegrationActions = (props) => {
	const { token } = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		event.preventDefault();
		event.stopPropagation();

		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		event.preventDefault();
		event.stopPropagation();

		setAnchorEl(null);
	};

	const onTriggerMLRevenue = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		await AdminService.theme.extension.addJob({
			input: {
				channel: 'cyberdyne.revenue.predict',
				type: 'cyberdyne',
				data: { token: token, scope: { token: token } },
				isLongRunningJob: true,
			},
		});
	};

	const onTriggerMLMerge = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		await AdminService.theme.extension.addJob({
			input: {
				channel: 'cyberdyne.transactions.merge',
				type: 'cyberdyne',
				data: { token: token, scope: { token: token } },
				isLongRunningJob: true,
			},
		});
	};

	const onTriggerMLForecastActions = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		await AdminService.theme.extension.addJob({
			input: {
				channel: 'cyberdyne.guide.actions',
				type: 'cyberdyne',
				data: { token: token, scope: { token: token } },
				isLongRunningJob: true,
			},
		});
	};

	const onTriggerInvoiceTransactions = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		await AdminService.theme.extension.addJob({
			input: {
				channel: 'transactions',
				type: 'invoice-service',
				data: { token: token, scope: { token: token } },
				isLongRunningJob: true,
			},
		});
	};

	const onTriggerVoucherExpenses = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		await AdminService.theme.extension.addJob({
			input: {
				channel: 'expenses',
				type: 'voucher-service',
				data: { token: token, scope: { token: token } },
				isLongRunningJob: true,
			},
		});
	};

	const onTriggerTransactionMerge = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		await AdminService.theme.extension.addJob({
			input: {
				channel: 'merge',
				type: 'transaction-service',
				data: { token: token, scope: { token: token } },
				isLongRunningJob: true,
			},
		});
	};

	return (
		<>
			<IconButton
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				size="small"
			>
				<Settings fontSize="small" />
			</IconButton>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem onClick={onTriggerMLRevenue}>
					Trigger ML (Revenue)
				</MenuItem>
				<MenuItem onClick={onTriggerMLMerge}>
					Trigger ML (Merge)
				</MenuItem>
				<MenuItem onClick={onTriggerMLForecastActions}>
					Trigger ML (Forecast actions)
				</MenuItem>
				<Divider />
				<MenuItem onClick={onTriggerInvoiceTransactions}>
					Trigger Invoice Transactions
				</MenuItem>
				<MenuItem onClick={onTriggerVoucherExpenses}>
					Trigger Voucher Expenses
				</MenuItem>
				<MenuItem onClick={onTriggerTransactionMerge}>
					Trigger Transaction Merge
				</MenuItem>
			</Menu>
		</>
	);
};

const Integration = ({ integration, token }) => {
	const client = useQueryClient();
	const setPrinterConfig = React.useCallback(
		async (field, value) => {
			await updateIntegration({
				accessToken:
					integration?.config?.server?.integrationAccessToken,
				id: integration._id,
				input: {
					config: {
						server: {
							ocr: {
								[field]: value === undefined ? null : value,
							},
						},
					},
				},
			});

			client.invalidateQueries({
				queryKey: ['companies', integration.companyId, 'integrations'],
				exact: true,
			});
		},
		[
			integration._id,
			integration.companyId,
			integration?.config?.server?.integrationAccessToken,
			client,
		],
	);

	return (
		<Accordion key={integration?._id} variant="outlined">
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Typography>
					<IntegrationActions
						token={
							integration?.config?.server?.integrationAccessToken
						}
					/>
					{integration?.key}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<TableContainer>
					<Table>
						<TableHead>
							<TableCell>Key</TableCell>
							<TableCell>Value</TableCell>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>{integration?._id}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Key</TableCell>
								<TableCell>{integration?.key}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Type</TableCell>
								<TableCell>{integration?.type}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Disabled</TableCell>
								<TableCell>
									{integration?.disabled ? 'Yes' : 'No'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Last sync{' '}
									{integration?.lastSync ? (
										<LastSyncOptions
											lastSync={integration?.lastSync}
											companyId={integration?.companyId}
											id={integration?._id}
											token={
												integration?.config?.server
													?.integrationAccessToken
											}
										/>
									) : null}
								</TableCell>
								<TableCell>
									{integration?.lastSync &&
										integration?.lastSync
											.split('T')
											.join(' ')}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Created after</TableCell>
								<TableCell>
									{integration?.createdAfter &&
										integration?.createdAfter
											.split('T')
											.join(' ')}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Status</TableCell>
								<TableCell>
									{integration?.status?.state}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Connected</TableCell>
								<TableCell>
									{integration?.config?.connected
										? 'Yes'
										: 'No'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>SessionID</TableCell>
								<TableCell>
									{
										jose.decodeJwt(
											integration?.config?.server
												?.integrationAccessToken,
										)?.data?.sessionId
									}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Created at</TableCell>
								<TableCell>
									{integration?.createdAt &&
										integration?.createdAt
											.split('T')
											.join(' ')}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Updated at</TableCell>
								<TableCell>
									{integration?.updatedAt &&
										integration?.updatedAt
											.split('T')
											.join(' ')}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				{integration?.key === 'printer' ? (
					<>
						<Typography variant="h6" gutterBottom>
							Printer Configuration
						</Typography>
						<TableContainer>
							<Table>
								<TableHead>
									<TableCell>Setting</TableCell>
									<TableCell>Value</TableCell>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>Force OCR</TableCell>
										<TableCell>
											{
												integration?.config?.server
													?.ocr?.['force-ocr']
											}
											<Checkbox
												checked={
													integration?.config?.server
														?.ocr?.['force-ocr']
												}
												onChange={(e) =>
													setPrinterConfig(
														'force-ocr',
														e.target.checked,
													)
												}
												inputProps={{
													'aria-label': 'controlled',
												}}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											Tesseract Pagesegmode
										</TableCell>
										<TableCell>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={
													integration?.config?.server
														?.ocr?.[
														'tesseract-pagesegmode'
													]
												}
												label="Tesseract Pagesegmode"
												onChange={(e) =>
													setPrinterConfig(
														'tesseract-pagesegmode',
														e.target.value,
													)
												}
											>
												<MenuItem>Default</MenuItem>
												<MenuItem value={'3'}>
													3
												</MenuItem>
												<MenuItem value={'6'}>
													6
												</MenuItem>
												<MenuItem value={'12'}>
													12
												</MenuItem>
											</Select>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</>
				) : null}
			</AccordionDetails>
		</Accordion>
	);
};

const IntegrationGroup = (props) => {
	const { type, objects } = props;

	return (
		<Stack>
			<Typography variant="h6" gutterBottom>
				{type}
			</Typography>

			{objects.length ? (
				objects.map((object) => (
					<Integration integration={object} key={object?._id} />
				))
			) : (
				<Typography>No integrations found</Typography>
			)}
		</Stack>
	);
};

const IntegrationTabContent = (props) => {
	const { id } = props;

	const accessToken = useSelector(selectToken);

	const { data } = useQuery({
		queryKey: ['companies', id, 'integrations'],
		queryFn: () =>
			CompanyService.company
				.fetchOne(
					{
						fields: `
						_id
						integrations {
							_id
							key
							type
							companyId
							disabled
							lastSync
							createdAfter
							status {
								state
							}
							config {
								connected
								server
								errors {
									code
									message
								}
							}
							entity
							createdAt
							updatedAt
						}
					`,
						id: id,
					},
					{ token: accessToken },
				)
				.then((response) => response?.integrations ?? []),
	});

	if (!data?.length) {
		return <Typography>No integrations found</Typography>;
	}

	const groups = groupBy(data ?? [], 'type');

	return (
		<Stack spacing={2}>
			{Object.keys(groups).map((type) => (
				<IntegrationGroup
					type={type}
					companyId={id}
					objects={groups[type]}
				/>
			))}
		</Stack>
	);
};

export default IntegrationTabContent;
