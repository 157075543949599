import { Countries } from './countries';

export const FIELDS = [
	{
		name: 'client.meta.clientNumber',
		label: 'Customer Number',
		required: true,
		order: 1,
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.name',
		label: 'Company Name',
		required: true,
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.info.orgNumber',
		label: 'Org Nummer',
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.info.vatNumber',
		label: 'VAT Nummer',
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.contact.billing.street',
		label: 'Address.StreetAddress',
		required: true,
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.contact.billing.street2',
		label: 'Address.CoAddress',
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.contact.billing.zipcode',
		label: 'Address.ZipCode',
		required: true,
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.contact.billing.city',
		label: 'Address.City',
		required: true,
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.contact.billing.country',
		label: 'Address.CountryCode',
		required: true,
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'client.info.language',
		label: 'Lang (SV, NO, FI, DA, others EN)',
		required: true,
		group: {
			name: 'customer',
			label: 'Customer',
		},
	},
	{
		name: 'invoice.sums.tax',
		label: 'VAT',
		required: true,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		rules: [
			{
				type: 'number',
			},
		],
	},
	{
		name: 'invoice.sums.total',
		label: 'Invoice Total',
		required: true,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		rules: [
			{
				type: 'number',
			},
		],
	},
	{
		name: 'invoice.sums.currency',
		label: 'Currency',
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		required: true,
	},
	{
		name: 'invoice.meta.invoiceNumber',
		label: 'Invoice Number',
		required: true,
		order: 2,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		rules: [
			{
				type: 'replace',
				source: '[^0-9]',
			},
		],
	},
	{
		name: 'invoice.dates.invoiceSent',
		label: 'Invoice Sent Date',
		order: 3,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		required: true,
	},
	{
		name: 'invoice.dates.invoiceDue',
		label: 'Invoice Due Date',
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		required: true,
	},
	{
		name: 'isCredit',
		label: 'Is Credit',
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
	},
	{
		name: 'identifier',
		label: 'Identifer',
	},
	{
		name: 'anchor',
		label: 'Anchor',
	},
	/*
	{
		name: 'info.transactionId',
		label: 'ReferenceInfo.TransactionId',
	},
	{
		name: 'invoice.meta.orderNumber',
		label: 'Invoice Number ( NOT OrderNumber )',
		required: true,
	},
	*/
	/*
	{
		name: 'info.salesDepartment',
		label: 'ReferenceInfo.SalesDepartment',
	},
	{
		name: 'info.referenceText',
		label: 'ReferenceInfo.ReferenceText',
	},
	{
		name: 'info.reservationId',
		label: 'ReferenceInfo.ReservationId',
	},
	
	{
		name: 'test.anchor',
		label: 'Anchor',
		required: true,
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'sums.total',
		label: 'AccountsReceivableAmount.AmountExclVat',
		required: true,
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'sums.total',
		label: 'AccountsReceivableAmount.AmountExclVat',
		required: true,
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'sums.vat',
		label: 'AccountsReceivableAmount.VatAmount',
		required: true,
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		label: 'AccountsReceivableAmount.ProductGroupId',
		name: 'groupId',
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'vatRate',
		label: 'AccountsReceivableAmount.VatRate',
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'vatExemptionCode',
		label: 'AccountsReceivableAmount.VatExemptionCode',
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'articleId',
		label: 'AccountsReceivableAmount.ArticleID',
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'incomePeriod',
		label: 'AccountsReceivableAmount.IncomePeriod',
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'text',
		label: 'AccountsReceivableAmount.Text',
		group: {
			extendable: true,
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'order.dates.invoiceDue',
		label: 'InvoiceServiceOrder.PreferredDueDate',
		group: {
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'order.sums.total',
		label: 'InvoiceServiceOrder.AmountExclVat',
		required: true,
		group: {
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'order.sums.vat',
		label: 'InvoiceServiceOrder.VatAmount',
		required: true,
		group: {
			name: 'order.amounts',
			label: 'Order Amounts',
		},
	},
	{
		name: 'article',
		label: 'Rows.Article',
		group: {
			extendable: true,
			name: 'rows',
			label: 'Invoice Rows',
		},
	},
	{
		name: 'description',
		label: 'Rows.Description',
		group: {
			extendable: true,
			name: 'rows',
			label: 'Invoice Rows',
		},
	},
	{
		name: 'amount',
		label: 'Rows.Amount',
		group: {
			extendable: true,
			name: 'rows',
			label: 'Invoice Rows',
		},
	},
	{
		name: 'units',
		label: 'Rows.Units',
		group: {
			extendable: true,
			name: 'rows',
			label: 'Invoice Rows',
		},
	},
	{
		name: 'price',
		label: 'Rows.Price',
		group: {
			extendable: true,
			name: 'rows',
			label: 'Invoice Rows',
		},
	},
	{
		name: 'discount',
		label: 'Rows.Discount',
		group: {
			extendable: true,
			name: 'rows',
			label: 'Invoice Rows',
		},
	},
	{
		name: 'total',
		label: 'Rows.Total',
		group: {
			extendable: true,
			name: 'rows',
			label: 'Invoice Rows',
		},
	},
	*/
];
