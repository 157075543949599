import React, { useCallback, useMemo } from 'react';

import Button from '@asteria/component-core/button';

import { Option, UncontrolledSelect } from '@asteria/component-form/select';

import { getEnv } from '../../../utils/dataUtils';
import { dialogTypes, showTypes } from '../utils/constans';

const Sync = ({
	linkState,
	translationSettings,
	disableSave,
	setDialogSettings = () => {},
	tableSettings,
	setTableSettings = () => {},
	onAction,
}) => {
	const setSettings = useCallback(
		({ name, value }) => {
			setTableSettings({ ...tableSettings, [name]: value });
		},
		[tableSettings],
	);

	const openCancel = useCallback(() => {
		setDialogSettings({ type: dialogTypes.CANCEL });
	}, []);

	const openApply = useCallback(() => {
		setDialogSettings({ type: dialogTypes.SAVE });
	}, []);

	const openDiscardSelected = useCallback(() => {
		setDialogSettings({ type: dialogTypes.DISCARD_SELECTED });
	}, []);
	const openDiscardAll = useCallback(() => {
		setDialogSettings({ type: dialogTypes.DISCARD_ALL });
	}, []);

	const syncInfo = useMemo(() => {
		const sourceEnv = getEnv(linkState?.request?.env);
		const destinationEnv = getEnv();
		const sourcePartner = linkState?.request?.partnerName;
		const destinationPartner = translationSettings?.partnerName;

		return (
			<p>
				<b>Lang: </b>
				{translationSettings?.code}
				<b> Env: </b>
				{sourceEnv === destinationEnv
					? destinationEnv
					: `${sourceEnv} => ${destinationEnv}`}
				<br />
				<b>Partner: </b>
				{sourcePartner === destinationPartner
					? destinationPartner
					: `${sourcePartner} => ${destinationPartner}`}
			</p>
		);
	}, [linkState]);

	const handleCopy = useCallback(() => onAction?.('sync:copy'), [onAction]);

	return (
		<>
			<div className="flex flex-row gap-2">{syncInfo}</div>
			<UncontrolledSelect
				className="w-48"
				size="small"
				name="changes"
				value={tableSettings.changes}
				onChange={setSettings}
			>
				<Option value={showTypes.ALL}>Show all </Option>
				<Option value={showTypes.SHOW_CHANGES}>Show changes</Option>
				<Option value={showTypes.SHOW_NEW}>Show new </Option>
				<Option value={showTypes.SHOW_EDITED}>Show edited </Option>
				<Option value={showTypes.SHOW_REMOVED}>Show deleted</Option>
			</UncontrolledSelect>
			<div className="flex flex-row gap-2 ml-auto my-auto">
				<Button
					label="Discard selected"
					onClick={openDiscardSelected}
					disabled={disableSave}
				/>
				<Button label="Discard all" onClick={openDiscardAll} />
				<Button
					label="Copy selected"
					onClick={handleCopy}
					disabled={disableSave}
				/>
				<Button label="Cancel" onClick={openCancel} />
				<Button
					variant="primary"
					label="Save"
					disabled={disableSave}
					onClick={openApply}
				/>
			</div>
		</>
	);
};

export default Sync;
