import React from 'react';

import { StarRate } from '@mui/icons-material';
import { Divider, ListItemIcon, MenuItem, Select } from '@mui/material';

const isSaved = (number, invoices) => {
	return invoices.some(
		(item) => item?.invoice?.meta?.invoiceNumber === number,
	);
};

export function InvoiceSelector({
	objects: $objects,
	invoicePages,
	onChange,
	value,
}) {
	const objects = Array.from($objects).sort((a, b) => {
		const source = a?.invoice?.meta?.invoiceNumber ?? '';

		const target = b?.invoice?.meta?.invoiceNumber ?? '';

		if (
			!Number.isNaN(Number.parseInt(source)) &&
			!Number.isNaN(Number.parseInt(target))
		) {
			return source - target;
		}

		return source.localeCompare(target);
	});

	if (invoicePages) {
		return (
			<Select value={value} onChange={onChange} fullWidth size="small">
				<Divider key="divider" />
				{invoicePages.map((invoice, index) => (
					<MenuItem
						key={invoice?.invoiceNumber ?? index}
						value={invoice?.invoiceNumber}
					>
						{invoice?.invoiceNumber}
						{isSaved(invoice?.invoiceNumber, $objects) ? (
							<ListItemIcon>
								<StarRate fontSize="small" />
							</ListItemIcon>
						) : null}
					</MenuItem>
				))}
			</Select>
		);
	}

	return (
		<Select value={value} onChange={onChange} fullWidth size="small">
			<MenuItem value="new">New Invoice</MenuItem>

			{objects.length
				? [
					<Divider key="divider" />,
					...objects.map((invoice) => (
						<MenuItem key={invoice?.id} value={invoice?.id}>
							{invoice?.invoice?.meta?.invoiceNumber}
						</MenuItem>
					)),
				  ]
				: null}
		</Select>
	);
}
