import React from 'react';

import { useSelector } from 'react-redux';

import {
	FormControlLabel,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { CompanyService } from '@asteria/backend-utils-services';

import { selectToken } from '../../../store/auth';

const GeneralTabContent = (props) => {
	const { id } = props;

	const accessToken = useSelector(selectToken);

	const { data } = useQuery({
		queryKey: ['companies', id, 'details'],
		queryFn: () =>
			CompanyService.company.fetchOne(
				{
					fields: `
						_id
						active
						name
						orgnumber
						settings {
							currency
							processPrintAutomaticly
						}
						identifiers {
							bg
							pg
							bic
							iban
						}
						createdAt
						updatedAt
						lastLoggedIn
					`,
					id: id,
				},
				{ token: accessToken },
			),
	});

	const queryClient = useQueryClient();

	const onChangeAutoProcessPrint = React.useCallback(async () => {
		await CompanyService.company.update({
			id,
			input: {
				settings: {
					processPrintAutomaticly:
						!data?.settings?.processPrintAutomaticly,
				},
			},
		});

		queryClient.invalidateQueries('layouts');
	}, [queryClient, id, data]);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableCell>Key</TableCell>
					<TableCell>Value</TableCell>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell>{data?._id}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Active</TableCell>
						<TableCell>{data?.active ? 'Yes' : 'No'}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>{data?.name}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Org number</TableCell>
						<TableCell>{data?.orgnumber}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Currency</TableCell>
						<TableCell>{data?.settings?.currency}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>BG</TableCell>
						<TableCell>{data?.identifiers?.bg}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>PG</TableCell>
						<TableCell>{data?.identifiers?.pg}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>BIC</TableCell>
						<TableCell>{data?.identifiers?.bic}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>IBAN</TableCell>
						<TableCell>{data?.identifiers?.iban}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Process Prints Automaticly</TableCell>
						<TableCell>
							<FormControlLabel
								control={
									<Switch
										checked={
											data?.settings
												?.processPrintAutomaticly
										}
										onChange={onChangeAutoProcessPrint}
									/>
								}
								label="Process Automaticly"
								labelPlacement="end"
								sx={{
									whiteSpace: 'nowrap',
								}}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Created at</TableCell>
						<TableCell>
							{data?.createdAt &&
								data?.createdAt.split('T').join(' ')}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Updated at</TableCell>
						<TableCell>
							{data?.updatedAt &&
								data?.updatedAt.split('T').join(' ')}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>Last login</TableCell>
						<TableCell>
							{data?.lastLoggedIn &&
								data?.lastLoggedIn.split('T').join(' ')}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default GeneralTabContent;
