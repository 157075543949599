import React from 'react';

import { Divider, List, ListItem, ListSubheader } from '@mui/material';

import { AddressField } from './address';
import { Field } from './field';
import { Input } from './input';

const Fields = [
	{
		label: 'Client Information',
	},
	{
		name: 'client.meta.clientNumber',
		label: 'Customer Number',
		required: true,
	},
	{
		name: 'client.name',
		label: 'Company Name',
		required: true,
	},
	{
		name: 'client.info.orgNumber',
		label: 'Org Nummer',
	},
	{
		name: 'client.info.vatNumber',
		label: 'VAT Nummer',
	},
	{},
	{
		label: 'Billing Address',
	},
];

export const Client = (props) => {
	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			{Fields.map(({ name, label }, index) => {
				if (!label) {
					return <Divider key={index} />;
				}

				if (!name) {
					return <ListSubheader key={index}>{label}</ListSubheader>;
				}

				return (
					<ListItem key={name}>
						<Field name={name} label={label} edit {...props} />
					</ListItem>
				);
			})}
			<ListItem>
				<AddressField name="client.contact.billing" {...props} />
			</ListItem>
		</List>
	);
};
