import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
	FormControl,
	InputLabel,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@tanstack/react-query';
import { endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';

import {
	CompanyService,
	InvoiceService,
	PartnerService,
} from '@asteria/backend-utils-services';

import PartnerSelector from '../../../../components/partner/selector';
import { selectToken } from '../../../../store/auth';

const InvoicesTableRow = (props) => {
	const { row, startDate, endDate } = props;
	return (
		<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
			<TableCell>
				<Link
					to={`/invoices/${row.company.id}/${format(
						startDate,
						'yyyy-MM-dd',
					)}/${format(endDate, 'yyyy-MM-dd')}`}
				>
					{row?.company?.name}
				</Link>
			</TableCell>
			<TableCell component="th" scope="row">
				<Link to={`/invoices/${row.company.id}`}>
					{row?.company?.id}
				</Link>
			</TableCell>
			<TableCell component="th" scope="row">
				{row?.count}
			</TableCell>
		</TableRow>
	);
};

const InvoicesStatistics = (props) => {
	const accessToken = useSelector(selectToken);
	const [date, setDate] = React.useState(startOfMonth(new Date()));
	const [partner, setPartner] = React.useState(null);

	const endDate = React.useMemo(() => endOfMonth(date), [date]);
	const { data: layouts = [], isLoading } = useQuery({
		queryKey: ['layout.invoices', date.toString()],
		queryFn: () =>
			InvoiceService.invoice.extension
				.invoiceLayouts(
					{
						status: ['PROCESSED', 'IGNORED'],
						startDate: date,
						endDate: endOfMonth(date),
					},
					{ token: accessToken },
				)
				.then(({ edges }) => edges.map(({ node }) => node)),
	});

	const { data: partners = [] } = useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['companies'],
		queryFn: () =>
			PartnerService.partner
				.fetch(
					{
						fields: `edges { node { id name } }`,
						pageFilters: { first: 0 },
					},
					{ token: accessToken },
				)
				.then(({ edges }) => edges.map(({ node }) => node)),
	});

	const { data: todayLayouts = [] } = useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['layout.invoices', 'today'],
		queryFn: () =>
			InvoiceService.invoice.extension
				.invoiceLayouts(
					{
						status: ['PROCESSED', 'IGNORED'],
						startDate: format(new Date(), 'yyyy-MM-dd'),
					},
					{ token: accessToken },
				)
				.then(({ edges }) => edges.map(({ node }) => node)),
	});

	const stats = React.useMemo(() => {
		return Object.values(
			layouts.reduce(
				(acc, item) => {
					if (!item.company) {
						return acc;
					}

					if (!acc[item.company.id]) {
						acc[item.company.id] = { ...item, count: 0 };
					}

					if (item.invoices && item.invoices.length > 0) {
						acc.total.count += item.invoices.length;
						acc[item.company.id].count =
							acc[item.company.id].count + item.invoices.length;
					} else {
						acc.total.count += 1;
						acc[item.company.id].count += 1;
					}

					return acc;
				},
				{
					total: {
						company: { id: 'Total', name: 'Total' },
						count: 0,
					},
				},
			),
		).sort(({ count: a }, { count: b }) => b - a);
	}, [layouts]);

	const todayStats = React.useMemo(() => {
		return todayLayouts.reduce(
			(acc, item) => {
				if (!item.company) {
					return acc;
				}

				if (!acc[item.company.id]) {
					acc[item.company.id] = { ...item, count: 0 };
				}

				if (item.invoices && item.invoices.length > 0) {
					acc.total.count += item.invoices.length;
					acc[item.company.id].count =
						acc[item.company.id].count + item.invoices.length;
				} else {
					acc.total.count += 1;
					acc[item.company.id].count += 1;
				}

				return acc;
			},
			{
				total: {
					company: { id: 'Total', name: 'Total' },
					count: 0,
				},
			},
		);
	}, [todayLayouts]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<PartnerSelector
				partners={partners}
				onChange={(e, item) => {
					setPartner(item?.id);
				}}
			/>
			<DatePicker
				label="Select Month"
				views={['month', 'year']}
				value={date}
				onChange={setDate}
			/>
			<Typography>{`Today ${
				todayStats?.total?.count ?? 0
			} invoices processed`}</Typography>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Company</TableCell>
							<TableCell>ID</TableCell>
							<TableCell>Invoices</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan="4">
									<Skeleton />
								</TableCell>
							</TableRow>
						) : null}
						{stats.map((row) => (
							<InvoicesTableRow
								key={row.company.id}
								row={row}
								startDate={date}
								endDate={endDate}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

InvoicesStatistics.displayName = 'InvoicesStatistics';

InvoicesStatistics.propTypes = { className: PropTypes.string };

export default InvoicesStatistics;
