import React from 'react';

import { useNavigate } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { formatISO9075 } from 'date-fns';

import {
	AdminUserService,
	CompanyService,
	UserService,
} from '@asteria/backend-utils-services';

import { useChat } from './hooks';

const Header = React.memo(function Header({ id }) {
	const navigate = useNavigate();

	const details = useChat({ id });

	const company = useQuery({
		queryKey: ['chats', 'company', details?.data?.companyId],
		queryFn: async () =>
			CompanyService.company.fetchOne({
				id: details?.data?.companyId,
				fields: '_id name orgnumber',
			}),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		enabled: !!details?.data?.companyId,
	});

	const createdBy = useQuery({
		queryKey: ['chats', 'createdBy', details?.data?.createdBy],
		queryFn: async () => {
			if (details?.data?.createdBy?.type === 'UserToken') {
				return UserService.user.fetchOne({
					id: details?.data?.createdBy?.id,
					fields: '_id firstName lastName username',
				});
			}

			return AdminUserService.adminUser.fetchOne({
				id: details?.data?.createdBy?.id,
				fields: '_id firstName lastName username',
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		enabled: !!details?.data?.createdBy,
	});

	return (
		<>
			<div className="flex items-center justify-start gap-2">
				<IconButton aria-label="back" onClick={() => navigate(-1)}>
					<ChevronLeftIcon />
				</IconButton>
				<h1 className="text-xl">Chat - {details?.data?.name ?? id}</h1>
			</div>
			<div className="grid grid-cols-4 gap-4">
				<Paper
					className={classNames(
						'p-4 flex flex-col items-center justify-center text-center',
						{ 'animate-pulse': company.isFetching },
					)}
				>
					<h4 className="text-sm text-zinc-400">Company</h4>
					<h2 className="text-lg font-medium">
						{[company?.data?.name, company?.data?.orgnumber]
							.filter(Boolean)
							.join(' - ')}
					</h2>
					<h3 className="text-sm font-medium text-zinc-400">
						{company?.data?._id}
					</h3>
				</Paper>

				<Paper
					className={classNames(
						'p-4 flex flex-col items-center justify-center text-center',
						{ 'animate-pulse': createdBy.isFetching },
					)}
				>
					<h4 className="text-sm text-zinc-400">Created by</h4>
					<h2 className="text-lg font-medium">
						{[
							[
								createdBy?.data?.firstName,
								createdBy?.data?.lastName,
							]
								.filter(Boolean)
								.join(' '),
							createdBy?.data?.username,
						]
							.filter(Boolean)
							.join(' - ')}
					</h2>

					<h3 className="text-sm font-medium text-zinc-400">
						{createdBy?.data?._id}
					</h3>
				</Paper>

				<Paper
					className={classNames(
						'p-4 flex flex-col items-center justify-center text-center',
						{ 'animate-pulse': details.isFetching },
					)}
				>
					<h4 className="text-sm text-zinc-400">Status</h4>
					<div className="flex gap-2 items-center">
						<span
							className={classNames('h-2 w-2 rounded-full', {
								'bg-orange-500':
									details?.data?.status === 'PENDING',
								'bg-green-500':
									details?.data?.status === 'RESOLVED',
								'bg-red-500':
									details?.data?.status === 'DELETED',
								'bg-gray-400 ': !details?.data?.status,
							})}
						/>
						<h2 className="text-lg font-medium">
							{details?.data?.status}
						</h2>
					</div>
				</Paper>

				<Paper
					className={classNames(
						'p-4 flex flex-col items-center justify-center text-center',
						{ 'animate-pulse': details?.isFetching },
					)}
				>
					<h4 className="text-sm text-zinc-400">Created at</h4>
					<h2 className="text-lg font-medium">
						{details?.data?.createdAt &&
							formatISO9075(details?.data?.createdAt)}
					</h2>
				</Paper>
			</div>
		</>
	);
});

export default Header;
