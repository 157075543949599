import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import { UncontrolledInput } from '@asteria/component-form/input';

import Table from '../../../components/Table/Table';
import Arrow from '../../../components/core/Arrow';
import { formatInterval, moveDate } from '../../../utils/dateUtils';
import {
	getFirstAction,
	getGeneralUsage,
	getTimeInInterface,
} from '../utils/usageUtils';

const UsageAnalyticsStatsTable = ({ listData, pageOptions, settings }) => {
	const [search, setSearch] = useState('');
	const [input, setInput] = React.useState('');

	const setInputText = useCallback(({ value }) => {
		setInput(value);
	}, []);

	const onSearchIconClick = useCallback(() => {
		setSearch(input);
	}, [input]);

	const selector = formatInterval({
		date: new Date(pageOptions.selector),
		range: settings.range,
	});

	const prevDate = moveDate({
		range: settings.range,
		date: new Date(pageOptions.selector),
		diff: -1,
	});

	const prevSelector = formatInterval({
		date: prevDate,
		range: settings.range,
	});

	const data = useMemo(() => {
		if (pageOptions.selectedStats === 'firstAction') {
			return getFirstAction({
				data: listData,
				selector: selector,
				prevSelector: prevSelector,
			});
		}

		if (pageOptions.selectedStats === 'timeInInterface') {
			return getTimeInInterface({
				data: listData,
				selector: selector,
				prevSelector: prevSelector,
			});
		}

		return getGeneralUsage({
			data: listData,
			selector: selector,
			prevSelector: prevSelector,
		});
	}, [listData, settings, pageOptions]);

	const rowProps = useCallback(() => {
		return {
			className: classNames(
				'hover:bg-gray-100 border-b border-gray-300 h-10',
			),
		};
	}, []);
	const headerProps = useCallback(() => {
		return {
			className: classNames('border-b border-gray-300 h-10'),
		};
	}, []);

	if (listData === null) {
		return null;
	}

	const columns = [
		{
			Header: 'Name',
			accessor: 'name',
			show: pageOptions.selectedStats === 'timeInInterface',
			class: 'text-left min-w-[150px]',
		},
		{
			Header: 'Event',
			accessor: 'event',
			show: pageOptions.selectedStats !== 'timeInInterface',
			class: 'text-left min-w-[150px]',
		},
		{
			Header: 'Click, %',
			accessor: 'percentage',
			show: pageOptions.selectedStats === 'firstAction',
			class: 'text-left min-w-[100px]',
		},
		{
			Header: '+/-',
			accessor: 'diff',
			class: 'text-center w-[80px]',
			Cell: ({ row }) => (
				<Arrow
					value={row.original.diff}
					ending={pageOptions.selectedStats !== null ? 's' : ''}
				/>
			),
		},
		{
			Header: 'Click',
			accessor: 'clicks',
			show: pageOptions.selectedStats === null,
			class: 'text-left w-[80px]',
		},
		{
			Header: 'Time, s',
			accessor: 'time',
			show: pageOptions.selectedStats !== null,
			class: 'text-left w-[80px]',
		},
	].map((value, index) => ({ ...value, id: index }));

	return (
		<div className="mb-8">
			<UncontrolledInput
				icon="magnifier"
				iconPosition="first"
				name="search"
				placeholder="Search... "
				onChange={setInputText}
				onIconClick={onSearchIconClick}
				value={input}
			/>
			<Table
				columns={columns}
				data={data}
				headerProps={headerProps}
				rowProps={rowProps}
				search={search}
			/>
		</div>
	);
};

export default UsageAnalyticsStatsTable;
