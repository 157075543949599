import React from 'react';

import { formatISO } from 'date-fns';

import { useChat, useUpdateMutation } from './hooks';
import MessageGroup from './message-group';
import { getUnreadMessages } from './utils';

const Messages = React.memo(function Messages({ id, wrapperRef }) {
	const details = useChat({ id });

	const update = useUpdateMutation({ id });

	React.useLayoutEffect(() => {
		if (!details.isFetching) {
			const timer = setTimeout(() => {
				wrapperRef?.current?.scrollBy?.({
					top: wrapperRef?.current?.scrollHeight + 100,
					behavior: 'smooth',
				});
			}, 500);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [details.isFetching]);

	const unreadMessages = React.useMemo(
		() => getUnreadMessages(details?.data?.messages),
		[details?.data?.messages],
	);

	const shouldUpdate =
		!!unreadMessages?.length &&
		!details?.isFetching &&
		!details?.isRefetching;

	React.useEffect(() => {
		if (shouldUpdate) {
			update.mutate(
				unreadMessages.map((object) => ({
					id: object?._id,
					read: true,
				})),
			);
		}
	}, [shouldUpdate]);

	if (details.isFetching && !details.isRefetching) {
		return null;
	}

	if (!details?.data?.messages?.length) {
		return (
			<div className="flex items-center justify-center flex-1">
				<h1 className="text-lg font-bold text-center">
					No messages yet
				</h1>
			</div>
		);
	}

	const groups = details?.data?.messages.reduce((acc, object) => {
		const createdAt = object.createdAt;
		const fmt = formatISO(createdAt, { representation: 'date' });

		if (acc[fmt] === undefined) {
			acc[fmt] = [];
		}

		acc[fmt].push(object);

		return acc;
	}, {});

	return Object.entries(groups).map(([date, messages]) => (
		<MessageGroup key={date} date={date} messages={messages} />
	));
});

export default Messages;
