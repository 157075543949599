import React, { useCallback, useEffect, useState } from 'react';

import { format } from 'date-fns';

import AsteriaCore from '@asteria/core';

import {
	CompanyService,
	IntegrationGateway,
	PredictionStorageService,
} from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form';
import Checkbox from '@asteria/component-form/checkbox';
import Modal from '@asteria/component-modal';

import { Context as AuthContext } from '../../context/auth';

const DEFAULT_COMPANY_FIELDS = `
_id
active
name
orgnumber
createdAt
deletedAt
rawPartners

users {
	edges {
		node {
			_id
			username
			rawCompanies
		}
	}
}
partners {
	edges {
		node {
			_id
			name
		}
	}
}
integrations {
	_id
	type
	key
	status {
		state
	}
}`;

const CompanyUsers = (props) => {
	const { objects = [] } = props;

	if (!objects.length) {
		return null;
	}

	return (
		<>
			{objects.map((object) => {
				let { lastLogin } = object;

				if (lastLogin) {
					lastLogin = format(lastLogin, 'yyyy-MM-dd HH:mm:ss');
				}

				return (
					<div key={`user-${object._id}`} className="border-b-2 py-2">
						<TextGroup>
							<Text>ID: {object._id}</Text>
							<Text>Username: {object.username || ' — '}</Text>
							<Text>Last login: {lastLogin || ' — '}</Text>
						</TextGroup>
					</div>
				);
			})}
		</>
	);
};

const CompanyIntegrations = (props) => {
	const { objects = [] } = props;

	if (!objects.length) {
		return null;
	}

	return (
		<>
			{objects.map((object) => {
				return (
					<div
						key={`integration-${object._id}`}
						className="border-b-2 py-2"
					>
						<TextGroup>
							<Text>ID: {object._id}</Text>
							<Text>Key: {object.key}</Text>
							<div className="grid grid-cols-2 gap-1">
								<Text>Type: {object.type}</Text>
								<Text>Status: {object.status.state}</Text>
							</div>
						</TextGroup>
					</div>
				);
			})}
		</>
	);
};

const CompanyDetails = (props) => {
	const { data = {} } = props;

	return (
		<div className='border-b-2 py-2"'>
			<div className="grid grid-cols-2 gap-1">
				<Text>
					<b>ID:</b> {data._id || data.id || ' — '}
				</Text>

				<Text>
					<b>Partner: </b> {data.partner || ' — '}
				</Text>

				<Text>
					<b>Status:</b> {data.active || ' — '}
				</Text>

				<Text>
					<b>Last login:</b> {data.lastLogin || ' — '}
				</Text>

				<Text>
					<b>Org Number:</b> {data.orgnumber || ' — '}
				</Text>

				<Text>
					<b>Address: </b> {data.address || ' — '}
				</Text>

				<Text>
					<b>CompanyID: </b> {data.companyId || ' — '}
				</Text>

				<Text>
					<b>BankID: </b> {data.bankId || ' — '}
				</Text>
			</div>
		</div>
	);
};

const CompanyContent = (props) => {
	const { onClose, onAction, ...company } = props;

	return (
		<Wrapper className="asteria-component__add-client">
			<Header>
				<Title size="title-5" type="title">
					{company.name}
				</Title>
			</Header>
			<Content scroll>
				<CompanyDetails data={company} />

				{company?.users?.length ? (
					<div className="my-4">
						<Text>
							<b>Users</b>
						</Text>
						<CompanyUsers objects={company.users} />
					</div>
				) : null}

				{company?.integrations?.length ? (
					<div className="my-4">
						<Text>
							<b>Integrations</b>
						</Text>
						<CompanyIntegrations objects={company.integrations} />
					</div>
				) : null}
			</Content>
			<Footer className="self-end">
				{/* {company?.integrations?.length ? (
					<Button
						variant="link"
						label="Re-import"
						onClick={() => {
							onAction?.('company:reimport');
						}}
					/>
				) : null} */}

				{!company?.integrations?.length ? (
					<Button
						variant="link"
						label="Re-create"
						onClick={() => {
							onAction?.('company:recreate');
						}}
					/>
				) : null}

				<Button
					variant="link"
					label="Clean"
					onClick={() => {
						onAction?.('company:clean');
					}}
				/>
				<Button
					variant="link"
					label="Run ML"
					onClick={() => {
						onAction?.('company:ml');
					}}
				/>
				<Button variant="primary" label="Close" onClick={onClose} />
			</Footer>
		</Wrapper>
	);
};

const CompanyCleanContent = (props) => {
	const { onClose, onAction, ...company } = props;

	const { state } = React.useContext(AuthContext);
	const [isLoading, setLoading] = React.useState(false);

	const handleClean = useCallback(() => {
		setLoading(true);

		CompanyService.company.extension
			.clean(
				{ filters: { _id: company?._id } },
				{ token: state?.accessToken },
			)
			.then(() => onAction?.('company:refresh'))
			.then(() => onClose?.())
			.catch((err) => console.error(err))
			.then(() => setLoading(false));
	}, [onClose, onAction, state?.accessToken, company?._id]);

	return (
		<Wrapper className="asteria-component__add-client">
			<Header>
				<Title size="title-5" type="title">
					{company.name}
				</Title>
			</Header>
			<Content scroll>
				<Text>Are you sure that you wanna clean company data?</Text>
			</Content>
			<Footer className="self-end">
				<Button variant="link" label="Back" onClick={onClose} />
				<Button
					variant="primary"
					label="Clean"
					onClick={handleClean}
					loading={isLoading}
				/>
			</Footer>
		</Wrapper>
	);
};

const CompanyRecreateContent = (props) => {
	const { onClose, onAction, ...company } = props;

	const { state } = React.useContext(AuthContext);
	const [isLoading, setLoading] = React.useState(false);

	const handleSubmit = useCallback(
		async (form) => {
			let integrations = [];

			const code = [company._id, new Date().toISOString()].join('.');

			if (form.testIntegration) {
				integrations.push({
					key: 'testIntegration',
					type: 'erp',
					config: {
						client: {
							authorizationCode: code,
							useCurrency: true,
						},
					},
				});
			}

			if (form.asteriaBank) {
				integrations.push({
					key: 'asteriaBank',
					type: 'bank',
					config: {
						client: {
							authorizationCode: code,
							useCurrency: true,
						},
					},
				});
			}

			if (!integrations.length) {
				return onClose?.();
			}

			setLoading(true);

			for (const input of integrations) {
				await IntegrationGateway.integration.extension
					.create(
						{ input: input, companyId: company._id },
						{ token: state?.accessToken },
					)
					.then(() => AsteriaCore.utils.sleep(1000))
					.catch((err) => console.error(err));
			}

			onAction?.('company:refresh');
			onClose?.();
			setLoading(false);
		},
		[onClose, onAction, state?.accessToken, company?._id],
	);

	return (
		<Wrapper className="asteria-component__add-client">
			<Header>
				<Title size="title-5" type="title">
					{company.name}
				</Title>
			</Header>
			<Form
				defaultValues={{ testIntegration: true, asteriaBank: true }}
				onSubmit={handleSubmit}
			>
				<Content scroll>
					<Text>
						What type of integrations do you want to create?
					</Text>
					<Checkbox
						iconPosition="first"
						label="Asteria ERP"
						name="testIntegration"
					/>
					<Checkbox
						iconPosition="first"
						label="Asteria Bank"
						name="asteriaBank"
					/>
				</Content>
				<Footer className="self-end">
					<Button variant="link" label="Back" onClick={onClose} />
					<Button
						variant="primary"
						label="Submit"
						type="submit"
						loading={isLoading}
					/>
				</Footer>
			</Form>
		</Wrapper>
	);
};

function formatCompany(data) {
	const users = (data?.users?.edges ?? []).map(({ node }) => node);
	const partners = (data?.partners?.edges ?? []).map(({ node }) => node);

	return {
		...data,
		users: users,
		partners: partners,
		partner: partners?.[0]?.name,
	};
}

const CompanyInfo = (props) => {
	const { companyData, showDialog, onClose } = props;

	const [company, setCompany] = useState(companyData);

	useEffect(() => {
		setCompany(companyData);
	}, [companyData]);

	const { state } = React.useContext(AuthContext);
	const [step, setStep] = useState(null);

	const handleClose = useCallback(() => onClose?.(), [onClose]);

	const handleAction = useCallback(
		(action) => {
			if (action === 'company:reimport') {
				setStep('reimport');
			}

			if (action === 'company:recreate') {
				setStep('recreate');
			}

			if (action === 'company:clean') {
				setStep('clean');
			}

			if (action === 'company:refresh') {
				CompanyService.company
					.fetchOne(
						{ id: company?._id, fields: DEFAULT_COMPANY_FIELDS },
						{ token: state?.accessToken },
					)
					.then(formatCompany)
					.then((data) => setCompany(data));
			}

			if (action === 'company:ml') {
				PredictionStorageService.prediction.extension.run(
					{ id: company?._id },
					{ token: state?.accessToken },
				);
			}

			if (action === 'back') {
				setStep(null);
			}
		},
		[state?.accessToken, company?._id],
	);

	const handleBack = useCallback(() => {
		handleAction('back');
	}, [handleAction]);

	if (!company) {
		return null;
	}

	let content = null;

	switch (step) {
		case 'reimport':
			break;

		case 'recreate':
			content = (
				<CompanyRecreateContent
					{...company}
					onClose={handleBack}
					onAction={handleAction}
				/>
			);
			break;

		case 'clean':
			content = (
				<CompanyCleanContent
					{...company}
					onClose={handleBack}
					onAction={handleAction}
				/>
			);

			break;

		default:
			content = (
				<CompanyContent
					{...company}
					onClose={handleClose}
					onAction={handleAction}
				/>
			);
	}

	return (
		<Modal
			onClose={handleClose}
			open={showDialog}
			size={step === null ? 'large' : undefined}
		>
			{content}
		</Modal>
	);
};

export default CompanyInfo;
