import React, { useMemo } from 'react';

import BarChart from './BarChart';
import LineChart from './LineChart';

const DataLineChart = ({
	data,
	config,
	type,
	customTooltip,
	customLegend,
	pageOptions,
	setPageOptions = () => {},
}) => {
	const chartItems = useMemo(() => {
		switch (type) {
			case 'bar':
				return (
					<BarChart
						data={data}
						config={config}
						pageOptions={pageOptions}
						setPageOptions={setPageOptions}
						customTooltip={customTooltip}
						customLegend={customLegend}
					/>
				);
			default:
				return (
					<LineChart
						data={data}
						config={config}
						pageOptions={pageOptions}
						setPageOptions={setPageOptions}
						customTooltip={customTooltip}
						customLegend={customLegend}
					/>
				);
		}
	}, [type, data, pageOptions]);

	return chartItems;
};

export default DataLineChart;
