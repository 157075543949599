import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

export const SetField = (props) => {
	const { name, operation } = props;
	const { register } = useFormContext();

	return (
		<Stack>
			<TextField
				id={name}
				label="Static Field Value"
				variant="outlined"
				fullWidth
				size="small"
				{...register(`${name}.value`)}
			/>
		</Stack>
	);
};
