import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	user: null,
	accessToken: localStorage.getItem('accessToken'),
	refreshToken: localStorage.getItem('refreshToken'),
	isAuthenticated: localStorage.getItem('accessToken') !== null,
};

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: () => {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('refreshToken');

			return {
				user: null,
				accessToken: null,
				refreshToken: null,
				isAuthenticated: false,
			};
		},
		login: (state, action) => {
			localStorage.setItem('accessToken', action.payload.accessToken);
			localStorage.setItem('refreshToken', action.payload.refreshToken);
			state.user = null;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			state.isAuthenticated = true;
		},
		setAdminUser: (state, action) => {
			state.user = action.payload;
		},
	},
});

export const { logout, login, setAdminUser } = slice.actions;
export default slice.reducer;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.accessToken;
