import React, { useMemo } from 'react';

import Arrow from '../../../components/core/Arrow';
import CircleMarker from '../../../components/core/CircleMarker';
import { bgPallette } from '../../../theme/palette';

const CustomTooltip = ({ active, payload, label }) => {
	const tooltipItems = useMemo(() => {
		if (!payload.length) {
			return null;
		}

		return payload[0].payload.tooltipData.map((item, index) => {
			const color =
				index < bgPallette.length
					? bgPallette[index]
					: bgPallette[bgPallette.length - 1];
			return (
				<div
					key={item.id}
					className="grid gap-1 grid-cols-[auto_3fr_1fr_80px]"
				>
					<CircleMarker color={color} />
					<p>{item.name}</p>
					<p className="text-right">{`${item.time}s`}</p>
					<Arrow value={item.diff} ending="s" />
				</div>
			);
		});
	}, [active, payload, label]);

	if (active && payload && payload.length) {
		return (
			<div className="bg-white p-4 border border-gray-300 w-72">
				<div className="pb-2">
					<p className="label">{label}</p>
				</div>
				<div className="grid gap-2">{tooltipItems}</div>
			</div>
		);
	}

	return null;
};

export default CustomTooltip;
