import AsteriaCore from '@asteria/core';

import { AdminService } from '@asteria/backend-utils-services';

AdminService.theme.extend({
	getQuery: () => `
		mutation AddProcessPrintJob($id: ID!) {
			addProcessPrintJob(id: $id)
		}
	`,
	key: `addProcessPrintJob`,
	loggerMethod: `services.adminService.addProcessPrintJob`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.adminService.addProcessPrintJob`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

AdminService.theme.extend({
	getQuery: () => `
		mutation AddJob($input: QueueAddJobInput!) {
			addJob(input: $input)
		}
	`,
	key: `addJob`,
	loggerMethod: `services.adminService.addJob`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.adminService.addJob`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});
