import React from 'react';

import { FIELDS } from './constants';

export function useFields() {
	return React.useMemo(() => {
		const fields = [...FIELDS];
		return fields
			.sort((a, b) => (a?.order ?? 99) - (b?.order ?? 99))
			.reduce((acc, object) => {
				if (object.group) {
					const name = object?.group?.name;

					if (!acc[name]) {
						acc[name] = { ...object.group, fields: [] };
					}

					acc[name].fields = acc[name].fields.concat(object);

					return acc;
				}

				/*
					if (object.required) {
						return {
							...acc,
							required: {
								...acc.required,
								fields: acc.required.fields.concat(object),
							},
						};
					}
					*/

				return acc;
			}, {});
	}, []);
}

export function useReactDraw({ active, canvasRef, backupRef, onRect }) {
	const rectRef = React.useRef(null);
	React.useEffect(() => {
		/**
		 * @type { HTMLCanvasElement }
		 */
		const source = canvasRef.current;

		/**
		 * @type { HTMLCanvasElement }
		 */
		const backup = backupRef.current;

		function handleMouseDown(event) {
			const zoom = window.devicePixelRatio;

			const cursorX = event.clientX;
			const cursorY = event.clientY;

			const { x: areaX, y: areaY } = source.getBoundingClientRect();

			const x = cursorX - areaX;
			const y = cursorY - areaY;

			rectRef.current = { x: x, y: y, zoom: zoom };

			const backupCtx = backup.getContext('2d');
			backupCtx.drawImage(source, 0, 0);
		}

		function handleMouseUp(event) {
			const cursorX = event.clientX;
			const cursorY = event.clientY;

			const {
				x: areaX,
				y: areaY,
				width: areaWidth,
				height: areaHeight,
			} = source.getBoundingClientRect();

			const x = cursorX - areaX;
			const y = cursorY - areaY;

			const { x: prevX, y: prevY } = rectRef.current;

			const sourceCtx = source.getContext('2d');

			sourceCtx.drawImage(backup, 0, 0);

			const x1 = Math.min(prevX, x);
			const x2 = x1 + Math.abs(x - prevX);

			const y1 = Math.min(prevY, y);
			const y2 = y1 + Math.abs(y - prevY);

			onRect?.({ x1, x2, y1, y2, areaWidth, areaHeight });

			rectRef.current = null;
		}

		function handleMouseMove(event) {
			if (!rectRef.current) {
				return;
			}

			// eslint-disable-next-line no-console
			// console.log('mousemove', event);

			// const ctx = node.getContext('2d');

			const cursorX = event.clientX;
			const cursorY = event.clientY;

			const { x: areaX, y: areaY } = source.getBoundingClientRect();

			const x = cursorX - areaX;
			const y = cursorY - areaY;

			const { x: prevX, y: prevY, zoom } = rectRef.current;

			const sourceCtx = source.getContext('2d');

			sourceCtx.reset();
			sourceCtx.drawImage(backup, 0, 0);

			sourceCtx.save();

			sourceCtx.globalAlpha = 0.1;
			sourceCtx.fillStyle = '#EE8F8B';

			sourceCtx.rect(
				Math.min(prevX, x) * zoom,
				Math.min(prevY, y) * zoom,
				Math.abs(x - prevX) * zoom,
				Math.abs(y - prevY) * zoom,
			);

			sourceCtx.fill();

			sourceCtx.restore();

			sourceCtx.strokeStyle = '#EE8F8B';
			sourceCtx.lineWidth = 1;
			// sourceCtx.lineCap = 'round';
			// sourceCtx.lineJoin = 'round';

			sourceCtx.stroke();

			sourceCtx.restore();
		}

		if (active) {
			backup.width = source.width;
			backup.height = source.height;

			source.addEventListener('mousedown', handleMouseDown);
			source.addEventListener('mouseup', handleMouseUp);
			source.addEventListener('mousemove', handleMouseMove);

			return () => {
				source.removeEventListener('mousedown', handleMouseDown);
				source.removeEventListener('mouseup', handleMouseUp);
				source.removeEventListener('mousemove', handleMouseMove);
			};
		}
	}, [active, canvasRef, backupRef, onRect]);
}
