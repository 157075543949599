import React, { useCallback } from 'react';

import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import { dialogTypes } from '../utils/constans';

const SyncDialogs = ({
	settings,
	setDialogSettings = () => {},
	saveData = () => {},
	discardAll = () => {},
	discardSelected = () => {},
	cancel = () => {},
}) => {
	const closeDialog = useCallback(() => {
		setDialogSettings({ type: null });
	}, []);

	return (
		<>
			<ConfirmationDialog
				title="Save changes?"
				subtitle="IMPORTANT: Only marked translations will be updated"
				isOpen={settings.type === dialogTypes.SAVE}
				onConfirm={saveData}
				onCancel={closeDialog}
			/>
			<ConfirmationDialog
				title="Back to translations?"
				subtitle="You will be redirected to the translations page"
				isOpen={settings.type === dialogTypes.CANCEL}
				onConfirm={cancel}
				onCancel={closeDialog}
			/>
			<ConfirmationDialog
				title="Discard changes?"
				subtitle="You will be redirected to the translations page"
				isOpen={settings.type === dialogTypes.DISCARD_ALL}
				onConfirm={discardAll}
				onCancel={closeDialog}
			/>
			<ConfirmationDialog
				title="Discard selected?"
				isOpen={settings.type === dialogTypes.DISCARD_SELECTED}
				onConfirm={discardSelected}
				onCancel={closeDialog}
			/>
		</>
	);
};

export default SyncDialogs;
