import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import SaveIcon from '@mui/icons-material/Save';
import { LinearProgress, MenuItem, Select, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGrid } from '@mui/x-data-grid';

import { LanguageService } from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';
import { envURL } from '../../utils/constants';

function diff(obj1, obj2) {
	const keys = [...new Set(Object.keys(obj1).concat(Object.keys(obj2)))];
	return keys
		.filter((key) => {
			return obj1[key] !== obj2[key];
		})
		.map((key) => ({ id: key, key, local: obj1[key], remote: obj2[key] }));
}

const TranslationsSync = (props) => {
	const { language, settings, onClose } = props;
	const [env, setEnv] = useState(null);
	const [local, setLocal] = useState(null);
	const [remote, setRemote] = useState(null);
	const [selected, setSelected] = useState([]);

	const accessToken = useSelector(selectToken);

	useEffect(() => {
		const fetch = async () => {
			const data = await LanguageService.translation.extension.fetch({
				fields: '_id code translations',
				partnerId: settings?.partnerId,
			});

			setLocal(data);
		};

		fetch();
	}, [accessToken, settings]);

	useEffect(() => {
		if (!env) {
			return;
		}

		const fetch = async () => {
			const data = await LanguageService.translation.extension.fetch({
				fields: '_id code translations',
				partnerId: settings?.partnerId,
				customURI: env,
			});

			setRemote(data);
		};

		fetch();
	}, [accessToken, env, settings]);

	const languages = useMemo(() => {
		if (!local || !remote) {
			return [];
		}

		const languages = [
			...new Set([
				...local.map(({ code }) => code),
				...remote.map(({ code }) => code),
			]),
		];

		return languages;
	}, [local, remote]);

	const difference = useMemo(() => {
		if (!local || !remote || !languages) {
			return [];
		}

		return languages.reduce((acc, language) => {
			const localLang = local.find(({ code }) => code === language) ?? {
				translations: {},
			};
			const remoteLang = remote.find(({ code }) => code === language) ?? {
				translations: {},
			};

			const remoteKeys = Object.keys(remoteLang.translations);
			const keys = Object.keys(localLang.translations).filter(
				(item) => !remoteKeys.includes(item),
			);

			console.log(remoteLang.translations);

			acc[language] = diff(
				localLang.translations,
				remoteLang.translations,
			);

			return acc;
		}, {});
	}, [languages, local, remote]);

	const onSync = useCallback(async () => {
		const remoteLang = remote.find(({ code }) => code === language) ?? {
			translations: {},
		};

		const translationsUpdate = selected.map((key) => ({
			key: key,
			language: language,
			content: remoteLang?.translations?.[key],
		}));

		await LanguageService.translation.extension
			.update(
				{
					input: translationsUpdate,
				},
				{ token: accessToken },
			)
			.catch((err) => {
				console.log('LanguageService.translation.update', err);
			});

		onClose?.();
	}, [accessToken, onClose, language, remote, selected]);

	const isLoading = !local || !remote || !languages;

	return (
		<div className="h-screen flex flex-col">
			<Typography variant="h2" gutterBottom>
				{`Sync Translations ${language}`}
			</Typography>
			<Select
				labelId="demo-simple-select-label"
				label="Environment"
				onChange={(e) => {
					setRemote(null);
					setEnv(envURL[e.target.value]);
				}}
			>
				<MenuItem value={'DEV_API_URL'}>Dev</MenuItem>
				<MenuItem value={'STAGE_API_URL'}>Stage</MenuItem>
				<MenuItem value={'PROD_API_URL'}>Production</MenuItem>
			</Select>
			{isLoading && env && <LinearProgress />}
			<DataGrid
				showQuickFilter
				checkboxSelection
				columns={[
					{
						field: 'key',
						headerName: 'Translation Key',
						flex: 1,
					},
					{
						field: 'local',
						headerName: 'Local',
						flex: 1,
					},
					{
						field: 'remote',
						headerName: 'Remote',
						flex: 1,
					},
				]}
				onRowSelectionModelChange={(e) => setSelected(e)}
				rows={difference?.[language] ?? []}
			/>
			<div className="py-8">
				<Stack spacing={2} direction="row" justifyContent="flex-end">
					<Button variant="contained" onClick={onClose}>
						Back
					</Button>
					<Button
						variant="contained"
						startIcon={<SaveIcon />}
						onClick={onSync}
					>
						Save
					</Button>
				</Stack>
			</div>
		</div>
	);
};

export default TranslationsSync;
