import React from 'react';

import {
	useFieldArray,
	useForm,
	useFormContext,
	useWatch,
} from 'react-hook-form';

import {
	Button,
	Divider,
	List,
	ListSubheader,
	Stack,
	TextField,
} from '@mui/material';

const Replacers = (props) => {
	const { index } = props;

	const { register } = useFormContext();

	return (
		<Stack>
			<TextField
				label="Replace"
				variant="outlined"
				{...register(
					`layout.configuration.pages.replace.${index}.selector`,
				)}
			/>
			<TextField
				label="Replace"
				variant="outlined"
				{...register(
					`layout.configuration.pages.replace.${index}.result`,
				)}
			/>
		</Stack>
	);
};

export const Settings = (props) => {
	const { register } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		name: 'layout.configuration.pages.splitter',
	});

	const onAdd = React.useCallback(() => {
		append({ selector: '' });
	}, [append]);

	const { fields: expanders, append: appendExpander } = useFieldArray({
		name: 'layout.configuration.pages.expander',
	});

	const onAddExpander = React.useCallback(() => {
		appendExpander({ selector: '' });
	}, [appendExpander]);

	const { fields: replacers, append: appendReplacer } = useFieldArray({
		name: 'layout.configuration.pages.replace',
	});

	const onAddReplacer = React.useCallback(() => {
		appendReplacer({ selector: '', result: '' });
	}, [appendReplacer]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<TextField
				label="Invoice Page Selector"
				variant="outlined"
				{...register(`layout.configuration.pages.selector`)}
			/>
			<Divider />
			<ListSubheader>Splitters</ListSubheader>
			{fields.map((field, index) => (
				<TextField
					key={field.id}
					label="Page Text Splitter"
					variant="outlined"
					{...register(
						`layout.configuration.pages.splitter.${index}.selector`,
					)}
				/>
			))}
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new
			</Button>
			<Divider />
			<ListSubheader>Replacers</ListSubheader>
			{replacers.map((field, index) => (
				<Replacers key={field.id} index={index} />
			))}
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAddReplacer}
			>
				Add new replacer
			</Button>
			<Divider />
			<ListSubheader>Expanders</ListSubheader>
			{expanders.map((field, index) => (
				<TextField
					key={field.id}
					label="Page Text Expander"
					variant="outlined"
					{...register(
						`layout.configuration.pages.expander.${index}.selector`,
					)}
				/>
			))}
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAddExpander}
			>
				Add new
			</Button>
		</List>
	);
};
