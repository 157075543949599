export const dialogTypes = {
	SAVE: 'saveSync',
	CANCEL: 'cancelSync',
	DISCARD_ALL: 'discardAllSync',
	DISCARD_SELECTED: 'discardSelectedSync',
};

export const showTypes = {
	ALL: 'all',
	SHOW_CHANGES: 'showChanges',
	SHOW_NEW: 'showNew',
	SHOW_REMOVED: 'showRemoved',
	SHOW_EDITED: 'showEdited',
};
