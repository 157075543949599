import React from 'react';

import { DataGrid } from '@mui/x-data-grid';

import { useInvoices } from './hooks';

function BatchTabInvoices({ id, companyId }) {
	const { query, data } = useInvoices({
		id,
		companyId,
		fields: `
			pageInfo { count }
			edges {
				node {
					_id
					type
					meta {
						invoiceNumber
						clientNumber
					}
					sums {
						original {
							total
							tax
							currency
						}
					}
				}
			}
		`,
	});

	return (
		<DataGrid
			columns={[
				{ field: 'invoiceId', headerName: 'ID', flex: 1 },
				{ field: 'status', headerName: 'Status' },
				{
					field: 'db.type',
					headerName: 'Type',
					valueGetter: ({ row }) => row?.db?.type,
				},
				{
					field: 'db.meta.invoiceNumber',
					headerName: 'No.',
					valueGetter: ({ row }) => row?.db?.meta?.invoiceNumber,
					width: 140,
				},
				{
					field: 'db.meta.clientNumber',
					headerName: 'Client No.',
					valueGetter: ({ row }) => row?.db?.meta?.clientNumber,
					width: 140,
				},
				{
					field: 'db.sums.original.currency',
					headerName: 'Currency',
					valueGetter: ({ row }) => row?.db?.sums?.original?.currency,
				},
				{
					field: 'db.sums.original.tax',
					headerName: 'Tax',
					valueGetter: ({ row }) => row?.db?.sums?.original?.tax,
					type: 'number',
				},
				{
					field: 'db.sums.original.total',
					headerName: 'Total',
					valueGetter: ({ row }) => row?.db?.sums?.original?.total,
					type: 'number',
				},
				{
					field: 'errors',
					headerName: 'Errors',
					valueGetter: ({ row }) =>
						row?.errors?.length
							? JSON.stringify(row?.errors)
							: null,
					flex: 1,
				},
			]}
			rows={data}
			getRowId={({ invoiceId }) => invoiceId}
			loading={query.isFetching}
		/>
	);
}

export default BatchTabInvoices;
