import React from 'react';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

const ConfirmationDialog = ({
	isOpen,
	onConfirm = () => {},
	onCancel = () => {},
	title,
	subtitle,
}) => {
	return (
		<Modal onClose={onCancel} open={isOpen}>
			<Wrapper className="asteria-component__add-client">
				<Header>
					<Title size="title-5" type="title">
						{title}
					</Title>
				</Header>
				<Content>
					<Text>{subtitle}</Text>
				</Content>
				<Footer className="self-end">
					<Button label="Cancel" onClick={onCancel} />
					<Button
						variant="primary"
						label="Confirm"
						onClick={onConfirm}
					/>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

export default ConfirmationDialog;
