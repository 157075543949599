import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import {
	Box,
	Button,
	Container,
	Grid,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { get, has, set } from 'lodash';

import { AdminService, PartnerService } from '@asteria/backend-utils-services';

import { selectToken } from '../../../store/auth';

const CustomWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 500,
	},
});

export default function PartnerAdd() {
	const accessToken = useSelector(selectToken);
	const navigate = useNavigate();
	const addPartner = useCallback(
		(event) => {
			event.preventDefault();

			const save = async () => {
				const formData = new FormData(event.currentTarget);
				const object = {};
				formData.forEach((value, key) => {
					if (!has(object, key)) {
						set(object, key, value);
						return;
					}

					const keyValue = get(object, key);

					if (!Array.isArray(keyValue)) {
						set(object, key, [keyValue]);
					}

					get(object, key).push(value);
				});

				if (object?.partner?.settings?.hosting?.domain) {
					object.partner.settings.hosting.active = true;
				}

				const {
					data: { id },
				} = await AdminService.theme.create(
					{ input: object.theme, fields: `data { id }` },
					{ token: accessToken },
				);

				set(object.partner, 'settings.themeId', id);

				await PartnerService.partner.create(
					{ input: object.partner },
					{ token: accessToken },
				);

				navigate('/partners');
			};

			save();
		},
		[accessToken, navigate],
	);

	return (
		<Container>
			<Paper
				variant="outlined"
				sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
				component="form"
				onSubmit={addPartner}
			>
				<Typography component="h1" variant="h4" align="center">
					New Partner
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							id="name"
							name="partner.name"
							label="Name"
							fullWidth
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6} />
					<Grid item xs={12} sm={12}>
						<Typography variant="h6">Settings</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="theme"
							name="theme.name"
							label="Theme Name"
							fullWidth
							required
							variant="standard"
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<CustomWidthTooltip title="Name used when looking for a custom theme in the code">
											<InfoIcon />
										</CustomWidthTooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="domain"
							name="partner.settings.hosting.domain"
							label="Domain"
							fullWidth
							variant="standard"
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<CustomWidthTooltip title="Subdomain used when Asteria is hosting (https://domain.asteria.ai)">
											<InfoIcon />
										</CustomWidthTooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="signupKey"
							name="partner.settings.signupKey"
							label="Signup Key"
							fullWidth
							variant="standard"
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							id="integrationKey"
							name="partner.settings.integrationKey"
							label="Integration"
							fullWidth
							variant="standard"
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<CustomWidthTooltip title="Automatic integration added on signup">
											<InfoIcon />
										</CustomWidthTooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}></Grid>
				</Grid>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						sx={{ mt: 3, ml: 1 }}
						type="submit"
					>
						Save
					</Button>
				</Box>
			</Paper>
		</Container>
	);
}
