import React, { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Paper } from '@mui/material';
import {
	DataGrid,
	GridActionsCellItem,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { GridToolbarContainer } from '@mui/x-data-grid';

import { AdminService, PartnerService } from '@asteria/backend-utils-services';

import { Circle } from '@asteria/component-core/progress';

import * as Hooks from '../../../hooks';
import { selectToken } from '../../../store/auth';

function PartnerToolbar(props) {
	const { handleAdd } = props;
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter />
			<div style={{ marginLeft: 'auto' }}>
				<Button
					color="primary"
					startIcon={<AddIcon />}
					onClick={handleAdd}
				>
					Add partner
				</Button>
			</div>
		</GridToolbarContainer>
	);
}

const Companies = () => {
	const accessToken = useSelector(selectToken);
	const navigate = useNavigate();
	const [refetch, setRefetch] = useState({});

	const requestOptions = useMemo(
		() => ({
			fields: `
				pageInfo {
					hasNextPage
					hasPreviousPage
					currentCursor
					count
					startCursor
					endCursor
				}
				edges {
					cursor
					node {
						id
						name
						settings {
							themeId
						}
					}
				}
			`,
			pageFilters: { first: 50 },
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[refetch],
	);

	const { data: partnerData, error: partnerDataError } =
		Hooks.request.usePagination({
			handler: PartnerService.partner.fetch,
			options: requestOptions,
			context: { token: accessToken },
		});

	const handleAdd = useCallback(() => {
		navigate('add');
	}, [navigate]);

	const handleDelete = React.useCallback(
		(partner) => async () => {
			console.log(partner);
			if (partner?.settings?.themeId) {
				console.log('Remove theme');
			}

			console.log('Remove partner');
			/*
			const {
				data: { id },
			} = await AdminService.theme.create(
				{ input: object.theme, fields: `data { id }` },
				{ token: accessToken },
			);

			
			*/

			await PartnerService.partner.remove(
				{ id: partner.id },
				{ token: accessToken },
			);

			setRefetch({});
		},
		[accessToken],
	);

	const handleEdit = React.useCallback(
		(id) => () => {
			navigate(`edit/${id}`);
		},
		[navigate],
	);

	const handleView = React.useCallback(
		(id) => () => {
			navigate(`view/${id}`);
		},
		[navigate],
	);

	const columns = useMemo(() => {
		return [
			{
				field: 'id',
				headerName: 'ID',
				flex: 1,
			},
			{
				field: 'name',
				headerName: 'Name',
				flex: 1,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 80,
				getActions: (params) => [
					<GridActionsCellItem
						icon={<VisibilityIcon />}
						label="View"
						onClick={handleView(params.id)}
					/>,
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						showInMenu
						onClick={handleEdit(params.id)}
					/>,
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Delete"
						showInMenu
						onClick={handleDelete(params)}
					/>,
				],
			},
		];
	}, []);

	return (
		<Paper
			elevation={0}
			sx={{
				flexGrow: 1,
			}}
		>
			{partnerData === null && partnerDataError === null ? (
				<Circle progress={-1} animateOnRender={false} />
			) : (
				<DataGrid
					columns={columns}
					rows={partnerData}
					showQuickFilter
					slots={{
						toolbar: PartnerToolbar,
					}}
					slotProps={{
						toolbar: { handleAdd },
					}}
				/>
			)}
		</Paper>
	);
};

export default Companies;
