import React from 'react';

import { useSelector } from 'react-redux';

import SendIcon from '@mui/icons-material/Send';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { InvoiceService } from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';

import './styles.scss';

const ChatModal = (props) => {
	const { id, open, onSubmit, onClose } = props;

	const accessToken = useSelector(selectToken);

	const [message, setMessage] = React.useState('');

	const { data, refetch } = useQuery({
		queryKey: ['messages', id],
		refetchInterval: 1000 * 15,
		queryFn: () =>
			InvoiceService.invoice.extension
				.getInvoiceLayoutMessage(
					{
						id: id,
					},
					{ token: accessToken },
				)
				.then((data) => data.messages)
				.then((response) => {
					return response.sort(
						(a, b) => new Date(a.createdAt) - new Date(b.createdAt),
					);
				}),
	});

	const handleChange = React.useCallback((event) => {
		setMessage(event?.target?.value);
	}, []);

	const handleSubmit = React.useCallback(() => {
		onSubmit?.('invoice:layout:message', {
			id: id,
			message: message,
			refetch: refetch,
		});

		setMessage('');
	}, [id, message, onSubmit, refetch]);

	return (
		<Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
			<DialogTitle>Messages</DialogTitle>
			<DialogContent sx={{ height: '400px' }}>
				<Grid container spacing={1}>
					{data?.map?.((object) => (
						<>
							<Grid key={`${object.id}-name`} item xs={1}>
								<Typography fontWeight={600}>
									{object?.userType === 'AdminToken'
										? 'You'
										: 'User'}
								</Typography>
							</Grid>
							<Grid key={`${object.id}-name`} item xs={8}>
								<Typography>{object?.message}</Typography>
							</Grid>
							<Grid key={`${object.id}-name`} item xs={3}>
								<Typography align="end" color="GrayText">
									{format(
										new Date(object?.createdAt),
										'yyyy-MM-dd HH:MM',
									)}
								</Typography>
							</Grid>
						</>
					))}
					<Grid item xs={12}>
						<FormControl
							size="medium"
							fullWidth
							variant="standard"
							required
						>
							<Input
								value={message}
								placeholder="Message"
								onChange={handleChange}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={handleSubmit}>
											<SendIcon />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

ChatModal.displayName = 'ChatModal';

export default ChatModal;
