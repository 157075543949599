import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Stack,
	Typography,
} from '@mui/material';

export const Move = (props) => {
	const { name, operation } = props;
	const { register } = useFormContext();

	return (
		<Stack>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">Direction</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={operation.dir}
					label="Operation"
					{...register(`${name}.dir`)}
				>
					<MenuItem value="DOWN">Down</MenuItem>
					<MenuItem value="UP">Up</MenuItem>
					<MenuItem value="LEFT">Left</MenuItem>
					<MenuItem value="RIGHT">Right</MenuItem>
					<MenuItem value="TOP">Top</MenuItem>
					<MenuItem value="BOTTOM">Bottom</MenuItem>
					<MenuItem value="END">End</MenuItem>
				</Select>
			</FormControl>
			<Typography>Threshold</Typography>
			<Slider
				value={operation.threshold ?? 100}
				max={100}
				min={1}
				aria-label="Threshold"
				valueLabelDisplay="on"
				{...register(`${name}.threshold`, { value: 3 })}
			/>
		</Stack>
	);
};
