import React, { useCallback, useMemo } from 'react';

import {
	CartesianGrid,
	Legend,
	Line,
	LineChart as LineCharts,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

const LineChart = ({
	data,
	config,
	customTooltip,
	customLegend,
	pageOptions,
	setPageOptions = () => {},
}) => {
	const lines = useMemo(() => {
		return config.map((item) => (
			<Line
				key={item.dataKey}
				hide={
					pageOptions.selectedStats !== item.dataKey &&
					pageOptions.selectedStats !== null
				}
				type="monotone"
				dataKey={item.dataKey}
				stroke={item.color}
				strokeWidth={2}
				name={item.name}
			/>
		));
	}, [data, config, pageOptions]);

	const setActiveLabel = useCallback(
		(event) => {
			if (event?.activeLabel) {
				setPageOptions({
					...pageOptions,
					selector: event?.activeLabel,
				});
			}
		},
		[pageOptions],
	);

	return (
		<ResponsiveContainer width="100%" height="100%">
			<LineCharts
				onClick={setActiveLabel}
				width={500}
				height={300}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<ReferenceLine
					x={pageOptions.selector}
					strokeWidth={42}
					strokeOpacity={0.4}
				/>
				<XAxis dataKey="date" />
				<YAxis />
				<Tooltip content={customTooltip} />
				<Legend content={customLegend} />
				{lines}
			</LineCharts>
		</ResponsiveContainer>
	);
};

export default LineChart;
