import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { compareDesc } from 'date-fns';
import { createClient } from 'graphql-ws';

import AsteriaCore from '@asteria/core';

import {
	CompanyService,
	SupportService,
} from '@asteria/backend-utils-services';

import { selectToken } from '../../../../store/auth';
import { QUERY_CHATS } from '../../constants';
import { formatChat } from '../../utils';

export function useChats() {
	return useQuery({
		queryKey: ['chats'],
		queryFn: async () =>
			SupportService.custom({
				query: QUERY_CHATS,
				variables: {
					chatPageFilters: { last: 0, orderField: 'createdAt' },
					messagePageFilters: { last: 0, orderField: 'updatedAt' },
				},
			}),

		select: (data) =>
			(data?.response?.edges ?? [])
				.map(({ node }) => formatChat(node))
				.sort((a, b) => {
					const source = a?.messages?.[0]?.createdAt ?? a?.createdAt;
					const target = b?.messages?.[0]?.createdAt ?? b?.createdAt;

					return compareDesc(source, target);
				}),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		placeholderData: [],
	});
}

export function useCompanyData(chats) {
	const companyIds = Array.from(
		new Set(chats.data.map((object) => object?.companyId)),
	);

	return useQuery({
		queryKey: ['chats', 'companies', { companyIds: companyIds }],
		queryFn: () =>
			CompanyService.company.fetch({
				pageFilters: { first: 0 },
				filters: { ids: companyIds },
				fields: `edges { node { _id name orgnumber } }`,
			}),

		select: (response) =>
			(response?.edges ?? []).reduce(
				(acc, { node }) => ({ ...acc, [node?._id]: node }),
				{},
			),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		placeholderData: {},

		enabled: !chats.isFetching,
	});
}

export function useSubscription() {
	const accessToken = useSelector(selectToken);

	const queryClient = useQueryClient();

	useEffect(() => {
		const url = AsteriaCore.Configuration.getValueAsync({
			key: 'services.supportService.graphqlUri',
		});

		const client = createClient({
			url: url.replace('http://', 'ws://').replace('https://', 'wss://'),
			connectionParams: { Authorization: `Bearer ${accessToken}` },
		});

		const unsubscribe = client.subscribe(
			{
				query: `
					subscription chatUpdated {
						chatUpdated {
							_id
						}
					}
				`,
			},
			{
				next: async ({ data }) => {
					await queryClient.invalidateQueries({
						predicate: (query) =>
							query.queryKey[0] === 'chats' &&
							(query.queryKey.length === 1 ||
								query.queryKey[1] === data?.chatUpdated?._id),
					});
				},

				// eslint-disable-next-line no-console
				complete: console.info,
				// eslint-disable-next-line no-console
				error: console.error,
			},
		);

		return unsubscribe;
	}, [accessToken, queryClient]);
}

export function useUpdateMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (form) =>
			SupportService.custom({
				query: `
					mutation UpdateChats(
						$input: [ChatUpdateInput!]!
						$companyId: ObjectId
					) {
						updateChats(input: $input, companyId: $companyId) {
							ok
							error
						}
					}
				`,
				variables: form,
			}),

		onSuccess: () =>
			queryClient.invalidateQueries({
				predicate: (query) => query.queryKey[0] === 'chats',
			}),
	});
}
