import AsteriaCore from '@asteria/core';

import { AuthService } from '@asteria/backend-utils-services';

AuthService.auth.extend({
	getQuery: () => `
		mutation LoginAdmin($username: String!, $password: String!, $code: String) {
			response: loginAdmin(username: $username, password: $password, code: $code) {
				ok
				error
				data {
					userId
					accessToken
					refreshToken
					accessTokenExpires
					refreshTokenExpires
					disabled
					revoked
					hasTwoFactor
				}
			}
		}
	`,
	key: `loginAdmin`,
	loggerMethod: `services.authService.loginAdmin`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.authService.registerTwoAuth`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

AuthService.auth.extend({
	getQuery: () => `
		mutation Request {
			registerTwoAuth
		}
	`,
	key: `registerTwoAuth`,
	loggerMethod: `services.authService.registerTwoAuth`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.authService.registerTwoAuth`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

AuthService.auth.extend({
	getQuery: () => `
		mutation Request($code: String!){
			validateTwoAuth(code: $code)
	  	}
	`,
	key: `validateTwoAuth`,
	loggerMethod: `services.authService.validateTwoAuth`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.authService.validateTwoAuth`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});
