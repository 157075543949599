import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Logo = ({ className, fill, click }) => {
	return (
		<div
			className={classNames('logo cursor-pointer', className)}
			onClick={click}
		>
			<svg width="39" height="48" viewBox="0 0 49 58" fill={fill}>
				<g>
					<path d="M24.8552 39.756C24.8552 39.2905 25.2398 38.9131 25.7143 38.9131C26.1887 38.9131 26.5734 39.2905 26.5734 39.756C26.5734 41.4006 24.934 42.6305 23.0068 42.6305C21.0789 42.6305 19.4395 41.4007 19.4395 39.756C19.4395 39.2905 19.8241 38.9131 20.2986 38.9131C20.773 38.9131 21.1577 39.2905 21.1577 39.756C21.1577 40.3559 21.9426 40.9447 23.0068 40.9447C24.0704 40.9447 24.8552 40.3559 24.8552 39.756Z" />
					<path d="M30.027 45.7745C30.027 45.309 30.4116 44.9316 30.8861 44.9316C31.3606 44.9316 31.7452 45.309 31.7452 45.7745C31.7452 47.4191 30.1059 48.649 28.1786 48.649C26.2508 48.649 24.6113 47.4192 24.6113 45.7745C24.6113 45.309 24.996 44.9316 25.4705 44.9316C25.945 44.9316 26.3296 45.309 26.3296 45.7745C26.3296 46.3745 27.1145 46.9633 28.1786 46.9633C29.2422 46.9633 30.027 46.3745 30.027 45.7745Z" />
					<path d="M44.0527 17.4053C44.0527 26.7361 36.4214 34.3106 27.0206 34.3106C17.6197 34.3106 9.98828 26.7361 9.98828 17.4053C9.98828 8.07461 17.6198 0.5 27.0206 0.5C36.4214 0.5 44.0527 8.07458 44.0527 17.4053ZM41.5056 17.4053C41.5056 9.47085 35.0146 3.02817 27.0206 3.02817C19.0265 3.02817 12.5354 9.47088 12.5354 17.4053C12.5354 25.3398 19.0265 31.7824 27.0206 31.7824C35.0146 31.7824 41.5056 25.3398 41.5056 17.4053Z" />
					<path d="M12.5444 17.8956C12.5444 18.5938 11.9722 19.1598 11.2663 19.1598C10.5605 19.1598 9.98828 18.5938 9.98828 17.8956C9.98828 14.4178 10.7719 11.819 12.4159 10.3714C14.2367 8.76814 16.8031 8.79561 19.7654 10.328C25.5993 13.3455 28.3581 17.6566 28.3581 24.1953C28.3581 24.8935 27.7859 25.4595 27.0801 25.4595C26.3742 25.4595 25.802 24.8935 25.802 24.1953C25.802 18.6178 23.5885 15.1587 18.581 12.5687C16.4693 11.4763 15.0231 11.4608 14.1153 12.2601C13.1294 13.1282 12.5444 15.0686 12.5444 17.8956Z" />
					<path d="M39.2361 18.8213C39.2361 20.8245 37.5984 22.4501 35.58 22.4501C33.5616 22.4501 31.9238 20.8246 31.9238 18.8213C31.9238 16.818 33.5616 15.1924 35.58 15.1924C37.5984 15.1924 39.2361 16.818 39.2361 18.8213ZM36.6791 18.8213C36.6791 18.2194 36.1861 17.7301 35.58 17.7301C34.9739 17.7301 34.4808 18.2194 34.4808 18.8213C34.4808 19.4231 34.9739 19.9124 35.58 19.9124C36.1861 19.9124 36.6791 19.4231 36.6791 18.8213Z" />
					<path d="M21.9373 18.8213C21.9373 20.8246 20.2995 22.4501 18.2811 22.4501C16.2627 22.4501 14.625 20.8245 14.625 18.8213C14.625 16.818 16.2627 15.1924 18.2811 15.1924C20.2995 15.1924 21.9373 16.818 21.9373 18.8213ZM19.3803 18.8213C19.3803 18.2194 18.8872 17.7301 18.2811 17.7301C17.675 17.7301 17.182 18.2194 17.182 18.8213C17.182 19.4231 17.675 19.9124 18.2811 19.9124C18.8872 19.9124 19.3803 19.4231 19.3803 18.8213Z" />
					<path d="M44.0515 17.8956C44.0515 18.5938 43.4793 19.1598 42.7734 19.1598C42.0676 19.1598 41.4954 18.5938 41.4954 17.8956C41.4954 15.0684 40.9105 13.1281 39.9247 12.2601C39.0171 11.4608 37.5709 11.4763 35.4589 12.5688C30.4513 15.1587 28.2377 18.6178 28.2377 24.1953C28.2377 24.8935 27.6655 25.4595 26.9597 25.4595C26.2538 25.4595 25.6816 24.8935 25.6816 24.1953C25.6816 17.6566 28.4405 13.3455 34.2745 10.328C37.2372 8.7956 39.8035 8.76814 41.6242 10.3714C43.268 11.819 44.0515 14.4177 44.0515 17.8956Z" />
					<path d="M46.0629 4.2928C46.3316 3.64594 47.0756 3.33869 47.7246 3.60653C48.3736 3.87438 48.6818 4.61589 48.4131 5.26275C48.3891 5.32045 48.3483 5.4125 48.2906 5.53415C48.1987 5.72774 48.0897 5.94106 47.9633 6.16942C47.6038 6.81902 47.1727 7.46823 46.6657 8.07936C45.3338 9.68481 43.7142 10.7582 41.7837 10.9886C39.2376 11.293 37.0517 11.8572 35.4118 12.7116C30.4284 15.3085 28.2254 18.7769 28.2254 24.3694C28.2254 25.0695 27.656 25.6371 26.9535 25.6371C26.2511 25.6371 25.6816 25.0695 25.6816 24.3694C25.6816 17.8131 28.4272 13.4904 34.2333 10.4647C36.1897 9.44544 38.6552 8.8091 41.481 8.47126C42.6701 8.32933 43.753 7.61171 44.7053 6.46372C45.1035 5.98383 45.4485 5.46409 45.736 4.94473C45.8361 4.76396 45.9212 4.59737 45.9911 4.45001C46.0305 4.3671 46.0544 4.31303 46.0629 4.2928Z" />
					<path d="M5.26895 5.26272C5.00024 4.61585 5.30853 3.87435 5.95754 3.60652C6.60655 3.33869 7.35052 3.64596 7.61924 4.29283C7.62764 4.31306 7.65159 4.36713 7.69094 4.45003C7.76088 4.59739 7.846 4.76398 7.94606 4.94476C8.2335 5.4641 8.57857 5.98384 8.9767 6.46373C9.92907 7.61171 11.0119 8.32933 12.2014 8.47129C15.0268 8.80908 17.4922 9.44543 19.449 10.4648C25.2549 13.4904 28.0004 17.8131 28.0004 24.3694C28.0004 25.0695 27.431 25.6371 26.7285 25.6371C26.0261 25.6371 25.4566 25.0695 25.4566 24.3694C25.4566 18.7769 23.2537 15.3085 18.2705 12.7116C16.6302 11.8572 14.4444 11.293 11.8987 10.9886C9.96783 10.7582 8.34819 9.68481 7.01629 8.07935C6.50929 7.46822 6.07826 6.819 5.71871 6.1694C5.59232 5.94104 5.48331 5.72771 5.39144 5.53413C5.3337 5.41247 5.29292 5.32042 5.26895 5.26272Z" />
					<path d="M41.377 17.6965C41.377 16.998 41.9359 16.4316 42.6254 16.4316C43.3149 16.4316 43.8738 16.998 43.8738 17.6965V52.8717C43.8738 53.5703 43.3149 54.1366 42.6254 54.1366C41.9359 54.1366 41.377 53.5703 41.377 52.8717V17.6965Z" />
					<path d="M9.98828 16.9929C9.98828 16.2919 10.5598 15.7236 11.2648 15.7236C11.9699 15.7236 12.5414 16.2919 12.5414 16.9929C12.5414 30.3968 15.1887 39.3434 20.4542 44.7661C25.2799 49.736 32.461 51.9522 42.7762 51.9522C43.4812 51.9522 44.0527 52.5205 44.0527 53.2215C44.0527 53.9224 43.4812 54.4907 42.7762 54.4907C31.8322 54.4907 24.0012 52.0738 18.6174 46.5292C12.8178 40.5565 9.98828 30.994 9.98828 16.9929Z" />
					<path d="M42.413 46.4618C43.1218 46.4618 43.6963 47.0306 43.6963 47.7323C43.6963 48.434 43.1218 49.0028 42.413 49.0028C39.3437 49.0028 36.6754 48.1608 34.412 46.5995C32.5124 45.2891 30.9613 43.5196 29.718 41.4123C28.764 39.7955 28.0439 38.0731 27.5215 36.3515C27.3386 35.7486 27.193 35.1874 27.0808 34.6811C27.012 34.3706 26.9689 34.1423 26.9477 34.0097C26.8367 33.3167 27.3142 32.6658 28.0142 32.5559C28.7142 32.446 29.3717 32.9187 29.4827 33.6117C29.4852 33.6276 29.4924 33.6688 29.5046 33.7336C29.5263 33.8483 29.5539 33.9832 29.5879 34.1367C29.6866 34.5821 29.8161 35.0813 29.9797 35.6204C30.4474 37.1617 31.0914 38.7022 31.9343 40.1307C34.2887 44.1212 37.6819 46.4618 42.413 46.4618Z" />
					<path d="M42.5965 52.0127C43.3018 52.0127 43.8736 52.5675 43.8736 53.2518C43.8736 53.9362 43.3018 54.4909 42.5965 54.4909H1.27704C0.57175 54.4909 0 53.9362 0 53.2518C0 52.5675 0.57175 52.0127 1.27704 52.0127H42.5965Z" />
					<path d="M17.4167 52.3876C17.9205 51.8877 18.7372 51.8877 19.2409 52.3877C19.7446 52.8878 19.7445 53.6984 19.2408 54.1984L16.2917 57.1253C15.788 57.6253 14.9713 57.6252 14.4676 57.1252C13.9639 56.6252 13.9639 55.8146 14.4677 55.3146L17.4167 52.3876Z" />
					<path d="M11.9382 52.1861C12.4284 52.6727 12.4285 53.4617 11.9383 53.9483C11.448 54.435 10.6532 54.435 10.1629 53.9484L6.07477 49.8909C5.58449 49.4043 5.58445 48.6154 6.07468 48.1287C6.56491 47.642 7.35977 47.642 7.85005 48.1286L11.9382 52.1861Z" />
				</g>
			</svg>
		</div>
	);
};

Logo.propTypes = {
	className: PropTypes.string,
	fill: PropTypes.string,
	onClick: PropTypes.string,
};

Logo.defaultProps = {
	fill: '',
};

export default Logo;
