/* Constants */

export const FETCH = 'FETCH';
export const SELECT = 'SELECT';
export const UPDATE = 'UPDATE';

/* Reducer */

export const initialState = {
	partner: null,
	partners: [],
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH: {
			const partners = action.payload;

			return {
				...state,
				partners: partners,
				partner: state.partner
					? partners.find(({ _id }) => _id === state.partner.id)
					: null,
			};
		}

		case SELECT: {
			const { partnerId } = action.payload;

			return {
				...state,
				partner: state.partners.find(({ _id }) => _id === partnerId),
			};
		}

		case UPDATE: {
			const { partnerId, data } = action.payload;
			const partners = [
				...state.partners.filter(({ _id }) => _id !== partnerId),
				data,
			];

			return {
				...state,
				partners: partners,
				partner: partners.find(
					({ _id }) => _id === state?.partner?._id,
				),
			};
		}

		default:
			return state;
	}
};

/* Actions */

export const fetch = (data) => ({ type: FETCH, payload: data });
export const select = (id) => ({ type: SELECT, payload: { partnerId: id } });
export const update = (id, data) => ({ type: UPDATE, payload: { id, data } });
