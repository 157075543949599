export const rangeType = {
	YEAR: 'YEAR',
	MONTH: 'MONTH',
	WEEK: 'WEEK',
	DAY: 'DAY',
};

export const dialogTypes = {
	VIEW_COMPANY: 'viewCompany',
};
