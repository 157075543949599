import AsteriaCore from '@asteria/core';

import { IntegrationGateway } from '@asteria/backend-utils-services';

IntegrationGateway.integration.extend({
	getQuery: (fields = 'ok error') => `
		mutation CreateIntegration(
			$input: [IntegrationCreateInputType!]!
			$companyId: ObjectId
		) {
			addIntegrations(input: $input, companyId: $companyId) {
				${fields}
			}
		}
	`,
	key: `create`,
	loggerMethod: `services.integrationGateway.create`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.integrationGateway.create`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});
