import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReplayIcon from '@mui/icons-material/Replay';
import { Paper } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { InvoiceService } from '@asteria/backend-utils-services';

import { formatBatch } from './utils';

function CompanyBatchesPage() {
	const { companyId } = useParams();

	const navigate = useNavigate();

	const query = useQuery({
		queryKey: ['batch', companyId, 'list'],
		queryFn: async () =>
			InvoiceService.service
				.fetch({
					companyId: companyId,
					filters: {
						status: ['SENT', 'ERROR', 'COMPLETED', 'REMOVED'],
					},
					pageFilters: { first: 0 },
					fields: `
						pageInfo { count }
						edges {
							node {
								id
								serviceId
								status
								data
								invoices {
									invoiceId
								}
								createdAt
								updatedAt
							}
						}
					`,
				})
				.then((response) =>
					(response?.edges ?? [])
						.map(({ node }) => node)
						.map((node) => formatBatch(node)),
				),

		placeholderData: [],
		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		retry: false,

		enabled: !!companyId,
	});

	const mutation = useMutation({
		mutationFn: async (id) =>
			InvoiceService.service.sendRequest({
				query: `
					mutation UploadServiceFiles($id: ID!, $companyId: ID) {
						response: uploadServiceFiles(
							id: $id
							companyId: $companyId
						) {
							ok
							error
							errors
						}
					}
				`,
				variables: { id: id, companyId: companyId },
			}),
		onSuccess: (response, id) => {
			enqueueSnackbar(
				`Batch with ID '${id}' successfully uploaded again`,
				{ variant: 'success' },
			);
		},
	});

	return (
		<Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
			<DataGrid
				disableRowSelectionOnClick
				columns={[
					{ field: 'id', headerName: 'ID', flex: 1 },
					{
						field: 'data.batch',
						headerName: 'Batch No.',
						valueGetter: ({ row }) => row?.data?.batch,
						type: 'number',
					},
					{ field: 'status', headerName: 'Status', width: 120 },
					{
						field: 'invoices',
						headerName: 'Invoices',
						valueGetter: ({ row }) => (row?.invoices ?? []).length,
						type: 'number',
						width: 120,
					},

					{
						field: 'data.currency',
						headerName: 'Currency',
						valueGetter: ({ row }) => row?.data?.currency,
					},
					{
						field: 'data.credit',
						headerName: 'Credit',
						valueGetter: ({ row }) => row?.data?.credit,
						width: 120,
						type: 'number',
					},
					{
						field: 'data.tax',
						headerName: 'Tax',
						valueGetter: ({ row }) => row?.data?.tax,
						width: 120,
						type: 'number',
					},
					{
						field: 'data.total',
						headerName: 'Total',
						valueGetter: ({ row }) => row?.data?.total,
						width: 120,
						type: 'number',
					},
					{
						field: 'createdAt',
						headerName: 'Created',
						type: 'dateTime',
						flex: 1,
					},
					{
						field: 'actions',
						type: 'actions',
						width: 80,
						getActions: (params) => [
							<GridActionsCellItem
								icon={<ReplayIcon />}
								label="Re-send"
								onClick={() => mutation.mutate(params.id)}
								disabled={mutation.isPending}
							/>,
							<GridActionsCellItem
								icon={<ChevronRightIcon />}
								label="Details"
								onClick={() =>
									navigate(params.id, { relative: true })
								}
							/>,
						],
					},
				]}
				rows={query.data}
				loading={query.isFetching}
			/>
		</Paper>
	);
}

export default CompanyBatchesPage;
