import React from 'react';

import { useSelector } from 'react-redux';

import ExpandMore from '@mui/icons-material/ExpandMore';
import Undo from '@mui/icons-material/Undo';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { CompanyService, UserService } from '@asteria/backend-utils-services';

import CashflowModal from '../../../components/CashflowModal';
import * as Hooks from '../../../hooks';
import { selectToken } from '../../../store/auth';

const EditableInput = (props) => {
	const {
		value,
		name,
		label,
		onChange,
		onReset,
		isModified,
		disabled,
		...rest
	} = props;

	const getFieldStyle = React.useCallback(() => {
		return isModified ? { backgroundColor: '#e3f2fd' } : {};
	}, [isModified]);

	return (
		<>
			<Grid item xs={4} display="flex" alignItems="center">
				<Typography>{label}</Typography>
			</Grid>
			<Grid item xs={7} justifyContent="center" alignItems="center">
				<TextField
					size="small"
					name={name}
					value={value}
					onChange={onChange}
					sx={getFieldStyle()}
					disabled={disabled}
					fullWidth
					{...rest}
				/>
			</Grid>
			<Grid item xs={1} justifyContent="center" alignItems="center">
				<IconButton disabled={disabled} onClick={() => onReset?.(name)}>
					<Undo />
				</IconButton>
			</Grid>
		</>
	);
};

const UserTab = ({ id, object, onOpen }) => {
	const [form, setForm] = React.useState(object);
	const [changedFields, setChangedFields] = React.useState({});

	const handleChange = React.useCallback(
		(event) => {
			const { name, value } = event.target;

			setForm({
				...form,
				[name]: value,
			});

			setChangedFields({
				...changedFields,
				[name]: true,
			});
		},
		[changedFields, form],
	);

	const handleResetForm = React.useCallback(() => {
		setForm({ ...object, newpassword: '' });

		setChangedFields({});
	}, [object]);

	const handleResetField = React.useCallback(
		(fieldName) => {
			setForm({
				...form,
				[fieldName]: object?.[fieldName],
			});

			setChangedFields({
				...changedFields,
				[fieldName]: false,
			});
		},
		[changedFields, form, object],
	);

	const handleSubmit = React.useCallback(
		async (event) => {
			event.preventDefault();

			if (changedFields?.newpassword && form?.newpassword?.length < 6) {
				enqueueSnackbar(`Password must contain at least 6 characters`, {
					variant: 'error',
				});

				return;
			}

			const modifiedFields = Object.keys(changedFields);

			const changes = modifiedFields.reduce((acc, key) => {
				acc[key] = form[key];

				return acc;
			}, {});

			try {
				await UserService.user.update({
					id: object?._id,
					input: changes,
				});

				enqueueSnackbar(`User data has been updated`, {
					variant: 'success',
				});
			} catch (e) {
				console.error(e);

				enqueueSnackbar(e?.message?.toString(), {
					variant: 'error',
				});
			}
		},
		[changedFields, form, object?._id],
	);

	return (
		<Accordion key={object?._id} variant="outlined">
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Typography>
					{[object?.firstName, object?.lastName]
						.filter(Boolean)
						.join(' ') ||
						object?.username ||
						object?._id}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box component="form" onSubmit={handleSubmit}>
					<Grid container marginBottom={2}>
						<Grid item xs={4} display="flex" gap={2}>
							<Button
								variant="outlined"
								onClick={() =>
									onOpen({ user: object, companyId: id })
								}
							>
								Cashflow
							</Button>
						</Grid>
						<Grid
							xs={8}
							item
							display="flex"
							gap={2}
							justifyContent="flex-end"
						>
							<Button
								type="submit"
								color="secondary"
								variant="outlined"
							>
								Save form
							</Button>
							<Button
								variant="outlined"
								color="primary"
								onClick={handleResetForm}
							>
								Reset form
							</Button>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={4}>
							Key
						</Grid>
						<Grid item xs={8}>
							Value
						</Grid>
						<Grid item xs={4}>
							ID
						</Grid>
						<Grid item xs={8}>
							{object?._id}
						</Grid>
						<EditableInput
							label="First name"
							name="firstName"
							value={form?.firstName}
							isModified={changedFields?.firstName}
							onChange={handleChange}
							onReset={handleResetField}
						/>
						<EditableInput
							label="Last name"
							name="lastName"
							value={form?.lastName}
							isModified={changedFields?.lastName}
							onChange={handleChange}
							onReset={handleResetField}
						/>
						<EditableInput
							label="User name (login)"
							name="username"
							value={form?.username}
							isModified={changedFields?.username}
							onChange={handleChange}
							onReset={handleResetField}
							required
						/>
						<Grid item xs={4}>
							Language
						</Grid>
						<Grid item xs={8}>
							{object?.settings?.language}
						</Grid>
						<Grid item xs={4}>
							Combined graph
						</Grid>
						<Grid item xs={8}>
							{object?.settings?.combineGraph ? 'Yes' : 'No'}
						</Grid>
						<Grid item xs={4}>
							Compact (Transaction list)
						</Grid>
						<Grid item xs={8}>
							{object?.settings?.compressList ? 'Yes' : 'No'}
						</Grid>
						<Grid item xs={4}>
							Columns (Transaction list)
						</Grid>
						<Grid item xs={8}>
							{(object?.settings?.listColumns ?? []).join(' | ')}
						</Grid>
						<Grid item xs={4}>
							2FA
						</Grid>
						<Grid item xs={8}>
							{object?.settings?.has2FA ? 'Yes' : 'No'}
						</Grid>
						<Grid item xs={4}>
							Roles
						</Grid>
						<Grid item xs={8}>
							{(object?.roles?.[id] ?? []).join(' | ')}
						</Grid>
						<Grid item xs={4}>
							Created at
						</Grid>
						<Grid item xs={8}>
							{object?.createdAt &&
								object?.createdAt.split('T').join(' ')}
						</Grid>
						<Grid item xs={4}>
							Updated at
						</Grid>
						<Grid item xs={8}>
							{object?.updatedAt &&
								object?.updatedAt.split('T').join(' ')}
						</Grid>
						<Grid item xs={4}>
							Last login
						</Grid>
						<Grid item xs={8}>
							{object?.lastLoggedIn &&
								object?.lastLoggedIn.split('T').join(' ')}
						</Grid>
						<EditableInput
							label="New Password"
							name="newpassword"
							value={form?.newpassword}
							isModified={changedFields?.newpassword}
							onChange={handleChange}
							onReset={handleResetField}
						/>
					</Grid>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

const UserTabContent = (props) => {
	const { id } = props;

	const accessToken = useSelector(selectToken);

	const [{ open, state }, { onClose, onOpen }] = Hooks.modals.useModalState();

	const { data } = useQuery({
		queryKey: ['companies', id, 'users'],
		queryFn: () =>
			CompanyService.company
				.fetchOne(
					{
						fields: `
							_id
							users(pageFilters: { first: 0 }) {
								edges {
									node {
										_id
										firstName
										lastName
										username
										settings {
											language
											combineGraph
											compressList
											listColumns
											has2FA
										}
										rawCompanies
										createdAt
										updatedAt
										lastLoggedIn
									}
								}
							}
						`,
						id: id,
					},
					{ token: accessToken },
				)
				.then((response) =>
					(response?.users?.edges ?? [])
						.map(({ node }) => node)
						.map((object) => ({
							...object,
							roles: (object?.rawCompanies ?? []).reduce(
								(acc, { companyId, roles }) => ({
									...acc,
									[companyId]: roles,
								}),
								{},
							),
						})),
				),
	});

	return (
		<>
			<CashflowModal open={open} onClose={onClose} {...state} />
			<Stack spacing={2}>
				{(data ?? []).map((object) => (
					<UserTab object={object} id={id} onOpen={onOpen} />
				))}
			</Stack>
		</>
	);
};

export default UserTabContent;
