import React, { useCallback, useMemo, useState } from 'react';

import {
	MdArrowLeft,
	MdArrowRight,
	MdOutlineBarChart,
	MdOutlineShowChart,
} from 'react-icons/md';

import { format } from 'date-fns';

import Graph from '../../../components/Graph/Graph';
import {
	formatInterval,
	getInterval,
	getIntervalUnits,
	moveDate,
} from '../../../utils/dateUtils';
import { getAvgTime, getTooltipData } from '../utils/usageUtils';

import CustomLegend from './CustomLegend';
import CustomTooltip from './CustomTooltip';

const UsageAnalyticsGraph = ({
	data,
	settings,
	setSettings,
	pageOptions,
	setPageOptions = () => {},
}) => {
	const [chartType, setChartType] = useState('line');

	const selectLineChart = useCallback(() => {
		setChartType('line');
	}, []);

	const selectBarChart = useCallback(() => {
		setChartType('bar');
	}, []);

	const nextDate = useCallback(() => {
		const next = moveDate({
			range: settings.range,
			date: settings.date,
			diff: 1,
		});
		setSettings({ ...settings, date: next });
	}, [settings]);

	const previousDate = useCallback(() => {
		const prev = moveDate({
			range: settings.range,
			date: settings.date,
			diff: -1,
		});
		setSettings({ ...settings, date: prev });
	}, [settings]);

	const graphData = useMemo(() => {
		const interval = getInterval({
			range: settings.range,
			selectedDate: moveDate({
				range: settings.range,
				date: settings.date,
				diff: 1,
			}),
		});

		const intervalUnits = getIntervalUnits({
			interval: interval,
			range: settings.range,
		});

		const yAxisData = intervalUnits.map((date) => {
			const selector = formatInterval({
				date: date,
				range: settings.range,
			});

			const prevDate = moveDate({
				range: settings.range,
				date: date,
				diff: -1,
			});

			const prevSelector = formatInterval({
				date: prevDate,
				range: settings.range,
			});

			const timeInInterface = getAvgTime({
				data: data,
				type: 'timeInInterface',
				selector: selector,
				prevSelector: prevSelector,
			});

			const firstAction = getAvgTime({
				data: data,
				type: 'userFirstAction',
				selector: selector,
				prevSelector: prevSelector,
			});

			return {
				date: format(date, 'yyyy-MM-dd'),
				firstAction: firstAction.value,
				timeInInterface: timeInInterface.value,
				tooltipData: getTooltipData({
					data: data,
					selector: selector,
					prevSelector: prevSelector,
					selectedStats: pageOptions.selectedStats,
					timeInInterface: timeInInterface,
					firstAction: firstAction,
				}),
			};
		});

		return yAxisData;
	}, [data, settings, pageOptions.selectedStats]);

	const config = useMemo(() => {
		return [
			{
				color: '#7dd3fc',
				dataKey: 'firstAction',
				name: 'Time in interface',
			},
			{
				color: '#86efac',
				dataKey: 'timeInInterface',
				name: 'Time to first action',
			},
		];
	}, []);

	return (
		<div className="flex flex-col">
			<div className="flex flex-row items-center">
				<p>Chart type: </p>
				<button
					className="text-gray-500 hover:text-gray-700 ml-2"
					onClick={selectLineChart}
				>
					<MdOutlineShowChart size={30} />
				</button>
				<button
					className="text-gray-500 hover:text-gray-700"
					onClick={selectBarChart}
				>
					<MdOutlineBarChart size={30} />
				</button>
			</div>
			<div className="flex flex-row h-[300px]">
				<button
					className="opacity-20 hover:opacity-100"
					onClick={previousDate}
				>
					<MdArrowLeft size={40} />
				</button>
				<Graph
					customTooltip={<CustomTooltip />}
					customLegend={<CustomLegend />}
					config={config}
					type={chartType}
					data={graphData}
					pageOptions={pageOptions}
					setPageOptions={setPageOptions}
				/>
				<button
					className="opacity-20 hover:opacity-100"
					onClick={nextDate}
				>
					<MdArrowRight size={40} />
				</button>
			</div>
		</div>
	);
};

export default UsageAnalyticsGraph;
