import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { InvoiceService } from '@asteria/backend-utils-services';

/**
 * @template
 * @param { import('@tanstack/react-query').UndefinedInitialDataOptions & { id: string, companyId: string } } options
 * @returns
 */
export function useBatch({ id, companyId, ...args }) {
	return useQuery({
		queryKey: ['batch', companyId, id],
		queryFn: async () =>
			InvoiceService.service.sendRequest({
				query: `
					query Request($id: ID!, $companyId: ID) {
						response: invoiceService(
							id: $id
							companyId: $companyId
						) {
							id
							serviceId
							status
							data
							invoices {
								invoiceId
								status
								errors
							}
							createdAt
							updatedAt
						}
					}
				`,
				variables: { id, companyId },
			}),

		...args,
	});
}

export function useInvoices({ id, companyId, fields, select }) {
	const batch = useBatch({
		id,
		companyId,
		select: (data) => data?.response?.invoices ?? [],
	});

	const invoices = useQuery({
		queryKey: ['batch', companyId, id, 'invoices'],
		queryFn: async () =>
			InvoiceService.invoice.fetch({
				pageFilters: { first: 0 },
				filters: { ids: batch.data.map(({ invoiceId }) => invoiceId) },
				fields: fields,
			}),
		select: (response) =>
			(response?.edges ?? [])
				.map(({ node }) => node)
				.reduce(
					(acc, object) => ({ ...acc, [object?._id]: object }),
					{},
				),
		enabled: !batch.isFetching,
	});

	return {
		query: invoices,
		batchQuery: batch,
		data: batch.data.map((object) => ({
			...object,
			db: invoices?.data?.[object?.invoiceId],
		})),
	};
}

export function useBatchUpdate({ id, companyId }) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (input) =>
			InvoiceService.service.sendRequest({
				query: `
					mutation Request(
						$id: ID!
						$input: InvoiceServiceInputType
						$companyId: ID
					) {
						updateInvoicesService(
							id: $id
							input: $input
							companyId: $companyId
						) {
							ok
							error
						}
					}
				`,
				variables: { id, companyId, input: input },
			}),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				predicate: (query) => {
					const keys = [].concat(query.queryKey);

					return (
						keys[0] === 'batch' &&
						keys[1] === companyId &&
						(keys[2] === 'list' || keys[2] === id)
					);
				},
			});

			enqueueSnackbar(`Batch with ID '${id}' successfully updated`, {
				variant: 'success',
			});
		},
	});
}
