import React from 'react';

import { Divider, List, ListItem, ListSubheader } from '@mui/material';

import { ColumnsField } from './columnsField';
import { Field } from './field';
import { Input } from './input';
import { RowsConfig } from './rowsConfig';

export const Rows = (props) => {
	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<Divider />
			<ListSubheader>Columns</ListSubheader>
			<ListItem>
				<ColumnsField {...props} />
			</ListItem>
			<Divider />
			<ListSubheader>Rows</ListSubheader>
			<ListItem>
				<RowsConfig {...props} />
			</ListItem>
		</List>
	);
};
