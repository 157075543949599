import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

export const templates = createSelector(
	(store) => store?.layouts?.templates,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const template = createSelector(
	templates,
	(store) => store?.layouts?.template,
	(templates, ID) =>
		templates.find((object) => (object?._id ?? object?.id) === ID),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
