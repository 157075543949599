import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { formatISO9075 } from 'date-fns';

import { useChats, useCompanyData, useUpdateMutation } from './hooks';

const ChatListPage = () => {
	const navigate = useNavigate();

	const chats = useChats();
	const companies = useCompanyData(chats);

	const columns = React.useMemo(
		() => [
			{ field: '_id', headerName: 'ID', width: 250 },
			{ field: 'name', headerName: 'Name', width: 250, editable: true },
			{
				field: 'companyId',
				headerName: 'Company',
				valueGetter: ({ value }) =>
					[
						companies.data?.[value]?.name,
						`(${[value, companies.data?.[value]?.orgnumber]
							.filter(Boolean)
							.join(', ')})`,
					]
						.filter(Boolean)
						.join(' '),
				width: 250,
			},
			{
				field: 'status',
				headerName: 'Status',
				editable: true,
				type: 'singleSelect',
				valueOptions: ['PENDING', 'RESOLVED', 'DELETED'],
			},
			{
				field: 'lastMessage',
				headerName: 'Last message',
				valueGetter: ({ row }) =>
					row?.messages?.[row?.messages?.length - 1]?.content,
				flex: 1,
			},
			{
				field: 'createdAt',
				headerName: 'Created at',
				type: 'dateTime',
				width: 250,
				valueFormatter: ({ value }) => formatISO9075(value),
			},
			{
				field: 'actions',
				type: 'actions',
				width: 40,
				getActions: (params) => [
					<GridActionsCellItem
						label="Details"
						icon={<ChevronRightIcon />}
						onClick={() => navigate(`/chats/${params.id}`)}
					/>,
				],
			},
		],
		[companies.data, navigate],
	);

	const mutation = useUpdateMutation();

	const onRowUpdate = (data) => {
		mutation.mutate({
			input: { id: data?._id, name: data?.name, status: data?.status },
			companyId: data?.companyId,
		});

		return data;
	};

	const getStyles = React.useCallback((data) => {
		const hasUnread = data.row.messages.some((object) => {
			return (
				object?.read === false &&
				object?.createdBy?.type !== 'AdminToken'
			);
		});

		return hasUnread
			? 'border-l-4 border-sky-400 bg-gray-50 border-solid'
			: 'border-l-4 border-transparent border-solid';
	}, []);

	return (
		<Paper className="grow flex flex-col p-4">
			<DataGrid
				columns={columns}
				rows={chats.data}
				loading={chats.isFetching || companies.isFetching}
				// disableColumnFilter
				disableColumnSelector
				// disableDensitySelector
				getRowClassName={getStyles}
				slots={{ toolbar: GridToolbar }}
				slotProps={{ toolbar: { showQuickFilter: true } }}
				initialState={{
					filter: {
						filterModel: {
							items: [
								{
									field: 'status',
									operator: 'isAnyOf',
									value: ['PENDING'],
								},
							],
						},
					},
				}}
				editMode="row"
				processRowUpdate={onRowUpdate}
			/>
		</Paper>
	);
};

export default ChatListPage;
