import React, { useCallback, useContext, useMemo, useState } from 'react';

import { PartnerService } from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';

import { Option, UncontrolledSelect } from '@asteria/component-form/select';

import * as Context from '../../context';
import * as Ducks from '../../ducks';
import NewPartnerDialog from '../dialogs/NewPartnerDialog';

const PartnerSelector = () => {
	const { state } = useContext(Context.auth.Context);
	const {
		state: { partner, partners: partnersData },
		dispatch,
	} = useContext(Context.partner.Context);

	const [isEditPartner, setEditPartner] = useState(false);
	const [showPartnerDialog, setShowPartnerDialog] = useState(false);

	const setPartner = useCallback(
		({ value }) => {
			dispatch(Ducks.partner.select(value));
		},
		[partnersData],
	);

	const toggleEditPartner = useCallback(() => {
		setEditPartner(true);
		setShowPartnerDialog(!showPartnerDialog);
	}, [isEditPartner, showPartnerDialog]);

	const toggleAddPartner = useCallback(() => {
		setEditPartner(false);
		setShowPartnerDialog(!showPartnerDialog);
	}, [isEditPartner, showPartnerDialog]);

	const onUpdatePartner = useCallback(
		async (data) => {
			const { data: response } = await PartnerService.partner.update(
				{
					fields: `data { _id name }`,
					id: partner._id,
					input: { ...data, _id: undefined },
				},
				{ token: state.accessToken },
			);

			dispatch(Ducks.partner.update(partner._id, response));
			setShowPartnerDialog(false);
		},
		[partner],
	);

	const create = useCallback(async (data) => {
		const { data: response } = await PartnerService.partner.create(
			{
				fields: `data { _id name }`,
				input: { ...data, _id: undefined },
			},
			{ token: state.accessToken },
		);

		dispatch(Ducks.partner.update(response._id, response));
		setShowPartnerDialog(false);
	}, []);

	const partnerOptions = useMemo(() => {
		if (!partnersData) {
			return [];
		}

		return partnersData.map((partner, index) => (
			<Option key={`partner-${index}`} value={partner._id}>
				{partner.name}
			</Option>
		));
	}, [partnersData]);

	return (
		<div>
			<div className="flex flex-row gap-2 my-2">
				<UncontrolledSelect
					className="w-64"
					size="small"
					name="partnerId"
					label="Partner: "
					value={partner?._id}
					placeholder="Select partner"
					onChange={setPartner}
				>
					<Option>All</Option>
					{partnerOptions}
				</UncontrolledSelect>
				{partner?._id && (
					<div>
						<Button
							icon="more"
							tooltip="Edit"
							onClick={toggleEditPartner}
						/>
					</div>
				)}
				<Button icon="add" onClick={toggleAddPartner} />
				<NewPartnerDialog
					partnerId={isEditPartner && partner?._id}
					isOpen={showPartnerDialog}
					onCancel={toggleAddPartner}
					onSave={isEditPartner ? onUpdatePartner : create}
				/>
			</div>
		</div>
	);
};

export default PartnerSelector;
