import React from 'react';

import { Route, Routes } from 'react-router-dom';

import InvoiceDetailsPage from './details';
import InvoicesPage from './invoices';
import InvoiceLayoutPage from './layout';

const InvoiceLayoutRouter = () => {
	return (
		<Routes>
			<Route index element={<InvoicesPage />} />
			<Route path="layout/:id" element={<InvoiceLayoutPage />} />
			<Route
				path=":id/:startDate/:endDate"
				element={<InvoiceDetailsPage />}
			/>
		</Routes>
	);
};

InvoiceLayoutRouter.displayName = 'InvoiceLayoutRouter';

InvoiceLayoutRouter.propTypes = {};

export default InvoiceLayoutRouter;
