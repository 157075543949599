/* eslint-disable indent */
import React from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { Client } from './fields/client';
import { Delivery } from './fields/delivery';
import { InvoiceFields } from './fields/invoice';
import { Rows } from './fields/rows';
import { Settings } from './fields/settings';
import { Vats } from './fields/vats';

const Content = (props) => {
	const { value, name, children } = props;

	return (
		<div role="tabpanel" hidden={value !== name}>
			<Box sx={{ width: '100%' }}>{children}</Box>
		</div>
	);
};

const Fields = (props) => {
	const { setSelectedField, show } = props;
	const [active, setActive] = React.useState('invoice');

	const onChange = React.useCallback((event, value) => {
		setActive(value);
	}, []);

	if (!show) {
		return null;
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Tabs
				value={active}
				onChange={onChange}
				variant="scrollable"
				scrollButtons="auto"
				sx={{ maxWidth: 520 }}
			>
				<Tab label="Client" value="client" />
				<Tab label="Invoice" value="invoice" />
				<Tab label="Delivery" value="delivery" />
				<Tab label="VAT" value="vats" />
				<Tab label="Rows" value="rows" />
				<Tab label="Settings" value="settings" />
			</Tabs>
			<Content value={active} name="client">
				<Client setSelectedField={setSelectedField} />
			</Content>
			<Content value={active} name="invoice">
				<InvoiceFields setSelectedField={setSelectedField} />
			</Content>
			<Content value={active} name="vats">
				<Vats setSelectedField={setSelectedField} />
			</Content>
			<Content value={active} name="delivery">
				<Delivery setSelectedField={setSelectedField} />
			</Content>
			<Content value={active} name="rows">
				<Rows setSelectedField={setSelectedField} />
			</Content>
			<Content value={active} name="settings">
				<Settings setSelectedField={setSelectedField} />
			</Content>
		</Box>
	);
};

export default Fields;
