import React from 'react';

export function useModalState() {
	const [{ open, state }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'OPEN':
					return {
						...state,
						open: true,
						state: action?.payload ?? {},
					};

				case 'CLOSE':
					return { ...state, open: false, state: {} };

				case 'TOGGLE':
					return {
						...state,
						open: !state.open,
						state: action?.payload ?? {},
					};

				default:
					return state;
			}
		},
		{ open: false, state: {} },
	);

	const onClose = React.useCallback(() => dispatch({ type: 'CLOSE' }), []);
	const onOpen = React.useCallback(
		(state) => dispatch({ type: 'OPEN', payload: state }),
		[],
	);
	const onToggle = React.useCallback(
		(state) => dispatch({ type: 'TOGGLE', payload: state }),
		[],
	);

	return [
		{ open, state },
		{ onClose: onClose, onOpen: onOpen, onToggle: onToggle },
	];
}
