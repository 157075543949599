import { configureStore } from '@reduxjs/toolkit';

import auth from './auth';
import layouts from './layouts';

export const store = configureStore({
	reducer: {
		auth,
		layouts,
	},
});
