import {
	addDays,
	addMonths,
	addWeeks,
	addYears,
	eachDayOfInterval,
	eachMonthOfInterval,
	eachWeekOfInterval,
	eachYearOfInterval,
	format,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
} from 'date-fns';

import { rangeType } from '../pages/home/utils/constants';

export const getIntervalUnits = (options) => {
	const { interval, range } = options;

	switch (range) {
		case rangeType.DAY:
			return eachDayOfInterval(interval);
		case rangeType.WEEK:
			return eachWeekOfInterval(interval);
		case rangeType.MONTH:
			return eachMonthOfInterval(interval);
		case rangeType.YEAR:
			return eachYearOfInterval(interval);
	}
};

export const getInterval = (options) => {
	const { range, selectedDate, diff = -5 } = options;
	const start = diff >= 0 ? 'start' : 'end';
	const end = diff >= 0 ? 'end' : 'start';

	switch (range) {
		case rangeType.DAY:
			return {
				[start]: startOfDay(selectedDate),
				[end]: startOfDay(addDays(selectedDate, diff)),
			};
		case rangeType.WEEK:
			return {
				[start]: startOfWeek(selectedDate),
				[end]: startOfWeek(addWeeks(selectedDate, diff)),
			};
		case rangeType.MONTH:
			return {
				[start]: startOfMonth(selectedDate),
				[end]: startOfMonth(addMonths(selectedDate, diff)),
			};
		case rangeType.YEAR:
			return {
				[start]: startOfYear(selectedDate),
				[end]: startOfYear(addYears(selectedDate, diff)),
			};
	}
};

export const getStartDate = (options) => {
	const { date, range } = options;
	switch (range) {
		case rangeType.DAY:
			return startOfDay(date);
		case rangeType.WEEK:
			return startOfWeek(date);
		case rangeType.MONTH:
			return startOfMonth(date);
		case rangeType.YEAR:
			return startOfYear(date);
	}
};

export const formatInterval = (options) => {
	const { date, range, diff = 0 } = options;
	switch (range) {
		case rangeType.DAY:
			return `${format(date, 'yyyy-MM-dd')}`;
		case rangeType.WEEK:
			return `${format(addWeeks(date, diff), 'II')}`;
		case rangeType.MONTH:
			return `${format(date, 'MM')}`;
		case rangeType.YEAR:
			return `${format(date, 'yyyy')}`;
	}
};

export const moveDate = (options) => {
	const { range, date, diff = 0 } = options;
	switch (range) {
		case rangeType.DAY:
			return addDays(date, diff);
		case rangeType.WEEK:
			return addWeeks(date, diff);
		case rangeType.MONTH:
			return addMonths(date, diff);
		case rangeType.YEAR:
			return addYears(date, diff);
	}
};
