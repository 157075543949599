import React, { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { Brush, Edit, Refresh, Settings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Stack,
	TextField,
} from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';
import { cleanNumber } from '../../../../../../components/pdf/utils';
import processor from '../../utils/processor';

export const Field = (props) => {
	const {
		label,
		name,
		required,
		setSelectedField,
		fieldType = 'text',
		isArrayObject,
		edit = false,
	} = props;
	const { pdfPageData, layoutData } = React.useContext(FormContext);
	const handleClick = React.useCallback(
		(event) => {
			setSelectedField(name);
		},
		[name, setSelectedField],
	);

	const { register, setValue, setError } = useFormContext();
	const value = useWatch({
		name: isArrayObject ? name.split('.').slice(0, -1).join('.') : name,
	});
	const operations = useWatch({ name: `layout.operations.${name}` });
	const { onChange, ...input } = register(
		isArrayObject ? name.split('.').slice(0, -1).join('.') : name,
	);

	useEffect(() => {
		if (!operations || operations.length === 0) {
			return;
		}

		let { results } = processor(
			{
				operations: Object.values(operations),
				pageData: pdfPageData,
				pdfData: layoutData,
			},
			{ isArray: isArrayObject },
		);

		if (results === null) {
			if (!fieldType.includes('array')) {
				setValue(name, '');
			}
			setError(name);
		} else {
			if (isArrayObject) {
				if (!Array.isArray(results)) {
					results = [];
				}

				const path = name.split('.');
				const basePath = path.slice(0, -1).join('.');
				const valueKey = path.slice(-1);
				results.forEach((value, index) => {
					if (fieldType === 'number') {
						value = Number.parseFloat(cleanNumber(value ?? ''));
					} else if (fieldType === 'signednumber') {
						value = Number.parseFloat(
							cleanNumber(value ?? '', { allowNegative: true }),
						);
					}

					if (Number.isNaN(value)) {
						value = null;
					}

					setValue([basePath, index, valueKey].join('.'), value, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				});
			} else {
				if (fieldType === 'number') {
					results = Number.parseFloat(cleanNumber(results));
				} else if (fieldType === 'signednumber') {
					results = Number.parseFloat(
						cleanNumber(results, { allowNegative: true }),
					);
				} else if (fieldType === 'numberString') {
					results = results.replace(/[^0-9]/g, '');
				} else if (Array.isArray(results)) {
					results = results.join(' ');
				}

				if (Number.isNaN(results)) {
					results = '';
				}

				setValue(name, results, {
					shouldValidate: true,
					shouldDirty: true,
					shouldTouch: true,
				});
			}
		}
	}, [
		operations,
		pdfPageData,
		layoutData,
		setValue,
		name,
		setError,
		fieldType,
		isArrayObject,
	]);

	const onInputChange = React.useCallback(
		(e) => {
			let results = e.target.value;
			if (fieldType === 'number') {
				results = Number.parseFloat(cleanNumber(results));
			} else if (fieldType === 'signednumber') {
				results = Number.parseFloat(
					cleanNumber(results, { allowNegative: true }),
				);
			} else if (fieldType === 'numberString') {
				results = results.replace(/[^0-9]/, '');
			}

			// onChange(e);

			setValue(name, results, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[name, setValue, fieldType],
	);

	const displayValue = React.useMemo(() => {
		if (isArrayObject) {
			return Object.values(value ?? {})
				.map((item) => item[name.split('.').slice(-1).join('')])
				.join(', ');
		}

		return value;
	}, [value, name, isArrayObject]);

	return (
		<Stack direction="row" flexGrow="1">
			<TextField
				id={name}
				label={label}
				variant="outlined"
				value={displayValue}
				fullWidth
				InputLabelProps={{ shrink: !!displayValue }}
				size="small"
				onChange={edit ? onInputChange : undefined}
			/>
			<IconButton aria-label="menu" onClick={handleClick}>
				<Edit />
			</IconButton>
		</Stack>
	);
};
