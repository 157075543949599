import React from 'react';

import { useSelector } from 'react-redux';

import { Info } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { formatISO } from 'date-fns';

import { CompanyService } from '@asteria/backend-utils-services';

import * as Hooks from '../../hooks';
import { selectToken } from '../../store/auth';

import CompanyDetailsModal from './CompanyDetailsModal';

const CompanyTable = (props) => {
	const accessToken = useSelector(selectToken);

	const [
		{ open: isDetailsOpen, state: detailsState },
		{ onOpen: onDetailsOpen, onClose: onDetailsClose },
	] = Hooks.modals.useModalState();

	const { data: companies, isLoading } = useQuery({
		queryKey: ['companies', 'list'],
		queryFn: () =>
			CompanyService.company
				.fetch(
					{
						pageFilters: { first: 0 },
						filters: { deleted: false },
						fields: 'edges { node { _id name orgnumber createdAt } }',
					},
					{ token: accessToken },
				)
				.then((response) =>
					(response?.edges ?? []).map(({ node }) => node),
				),
	});

	return (
		<>
			<CompanyDetailsModal
				open={isDetailsOpen}
				onClose={onDetailsClose}
				{...detailsState}
			/>
			<DataGrid
				disableRowSelectionOnClick
				columns={[
					{ field: '_id', headerName: 'ID', flex: 1 },
					{ field: 'name', headerName: 'Name', flex: 1 },
					{ field: 'orgnumber', headerName: 'Org Number', flex: 1 },
					{
						field: 'createdAt',
						headerName: 'Created',
						flex: 1,
						type: 'date',
						valueGetter: ({ value }) => value && new Date(value),
						valueFormatter: ({ value }) =>
							value &&
							formatISO(value, { representation: 'date' }),
					},
					{
						field: 'actions',
						type: 'actions',
						width: 40,
						getActions: (params) => [
							<GridActionsCellItem
								label="Details"
								onClick={() => onDetailsOpen({ id: params.id })}
								icon={<Info />}
							/>,
						],
					},
				]}
				rows={companies ?? []}
				loading={isLoading}
				getRowId={({ _id }) => _id}
				slots={{ toolbar: GridToolbar }}
				slotProps={{
					toolbar: {
						showQuickFilter: true,
					},
				}}
			/>
		</>
	);
};

export default CompanyTable;
