import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

import { InvoicesContext } from '../context';

const InvoiceSearch = ({ setSearch }) => {
	const handleChange = (event) => {
		setSearch(event?.target?.value);
	};

	return (
		<Input
			placeholder="Search..."
			sx={{ py: 1, width: '100%' }}
			startAdornment={
				<InputAdornment position="start">
					<SearchIcon />
				</InputAdornment>
			}
			onChange={handleChange}
		/>
	);
};

InvoiceSearch.displayName = 'InvoiceSearch';

InvoiceSearch.propTypes = {};

export default InvoiceSearch;
