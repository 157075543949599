import { formatISO, parse } from 'date-fns';

const formatDate = ({ op, node, results }) => {
	if (!results) {
		return results;
	}

	const matchingFormat = op.formats.find(({ matcher }) =>
		new RegExp(matcher).test(results),
	);

	if (!matchingFormat) {
		return [null, node];
	}

	const date = parse(results.trim(), matchingFormat.format, new Date());

	if (date instanceof Date && !isNaN(date)) {
		return [formatISO(date, { representation: 'date' }), node];
	}

	return [null, node];
};

export default formatDate;
