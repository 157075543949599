/* Constants */

export const REQUEST_INFLIGHT = 'REQUEST_INFLIGHT';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

/* Reducer */

export const initialState = { data: null, error: null, isLoading: false };

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_INFLIGHT:
			return { ...state, isLoading: true };

		case REQUEST_SUCCESS:
			return { ...state, data: action.payload, isLoading: false };

		case REQUEST_FAILURE:
			return { ...state, error: action.payload, isLoading: false };

		default:
			return state;
	}
};

/* Actions */

export const requestInflight = () => ({ type: REQUEST_INFLIGHT });

export const requestSuccess = (data) => ({
	type: REQUEST_SUCCESS,
	payload: data,
});

export const requestFailure = (error) => ({
	type: REQUEST_FAILURE,
	payload: error,
});
