import React from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Breadcrumbs, Link, Typography } from '@mui/material';
import { capitalize } from 'lodash';

function LinkRouter(props) {
	return <Link {...props} component={RouterLink} />;
}

const BreadcrumbsNavigation = () => {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);

	return (
		<>
			<Breadcrumbs aria-label="breadcrumb">
				<LinkRouter underline="hover" color="inherit" to="/">
					Home
				</LinkRouter>
				{pathnames.map((value, index) => {
					const last = index === pathnames.length - 1;
					const to = `/${pathnames.slice(0, index + 1).join('/')}`;

					return last ? (
						<Typography color="text.primary" key={to}>
							{capitalize(pathnames[index])}
						</Typography>
					) : (
						<LinkRouter
							underline="hover"
							color="inherit"
							to={to}
							key={to}
						>
							{capitalize(pathnames[index])}
						</LinkRouter>
					);
				})}
			</Breadcrumbs>
			<Typography
				component="h1"
				variant="h6"
				color="inherit"
				noWrap
				className="text-center"
				sx={{ flexGrow: 1 }}
			>
				{capitalize(pathnames[pathnames.length - 1])}
			</Typography>
		</>
	);
};

export default BreadcrumbsNavigation;
