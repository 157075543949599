import React, { useCallback, useState } from 'react';

import { MdDone, MdEdit } from 'react-icons/md';

import classNames from 'classnames';

import CircleMarker from '../core/CircleMarker';

const Box = ({ item, selected, setSelected, id }) => {
	const [editData, setEditData] = useState(0);
	const onClick = useCallback(
		(e) => {
			e.preventDefault();
			setSelected(!selected ? item.id : null);
		},
		[item, setSelected],
	);

	return (
		<div id={id} className="flex flex-nowrap basis-full mr-0 flex-grow">
			<div className="inline-block flex-grow">
				<div
					className={classNames(
						`flex items-center flex-col px-8 py-8 bg-white min-w-[9rem] h-full rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out`,
						selected
							? 'bg-gray-100 text-gray-900'
							: 'text-gray-700',
					)}
					onClick={onClick}
				>
					<div className="my-2">
						<CircleMarker color={item.color} />
					</div>
					<div className="flex flex-row">
						{item.editable ? (
							<>
								{editData ? (
									<>
										<input
											className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:ring-indigo-500 focus:border-indigo-500"
											placeholder="Enter value"
										/>
										<button
											className="ml-2 opacity-100 hover:opacity-100"
											onClick={() => setEditData(false)}
										>
											<MdDone />
										</button>
									</>
								) : (
									<>
										<h3 className="py-2 text-4xl font-bold font-mono">
											{item.value}
										</h3>
										<button
											className="ml-2 opacity-0 hover:opacity-100"
											onClick={() => setEditData(true)}
										>
											<MdEdit />
										</button>
									</>
								)}
							</>
						) : (
							<h3 className="pt-2 text-4xl font-bold font-mono">
								{item.value}
							</h3>
						)}
					</div>
					<p className="text-md whitespace-pre-line text-center">
						{item.desc}
					</p>
					<div className="mt-auto">
						<div className="py-1 text-gray-500">
							{item.category}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Box;
