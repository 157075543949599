import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, IconButton, Paper, Tab, Tabs, Typography } from '@mui/material';

import { TabContent } from '../common';

import BatchTabDetails from './details';
import BatchTabErrors from './errors';
import BatchTabInvoices from './invoices';

function BatchDetails() {
	const { companyId, batchId } = useParams();
	const navigate = useNavigate();

	const [tabIndex, setTabIndex] = React.useState(0);

	const onChangeTab = (event, value) => {
		setTabIndex(value);
	};

	return (
		<>
			<div className="mb-4 flex items-center gap-2">
				<IconButton aria-label="back" onClick={() => navigate(-1)}>
					<ChevronLeftIcon fontSize="large" />
				</IconButton>
				<Typography variant="h4">Batch details</Typography>
			</div>
			<Paper className="flex flex-col flex-1">
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={tabIndex}
						onChange={onChangeTab}
						aria-label="Company tabs"
					>
						<Tab
							label="Details"
							id="batch-tab-details"
							aria-controls="batch-tabpanel-details"
						/>
						<Tab
							label="Errors"
							id="batch-tab-errors"
							aria-controls="batch-tabpanel-errors"
						/>
						<Tab
							label="Invoices"
							id="batch-tab-invoices"
							aria-controls="batch-tabpanel-invoices"
						/>
					</Tabs>
				</Box>
				<TabContent active={tabIndex === 0} name="details">
					<BatchTabDetails id={batchId} companyId={companyId} />
				</TabContent>
				<TabContent active={tabIndex === 1} name="errors">
					<BatchTabErrors id={batchId} companyId={companyId} />
				</TabContent>
				<TabContent active={tabIndex === 2} name="invoices">
					<BatchTabInvoices id={batchId} companyId={companyId} />
				</TabContent>
			</Paper>
		</>
	);
}

export default BatchDetails;
