import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Visibility } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Paper } from '@mui/material';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridRowEditStopReasons,
	GridRowModes,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { set } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { useLocation, useNavigate } from 'react-router-dom';

import { ClientService, InvoiceService } from '@asteria/backend-utils-services';

import { selectToken } from '../../../store/auth';
import InvoiceSearch from '../components/Search';

import './styles.scss';

const InvoiceDetailsPage = (props) => {
	const accessToken = useSelector(selectToken);
	const [rowModesModel, setRowModesModel] = React.useState({});
	const [invoices, setInvoices] = React.useState([]);
	const { id: companyId } = useParams();
	const navigate = useNavigate();

	const handleEditClick = React.useMemo(
		() => (id) => () => {
			setRowModesModel({
				...rowModesModel,
				[id]: { mode: GridRowModes.Edit },
			});
		},
		[rowModesModel],
	);

	const handleSaveClick = React.useMemo(
		() => (id) => () => {
			setRowModesModel({
				...rowModesModel,
				[id]: { mode: GridRowModes.View },
			});
		},
		[rowModesModel],
	);

	const handleDeleteClick = React.useMemo(
		() => (id) => () => {
			setInvoices(
				invoices.map((row) =>
					row.id === id ? { ...row, $removing: !row.$removing } : row,
				),
			);
		},
		[invoices],
	);

	const handleCancelClick = React.useMemo(
		() => (id) => () => {
			setRowModesModel({
				...rowModesModel,
				[id]: { mode: GridRowModes.View, ignoreModifications: true },
			});

			const editedRow = invoices.find((row) => row.id === id);
			if (editedRow.isNew) {
				setInvoices(invoices.filter((row) => row.id !== id));
			}
		},
		[rowModesModel, invoices],
	);

	const onInvoiceUpdate = React.useCallback(
		async (newRow) => {
			const form = Object.entries(
				AsteriaCore.utils.flatObject(newRow),
			).reduce((acc, [key, value]) => set(acc, key, value), {});

			const invoice = { ...form };
			delete invoice['client'];
			delete invoice['sums']['original']['taxFaction'];
			delete invoice['createdAt'];
			delete invoice['updatedAt'];

			if (invoice['dates']['invoiceDue']) {
				invoice['dates']['invoiceDue'] = format(
					invoice['dates']['invoiceDue'],
					'yyyy-MM-dd',
				);
			}

			if (invoice['dates']['invoiceSent']) {
				invoice['dates']['invoiceSent'] = format(
					invoice['dates']['invoiceSent'],
					'yyyy-MM-dd',
				);
			}

			await InvoiceService.invoice.extension.update({
				input: invoice,
				companyId: companyId,
			});

			enqueueSnackbar(
				`Invoice with ID '${form.id}' successfully updated`,
				{ variant: 'success' },
			);

			const client = { ...form.client };
			client._id = client.id;
			delete client['id'];
			delete client['contact']['info'];

			await ClientService.client.update({
				input: client,
				companyId: companyId,
			});

			enqueueSnackbar(
				`Client with ID '${form.client.id}' successfully updated`,
				{ variant: 'success' },
			);

			setInvoices((invoices) =>
				invoices.map((row) => {
					if (row.id === form.id) {
						return form;
					}

					if (row.clientId === form.clientId) {
						return { ...row, client: form.client };
					}

					return row;
				}),
			);

			return form;
		},
		[companyId],
	);

	const onInvoiceRemove = React.useMemo(
		() => (id) => async () => {
			try {
				await InvoiceService.invoice.extension.remove({
					ids: id,
					companyId: companyId,
				});
			} catch (err) {
				enqueueSnackbar(err.message, { variant: 'error' });
				return;
			}

			enqueueSnackbar(`Invoice with ID '${id}' successfully deleted`, {
				variant: 'success',
			});

			setInvoices((rows) => rows.filter((row) => row.id !== id));
		},
		[companyId],
	);

	const openLayout = React.useMemo(
		() => (id) => () => {
			const invoice = invoices.find((row) => row.id === id);


			navigate(`/invoices/layout/${invoice.invoiceLayout}`);
		},
		[invoices, navigate],
	);

	const columns = React.useMemo(() => {
		return [
			{ field: 'id', headerName: 'ID', width: 90 },
			{
				field: 'meta.invoiceNumber',
				headerName: 'Invoice Number',
				valueGetter: (params) => params.row?.meta?.invoiceNumber,
				editable: true,
			},
			{
				field: 'type',
				headerName: 'Type',
				valueGetter: (params) => params.row?.type,
				editable: true,
			},
			{
				field: 'client.name',
				headerName: 'Client Name',
				valueGetter: (params) => params.row?.client?.name,
				editable: true,
				width: 200,
			},
			{
				field: 'client.meta.clientNumber',
				headerName: 'Client Number',
				valueGetter: (params) => params.row?.client?.meta?.clientNumber,
				editable: true,
			},
			{
				field: 'client.contact.billing.street',
				headerName: 'Street',
				valueGetter: (params) =>
					params.row?.client?.contact?.billing?.street,
				editable: true,
				width: 200,
			},
			{
				field: 'client.contact.billing.street2',
				headerName: 'CO',
				valueGetter: (params) =>
					params.row?.client?.contact?.billing?.street2,
				editable: true,
			},
			{
				field: 'client.contact.billing.zipcode',
				headerName: 'ZipCode',
				valueGetter: (params) =>
					params.row?.client?.contact?.billing?.zipcode,
				editable: true,
			},
			{
				field: 'client.contact.billing.city',
				headerName: 'city',
				valueGetter: (params) =>
					params.row?.client?.contact?.billing?.city,
				editable: true,
				width: 150,
			},
			{
				field: 'client.contact.billing.country',
				headerName: 'Country',
				valueGetter: (params) =>
					params.row?.client?.contact?.billing?.country,
				editable: true,
				width: 25,
			},
			{
				field: 'client.info.language',
				headerName: 'Lang',
				valueGetter: (params) => params.row?.client?.info?.language,
				editable: true,
				width: 25,
			},
			{
				field: 'sums.original.total',
				type: 'number',
				headerName: 'Total',
				valueGetter: (params) => params.row?.sums?.original?.total,
				editable: true,
			},
			{
				field: 'sums.original.tax',
				type: 'number',
				headerName: 'VAT',
				valueGetter: (params) => params.row?.sums?.original?.tax,
				editable: true,
			},
			{
				field: 'sums.original.taxFaction',
				headerName: 'VAT %',
				valueGetter: (params) =>
					Math.round(
						(params.row?.sums?.original?.tax /
							params.row?.sums?.original?.total) *
							100,
					),
				width: 30,
				editable: true,
			},
			{
				field: 'sums.original.currency',
				headerName: 'Currency',
				valueGetter: (params) => params.row?.sums?.original?.currency,
				editable: true,
			},
			{
				field: 'dates.invoiceSent',
				type: 'date',
				headerName: 'Sent',
				valueGetter: (params) =>
					parseISO(params.row?.dates?.invoiceSent),
				editable: true,
				width: 100,
			},
			{
				field: 'dates.invoiceDue',
				type: 'date',
				headerName: 'Due',
				valueGetter: (params) =>
					parseISO(params.row?.dates?.invoiceDue),
				editable: true,
				width: 100,
			},
			{
				field: 'createdAt',
				type: 'dateTime',
				headerName: 'Created AT',
				valueGetter: (params) => params.row?.createdAt,
				width: 100,
			},
			{
				field: 'updatedAt',
				type: 'dateTime',
				headerName: 'Updated AT',
				valueGetter: (params) => params.row?.updatedAt ?? params.row?.createdAt,
				width: 100,
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				getActions: ({ id, row }) => {
					const isInEditMode =
						rowModesModel[id]?.mode === GridRowModes.Edit;

					if (isInEditMode) {
						return [
							<GridActionsCellItem
								icon={<SaveIcon />}
								label="Save"
								sx={{
									color: 'primary.main',
								}}
								onClick={handleSaveClick(id)}
							/>,
							<GridActionsCellItem
								icon={<CancelIcon />}
								label="Cancel"
								className="textPrimary"
								onClick={handleCancelClick(id)}
								color="inherit"
							/>,
						];
					}

					if (row?.$removing) {
						return [
							<GridActionsCellItem
								icon={<DeleteIcon />}
								label="Save"
								sx={{ color: 'primary.main' }}
								onClick={onInvoiceRemove(id)}
							/>,
							<GridActionsCellItem
								icon={<CancelIcon />}
								label="Cancel"
								className="textPrimary"
								onClick={handleDeleteClick(id)}
								color="inherit"
							/>,
						];
					}

					return [
						<GridActionsCellItem
							icon={<EditIcon />}
							label="Edit"
							className="textPrimary"
							onClick={handleEditClick(id)}
							color="inherit"
						/>,
						<GridActionsCellItem
							icon={<DeleteIcon />}
							label="Delete"
							onClick={handleDeleteClick(id)}
							color="inherit"
						/>,
						<GridActionsCellItem
							icon={<Visibility />}
							label="View Print"
							color="inherit"
							onClick={openLayout(id)}
						/>,
					];
				},
			},
		];
	}, [
		rowModesModel,
		handleEditClick,
		handleDeleteClick,
		handleSaveClick,
		handleCancelClick,
		onInvoiceRemove,
	]);

	const { data: response, isLoading } = useQuery({
		queryKey: ['company', companyId, 'invoices'],
		queryFn: () =>
			InvoiceService.invoice.extension.companyInvoices(
				{
					pageFilters: { last: 0, orderField: 'createdAt' },
					companyId: companyId,
					fields: `
							edges {
								node {
									id
									type
									clientType
									clientId
									client {
										id
										name
										type
										meta {
											clientNumber
											source
										}
										info {
											language
										}

										contact {
											billing {
												street
												street2
												zipcode
												city
												country
											}
										}
									}
									meta {
										invoiceNumber
									}
									sums {
										original {
											total
											tax
											currency
										}
									}
									dates {
										invoiceSent
										invoiceDue
									}
									invoiceLayout
									createdAt
									updatedAt
								}
							}
							pageInfo {
								hasNextPage
								hasPreviousPage
								currentCursor
								count
								startCursor
								endCursor
							}
					`,
				},
				{ token: accessToken },
			),
	});

	React.useEffect(() => {
		if (!response) {
			setInvoices([]);
			return;
		}
		setInvoices(
			response.edges.map(({ node }) => ({
				...node,
				createdAt: new Date(node.createdAt),
				updatedAt: new Date(node.updatedAt),
			})),
		);
	}, [response]);

	const handleRowModesModelChange = React.useCallback((newRowModesModel) => {
		setRowModesModel(newRowModesModel);
	}, []);

	const handleRowEditStop = React.useCallback((params, event) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	}, []);

	const onProcessRowUpdateError = React.useCallback((e) => {
		enqueueSnackbar(e.message, {
			variant: 'error',
		});
	}, []);

	return (
		<Paper
			elevation={0}
			sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<InvoiceSearch />
			</Box>
			<DataGrid
				rows={invoices}
				columns={columns}
				loading={isLoading}
				pageSizeOptions={[25, 50, 100]}
				checkboxSelection
				disableRowSelectionOnClick
				pagination
				editMode="row"
				rowModesModel={rowModesModel}
				onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={onInvoiceUpdate}
				onProcessRowUpdateError={onProcessRowUpdateError}
			/>
		</Paper>
	);
};

InvoiceDetailsPage.displayName = 'InvoiceDetailsPage';

InvoiceDetailsPage.propTypes = { className: PropTypes.string };

export default InvoiceDetailsPage;
