import React from 'react';

import { Route, Routes } from 'react-router-dom';

import BatchDetailsPage from './details';
import CompanyBatchesPage from './list';
import NotFound from './not-found';

function BatchPage(props) {
	return (
		<Routes>
			<Route path=":companyId" element={<CompanyBatchesPage />} />
			<Route path=":companyId/:batchId" element={<BatchDetailsPage />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default BatchPage;
