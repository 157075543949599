import React from 'react';

import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { InvoicesContext } from '../context';

import InvoicesLayoutTab from './layouts';
import InvoicesListTab from './list';
import CompaniesLayoutTab from './companies';

const CustomTabPanel = (props) => {
	const { children, value, index } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && (
				<Paper variant="outlined" sx={{ p: 2 }} elevation={0}>
					{children}
				</Paper>
			)}
		</div>
	);
};

CustomTabPanel.displayName = 'CustomTabPanel';

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	value: PropTypes.string,
	index: PropTypes.string,
};

const InvoicesPage = (props) => {
	const [value, setValue] = React.useState('prints');

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [filters, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'SEARCH':
					return { ...state, search: action?.payload };

				case 'TYPE':
					return {
						...state,
						type: action?.payload,
					};

				case 'AUTO_APPROVE':
					return {
						...state,
						autoApproval: action?.payload,
					};

				default:
					return state;
			}
		},
		{ type: 'all', search: null, autoApproval: false },
	);

	const onAction = React.useCallback((action, data) => {
		if (action === 'filters:search') {
			dispatch({ type: 'SEARCH', payload: data });
		}

		if (action === 'filters:type') {
			dispatch({ type: 'TYPE', payload: data });
		}

		if (action === 'filters:auto-approval') {
			dispatch({ type: 'AUTO_APPROVE', payload: data });
		}
	}, []);

	const ctx = React.useMemo(
		() => ({ onAction: onAction, filters: filters }),
		[filters, onAction],
	);

	return (
		<InvoicesContext.Provider value={ctx}>
			<Paper
				elevation={0}
				sx={{
					height: '100%',
					p: 4,
				}}
			>
				<Typography variant="h2" gutterBottom>
					Printed Invoices
				</Typography>

				<Box sx={{ width: '100%' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						<Tab label="Companies" value="companies" />
						<Tab label="Prints" value="prints" />
						<Tab label="Invoices" value="list" />
					</Tabs>
					<CustomTabPanel value={value} index="companies">
						<CompaniesLayoutTab />
					</CustomTabPanel>
					<CustomTabPanel value={value} index="prints">
						<InvoicesLayoutTab />
					</CustomTabPanel>
					<CustomTabPanel value={value} index="list">
						<InvoicesListTab />
					</CustomTabPanel>
				</Box>
			</Paper>
		</InvoicesContext.Provider>
	);
};

InvoicesPage.displayName = 'InvoicesPage';

InvoicesPage.propTypes = { className: PropTypes.string };

export default InvoicesPage;
