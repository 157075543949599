import React from 'react';

import { useSelector } from 'react-redux';

import { Grid, Paper, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import {
	CompanyService,
	PartnerService,
} from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';

import CompanyTable from './CompanyTable';

const HomePage = (props) => {
	const accessToken = useSelector(selectToken);

	const { data: partners } = useQuery({
		queryKey: ['partners', 'count'],
		queryFn: () =>
			PartnerService.partner
				.fetch(
					{ fields: 'pageInfo { count } edges { node { _id } }' },
					{ token: accessToken },
				)
				.then((response) => response?.pageInfo?.count),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	const { data: companies } = useQuery({
		queryKey: ['companies', 'count'],
		queryFn: () =>
			CompanyService.company
				.fetch(
					{
						fields: 'pageInfo { count } edges { node { _id } }',
						filters: { deleted: false },
					},
					{ token: accessToken },
				)
				.then((response) => response?.pageInfo?.count),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	return (
		<Stack
			direction="column"
			spacing={2}
			sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
		>
			<Grid container spacing={2}>
				<Grid item xs>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>Partners</Typography>
						<Typography variant="h5">{partners ?? 0}</Typography>
					</Paper>
				</Grid>

				<Grid item xs>
					<Paper sx={{ p: 2, textAlign: 'center' }}>
						<Typography gutterBottom>Companies</Typography>
						<Typography variant="h5">{companies ?? 0}</Typography>
					</Paper>
				</Grid>
			</Grid>

			<Paper sx={{ p: 2, flex: 1 }}>
				<CompanyTable />
			</Paper>
		</Stack>
	);
};

export default HomePage;
