import React, { useEffect } from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Brush, Edit, Refresh, Settings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Button,
	Checkbox,
	FormControl,
	IconButton,
	InputLabel,
	ListItemIcon,
	Menu,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';
import processor from '../../utils/processor';

const StaticColumns = [
	'productId',
	'description',
	'qty',
	'qtyUnit',
	'qtyAndUnit',
	'price',
	'discount',
	'total',
	'vat',
	'vatIndex',
];

const ColumnsConfig = (props) => {
	const { baseName, index } = props;
	const { register, setValue, setError, watch } = useFormContext();
	const column = useWatch({ name: `${baseName}.${index}` });
	const columnValues = useWatch({ name: `invoice.columns` });

	const results = Object.values(columnValues ?? {}).find(
		({ key }) => key === column?.key,
	);

	const isCustom = !StaticColumns.includes(column?.key) && column?.key;

	return (
		<Stack direction="row">
			<TextField
				label="Selector"
				variant="outlined"
				value={column?.selector}
				{...register(`${baseName}.${index}.selector`)}
			/>
			<FormControl fullWidth>
				<InputLabel id={`layout-columns-${index}-field-label`}>
					Column Type
				</InputLabel>
				{!isCustom ? (
					<Select
						label="Column Field"
						labelId={`layout-columns-${index}-field-field`}
						id={`layout-columns-${index}-field`}
						value={column?.key}
						{...register(`${baseName}.${index}.key`)}
					>
						<MenuItem value="custom">Text</MenuItem>
						<MenuItem value="productId">Product ID</MenuItem>
						<MenuItem value="description">Description</MenuItem>
						<MenuItem value="qty">Count</MenuItem>
						<MenuItem value="qtyUnit">Count Type</MenuItem>
						<MenuItem value="qtyAndUnit">Count And Type</MenuItem>
						<MenuItem value="price">Price</MenuItem>
						<MenuItem value="discount">Discount</MenuItem>
						<MenuItem value="total">Total</MenuItem>
						<MenuItem value="vat">VAT</MenuItem>
						<MenuItem value="vatIndex">VAT Index</MenuItem>
					</Select>
				) : (
					<TextField
						label="Key"
						variant="outlined"
						{...register(`${baseName}.${index}.key`)}
					/>
				)}
			</FormControl>
			<TextField
				label="Result"
				variant="outlined"
				value={results?.label ?? ''}
				InputProps={{
					readOnly: true,
				}}
			/>
			<Checkbox
				checked={column?.required ?? false}
				onChange={(event) => {
					setValue(
						`${baseName}.${index}.required`,
						event.target.checked,
					);
				}}
			/>
		</Stack>
	);
};

export const ColumnsField = (props) => {
	const { required, setSelectedField } = props;
	const { pdfPageData, layoutData } = React.useContext(FormContext);

	const baseName = `layout.configuration.columns`;
	const { fields, append, remove } = useFieldArray({
		name: baseName,
	});

	const onAdd = React.useCallback(() => {
		append({ key: '' });
	}, [append]);

	const handleClick = React.useCallback(
		(event) => {
			setSelectedField('columns');
		},
		[setSelectedField],
	);

	const { register, setValue, setError } = useFormContext();
	const operations = useWatch({ name: `layout.operations.columns` });
	const config = useWatch({ name: `layout.configuration.columns` });
	const { onChange, ...input } = register('invoice.columns', {
		required: required,
	});

	useEffect(() => {
		if (!operations) {
			return;
		}

		const { results } = processor({
			operations: Object.values(operations),
			pageData: pdfPageData,
			pdfData: layoutData,
		});

		if (results === null) {
			setValue('invoice.columns', []);
			setError('invoice.columns');
		} else {
			let offsetIndex = 0;
			const columns = Object.values(config ?? {})
				.map(({ selector, key }, index) => {
					if (selector) {
						// TODO: Fix it
						if (
							!new RegExp(selector).test(
								results[index + offsetIndex],
							)
						) {
							offsetIndex -= 1;
							return null;
						}
					}

					return {
						key: key,
						label: results[index + offsetIndex] ?? '',
					};
				})
				.filter(Boolean);

			setValue('invoice.columns', columns);
		}
	}, [operations, pdfPageData, layoutData, setValue, setError, config]);

	return (
		<Stack direction="column">
			<IconButton aria-label="menu" onClick={handleClick}>
				<Edit />
			</IconButton>
			{fields.map((column, index) => (
				<ColumnsConfig
					key={column.id}
					baseName={baseName}
					index={index}
				/>
			))}
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new column
			</Button>
		</Stack>
	);
};
