import React from 'react';

import { FormControlLabel, Switch } from '@mui/material';

export function ClientSwitch({ checked, onChange }) {
	return (
		<FormControlLabel
			control={<Switch checked={checked} onChange={onChange} />}
			label="Load client"
			labelPlacement="end"
			sx={{
				whiteSpace: 'nowrap',
			}}
		/>
	);
}
