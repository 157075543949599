export const rangeType = {
	YEAR: 'YEAR',
	MONTH: 'MONTH',
	WEEK: 'WEEK',
	DAY: 'DAY',
};

export const dialogTypes = {
	EDIT_COMPANY: 'editCompany',
	DELETE_COMPANY: 'deleteCompany',
	DISABLE_COMPANY: 'disableCompany',
	VIEW_COMPANY: 'viewCompany',
	CREATE_PARTNER: 'createPartner',
};
