import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Close, Edit, Refresh, Save } from '@mui/icons-material';
import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	TextField,
} from '@mui/material';

import * as LayoutsStore from '../../store/layouts';
import Form from '../form';

import './styles.scss';

const ManageTemplateContent = (props) => {
	const { onClose, data, onSubmit } = props;

	const { register } = useFormContext();

	const onRemove = async (event) => {
		await onSubmit?.('invoice:layout:template:delete', { id: data?.id });

		return onClose?.(event);
	};

	return (
		<>
			<DialogTitle>
				{data?.id ? 'Update template' : 'Create template'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Veniam magni aut accusantium et ipsum hic. Esse eum enim.
					Accusamus esse molestias quam rerum suscipit ab adipisci. Ex
					exercitationem doloremque. Id dolorem suscipit corrupti
					consequatur iure.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					fullWidth
					variant="standard"
					{...register('name', { required: true })}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				{data?.id ? <Button onClick={onRemove}>Remove</Button> : null}
				<Button type="submit">Save</Button>
			</DialogActions>
		</>
	);
};

const ManageTemplate = (props) => {
	const { open, onClose, data, onSubmit } = props;

	const layout = useWatch({ name: 'layout' });

	const handleSubmit = React.useCallback(
		async (form) => {
			if (form?.id) {
				await onSubmit?.('invoice:layout:template:update', form);
			} else {
				await onSubmit?.('invoice:layout:template:create', form);
			}

			return onClose?.();
		},
		[onClose, onSubmit],
	);

	return (
		<Dialog open={open} onClose={onClose} scroll="paper">
			<Form onSubmit={handleSubmit} values={{ ...data, layout: layout }}>
				<ManageTemplateContent {...props} />
			</Form>
		</Dialog>
	);
};

const TemplateSelector = React.memo((props) => {
	const { onAction, onSubmit, selectedTemplate } = props;

	const templates = useSelector(LayoutsStore.selectors.templates);
	let template = useSelector(LayoutsStore.selectors.template);

	if (selectedTemplate) {
		template = selectedTemplate.value;
	}

	const [search, setSearch] = React.useState('');

	const [{ open: isModalOpen, state: modalState }, dispatch] =
		React.useReducer(
			(state, action) => {
				switch (action?.type) {
					case 'OPEN':
						return {
							...state,
							open: true,
							state: action?.payload ?? state?.state,
						};

					case 'CLOSE':
						return { ...state, open: false, state: {} };

					default:
						return state;
				}
			},
			{ open: false, state: {} },
		);

	const [dropdownRef, setDropdownRef] = React.useState(null);
	const isDropdownOpen = Boolean(dropdownRef);

	const onModalOpen = (state) => {
		dispatch({ type: 'OPEN', payload: state });
	};

	const onModalClose = () => {
		dispatch({ type: 'CLOSE' });
	};

	const onDropdownOpen = (event) => {
		setDropdownRef(event.currentTarget);
	};

	const onDropdownClose = () => {
		setDropdownRef(null);
	};

	const onCreate = (event) => {
		onDropdownClose(event);
		onModalOpen();
	};

	const onUpdate = (data) => {
		return (event) => {
			onDropdownClose(event);
			onModalOpen({ data: data });
		};
	};

	const onSelect = (template) => {
		return (event) => {
			if (!selectedTemplate) {
				onAction?.('template:select', template);
			} else {
				selectedTemplate.value = template;
			}

			onDropdownClose(event);
		};
	};

	const objects = templates.filter((object) =>
		object?.name?.toLowerCase?.()?.includes?.(search.toLowerCase()),
	);

	return (
		<>
			<ManageTemplate
				open={isModalOpen}
				onClose={onModalClose}
				onSubmit={onSubmit}
				{...modalState}
			/>
			<Grid item xs>
				<Button
					aria-controls={isDropdownOpen ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={isDropdownOpen ? 'true' : undefined}
					onClick={onDropdownOpen}
					fullWidth
				>
					{template ? template?.name : 'Template'}
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={dropdownRef}
					open={isDropdownOpen}
					onClose={onDropdownClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<List
						disablePadding
						sx={{
							overflow: 'auto',
							maxHeight: 300,
						}}
					>
						<ListItem disablePadding>
							<ListItemButton onClick={onCreate}>
								<ListItemText primary="Create" />
							</ListItemButton>
						</ListItem>

						{templates.length ? (
							<>
								<Divider />
								<ListItem>
									<TextField
										id="search"
										label="Search"
										variant="outlined"
										name="search"
										autoComplete="false"
										size="small"
										value={search}
										onChange={(event) =>
											setSearch(event?.target?.value)
										}
									/>
								</ListItem>
								<Divider />
								{[]
									.concat([
										{ _id: null, name: 'Not selected' },
									])
									.concat(objects)

									.map((object, index) => (
										<ListItem
											key={object?._id ?? index}
											secondaryAction={
												object?._id ? (
													<IconButton
														edge="end"
														size="small"
														onClick={onUpdate({
															id: object?._id,
															name: object?.name,
														})}
													>
														<Edit fontSize="small" />
													</IconButton>
												) : null
											}
											disablePadding
										>
											<ListItemButton
												onClick={onSelect(object)}
											>
												<ListItemText
													primary={object?.name}
												/>
											</ListItemButton>
										</ListItem>
									))}
							</>
						) : null}
					</List>
				</Menu>
			</Grid>
			<Grid item xs="auto">
				<ButtonGroup>
					<IconButton
						onClick={() =>
							onSubmit?.('invoice:layout:template:save')
						}
						disabled={!template}
						size="small"
					>
						<Save fontSize="small" />
					</IconButton>
					<IconButton
						onClick={() =>
							onSubmit?.('invoice:layout:sums:refresh')
						}
						disabled={!template}
						size="small"
					>
						<Refresh fontSize="small" />
					</IconButton>
					<IconButton
						onClick={() =>
							onSubmit?.('invoice:layout:template:refresh')
						}
						disabled={!template}
						size="small"
					>
						<Refresh fontSize="small" />
					</IconButton>
				</ButtonGroup>
			</Grid>
		</>
	);
});

TemplateSelector.displayName = 'TemplateSelector';

export default TemplateSelector;
