import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PartnerAdd from './components/add';
import PartnerEdit from './components/edit';
import PartnerList from './components/list';
import PartnerView from './components/view';

export default function PartnersPage() {
	return (
		<Routes>
			<Route path="add" element={<PartnerAdd />} />
			<Route path="edit/:id" element={<PartnerEdit />} />
			<Route path="view/:id" element={<PartnerView />} />
			<Route path="" element={<PartnerList />}></Route>
		</Routes>
	);
}
