const move = ({ op, node }) => {
	let operationResults = null;
	const isArray = Array.isArray(node);
	const nodes = isArray ? node : [node];
	node = [];
	for (let i = 0; i < nodes.length; i += 1) {
		let nextNode = null;

		const threshold = (op.threshold ?? 100) / 100;

		if (op.dir === 'DOWN') {
			nextNode = nodes[i]?.siblings?.down;
		}

		if (op.dir === 'UP') {
			nextNode = nodes[i]?.siblings?.up;
		}

		if (op.dir === 'LEFT') {
			nextNode = nodes[i]?.siblings?.left;
		}

		if (op.dir === 'RIGHT') {
			nextNode = nodes[i]?.siblings?.right;
		}

		if (op.dir === 'TOP') {
			for (
				let c = nodes[i];
				c.siblings?.up && c?.siblings?.up?.distance <= threshold;
				c = c.siblings?.up?.node
			) {
				nextNode = c.siblings.up;
			}
		}

		if (op.dir === 'BOTTOM') {
			for (
				let c = nodes[i];
				c.siblings?.down && c?.siblings?.down?.distance <= threshold;
				c = c.siblings?.down?.node
			) {
				nextNode = c.siblings.down;
			}
		}

		if (op.dir === 'END') {
			nextNode = nodes[i]?.siblings?.end;
		}

		if (!nextNode) {
			return [operationResults, null];
		}

		node.push(nextNode.distance <= threshold ? nextNode.node : null);
	}

	if (node === null || node.includes(null)) {
		return [operationResults, null];
	}

	if (!isArray) {
		node = node[0];
	}

	return [operationResults, node];
};

export default move;
