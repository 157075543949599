import { useChats, useSubscription } from '../../pages/chats/pages/list/hooks';

function getUnreadMessages(messages) {
	if (!messages) {
		return 0;
	}

	const unreadMessages = messages?.filter(
		(object) =>
			object?.createdBy?.type !== 'AdminToken' && object?.read === false,
	);

	return unreadMessages?.length;
}

export function useBadge(item) {
	const chats = useChats();

	if (item?.name === 'chats') {
		const pendingChats = chats?.data.filter(
			({ status }) => status === 'PENDING',
		);

		return pendingChats?.reduce((acc, item) => {
			return acc + getUnreadMessages(item?.messages);
		}, 0);
	}

	return null;
}
