import React from 'react';

import { Accordion, AccordionDetails, Paper, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { Controls } from './controls';
import { FieldEdit } from './edit';
import Fields from './fields';
import { Links } from './links';

const SidePane = (props) => {
	const {
		id,
		selectedInvoice,
		selectedTemplate,
		setInvoice,
		invoices,
		invoicePages,
		loadClient,
		setLoadClient,
		autoRefreshData,
		setAutoRefreshData,
		onAction,
		onSubmit,
		url,
		showOCR,
	} = props;

	const [field, setField] = React.useState(null);

	return (
		<Stack
			px={2}
			spacing={2}
			sx={{ overflow: 'hidden', flexGrow: 1, overflow: 'hidden' }}
		>
			<Paper>
				<Controls
					id={id}
					selectedInvoice={selectedInvoice}
					setInvoice={setInvoice}
					invoices={invoices}
					invoicePages={invoicePages}
					loadClient={loadClient}
					setLoadClient={setLoadClient}
					autoRefreshData={autoRefreshData}
					setAutoRefreshData={setAutoRefreshData}
					onAction={onAction}
					onSubmit={onSubmit}
					selectedTemplate={selectedTemplate}
				/>
			</Paper>
			<Paper sx={{ flexGrow: 1, overflow: 'auto' }}>
				<Accordion defaultExpanded>
					<AccordionDetails>
						{field ? (
							<FieldEdit field={field} setField={setField} />
						) : null}
						<Fields setSelectedField={setField} show={!field} />
					</AccordionDetails>
				</Accordion>
			</Paper>
			<Paper>
				<Links url={url} showOCR={showOCR} />
			</Paper>
		</Stack>
	);
};

SidePane.displayName = 'SidePane';

SidePane.propTypes = { className: PropTypes.string };

export default SidePane;
