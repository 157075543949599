import { compareAsc, parseISO } from 'date-fns';

export function formatChatMessage(node) {
	return {
		...node,
		createdAt: node?.createdAt ? parseISO(node?.createdAt) : null,
		updatedAt: node?.updatedAt ? parseISO(node?.updatedAt) : null,
		deletedAt: node?.deletedAt ? parseISO(node?.deletedAt) : null,
	};
}

export function formatChat(node) {
	return {
		...node,
		createdAt: node?.createdAt ? parseISO(node?.createdAt) : null,
		updatedAt: node?.updatedAt ? parseISO(node?.updatedAt) : null,
		deletedAt: node?.deletedAt ? parseISO(node?.deletedAt) : null,
		messages: (node?.messages?.edges ?? [])
			.map(({ node }) => formatChatMessage(node))
			.sort(({ createdAt: a }, { createdAt: b }) => compareAsc(a, b)),
	};
}
