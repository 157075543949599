import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { FormControlLabel, Switch } from '@mui/material';

export function CreditSwitch() {
	const { register, setValue } = useFormContext();

	const form = useWatch();

	return (
		<FormControlLabel
			control={
				<Switch
					defaultValue="invoice"
					{...register('isCredit')}
					checked={
						form?.isCredit !== 'false' &&
						form?.isCredit !== false &&
						form?.isCredit !== 'credit' &&
						!!form?.isCredit
					}
					onChange={(e) => {
						setValue('isCredit', e.target.checked);
					}}
				/>
			}
			label="Credit"
			labelPlacement="end"
			sx={{ whiteSpace: 'nowrap' }}
		/>
	);
}
