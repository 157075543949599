import processor from './processor';

const getInvoicePages = ({ operations, pages }) => {
	// Attempt to detect invoice pages

	if (!operations) {
		return [];
	}

	const invoiceNumbers = pages.map((pageData) => {
		const { results } = processor({
			operations: operations,
			pdfData: { pdfData: { Pages: [pageData] } },
		});

		return results;
	});

	const invoices = [];
	let startPage = 0;
	for (let i = 1; i < invoiceNumbers.length; i += 1) {
		if (
			invoiceNumbers[i].replace(/ /g, '') !==
			invoiceNumbers[i - 1].replace(/ /g, '')
		) {
			invoices.push({
				startPage,
				endPage: i - 1,
				pages: pages.slice(startPage, i),
				invoiceNumber: invoiceNumbers[i - 1].replace(/ /g, ''),
			});
			startPage = i;
		}
	}

	invoices.push({
		startPage,
		endPage: invoiceNumbers.length - 1,
		pages: pages.slice(startPage),
		invoiceNumber: invoiceNumbers[startPage],
	});

	return invoices;
};

export default getInvoicePages;
