import React from 'react';

import { useFormContext } from 'react-hook-form';

import {
	FormControl,
	Grid,
	Input,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

export const Read = (props) => {
	const { name, operation } = props;
	const { register } = useFormContext();

	return (
		<Stack>
			<InputLabel id="demo-simple-select-label">Items</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={operation.items ?? 'one'}
				label="Operation"
				{...register(`${name}.items`)}
			>
				<MenuItem value="one">One</MenuItem>
				<MenuItem value="line">Line</MenuItem>
				<MenuItem value="lines">Lines</MenuItem>
				<MenuItem value="column">Column</MenuItem>
				<MenuItem value="table">Table</MenuItem>
			</Select>
			<Typography>Threshold</Typography>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs>
					<Slider
						value={operation.threshold ?? 100}
						max={100}
						min={0}
						aria-label="Threshold"
						valueLabelDisplay="on"
						{...register(`${name}.threshold`, { value: 100 })}
					/>
				</Grid>
				<Grid item>
					<Input
						value={operation.threshold ?? 100}
						size="small"
						{...register(`${name}.threshold`, { value: 100 })}
					/>
				</Grid>
			</Grid>
			{operation.items === 'one' || !operation.items ? (
				<Grid container spacing={2} alignItems="center">
					<Grid item xs>
						<Slider
							value={operation.thresholdWord ?? 0}
							max={100}
							min={0}
							aria-label="Threshold"
							valueLabelDisplay="on"
							{...register(`${name}.thresholdWord`, {
								value: 0,
							})}
						/>
					</Grid>
					<Grid item>
						<Input
							value={operation.thresholdWord ?? 0}
							size="small"
							{...register(`${name}.thresholdWord`, {
								value: 0,
							})}
						/>
					</Grid>
				</Grid>
			) : null}
			{operation.items === 'line' ? (
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label2">
						Direction
					</InputLabel>
					<Select
						labelId="demo-simple-select-label2"
						id="demo-simple-select2"
						value={operation.direction ?? 'both'}
						label="Operation"
						{...register(`${name}.direction`)}
					>
						<MenuItem value="before">Before</MenuItem>
						<MenuItem value="after">After</MenuItem>
						<MenuItem value="both">Both</MenuItem>
					</Select>
				</FormControl>
			) : null}
			{operation.items === 'line' ? (
				<Grid container spacing={2} alignItems="center">
					<Grid item xs>
						<Slider
							value={operation.wordDistance ?? 100}
							max={100}
							min={1}
							aria-label="Word Distance"
							valueLabelDisplay="on"
							{...register(`${name}.wordDistance`, {
								value: 100,
							})}
						/>
					</Grid>
					<Grid item>
						<Input
							value={operation.wordDistance ?? 100}
							size="small"
							{...register(`${name}.wordDistance`, {
								value: 100,
							})}
						/>
					</Grid>
				</Grid>
			) : null}
			{operation.items === 'table' ? (
				<Grid container spacing={2} alignItems="center">
					<Grid item xs>
						<Slider
							value={operation.linethreshold ?? 5}
							max={100}
							min={1}
							aria-label="Threshold"
							valueLabelDisplay="on"
							{...register(`${name}.linethreshold`, {
								value: 5,
							})}
						/>
					</Grid>
					<Grid item>
						<Input
							value={operation.linethreshold ?? 5}
							size="small"
							{...register(`${name}.linethreshold`, {
								value: 5,
							})}
						/>
					</Grid>
				</Grid>
			) : null}
			{operation.items === 'line' ? (
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Input
							value={operation.wordCount ?? 0}
							size="small"
							{...register(`${name}.wordCount`, {
								value: 0,
							})}
						/>
					</Grid>
				</Grid>
			) : null}
			{operation.items === 'column' ? (
				<>
					<Typography>Threshold Width</Typography>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs>
							<Slider
								value={operation.thresholdWidth ?? 0}
								max={100}
								min={0}
								aria-label="Threshold Width"
								valueLabelDisplay="on"
								{...register(`${name}.thresholdWidth`, {
									value: 0,
								})}
							/>
						</Grid>
						<Grid item>
							<Input
								value={operation.thresholdWidth ?? 0}
								size="small"
								{...register(`${name}.thresholdWidth`, {
									value: 0,
								})}
							/>
						</Grid>
					</Grid>
				</>
			) : null}
			{operation.items === 'column' ? (
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Input
							value={operation.stopper}
							placeholder="Stopper"
							size="small"
							{...register(`${name}.stopper`)}
						/>
					</Grid>
				</Grid>
			) : null}
			{operation.items === 'table' ? (
				<Grid container spacing={2} alignItems="center">
					<Grid item xs>
						<Slider
							value={operation.linemerge ?? 1.3}
							max={100}
							min={1}
							aria-label="Threshold"
							valueLabelDisplay="on"
							{...register(`${name}.linemerge`, {
								value: 1.3,
							})}
						/>
					</Grid>
					<Grid item>
						<Input
							value={operation.linemerge ?? 1.3}
							size="small"
							{...register(`${name}.linemerge`, {
								value: 1.3,
							})}
						/>
					</Grid>
					<Grid item>
						<Input
							value={operation.stopper}
							placeholder="Stopper"
							size="small"
							{...register(`${name}.stopper`)}
						/>
					</Grid>
					<Grid item>
						<Input
							value={operation.column}
							placeholder="Column"
							size="small"
							{...register(`${name}.column`)}
						/>
					</Grid>
				</Grid>
			) : null}
			<Grid container spacing={2} alignItems="center">
				<Grid item xs>
					<TextField
						label="Selector"
						variant="outlined"
						fullWidth
						size="small"
						{...register(`${name}.selector`)}
					/>
				</Grid>
				<Grid item>
					<TextField
						label="Result"
						variant="outlined"
						fullWidth
						size="small"
						{...register(`${name}.result`)}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
};
