import AsteriaCore from '@asteria/core';

import { LanguageService } from '@asteria/backend-utils-services';

LanguageService.translation.extend({
	getQuery: () => `
		query Request {
			response: translationKeys {
				id
                key
                default
                description
                verified
			}
		}
	`,
	key: `keys`,
	loggerMethod: `services.languageService.keys`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.keys`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

LanguageService.translation.extend({
	getQuery: () => `
		mutation Request($input: [UpdateTranslationKeyInputType]!) {
			response: updateTranslationKeys(input: $input) {
				ok
				data { id key }
			}
		}
	`,
	key: `updateKeys`,
	loggerMethod: `services.languageService.updateKeys`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.updateKeys`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

LanguageService.translation.extend({
	getQuery: () => `
		mutation Request($input: [ObjectId]!) {
			response: deleteTranslationKeys(input: $input) {
				ok
			}
		}
	`,
	key: `deleteKeys`,
	loggerMethod: `services.languageService.deleteKeys`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.updateKeys`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

LanguageService.translation.extend({
	getQuery: () => `
		query TranslationDetails($themeId: ObjectId, $partnerId: ObjectId) {
			translations(themeId: $themeId, partnerId: $partnerId) {
				code
    			translations
			}
		}
	`,
	key: `fetch`,
	loggerMethod: `services.languageService.fetch`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.fetch`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

LanguageService.translation.extend({
	getQuery: () => `
		query TranslationDetails($themeId: ObjectId!, $partnerId: ObjectId) {
			translations(themeId: $themeId, partnerId: $partnerId) {
				code
    			translations
			}
		}
	`,
	key: `fetchOld`,
	loggerMethod: `services.languageService.fetch`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.fetch`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

LanguageService.translation.extend({
	getQuery: () => `
		query TranslationDetails($language: String, $themeId: ObjectId, $partnerId: ObjectId) {
			translationNew(themeId: $themeId, partnerId: $partnerId, language: $language) {
				language
    			translations
			}
		}
	`,
	key: `fetchOne`,
	loggerMethod: `services.languageService.fetchOne`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.fetchOne`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

LanguageService.translation.extend({
	getQuery: () => `
		mutation Request($input: [UpdateTranslationsInputType!]) {
			response: updateTranslations(input: $input) {
				ok
			}
		}
	`,
	key: `update`,
	loggerMethod: `services.languageService.update`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.update`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

LanguageService.translation.extend({
	getQuery: () => `
		mutation Request($input: [DeleteTranslationInputType]!) {
			response: deleteTranslations(input: $input) {
				ok
			}
		}
	`,
	key: `delete`,
	loggerMethod: `services.languageService.delete`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.languageService.delete`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});
