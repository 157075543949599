import React from 'react';

import { useFieldArray } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, List, ListItem, Typography } from '@mui/material';
import { uniqueId } from 'lodash';

import { Operation } from './operation';

export const FieldEdit = (props) => {
	const { field, setField } = props;
	const baseName = `layout.operations.${field}`;
	const { fields, append, move, remove } = useFieldArray({
		name: baseName,
	});

	const onAdd = React.useCallback(() => {
		append({ uuid: uniqueId(), type: '', dir: '' });
	}, [append]);

	const onBack = React.useCallback(() => setField(null), [setField]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<ListItem>
				<Typography>{field}</Typography>
				<IconButton color="primary" aria-label="Back" onClick={onBack}>
					<ArrowBackIcon />
				</IconButton>
			</ListItem>
			{fields.map((item, index) => (
				<ListItem key={item.id}>
					<Operation
						name={`${baseName}.${index}`}
						index={index}
						baseName={baseName}
						first={index === 0}
						last={index === fields.length - 1}
						remove={remove}
						move={move}
					/>
				</ListItem>
			))}
			<ListItem>
				<Button
					variant="outlined"
					color="success"
					fullWidth
					onClick={onAdd}
				>
					Add new opertaion
				</Button>
			</ListItem>
		</List>
	);
};
