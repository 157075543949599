import React from 'react';

const Error = ({ error }) => {
	return (
		<div className="flex justify-center py-4 m-4 border-2 border-red-200 text-red-900 bg-red-100">
			{error?.message || 'Unknown error!'}
		</div>
	);
};

export default Error;
