import React from 'react';

import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import InvoiceFilters from '../../components/Filters';
import InvoiceSearch from '../../components/Search';

import InvoicesStatistics from './invoiceStatistics';

import './styles.scss';

const InvoicesListTab = (props) => {
	return (
		<Paper
			elevation={0}
			sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<InvoiceFilters />
				<InvoiceSearch />
			</Box>
			<InvoicesStatistics />
		</Paper>
	);
};

InvoicesListTab.displayName = 'InvoicesListTab';

InvoicesListTab.propTypes = { className: PropTypes.string };

export default InvoicesListTab;
