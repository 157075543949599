import React, { useCallback, useMemo } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PartnerSelector = React.memo(
	({ partners, onChange, multiple, chips, sx }) => {
		const options = useMemo(() => {
			return partners.sort(({ name: a }, { name: b }) =>
				a.localeCompare(b),
			);
		}, [partners]);

		const renderOptions = useMemo(() => {
			if (multiple) {
				return (props, option, { selected }) => (
					<li {...props}>
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
						{option.name}
					</li>
				);
			}

			return;
		}, [multiple]);

		const getOptionLabel = useCallback((option) => option.name, []);

		const renderInput = useCallback(
			(params) => (
				<TextField
					{...params}
					variant="standard"
					placeholder="Selected partners"
				/>
			),
			[],
		);

		return (
			<Autocomplete
				sx={sx}
				disablePortal
				clearOnEscape
				multiple={multiple}
				id="tags-standard"
				options={options}
				disableCloseOnSelect={multiple}
				getOptionLabel={getOptionLabel}
				onChange={onChange}
				renderOption={renderOptions}
				renderInput={renderInput}
			/>
		);
	},
);

export default PartnerSelector;
