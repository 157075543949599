import React from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
	KeyboardArrowDown,
	KeyboardArrowUp,
	MoreVert,
} from '@mui/icons-material';
import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	Paper,
	Select,
	Stack,
} from '@mui/material';

import { Filter } from './filter';
import { LookUpAndRead } from './findMoveRead';
import { FormatDate } from './formatDate';
import { LookUp } from './lookup';
import { Move } from './move';
import { Read } from './read';
import { Results } from './results';
import { SetField } from './set';
import { Append } from './append';

export const Operation = (props) => {
	const { name, baseName, index, remove, move, first, last } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = React.useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleToggle = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onRuleMoveUp = React.useCallback(() => {
		move(index, index - 1);
	}, [move, index]);

	const onRuleMoveDown = React.useCallback(() => {
		move(index, index + 1);
	}, [move, index]);

	const { register } = useFormContext();
	const operation = useWatch({ name: name });

	return (
		<Paper sx={{ width: '100%' }}>
			<Box p={2}>
				<Stack flexGrow="1" spacing={2} sx={{ position: 'relative' }}>
					<Stack direction="row">
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
								Operation
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={operation?.type}
								label="Operation"
								{...register(`${name}.type`)}
							>
								<MenuItem value="lookup">Find</MenuItem>
								<MenuItem value="lookupAll">Find All</MenuItem>
								<MenuItem value="move">Move</MenuItem>
								<MenuItem value="lookupAndRead">
									FindAndRead
								</MenuItem>
								<MenuItem value="read">Read</MenuItem>
								<MenuItem value="set">Set</MenuItem>
								<MenuItem value="append">Append</MenuItem>
								<MenuItem value="date">Format Date</MenuItem>
								<MenuItem value="filter">Filter</MenuItem>
								<MenuItem value="sum">Sum</MenuItem>
								<MenuItem value="prepend">Prepend</MenuItem>
								<MenuItem value="adjustDate">Adjust date</MenuItem>
							</Select>
						</FormControl>
						<IconButton
							aria-label="settings"
							size="small"
							onClick={handleClick}
						>
							<MoreVert />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&::before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform:
											'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							}}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
						>
							<MenuItem onClick={handleToggle}>Disable</MenuItem>
							<MenuItem onClick={() => remove(index)}>
								Remove
							</MenuItem>
						</Menu>
					</Stack>

					{operation.type === 'lookup' ||
					operation.type === 'lookupAll' ? (
							<LookUp name={name} operation={operation} />
						) : null}
					{operation.type === 'move' ? (
						<Move name={name} operation={operation} />
					) : null}
					{operation.type === 'lookupAndRead' ? (
						<LookUpAndRead name={name} operation={operation} />
					) : null}
					{operation.type === 'read' ? (
						<Read name={name} operation={operation} />
					) : null}
					{operation.type === 'set' ? (
						<SetField name={name} operation={operation} />
					) : null}
					{operation.type === 'date' ? (
						<FormatDate name={name} operation={operation} />
					) : null}
					{operation.type === 'filter' ? (
						<Filter name={name} operation={operation} />
					) : null}
					{operation.type === 'append' || operation.type === 'prepend' ? (
						<Append name={name} operation={operation} />
					) : null}
					<Results operation={operation} baseName={baseName} />
					<ButtonGroup variant="outlined">
						<Button disabled={first} onClick={onRuleMoveUp}>
							<KeyboardArrowUp />
						</Button>
						<Button disabled={last} onClick={onRuleMoveDown}>
							<KeyboardArrowDown />
						</Button>
					</ButtonGroup>
				</Stack>
			</Box>
		</Paper>
	);
};
