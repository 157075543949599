import React from 'react';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Link,
	List,
	ListItem,
	Typography,
} from '@mui/material';

export const Links = ({ url, showOCR }) => {
	return (
		<Accordion>
			<AccordionSummary>
				<Typography>Useful links</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<List>
					<ListItem divider>
						<Link
							href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes"
							target="__blank"
						>
							Country Codes
						</Link>
					</ListItem>
					<ListItem divider>
						<Link href={url.replace('_ocr', '')} target="__blank">
							Download PDF
						</Link>
					</ListItem>
					<ListItem divider>
						<Link onClick={() => (showOCR.value = true)}>
							Use OCR
						</Link>
					</ListItem>
				</List>
			</AccordionDetails>
		</Accordion>
	);
};
