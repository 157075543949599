import React from 'react';

import { Route, Routes } from 'react-router-dom';

import ChatDetailsPage from './pages/details';
import ChatListPage from './pages/list';

const ChatRouter = () => {
	return (
		<Routes>
			<Route index element={<ChatListPage />} />
			<Route path=":id" element={<ChatDetailsPage />} />
		</Routes>
	);
};

export default ChatRouter;
