import React from 'react';

import PlusIcon from '@mui/icons-material/PlusOne';
import { Button, IconButton } from '@mui/material';
import classNames from 'classnames';

import { FormContext } from './context';

import './styles.scss';

export const PDFSection = (props) => {
	const {
		x,
		y,
		height,
		width,
		type,
		onClick,
		connector,
		tooltip,
		highlight,
		onMouseEnter,
		onMouseLeave,
		visible = true,
	} = props;

	return (
		<div
			className={classNames(
				'asteria-component__invoice-document__section',
				{
					[`asteria--type-${type}`]: type,
					'asteria--state-highlight': highlight,
					'asteria--state-hidden': !visible,
				},
			)}
			style={{
				'--x': x,
				'--y': y,
				'--height': height,
				'--width': width,
			}}
			onClick={onClick}
			title={tooltip}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{connector ? (
				<div className="asteria-component__invoice-document__section__connector-wrapper"></div>
			) : null}
		</div>
	);
};

export const PDFSelectedSection = (props) => {
	const { x, y, height, width, label } = props;

	return (
		<PDFSection
			type="selected"
			x={`${x}px`}
			y={`${y}px`}
			height={`${height}px`}
			width={`${width}px`}
			connector
			label={label}
		/>
	);
};

export const PDFTextSection = (props) => {
	const { page, item, highlight, setHighlight } = props;

	const { x, y, w, h, text } = item;
	const dx = (x / page?.Width) * 100;
	const dy = (y / page?.Height) * 100;
	const width = (w / page?.Width) * 100;
	const height = (h / page?.Height) * 100;

	const { setActive } = React.useContext(FormContext);

	const onClick = React.useCallback(
		(e) => {
			setActive(item);
		},
		[setActive, item],
	);

	if (!page) {
		return null;
	}

	return (
		<PDFSection
			type="highlight"
			highlight={highlight}
			x={`${dx}%`}
			y={`${dy}%`}
			height={`${height}%`}
			width={`${width}%`}
			onClick={onClick}
			onMouseEnter={() => {
				setHighlight([
					item.siblings?.left?.node?.uuid,
					item.siblings?.right?.node?.uuid,
					item.siblings?.up?.node?.uuid,
					item.siblings?.down?.node?.uuid,
				]);
			}}
			onMouseLeave={() => setHighlight([])}
			tooltip={item.text}
		/>
	);
};
