import React from 'react';

import { MdArrowDownward, MdArrowUpward, MdDragHandle } from 'react-icons/md';

const Arrow = ({ value, ending = '' }) => {
	if (value > 0) {
		return (
			<div className="flex flex-row gap-1">
				<MdArrowUpward size={24} color="#22c55e" />
				<p className="text-md font-medium text-green-500">{`${value}${ending}`}</p>
			</div>
		);
	}

	if (value === 0) {
		return (
			<div className="flex flex-row gap-1">
				<MdDragHandle size={24} color="#eab308" />
				<p className="text-md font-medium text-yellow-500">{`${value}${ending}`}</p>
			</div>
		);
	}
	return (
		<div className="flex flex-row gap-1">
			<MdArrowDownward size={24} color="#ef4444" />
			<p className="text-md font-medium text-red-500">{`${value}${ending}`}</p>
		</div>
	);
};

export default Arrow;
