import React from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers';

import { InvoicesContext } from '../context';
import { format } from 'date-fns';

const FilterItem = (props) => {
	const { active, filter, onClick } = props;

	const handleClick = React.useCallback(
		(event) => {
			onClick(event, filter);
		},
		[filter, onClick],
	);

	return (
		<Chip
			label={filter?.label}
			variant={filter?.type === active ? 'outlined' : undefined}
			onClick={handleClick}
		/>
	);
};

FilterItem.displayName = 'FilterItem';

FilterItem.propTypes = {};

const InvoiceFilters = ({ setFilter, filters = {}, statusCounts = {} }) => {
	const filterTypeOptions = React.useMemo(
		() => [
			{ label: 'All', type: 'ALL' },
			{ label: 'Loading', type: 'LOADING' },
			{ label: `Pending (${statusCounts.PENDING})`, type: 'PENDING' },
			{ label: `Error (${statusCounts.ERROR})`, type: 'ERROR' },
			{ label: 'Queue', type: 'QUEUE' },
			{ label: 'Running', type: 'RUNNING' },
			{ label: 'Processed', type: 'PROCESSED' },
			{ label: 'Ignored', type: 'IGNORED' },
		],
		[statusCounts],
	);

	const handleChangeStatus = React.useCallback(
		(event) => {
			setFilter?.({ ...filters, status: event.target.value });
		},
		[setFilter, filters],
	);

	const handleChangeStartDate = React.useCallback(
		(date) => {
			localStorage.invoiceFilerDate = format(date, 'yyyy-MM-dd');
			setFilter?.({ ...filters, startDate: date });
		},
		[setFilter, filters],
	);

	const handleChangeStage = React.useCallback(
		(event) => {
			setFilter?.({ ...filters, clientStage: event.target.value });
		},
		[setFilter, filters],
	);

	return (
		<Stack direction="row" spacing={1}>
			<FormControl sx={{ minWidth: 120 }} size="small">
				<InputLabel id="filter-status-label">Status</InputLabel>
				<Select
					labelId="filter-status-labek"
					id="filter-status"
					value={filters.status}
					label="Status"
					onChange={handleChangeStatus}
				>
					{filterTypeOptions.map((filter) => (
						<MenuItem value={filter?.type}>
							{filter?.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl sx={{ minWidth: 120 }} size="small">
				<InputLabel id="filter-stage-label">Client Stage</InputLabel>
				<Select
					labelId="filter-stage-labek"
					id="filter-status"
					value={filters.clientStage}
					label="stage"
					onChange={handleChangeStage}
					disabled={filters?.status !== 'PENDING'}
				>
					<MenuItem value="APPROVED">Approved</MenuItem>
					<MenuItem value="READY">Ready</MenuItem>
					<MenuItem value="VERIFICATION">Verfiication</MenuItem>
				</Select>
			</FormControl>
			<DatePicker
				label="View From"
				slotProps={{ textField: { size: 'small' } }}
				value={filters?.startDate}
				onChange={handleChangeStartDate}
			/>
		</Stack>
	);
};

InvoiceFilters.displayName = 'InvoiceFilters';

InvoiceFilters.propTypes = {};

export default InvoiceFilters;
