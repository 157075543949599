import { createSlice } from '@reduxjs/toolkit';

export * as selectors from './selectors';

const initialState = {
	templates: [],
	template: null,
};

const slice = createSlice({
	name: 'layouts',
	initialState: initialState,
	reducers: {
		setTemplates: function (state, { payload }) {
			state.templates = payload ?? [];

			if (state.template) {
				const template = state.templates.find(
					(object) => (object?._id ?? object?.id) === state.template,
				);

				if (!template) {
					state.template = null;
				}
			}
		},
		setTemplate: function (state, { payload }) {
			state.template = payload;
		},
	},
});

export const { setTemplates, setTemplate } = slice.actions;

export default slice.reducer;
