import React, { useContext, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LinearProgress, Paper, Stack } from '@mui/material';
import {
	DataGrid,
	GridActionsCellItem,
	GridToolbarContainer,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';

import { AuthService, CompanyService } from '@asteria/backend-utils-services';

import PartnerSelectorDialog from '../../components/dialogs/PartnerSelectorDialog';
import Error from '../../components/form/Error';
import PartnerSelector from '../../components/partner/selector';
import * as Context from '../../context';
import * as Hooks from '../../hooks';
import { selectToken } from '../../store/auth';
import Config from '../../utils/configuration';

import CompanyView from './view';

function EditToolbar(props) {
	const { partners, setPartner } = props;

	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter />
			<PartnerSelector
				sx={{
					ml: 'auto',
					width: '200px',
				}}
				partners={partners}
				onChange={(e, item) => {
					if (item) {
						setPartner(item);
					} else {
						setPartner(null);
					}
				}}
			/>
		</GridToolbarContainer>
	);
}

const Companies = () => {
	const accessToken = useSelector(selectToken);
	const [partner, setPartner] = useState(null);
	const {
		state: { partners },
	} = useContext(Context.partner.Context);
	const navigate = useNavigate();

	const requestOptions = useMemo(
		() => ({
			pageFilters: { first: 500, orderField: 'createdAt' },
			userPageFilters: { first: 0 },
			filters: { partnerId: partner?._id },
			analyticsFilter: {
				type: ['auth.logged.in'],
			},
		}),
		[partner?._id],
	);

	const {
		data: companyData = [],
		error: companyDataError,
		isLoading,
	} = Hooks.request.usePagination({
		handler: CompanyService.company.extension.fetchTable,
		options: requestOptions,
		context: { token: accessToken },
	});

	const handleDelete = React.useCallback((id) => () => {}, []);

	const handleEdit = React.useCallback(
		(id) => () => {
			navigate(`edit/${id}`);
		},
		[navigate],
	);

	const handleView = React.useCallback(
		(id) => () => {
			navigate(`view/${id}`);
		},
		[navigate],
	);

	const [selectedCompany, setSelectedCompany] = useState(null);

	const handleCashflow = React.useCallback(
		(company) => async () => {
			/*
			const [user = null] = company.users.edges.map(({ node }) => node);

			if (!user) {
				return;
			}

			const { data: { accessToken: cashflowAccessToken } = {} } =
				await AuthService.auth.login(
					{
						username: user?.username || user?._id,
						password: 'dummy',
					},
					{ token: accessToken },
				);

			const widgetEnvUrl = await Config.getValueAsync({
				key: 'widgetUrl',
			});

			const widgetUrl = `${widgetEnvUrl}?token=${cashflowAccessToken}&site=swedbank`;

			window.open(widgetUrl);
			*/
			setSelectedCompany(company);
		},
		[accessToken],
	);

	const showCashflow = React.useCallback(
		async (partner) => {
			const [user = null] = selectedCompany.users.edges.map(
				({ node }) => node,
			);

			if (!user) {
				return;
			}

			const { data: { accessToken: cashflowAccessToken } = {} } =
				await AuthService.auth.login(
					{
						username: user?.username || user?._id,
						password: 'dummy',
					},
					{ token: accessToken },
				);

			const widgetEnvUrl = await Config.getValueAsync({
				key: 'widgetUrl',
			});

			const site = partner?.settings?.hosting?.domain ?? 'asteria';
			const widget = partner?.settings?.hosting?.widget ?? 'full';

			const widgetUrl = `${widgetEnvUrl}?token=${cashflowAccessToken}&site=${site}&widget=${widget}`;
			console.log(widgetUrl);
			window.open(widgetUrl);
			setSelectedCompany(null);
		},
		[accessToken, selectedCompany],
	);

	const columns = useMemo(() => {
		return [
			{
				field: 'id',
				headerName: 'ID',
				width: 220,
			},
			{
				field: 'name',
				headerName: 'Name',
				flex: 1,
			},
			{
				field: 'orgnumber',
				headerName: 'Org Number',
			},
			{
				field: 'partner',
				valueGetter: (params) =>
					params?.row?.partners?.edges?.[0]?.node?.name || '',
				headerName: 'Partner',
				width: 250,
			},
			{
				field: 'createdAt',
				headerName: 'Joined',
				valueFormatter: (params) =>
					params.value
						? format(parseISO(params.value), 'yyyy-MM-dd HH:MM')
						: '',
				width: 250,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 80,
				getActions: (params) => [
					<GridActionsCellItem
						icon={<VisibilityIcon />}
						label="View"
						onClick={handleView(params.id)}
					/>,
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						showInMenu
						onClick={handleEdit(params.id)}
					/>,
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Delete"
						showInMenu
						onClick={handleDelete(params.id)}
					/>,
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Cashflow"
						showInMenu
						onClick={handleCashflow(params.row)}
					/>,
				],
			},
		];
	}, [handleView, handleEdit, handleDelete]);

	if (companyDataError) {
		return <Error error={companyDataError} />;
	}

	return (
		<Paper
			elevation={0}
			sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Routes>
				<Route path="view/:id" element={<CompanyView />} />
			</Routes>
			<PartnerSelectorDialog
				open={!!selectedCompany}
				onClose={showCashflow}
			/>
			<Stack spacing={3} sx={{ height: '100%' }}>
				<DataGrid
					columns={columns}
					loading={isLoading}
					rows={companyData ?? []}
					slots={{
						loadingOverlay: LinearProgress,
						toolbar: EditToolbar,
					}}
					slotProps={{
						toolbar: {
							partners,
							setPartner: setPartner,
						},
					}}
				/>
			</Stack>
		</Paper>
	);
};

export default Companies;
