import React, { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { Brush, Edit, Refresh, Settings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Stack,
	TextField,
} from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';
import { Countries } from '../../../../../../components/pdf/countries';
import { cleanNumber } from '../../../../../../components/pdf/utils';
import processor from '../../utils/processor';

const ZIPCODE_Regex =
	/^(([a-zA-Z]{1,2})?[- ]*([0-9 -]{4,8}(?![0-9 ])))|(([a-zA-Z]{1,2}-)?([0-9 -]{4,8}(?![0-9 ])))/;
const processAddress = (parts) => {
	parts = Array.isArray(parts) ? parts : [];
	parts = parts.map((txt) => txt?.replace?.(/ {2,}/g, ''));
	const reverseParts = [...parts].reverse();
	let zipCodeIndex = reverseParts.findIndex((part) =>
		ZIPCODE_Regex.test(part),
	);

	if (zipCodeIndex < 0) {
		return null;
	}

	zipCodeIndex = parts.length - zipCodeIndex - 1;

	if (zipCodeIndex === -1) {
		// Unable to find zipCode, what to do ?
		return null;
	}

	let street =
		zipCodeIndex === 0 ? '.' : parts[Math.max(0, zipCodeIndex - 1)].trim();

	let street2 = '';
	let country = '';
	let name = parts?.[0] ?? '';
	street2 = parts
		.slice(0, Math.max(0, zipCodeIndex - 1))
		.reduce((arr, part) => {
			return [...arr, part];
		}, [])
		.join(' ')
		.trim()
		.substring(0, 35);

	const zipMatch = ZIPCODE_Regex.exec(parts[zipCodeIndex]);

	let [zipcode] = zipMatch;

	const city = parts[zipCodeIndex].replace(zipcode, '');
	if (zipCodeIndex < parts.length - 1) {
		country = parts[zipCodeIndex + 1].trim();
	} else {
		country = 'Sweden';
	}

	zipcode = zipcode.trim();

	if (
		Object.values(Countries).includes(
			zipcode?.substring(0, 2).toUpperCase(),
		)
	) {
		const countryIndex = Object.values(Countries).indexOf(
			zipcode.substring(0, 2).toUpperCase(),
		);

		if (countryIndex !== -1) {
			country = Object.keys(Countries)[countryIndex];
		}
	}

	if (
		zipcode.startsWith('SE') ||
		zipcode.startsWith('FI') ||
		zipcode.startsWith('NO') ||
		zipcode.startsWith('N-') ||
		zipcode.startsWith('DK')
	) {
		zipcode = zipcode.replace(/[^0-9]/g, '');
	}

	const data = {
		street2,
		street,
		zipcode,
		city,
		country: Countries[(country ?? 'Sweden').toLowerCase()] || '',
		name,
	};

	return data;
};

export const AddressField = (props) => {
	const { name, setSelectedField } = props;
	const { pdfPageData, layoutData } = React.useContext(FormContext);
	const handleClick = React.useCallback(
		(event) => {
			setSelectedField(name);
		},
		[name, setSelectedField],
	);

	const { register, setValue, resetField, setError } = useFormContext();
	const operations = useWatch({ name: `layout.operations.${name}` });
	const value = useWatch({ name: name });
	// register('invoice.contact.shipping');
	// const address = useWatch({ name: name });

	const handleRefresh = React.useCallback(() => {
		if (!operations || operations.length === 0) {
			return;
		}

		let { results } = processor({
			operations: Object.values(operations),
			pageData: pdfPageData,
			pdfData: layoutData,
		});

		const addresss = processAddress(results);

		if (!addresss || !results) {
			console.log('Reset', name);
		} else {
			setValue(name, addresss);
		}
	}, [operations, pdfPageData, layoutData, setValue, name]);

	useEffect(() => {
		if (!operations || operations.length === 0) {
			return;
		}

		let { results } = processor({
			operations: Object.values(operations),
			pageData: pdfPageData,
			pdfData: layoutData,
		});

		const addresss = processAddress(results);

		if (!addresss || !results) {
			console.log('Reset', name);
		} else {
			setValue(name, addresss);
		}
	}, [
		operations,
		pdfPageData,
		layoutData,
		setValue,
		name,
		setError,
		resetField,
	]);

	return (
		<Stack direction="column">
			<TextField
				label="Street"
				variant="outlined"
				fullWidth
				size="small"
				value={value?.street || ''}
				{...register(`${name}.street`)}
			/>
			<TextField
				label="Street2"
				variant="outlined"
				fullWidth
				size="small"
				value={value?.street2 || ''}
				{...register(`${name}.street2`)}
			/>
			<TextField
				label="ZipCode"
				variant="outlined"
				fullWidth
				size="small"
				value={value?.zipcode || ''}
				{...register(`${name}.zipcode`)}
			/>
			<TextField
				label="City"
				variant="outlined"
				fullWidth
				size="small"
				value={value?.city || ''}
				{...register(`${name}.city`)}
			/>
			<TextField
				label="Country"
				variant="outlined"
				fullWidth
				size="small"
				value={value?.country || ''}
				{...register(`${name}.country`)}
			/>
			<IconButton aria-label="menu" onClick={handleClick}>
				<Edit />
			</IconButton>
			<IconButton aria-label="menu" onClick={handleRefresh}>
				<Refresh />
			</IconButton>
		</Stack>
	);
};
