import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import Form from '@asteria/component-form';
import Input from '@asteria/component-form/input';

import Table from '../../../components/Table/Table';
import { showTypes } from '../utils/constans';

const Checkbox = ({ indeterminate, ...rest }) => {
	const defaultRef = React.useRef();

	React.useEffect(() => {
		defaultRef.current.indeterminate = indeterminate;
	}, [defaultRef, indeterminate]);

	return <input ref={defaultRef} type="checkbox" {...rest} />;
};

const SyncTable = (props) => {
	const { data, getSelectedRows, settings } = props;

	const tableData = useMemo(() => {
		if (!data) {
			return [];
		}

		return Object.keys(data)
			.map((key) => data[key])
			.sort((a, b) => a.id.localeCompare(b.id));
	}, [data]);

	const filteredData = useMemo(() => {
		if (settings.changes === showTypes.SHOW_CHANGES) {
			return tableData.filter((item) => item.kind);
		} else if (settings.changes === showTypes.SHOW_NEW) {
			return tableData.filter((item) => item.kind === 'N');
		} else if (settings.changes === showTypes.SHOW_EDITED) {
			return tableData.filter((item) => item.kind === 'E');
		} else if (settings.changes === showTypes.SHOW_REMOVED) {
			return tableData.filter((item) => item.kind === 'D');
		} else {
			return tableData;
		}
	}, [tableData, settings.changes]);

	const columns = useMemo(
		() => [
			{
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => {
					return <Checkbox {...getToggleAllRowsSelectedProps()} />;
				},
				Cell: ({ row }) => {
					const rowProps = row.getToggleRowSelectedProps();
					return (
						<Checkbox
							{...rowProps}
							checked={!row.original.kind || rowProps.checked}
							disabled={!row.original.kind}
						/>
					);
				},
			},

			{
				id: 'id_sync',
				Header: 'Key',
				accessor: 'id',
				class: 'text-left align-top w-1/3 break-all',
			},
			{
				id: 'original_sync',
				Header: 'Old',
				accessor: 'original',
				class: 'text-left align-top w-1/3 break-all',
			},
			{
				id: 'new_sync',
				Header: 'New',
				accessor: 'new',
				class: 'text-left align-top w-1/3 break-all',
			},
		],
		[],
	);

	const rowProps = useCallback(({ original, isSelected }) => {
		return {
			className: classNames('hover:bg-gray-100', {
				'bg-green-100 hover:bg-green-300 text-green-800 font-semibold':
					original.kind === 'N',
				'bg-red-100 hover:bg-red-300 text-red-800 font-semibold':
					original.kind === 'D',
				'bg-amber-100 hover:bg-amber-300 text-amber-800 font-semibold':
					original.kind === 'E',
				'bg-green-200': isSelected && original.kind === 'N',
				'bg-red-200': isSelected && original.kind === 'D',
				'bg-amber-200': isSelected && original.kind === 'E',
			}),
		};
	}, []);

	const [search, setSearch] = React.useState('');

	const handleSearchChange = React.useCallback(({ value }) => {
		setSearch(value);
	}, []);

	return (
		<Form>
			<Input
				name="search"
				placeholder="Search..."
				onChange={handleSearchChange}
				debounce={200}
				icon="magnifier"
			/>
			<Table
				columns={columns}
				data={filteredData}
				rowProps={rowProps}
				getSelectedRows={getSelectedRows}
				search={search}
			/>
		</Form>
	);
};

export default SyncTable;
