/* eslint-disable indent */
import React, { useCallback, useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import classNames from 'classnames';
import { get, has } from 'lodash';
import { useSnackbar } from 'notistack';
import { createScheduler, createWorker } from 'tesseract.js';

import { InvoiceService } from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';
import * as LayoutsStore from '../../store/layouts';
import Form from '../form';

import { ActionsMenu } from './actionsMenu';
import { FIELDS } from './constants';
import { FormContext } from './context';
import { Countries, Language } from './countries';
import { useReactDraw } from './hooks';
import { PDFSelectedSection } from './section';
import SidePane from './sidePane';
import {
	detectValue,
	getInvoiceRows,
	readValue,
	readValueWithRules,
} from './utils';

import './styles.scss';

const ocrScheduler = createScheduler();
createWorker('swe+eng+deu').then((worker) => {
	ocrScheduler.addWorker(worker);
});

function formatLayoutRequest(layout) {
	return Object.fromEntries(
		Object.entries(layout ?? {}).map(([key, value]) => [
			key.includes('$') ? key.replace('$', '__') : key,
			value,
		]),
	);
}

async function processLayout(
	{ $area, $settings, $manual, $fields = null },
	{ canvas, scheduler, setValue, clients },
) {
	let foundClient = false;
	// const settings = AsteriaCore.utils.flatObject($settings);

	// prefetch client
	if ($fields === null && has($area, 'client.meta.clientNumber')) {
		const clientNumber = await readValue(
			{
				name: 'client.meta.clientNumber',
				area: get($area, 'client.meta.clientNumber'),
				rules: get($settings, 'client.meta.clientNumber'),
			},
			{ canvas, scheduler },
		);

		const client = clients.find(
			(c) => c?.meta?.clientNumber === clientNumber.trim(),
		);

		if (client) {
			if (
				!client?.contact?.billing?.street &&
				client?.contact?.general?.street
			) {
				client.contact.billing = client.contact.general;
			}
			foundClient = true;
			setValue('client', client);
		}
	}

	if (has($area, 'rows.baseline')) {
		const area = get($area, 'rows.baseline');
		const {
			blocks: [baselineBlock],
		} = await detectValue(area, canvas, scheduler, {
			preserve_interword_spaces: '1',
		});

		setValue('layout.rows.baseline', baselineBlock.baseline.x0);

		if (has($area, 'rows.block')) {
			const area = get($area, 'rows.block');
			const { lines } = await detectValue(area, canvas, scheduler, {
				preserve_interword_spaces: '1',
			});

			const baseline = parseInt(baselineBlock.baseline.x0);

			const rows = getInvoiceRows(baseline, lines);
		}
	}

	await Promise.all(
		FIELDS.map(async ({ name }) => {
			if (foundClient && name.startsWith('client.')) {
				return null;
			}

			if ($fields !== null && !$fields.includes(name)) {
				return null;
			}

			const manual = get($manual, name);
			const area = get($area, name);
			const setting = get($settings, name);

			let value = '';

			if (area) {
				value = await readValue(
					{
						name: name,
						area: area,
						rules: setting,
					},
					{ canvas, scheduler },
				);
			}

			if (
				(value === null || value === '' || value === undefined) &&
				manual
			) {
				value = manual;
			}

			setValue(name, value);
		}),
	);
}

const PDF = (props) => {
	const {
		className,
		id,
		url,
		height,
		width,
		scale = 2,
		onIgnore,
		onRevert,
		onProcessed,
		invoices,
		clients,
		status,
		company,
		version,
		onRemove,
		onSubmit: onFormSubmit,
		data,
		showOCR,
	} = props;

	const accessToken = useSelector(selectToken);
	const { setValue, getValues, reset } = useFormContext();
	const [loadClient, setLoadClient] = React.useState(true);
	const [selectedInvoice, setSelectedInvoice] = React.useState(
		data?.invoice?.id ? data : 0,
	);
	const dispatch = useDispatch();
	const store = useStore();

	const { enqueueSnackbar } = useSnackbar();

	const [templatesFetched, setTemplatesFetched] = React.useState(false);
	const [pages, setPages] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [autoRefreshData, setAutoRefreshData] = React.useState(true);
	const [processingLayout, setProcessingLayout] = React.useState(false);
	const [lockSave, setLockSave] = React.useState(false);

	const [highlight, setHighlight] = React.useState([]);

	const inputRef = React.useRef(null);
	const canvasRef = React.useRef(null);
	const backupRef = React.useRef(null);
	const rectCanvasRef = React.useRef(null);

	const [{ active, area: activeArea, drawing }, dispatchPDF] =
		React.useReducer(
			(state, action) => {
				switch (action?.type) {
					case 'SET': {
						const active = action?.payload?.active
							? state?.active === action?.payload?.active
								? null
								: action?.payload?.active
							: state?.active;

						return {
							...state,
							active: active,
							area: action?.payload?.area,
							drawing: active
								? action?.payload?.drawing ?? state?.drawing
								: false,
						};
					}

					case 'RESET':
						return { ...state, active: null, drawing: false };

					default:
						return state;
				}
			},
			{ active: null, drawing: false },
		);

	React.useEffect(() => {
		return () => {
			dispatch(LayoutsStore.setTemplate(null));
		};
	}, [dispatch]);

	const section = React.useMemo(() => {
		if (drawing !== true) {
			return getValues(highlight);
		}

		return getValues(highlight);
	}, [getValues, highlight, drawing]);

	const setInvoice = React.useCallback(
		(e) => {
			const form = getValues();
			const invoice =
				invoices.find(({ id }) => id === e.target.value) ?? 'new';

			if (invoice === 'new') {
				setSelectedInvoice(0);
				reset({
					...form,
					isCredit: false,
					invoice: {},
					client: {},
					layout: { ...form.layout, fullPage: undefined },
					rows: [],
				});
			} else {
				setSelectedInvoice(invoice);
				reset({
					...form,
					layout: { ...form.layout, fullPage: undefined },
					...invoice,
				});
			}
		},
		[invoices, getValues, reset],
	);

	const onInvoiceRemove = useCallback(async () => {
		const invoiceId =
			selectedInvoice?.invoice?._id ?? selectedInvoice?.invoice?.id;

		const clientId =
			selectedInvoice?.client?._id ?? selectedInvoice?.client?.id;

		const companyId = getValues()?.companyId;

		if (!invoiceId) {
			return;
		}

		const response = await onRemove?.({ invoiceId, clientId, companyId });

		if (!response) {
			return;
		}

		const form = getValues();
		reset({ ...form, invoice: {}, client: {} });
		setSelectedInvoice(0);
	}, [
		getValues,
		onRemove,
		reset,
		selectedInvoice?.client?._id,
		selectedInvoice?.client?.id,
		selectedInvoice?.invoice?._id,
		selectedInvoice?.invoice?.id,
	]);

	const onChangeActive = React.useCallback((event) => {
		const { area, name, drawing } = event;

		dispatchPDF({
			type: 'SET',
			payload: { active: name, area: area, drawing: drawing },
		});
	}, []);

	const onDrawnRect = React.useCallback(
		async ({ x1, x2, y1, y2 }) => {
			const area = {
				x1: x1,
				y1: y1,
				x2: x2,
				y2: y2,
			};

			setValue(activeArea, area);
			if (active === 'layout.fullPage') {
				const { lines } = await detectValue(
					area,
					backupRef.current,
					ocrScheduler,
					{
						preserve_interword_spaces: '1',
					},
				);

				setValue(
					'layout.fullPage',
					lines.map(({ text }) => text),
				);
			} else if (active === 'layout.rows.baseline') {
				const {
					blocks: [baselineBlock],
				} = await detectValue(area, backupRef.current, ocrScheduler, {
					preserve_interword_spaces: '1',
				});

				setValue(active, baselineBlock.baseline.x0);
			} else if (active === 'layout.rows.block') {
				const { lines } = await detectValue(
					area,
					backupRef.current,
					ocrScheduler,
					{
						preserve_interword_spaces: '1',
					},
				);

				const baseline = parseInt(getValues('layout.rows.baseline'));

				const rows = getInvoiceRows(baseline, lines);
			} else if (active.startsWith('layout.column')) {
				const {
					blocks: [block],
				} = await detectValue(area, backupRef.current, ocrScheduler, {
					preserve_interword_spaces: '1',
				});
				setValue(`${active}.label`, block?.text?.trim?.());
				setValue(`${active}.baseline`, block.baseline);
			} else {
				const text = await readValueWithRules(
					{ name: active, area },
					{
						canvas: backupRef.current,
						scheduler: ocrScheduler,
						getValues,
					},
				);

				setValue(active, text);

				if (active === 'client.meta.clientNumber') {
					const client = clients.find(
						(c) => c?.meta?.clientNumber === text.trim(),
					);

					if (client && loadClient) {
						if (
							!client?.contact?.billing?.street &&
							client?.contact?.general?.street
						) {
							client.contact.billing = client.contact.general;
						}

						setValue('client', client);
					}
				}
			}

			dispatchPDF({
				type: 'SET',
				payload: { active: active, drawing: false },
			});
		},
		[active, activeArea, setValue, clients, loadClient, getValues],
	);

	// Check for country changes
	const country = useWatch({ name: 'client.contact.billing.country' });

	useEffect(() => {
		if (country && Countries[country.toLowerCase()]) {
			// Attempt to map to code
			const countryCode = Countries[country.toLowerCase()];
			setValue('client.contact.billing.country', countryCode);
		}

		if (country && country.length === 2 && Language[country]) {
			setValue('client.info.language', Language[country]);
		}
	}, [country, setValue]);

	const invoiceNumber = useWatch({ name: 'invoice.meta.invoiceNumber' });

	useEffect(() => {
		const fetchInvoice = async () => {
			const response =
				await InvoiceService.invoice.extension.companyInvoices(
					{
						companyId: company.id,
						filters: { meta: { invoiceNumber } },
						fields: `edges { node { id services { status } } }`,
					},
					{ token: accessToken },
				);

			const [{ node: dbInvoice } = {}] = response?.edges ?? [];

			if (dbInvoice && !invoices.find(({ id }) => dbInvoice.id === id)) {
				if (
					dbInvoice?.services?.[0]?.status === 'SENT' ||
					dbInvoice?.services?.[0]?.status === 'COMPLETED'
				) {
					enqueueSnackbar(
						`Invoice with invoice number ${invoiceNumber} allready exists and sent to Provider`,
						{
							variant: 'error',
						},
					);
					setLockSave(true);
				} else {
					enqueueSnackbar(
						`Invoice with invoice number ${invoiceNumber} allready exists`,
						{
							variant: 'warning',
						},
					);
					setLockSave(false);
				}
			} else {
				setLockSave(false);
			}
		};

		if (invoiceNumber) {
			fetchInvoice();
		}
	}, [
		company,
		invoiceNumber,
		setValue,
		invoices,
		accessToken,
		enqueueSnackbar,
	]);

	useReactDraw({
		active: active,
		onRect: onDrawnRect,
		canvasRef: canvasRef,
		backupRef: backupRef,
	});

	const onDocumentLoad = React.useCallback((document) => {
		setPages(document.numPages);
	}, []);

	const onNext = React.useCallback(() => {
		setPage((page) => page + 1);
	}, []);

	const onBack = React.useCallback(() => {
		setPage((page) => page - 1);
	}, []);

	const onAction = React.useCallback(
		async (action, data) => {
			if (action === 'template:select') {
				reset();
				setProcessingLayout(true);

				const ID = data?._id ?? data?.id;

				const { $area, $settings, $manual } = Object.fromEntries(
					Object.entries(data?.layout ?? {}).map(([key, value]) => [
						key.includes('__') ? key.replace('__', '$') : key,
						value,
					]),
				);

				dispatch(LayoutsStore.setTemplate(ID));
				setValue(`layout.$area`, $area);
				setValue(`layout.$settings`, $settings);
				setValue(`layout.$manual`, $manual);
				setValue('company.templateId', ID);
				setValue('layout.columns', data?.layout?.columns);
				setValue('layout.rowRegex', data?.layout?.rowRegex);

				try {
					await processLayout(
						{ $area, $settings, $manual },
						{
							canvas: canvasRef.current,
							scheduler: ocrScheduler,
							setValue,
							clients: loadClient ? clients : [],
						},
					);
				} catch (e) {
					console.error(e);
				}

				setProcessingLayout(false);
			} else if (action === 'template:set') {
				const ID = data?._id ?? data?.id;

				const { $area, $settings, $manual } = Object.fromEntries(
					Object.entries(data?.layout ?? {}).map(([key, value]) => [
						key.includes('__') ? key.replace('__', '$') : key,
						value,
					]),
				);

				dispatch(LayoutsStore.setTemplate(ID));
				setValue(`layout.$area`, $area);
				setValue(`layout.$settings`, $settings);
				setValue(`layout.$manual`, $manual);
				setValue('company.templateId', ID);
				setValue('layout.columns', data?.layout?.columns);
				setValue('layout.rowRegex', data?.layout?.rowRegex);
			}
		},
		[dispatch, reset, clients, setValue, loadClient, canvasRef],
	);

	const onSubmit = React.useCallback(
		async (action, data) => {
			if (action === 'invoice:layout:message') {
				const response = await InvoiceService.invoice.extension
					.addInvoiceLayoutMessage(
						{ id: data.id, message: data.message },
						{ token: accessToken },
					)
					.catch((err) => ({ ok: false, error: err }));

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.warn(response.error);
				}

				await data?.refetch();
			}

			if (action === 'invoice:layout:template:fetch') {
				return InvoiceService.invoice.extension
					.invoiceLayoutTemplates({
						pageFilters: { first: 0 },
						fields: `edges { node { _id name createdAt updatedAt layout } }`,
					})
					.then((response) =>
						(response?.edges ?? []).map(({ node }) => node),
					)
					.then((response) => {
						dispatch(LayoutsStore.setTemplates(response));
						setTemplatesFetched(true);
						return response;
					});
			}

			if (action === 'invoice:layout:template:create') {
				const { layout: $layout, ...input } = data;

				const layout = formatLayoutRequest($layout);

				await InvoiceService.invoice.extension.addInvoiceLayoutTemplate(
					{
						input: { ...input, layout: layout },
					},
				);

				enqueueSnackbar(`Template created`, { variant: 'success' });

				return onSubmit?.('invoice:layout:template:fetch');
			}

			if (action === 'invoice:layout:template:update') {
				const { id, layout: $layout, ...input } = data;

				const layout = formatLayoutRequest($layout);

				await InvoiceService.invoice.extension.updateInvoiceLayoutTemplate(
					{
						id: id,
						input: { ...input, layout: layout },
					},
				);

				enqueueSnackbar(`Template updated`, { variant: 'success' });

				return onSubmit?.('invoice:layout:template:fetch');
			}

			if (action === 'invoice:layout:template:delete') {
				const { id } = data;

				await InvoiceService.invoice.extension.deleteInvoiceLayoutTemplate(
					{
						id: id,
					},
				);

				enqueueSnackbar(`Template deleted`, { variant: 'success' });

				return onSubmit?.('invoice:layout:template:fetch');
			}

			if (action === 'invoice:layout:template:save') {
				const template = LayoutsStore.selectors.template(
					store.getState(),
				);
				const templateId = template?._id ?? template?.id;

				const layout = formatLayoutRequest(getValues('layout'));

				if (layout.fullPage) {
					delete layout.fullPage;
				}

				return onSubmit?.('invoice:layout:template:update', {
					id: templateId,
					layout: layout,
				});
			}

			if (action === 'invoice:layout:template:refresh') {
				const template = LayoutsStore.selectors.template(
					store.getState(),
				);

				enqueueSnackbar(`Template refreshed`, { variant: 'success' });

				return onAction?.('template:select', template);
			}

			if (action === 'invoice:layout:sums:refresh') {
				const $area = getValues(`layout.$area`);
				const $settings = getValues(`layout.$settings`);
				const $manual = getValues(`layout.$manual`);
				return await processLayout(
					{
						$area,
						$settings,
						$manual,
						$fields: [
							'invoice.sums.tax',
							'invoice.sums.total',
							'invoice.sums.currency',
						],
					},
					{
						canvas: canvasRef.current,
						scheduler: ocrScheduler,
						setValue,
						clients: loadClient ? clients : [],
					},
				);
			}

			if (action === 'invoice:layout:template:clear') {
				const template = LayoutsStore.selectors.template(
					store.getState(),
				);
				const templateId = template?._id ?? template?.id;

				await onSubmit?.('invoice:layout:template:update', {
					id: templateId,
					layout: {},
				});

				return onAction?.('template:select', template);
			}
		},
		[
			dispatch,
			getValues,
			clients,
			loadClient,
			setValue,
			store,
			onAction,
			enqueueSnackbar,
		],
	);

	React.useEffect(() => {
		onSubmit?.('invoice:layout:template:fetch');
	}, [onSubmit]);

	const ctx = React.useMemo(
		() => ({
			active: active,
			setActive: onChangeActive,
			setHighlight: setHighlight,
			canvas: canvasRef,
			clipCanvas: rectCanvasRef,
			scheduler: ocrScheduler,
		}),
		[active, onChangeActive, setHighlight],
	);

	const templates = useSelector(LayoutsStore.selectors.templates);
	const onLoaded = React.useCallback(async () => {
		if (!autoRefreshData) {
			return;
		}

		const srcCanvas = canvasRef.current;
		const clipCanvas = rectCanvasRef.current;

		let currentClientNumber = null;
		const $area = getValues('layout.$area');
		if ($area?.client?.meta?.clientNumber) {
			currentClientNumber = await readValueWithRules(
				{
					name: 'client.meta.clientNumber',
					area: $area?.client?.meta?.clientNumber,
				},
				{
					canvas: srcCanvas,
					clipCanvas: clipCanvas,
					scheduler: ocrScheduler,
					getValues,
				},
			);
		}

		const printTemplates = []
			.concat(company?.settings?.printTemplates)
			.filter(Boolean);

		const templateId = printTemplates?.[0]?.templateId;

		if (templateId && status !== 'PROCESSED' && !currentClientNumber) {
			const layout = templates.find(({ _id }) => _id === templateId);

			if (layout) {
				onAction?.('template:select', layout);
			}
		} else if (templateId) {
			const layout = templates.find(({ _id }) => _id === templateId);

			if (layout) {
				onAction?.('template:set', layout);
			}
		}
	}, [onAction, company, templates, status, getValues, autoRefreshData]);

	const companyId = useWatch({ name: 'companyId' });

	if (!templatesFetched) {
		return null;
	}

	return (
		<FormContext.Provider value={ctx}>
			<ActionsMenu
				onIgnore={onIgnore}
				onRevert={onRevert}
				onFormSubmit={onFormSubmit}
				onProcessed={onProcessed}
				onRemove={onInvoiceRemove}
				onInvoiceChange={setInvoice}
				companyId={companyId}
				processingLayout={processingLayout}
				lockSave={lockSave}
				id={id}
				url={url}
			/>
			<canvas ref={backupRef} className="hidden" />
			<canvas ref={rectCanvasRef} className="hidden" />
			<div
				style={{
					flexGrow: 1,
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
				}}
			>
				<Paper>
					<Grid container spacing={2} justifyContent="space-between">
						<Grid item>
							<IconButton
								variant="outlined"
								onClick={onBack}
								disabled={page <= 1}
							>
								<ArrowBackIos />
							</IconButton>
						</Grid>
						<Grid item>
							<Typography
								variant="h4"
								sx={{ flexGrow: 1 }}
							>{`Page ${page} of ${pages} (v.${version})`}</Typography>
						</Grid>
						<Grid item>
							<IconButton
								variant="outlined"
								onClick={onNext}
								disabled={page >= pages}
							>
								<ArrowForwardIos />
							</IconButton>
						</Grid>
					</Grid>
				</Paper>

				<Paper sx={{ p: 2, mt: 2, flex: 1 }}>
					<div
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div
							style={{
								overflow: 'hidden',
								display: 'flex',
								flexGrow: 1,
								flexDirection: 'column',
							}}
						>
							<div
								style={{
									overflow: 'auto',
									flex: '1 1 0',
								}}
							>
								<Document
									file={url}
									className={classNames(
										'asteria-component__invoice-document',
										className,
									)}
									onLoadSuccess={onDocumentLoad}
								>
									<div
										className={classNames(
											'asteria-component__invoice-document__page-details',
											'asteria--state-editable',
											{
												'asteria--state-drawing':
													drawing,
											},
										)}
									>
										<div className="asteria-component__invoice-document__page-wrapper">
											<Page
												pageNumber={page}
												renderAnnotationLayer={false}
												renderTextLayer={false}
												className="asteria-component__invoice-document__page"
												inputRef={inputRef}
												canvasRef={canvasRef}
												height={height}
												scale={scale}
												width={width}
												onRenderSuccess={onLoaded}
											/>
											{section ? (
												<PDFSelectedSection
													x={section.x1}
													y={section.y1}
													width={
														section.x2 - section.x1
													}
													height={
														section.y2 - section.y1
													}
												/>
											) : null}
										</div>
									</div>
								</Document>
							</div>
						</div>
					</div>
				</Paper>
			</div>
			<SidePane
				selectedInvoice={selectedInvoice}
				setInvoice={setInvoice}
				invoices={invoices}
				loadClient={loadClient}
				setLoadClient={setLoadClient}
				autoRefreshData={autoRefreshData}
				setAutoRefreshData={setAutoRefreshData}
				onAction={onAction}
				onSubmit={onSubmit}
				id={id}
				url={url}
				showOCR={showOCR}
			/>
			{/*
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<ActionsMenu
						onIgnore={onIgnore}
						onRevert={onRevert}
						onFormSubmit={onFormSubmit}
						onProcessed={onProcessed}
						onRemove={onInvoiceRemove}
						onInvoiceChange={setInvoice}
						companyId={companyId}
						processingLayout={processingLayout}
						lockSave={lockSave}
					/>
					<canvas ref={backupRef} className="hidden" />
					<canvas ref={rectCanvasRef} className="hidden" />

					<div className="asteria-component__invoice-document-controls">
						<IconButton
							variant="outlined"
							onClick={onBack}
							disabled={page <= 1}
						>
							<ArrowBackIos />
						</IconButton>
						<Typography
							variant="h4"
							sx={{ flexGrow: 1 }}
						>{`Page ${page} of ${pages} (v.${version})`}</Typography>
						<IconButton
							variant="outlined"
							onClick={onNext}
							disabled={page >= pages}
						>
							<ArrowForwardIos />
						</IconButton>
					</div>
					<Document
						file={url}
						className={classNames(
							'asteria-component__invoice-document',
							className,
						)}
						onLoadSuccess={onDocumentLoad}
					>
						<div
							className={classNames(
								'asteria-component__invoice-document__page-details',
								'asteria--state-editable',
								{
									'asteria--state-drawing': drawing,
								},
							)}
						>
							<div className="asteria-component__invoice-document__page-wrapper">
								<Page
									pageNumber={page}
									renderAnnotationLayer={false}
									renderTextLayer={false}
									className="asteria-component__invoice-document__page"
									inputRef={inputRef}
									canvasRef={canvasRef}
									height={height}
									scale={scale}
									width={width}
									onRenderSuccess={onLoaded}
								/>
								{section ? (
									<PDFSelectedSection
										x={section.x1}
										y={section.y1}
										width={section.x2 - section.x1}
										height={section.y2 - section.y1}
									/>
								) : null}
							</div>
						</div>
					</Document>
				</div>
				<SidePane
					selectedInvoice={selectedInvoice}
					setInvoice={setInvoice}
					invoices={invoices}
					loadClient={loadClient}
					setLoadClient={setLoadClient}
					autoRefreshData={autoRefreshData}
					setAutoRefreshData={setAutoRefreshData}
					onAction={onAction}
					onSubmit={onSubmit}
					url={url}
				/>
								*/}
		</FormContext.Provider>
	);
};

const PDFForm = (props) => {
	const { onSubmit, data } = props;

	return (
		<Form
			onSubmit={onSubmit}
			defaultValues={data}
			className="asteria-component__invoice-document__form"
		>
			<PDF {...props} />
		</Form>
	);
};

export default PDFForm;
