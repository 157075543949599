import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import HideSourceIcon from '@mui/icons-material/HideSource';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import { Divider, Fab, ListItemIcon, Menu, MenuItem } from '@mui/material';

import { InvoiceService, UserService } from '@asteria/backend-utils-services';

import { selectToken } from '../../store/auth';

export const ActionsMenu = ({
	onIgnore,
	onRevert,
	onRemove,
	onProcessed,
	onFormSubmit,
	onInvoiceChange,
	companyId,
	lockSave,
	processingLayout,
	invoicePages,
	selectedInvoice,
	setInvoice,
	id,
	url,
}) => {
	const navigate = useNavigate();
	const { reset, handleSubmit } = useFormContext();
	const anchorRef = React.useRef(null);
	const [showActionsMenu, setActionsMenu] = React.useState(false);
	const accessToken = useSelector(selectToken);
	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const state = useFormState();

	const onSave = React.useCallback(async () => {
		if (lockSave) {
			return false;
		}
		console.log('OnSve', onFormSubmit);

		try {
			await handleSubmit(onFormSubmit, (e, t) => {
				console.log(e, t);
			})();
		} catch (e) {
			console.log(e);
		}
		setActionsMenu(false);
	}, [handleSubmit, lockSave, onFormSubmit]);

	const onSaveAndReset = React.useCallback(async () => {
		if (lockSave) {
			return false;
		}
		await handleSubmit(async (data) => {
			await onFormSubmit(data);
			onInvoiceChange({ target: { value: 'new' } });
		})();
		setActionsMenu(false);
	}, [handleSubmit, lockSave, onFormSubmit, onInvoiceChange]);

	const onSaveAndBack = React.useCallback(async () => {
		if (lockSave) {
			return false;
		}
		await handleSubmit(async (data) => {
			await onFormSubmit(data);
			navigate(`/invoices`);
		})();
	}, [handleSubmit, lockSave, navigate, onFormSubmit]);

	const onNext = React.useCallback(() => {
		if (!invoicePages) {
			return;
		}

		const index = invoicePages.findIndex(
			(invoice) =>
				invoice?.invoiceNumber === selectedInvoice?.invoiceNumber,
		);

		if (index + 1 !== invoicePages.length) {
			setInvoice({
				target: { value: invoicePages[index + 1]?.invoiceNumber },
			});
		}
	}, [invoicePages, setInvoice, selectedInvoice]);

	const onSaveAndNext = React.useCallback(async () => {
		if (lockSave) {
			return false;
		}
		await handleSubmit(async (data) => {
			await onFormSubmit(data);
			// onInvoiceChange({ target: { value: 'new' } });
			/*
			await InvoiceService.invoice.extension
				.invoiceLayouts(
					{
						status: 'PENDING',
						companyId: companyId,
					},
					{ token: accessToken },
				)
				.then(({ edges }) => edges.map(({ node }) => node))
				.then((response) => {
					const [, nextLayout] = response;

					if (!nextLayout) {
						navigate(`/invoices`);
					} else {
						navigate(`/invoices/layout/${nextLayout.id}`);
					}
				});
			*/
			onNext();
		})();
	}, [handleSubmit, lockSave, onFormSubmit, onInvoiceChange, onNext]);

	const onReRunOcr = React.useCallback(async () => {
		const fileName = url.split('/').at(-1);

		const {
			edges: [{ node: user }],
		} = await UserService.user.fetch({
			filters: { companyId: companyId },
		});

		let psFilePath = fileName
			.replace('_print.pdf', '.ps')
			.replace('.pdf', '.ps');

		const uri = `https://printer.asteria.ai/invoices/process/${companyId}/${user.id}/${id}/${psFilePath}`;

		fetch(uri);
	}, [url, companyId, id]);

	const onFormReset = React.useCallback(() => {
		reset();
	}, [reset]);

	if (processingLayout) {
		return null;
	}

	return (
		<>
			<Fab
				color="primary"
				ref={anchorRef}
				onClick={toggleActionsMenu}
				sx={{
					position: 'fixed',
					bottom: '10px',
					right: '10px',
					zIndex: 10000,
				}}
			>
				<MoreVertIcon />
			</Fab>
			<Menu
				anchorEl={anchorRef.current}
				open={showActionsMenu}
				onClose={() => setActionsMenu(false)}
			>
				<MenuItem onClick={onReRunOcr} disabled={lockSave}>
					<ListItemIcon>
						<SaveIcon fontSize="small" />
					</ListItemIcon>
					Rerun Invoice OCR
				</MenuItem>
				<MenuItem onClick={onSave} disabled={lockSave}>
					<ListItemIcon>
						<SaveIcon fontSize="small" />
					</ListItemIcon>
					Save Invoice
				</MenuItem>
				<MenuItem onClick={onSaveAndBack} disabled={lockSave}>
					<ListItemIcon>
						<SaveIcon fontSize="small" />
					</ListItemIcon>
					Save Invoice and back
				</MenuItem>
				<MenuItem onClick={onSaveAndNext} disabled={lockSave}>
					<ListItemIcon>
						<SaveIcon fontSize="small" />
					</ListItemIcon>
					Save Invoice and Next
				</MenuItem>
				<MenuItem onClick={onSaveAndReset} disabled={lockSave}>
					<ListItemIcon>
						<SaveIcon fontSize="small" />
					</ListItemIcon>
					Save and Reset form
				</MenuItem>

				<Divider />

				<MenuItem onClick={onFormReset}>
					<ListItemIcon>
						<FormatClearIcon fontSize="small" />
					</ListItemIcon>
					Clear Invoice
				</MenuItem>
				<MenuItem onClick={onRemove}>
					<ListItemIcon>
						<DeleteIcon fontSize="small" />
					</ListItemIcon>
					Remove Invoice
				</MenuItem>
				<Divider />
				<MenuItem onClick={onIgnore}>
					<ListItemIcon>
						<HideSourceIcon fontSize="small" />
					</ListItemIcon>
					Ignore Layout
				</MenuItem>
				<MenuItem onClick={onRevert}>
					<ListItemIcon>
						<HistoryIcon />
					</ListItemIcon>
					Revert Layout
				</MenuItem>
				<MenuItem onClick={onProcessed}>
					<ListItemIcon>
						<DoneIcon />
					</ListItemIcon>
					Processed Layout
				</MenuItem>
			</Menu>
		</>
	);
};
