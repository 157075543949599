/* eslint-disable indent */
import React, { useEffect } from 'react';

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
	Brush,
	Delete,
	ExpandMore,
	MoreVert,
	Refresh,
	Settings,
} from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	Collapse,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { get, uniqueId } from 'lodash';

import { CompanyService } from '@asteria/backend-utils-services';

import { FormContext } from './context';
import { useFields } from './hooks';
import { InputWrapper } from './input';
import { detectValue, getInvoiceRows, readValueWithRules } from './utils';

import './styles.scss';

const InputField = ({ name, label, onFocus }) => {
	const { register } = useFormContext();
	const input = register(name);
	const value = useWatch({ name: name });
	return (
		<TextField
			label={label}
			variant="outlined"
			fullWidth
			onFocus={onFocus}
			InputLabelProps={{ shrink: !!value && value !== 0 }}
			{...input}
		/>
	);
};

const ExtendableField = (props) => {
	const { fields, label, onRemove } = props;
	const [open, setOpen] = React.useState(true);

	const onToggle = React.useCallback(() => {
		setOpen((value) => !value);
	}, []);

	return (
		<>
			<ListItem
				secondaryAction={
					<IconButton
						edge="end"
						aria-label="delete"
						onClick={onRemove}
					>
						<Delete />
					</IconButton>
				}
			>
				<ListItemButton onClick={onToggle}>
					<ListItemText primary={label} />
				</ListItemButton>
			</ListItem>
			<Collapse in={open}>
				<Fields fields={fields} nested />
			</Collapse>
		</>
	);
};

const ExtendableFields = (props) => {
	const { name, label, fields } = props;

	const { fields: rows, append, remove } = useFieldArray({ name: name });

	const onAdd = React.useCallback(() => {
		append(fields.reduce((acc, { name }) => ({ ...acc, [name]: '' }), {}));
	}, [append, fields]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new record
			</Button>
			{rows.map((field, index) => (
				<ExtendableField
					key={field.id}
					fields={fields.map((object) => ({
						...object,
						name: [name, index, object?.name].join('.'),
					}))}
					label={`${label}: #${index + 1}`}
					onRemove={() => remove(index)}
				/>
			))}
		</List>
	);
};

const Fields = (props) => {
	const { fields, nested } = props;

	const { available, extendable } = fields.reduce(
		(acc, object) => {
			const isExtendable = object?.group?.extendable;

			if (isExtendable) {
				acc.extendable = acc.extendable.concat(object);
			} else {
				acc.available = acc.available.concat(object);
			}

			return acc;
		},
		{ available: [], extendable: [] },
	);

	return (
		<>
			{(nested ? fields.length : available.length) ? (
				<List
					sx={{
						width: '100%',
						bgcolor: 'background.paper',
					}}
				>
					{(nested ? fields : available).map((field) => (
						<ListItem key={field?.name}>
							<InputWrapper {...field} />
						</ListItem>
					))}
				</List>
			) : null}
			{extendable.length ? (
				<ExtendableFields {...props} fields={extendable} />
			) : null}
		</>
	);
};

const Content = (props) => {
	const { value, name, children } = props;

	return (
		<div role="tabpanel" hidden={value !== name}>
			<Box sx={{ width: '100%' }}>{value === name ? children : null}</Box>
		</div>
	);
};

const Anchor = (props) => {
	const { field, index, remove } = props;
	const anchorRef = React.useRef(null);
	const { register } = useFormContext();
	const { setActive, setHighlight } = React.useContext(FormContext);
	const [showActionsMenu, setActionsMenu] = React.useState(false);
	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({ name: `layout.anchors.${index}.area`, drawing: true });
	}, [index, setActive]);

	const onFocus = React.useCallback(() => {
		setHighlight(`layout.anchors.${index}.area`);
	}, [setHighlight, index]);

	const onRemove = React.useCallback(() => {
		remove(index);
	}, [remove, index]);

	return (
		<ListItem>
			<Grid container direction="row" alignItems="center" spacing={2}>
				<Grid item xs>
					<TextField
						label="Anchor"
						variant="outlined"
						fullWidth
						onFocus={onFocus}
						{...register(`layout.anchors.${index}.name`)}
					/>
				</Grid>
				<Grid item xs="auto">
					<IconButton
						ref={anchorRef}
						onClick={toggleActionsMenu}
						size="large"
					>
						<MoreVert />
					</IconButton>
					<Menu
						anchorEl={anchorRef.current}
						open={showActionsMenu}
						onClose={() => setActionsMenu(false)}
					>
						<MenuItem onClick={onDrawClick}>
							<IconButton size="small" color="info">
								<Brush size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem>
							<IconButton size="small" color="secondary">
								<Settings size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem onClick={onRemove}>
							<IconButton
								size="small"
								color="error"
								onClick={onRemove}
							>
								<Delete size="small" />
							</IconButton>
						</MenuItem>
					</Menu>
				</Grid>
			</Grid>
		</ListItem>
	);
};

const Anchors = () => {
	const { fields, append, remove } = useFieldArray({
		name: 'layout.anchors',
	});

	const onAdd = React.useCallback(() => {
		append({ id: uniqueId() });
	}, [append]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new record
			</Button>
			{fields.map((field, index) => (
				<Anchor
					key={field?.id}
					field={field}
					index={index}
					remove={remove}
				/>
			))}
		</List>
	);
};

const KeyPoint = (props) => {
	const { field, index, remove } = props;
	const anchorRef = React.useRef(null);
	const { register } = useFormContext();
	const { setActive, setHighlight } = React.useContext(FormContext);
	const [showActionsMenu, setActionsMenu] = React.useState(false);

	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({
			area: `layout.$area.keypoints.${index}`,
			name: `tmp.keypoints.${index}`,
			drawing: true,
		});
	}, [index, setActive]);

	const onFocus = React.useCallback(() => {
		setHighlight(`layout.$area.keypoints.${index}`);
	}, [setHighlight, index]);

	const onRemove = React.useCallback(() => {
		remove(index);
	}, [remove, index]);

	return (
		<ListItem>
			<Grid container direction="row" alignItems="center" spacing={2}>
				<Grid item xs>
					<TextField
						label="KeyPoint"
						variant="outlined"
						fullWidth
						onFocus={onFocus}
						{...register(`layout.keypoints.${index}`)}
					/>
				</Grid>
				<Grid item xs="auto">
					<IconButton
						ref={anchorRef}
						onClick={toggleActionsMenu}
						size="large"
					>
						<MoreVert />
					</IconButton>
					<Menu
						anchorEl={anchorRef.current}
						open={showActionsMenu}
						onClose={() => setActionsMenu(false)}
					>
						<MenuItem onClick={onDrawClick}>
							<IconButton size="small" color="info">
								<Brush size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem>
							<IconButton size="small" color="secondary">
								<Settings size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem onClick={onRemove}>
							<IconButton
								size="small"
								color="error"
								onClick={onRemove}
							>
								<Delete size="small" />
							</IconButton>
						</MenuItem>
					</Menu>
				</Grid>
			</Grid>
		</ListItem>
	);
};

const KeyPoints = () => {
	const { fields, append, remove } = useFieldArray({
		name: 'layout.keypoints',
	});

	const onAdd = React.useCallback(() => {
		append('');
	}, [append]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new record
			</Button>
			{fields.map((field, index) => (
				<KeyPoint
					key={field?.id}
					field={field}
					index={index}
					remove={remove}
				/>
			))}
		</List>
	);
};

const InvoiceColumn = (props) => {
	const { field, index, remove } = props;
	const anchorRef = React.useRef(null);
	const { register } = useFormContext();
	const { setActive, setHighlight } = React.useContext(FormContext);
	const [showActionsMenu, setActionsMenu] = React.useState(false);

	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({
			area: `layout.$area.columns.${index}`,
			name: `layout.columns.${index}`,
			drawing: true,
		});
	}, [index, setActive]);

	const onFocus = React.useCallback(() => {
		setHighlight(`layout.$area.columns.${index}`);
	}, [setHighlight, index]);

	const onRemove = React.useCallback(() => {
		remove(index);
	}, [remove, index]);

	const name = useWatch({ name: `layout.columns.${index}.label` });
	const alignment = useWatch({ name: `layout.columns.${index}.alignment` });
	const fieldType = useWatch({ name: `layout.columns.${index}.field` });

	return (
		<ListItem>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography>{name || `Columns ${index + 1}`}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid
						container
						direction="column"
						alignItems="left"
						spacing={1}
					>
						<Grid item>
							<InputField
								name={`layout.columns.${index}.label`}
								onFocus={onFocus}
								label="Column Label"
							/>
						</Grid>
						<Grid item>
							<InputField
								name={`layout.columns.${index}.baseline.x1`}
								onFocus={onFocus}
								label="Column Baseline"
							/>
						</Grid>
						<Grid item>
							<FormControl fullWidth>
								<InputLabel
									id={`layout-columns-${index}-alignment-label`}
								>
									Column Alignment
								</InputLabel>
								<Select
									label="Column Alignment"
									labelId={`layout-columns-${index}-alignment-label`}
									id={`layout-columns-${index}-alignment`}
									value={alignment}
									{...register(
										`layout.columns.${index}.alignment`,
									)}
								>
									<MenuItem value="LEFT">Left</MenuItem>
									<MenuItem value="RIGHT">Right</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl fullWidth>
								<InputLabel
									id={`layout-columns-${index}-field-label`}
								>
									Column Type
								</InputLabel>
								<Select
									label="Column Field"
									labelId={`layout-columns-${index}-field-field`}
									id={`layout-columns-${index}-field`}
									value={fieldType}
									{...register(
										`layout.columns.${index}.field`,
									)}
								>
									<MenuItem value="text">Text</MenuItem>
									<MenuItem value="productId">
										Product ID
									</MenuItem>
									<MenuItem value="description">
										Description
									</MenuItem>
									<MenuItem value="qty">Count</MenuItem>
									<MenuItem value="qtyUnit">
										Count Type
									</MenuItem>
									<MenuItem value="price">Price</MenuItem>
									<MenuItem value="discount">
										Discount
									</MenuItem>
									<MenuItem value="total">Total</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						{fieldType === 'text' ? (
							<Grid item>
								<InputField
									name={`layout.columns.${index}.key`}
									onFocus={onFocus}
									label="Column key"
								/>
							</Grid>
						) : null}
						<Grid item>
							<IconButton
								ref={anchorRef}
								onClick={toggleActionsMenu}
								size="large"
							>
								<MoreVert />
							</IconButton>
							<Menu
								anchorEl={anchorRef.current}
								open={showActionsMenu}
								onClose={() => setActionsMenu(false)}
							>
								<MenuItem onClick={onDrawClick}>
									<IconButton size="small" color="info">
										<Brush size="small" />
									</IconButton>
								</MenuItem>
								<MenuItem>
									<IconButton size="small" color="secondary">
										<Settings size="small" />
									</IconButton>
								</MenuItem>
								<MenuItem onClick={onRemove}>
									<IconButton
										size="small"
										color="error"
										onClick={onRemove}
									>
										<Delete size="small" />
									</IconButton>
								</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</ListItem>
	);
};

const RowBaseLine = (props) => {
	const anchorRef = React.useRef(null);
	const { register } = useFormContext();
	const { setActive, setHighlight } = React.useContext(FormContext);
	const [showActionsMenu, setActionsMenu] = React.useState(false);

	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({
			area: `layout.$area.rows.baseline`,
			name: `layout.rows.baseline`,
			drawing: true,
		});
	}, [setActive]);

	const onFocus = React.useCallback(() => {
		setHighlight(`layout.$area.rows.baseline`);
	}, [setHighlight]);

	return (
		<ListItem>
			<Grid container direction="row" alignItems="center" spacing={2}>
				<Grid item xs>
					<InputField
						name={`layout.rows.baseline`}
						onFocus={onFocus}
					/>
				</Grid>
				<Grid item xs="auto">
					<IconButton
						ref={anchorRef}
						onClick={toggleActionsMenu}
						size="large"
					>
						<MoreVert />
					</IconButton>
					<Menu
						anchorEl={anchorRef.current}
						open={showActionsMenu}
						onClose={() => setActionsMenu(false)}
					>
						<MenuItem onClick={onDrawClick}>
							<IconButton size="small" color="info">
								<Brush size="small" />
							</IconButton>
						</MenuItem>
					</Menu>
				</Grid>
			</Grid>
		</ListItem>
	);
};

const InvoiceRowField = (props) => {
	const { column, index } = props;
	const anchorRef = React.useRef(null);

	const [showActionsMenu, setActionsMenu] = React.useState(false);

	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const { setActive, setHighlight } = React.useContext(FormContext);

	const field = React.useMemo(() => {
		if (column.field === 'text') {
			return column.key;
		}

		return column.field;
	}, [column]);

	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({
			area: `tmp.layout.rows.${index}.${field}`,
			name: `rows.${index}.${field}`,
			drawing: true,
		});
	}, [index, setActive, setActionsMenu, field]);

	const onFocus = React.useCallback(() => {
		setHighlight(`tmp.layout.rows.${index}.${field}`);
	}, [setHighlight, index, field]);

	return (
		<>
			<Grid item>
				<InputField
					name={`rows.${index}.${field}`}
					onFocus={onFocus}
					label={column.label}
				/>
			</Grid>
			<Grid item>
				<IconButton
					ref={anchorRef}
					onClick={toggleActionsMenu}
					size="large"
				>
					<MoreVert />
				</IconButton>
				<Menu
					anchorEl={anchorRef.current}
					open={showActionsMenu}
					onClose={() => setActionsMenu(false)}
				>
					<MenuItem onClick={onDrawClick}>
						<IconButton size="small" color="info">
							<Brush size="small" />
						</IconButton>
					</MenuItem>
				</Menu>
			</Grid>
		</>
	);
};

const InvoiceRowColumnField = (props) => {
	const { name, index } = props;
	const anchorRef = React.useRef(null);

	const [showActionsMenu, setActionsMenu] = React.useState(false);

	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const { setActive, setHighlight } = React.useContext(FormContext);

	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({
			area: `tmp.layout.${name}.${index}`,
			name: `${name}.${index}.value`,
			drawing: true,
		});
	}, [index, setActive, name, setActionsMenu]);

	const onFocus = React.useCallback(() => {
		setHighlight(`tmp.layout.${name}.${index}`);
	}, [setHighlight, name, index]);

	return (
		<>
			<Grid item>
				<InputField
					name={`${name}.${index}.key`}
					onFocus={onFocus}
					label="Key"
				/>
			</Grid>
			<Grid item>
				<InputField
					name={`${name}.${index}.value`}
					onFocus={onFocus}
					label="Value"
				/>
			</Grid>
			<Grid item>
				<IconButton
					ref={anchorRef}
					onClick={toggleActionsMenu}
					size="large"
				>
					<MoreVert />
				</IconButton>
				<Menu
					anchorEl={anchorRef.current}
					open={showActionsMenu}
					onClose={() => setActionsMenu(false)}
				>
					<MenuItem onClick={onDrawClick}>
						<IconButton size="small" color="info">
							<Brush size="small" />
						</IconButton>
					</MenuItem>
				</Menu>
			</Grid>
		</>
	);
};

const InvoiceRow = (props) => {
	const { index } = props;
	const { setValue } = useFormContext();
	const columns = useWatch({ name: 'layout.columns' });
	const fullRow = useWatch({ name: `tmp.row.${index}` });
	const rowRegex = useWatch({ name: `layout.rowRegex` });

	const { fields, append, remove } = useFieldArray({
		name: `rows.${index}.extra`,
	});

	const onAdd = React.useCallback(() => {
		append('');
	}, [append]);

	useEffect(() => {
		if (!rowRegex || !fullRow) {
			return;
		}
		const regex = new RegExp(rowRegex, 'gm');
		const result = regex.exec(fullRow);

		if (result) {
			Object.entries(result.groups).forEach(([key, value]) => {
				setValue(`rows.${index}.${key}`, value);
			});
		}
	}, [fullRow, index, setValue, rowRegex]);

	return (
		<ListItem>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography>{`Row ${index + 1}`}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid
						container
						direction="row"
						alignItems="left"
						spacing={1}
					>
						{(columns ?? []).map((column) => (
							<InvoiceRowField
								key={column.key ?? column.field ?? column.id}
								column={column}
								index={index}
							/>
						))}
						<Button
							variant="outlined"
							color="success"
							fullWidth
							onClick={onAdd}
						>
							Add new column
						</Button>
						{fields.map((field, indexExtra) => (
							<InputWrapper
								key={indexExtra}
								name={`rows.${index}.extra.${indexExtra}`}
								label="Row"
							/>
						))}
						<Grid item>
							<InputWrapper
								name={`tmp.row.${index}`}
								label="Row"
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</ListItem>
	);
};

const RowsBlock = (props) => {
	const anchorRef = React.useRef(null);
	const { register, getValues } = useFormContext();
	const { setActive, setHighlight, canvas, scheduler } =
		React.useContext(FormContext);
	const [showActionsMenu, setActionsMenu] = React.useState(false);

	const area = useWatch({ name: 'layout.$area.rows.block' });

	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({
			area: `layout.$area.rows.block`,
			name: `layout.rows.block`,
			drawing: true,
		});
	}, [setActive]);

	const onFocus = React.useCallback(() => {
		setHighlight(`layout.$area.rows.block`);
	}, [setHighlight]);

	const onRefresh = React.useCallback(async () => {
		setActionsMenu(false);
		const { lines } = await detectValue(area, canvas.current, scheduler, {
			preserve_interword_spaces: '1',
		});

		const baseline = parseInt(getValues('layout.rows.baseline'));

		const rows = getInvoiceRows(baseline, lines);
	}, [area, canvas, scheduler, getValues]);

	return (
		<ListItem>
			<Grid container direction="row" alignItems="center" spacing={2}>
				<Grid item xs>
					<InputField name={`layout.rows.block`} onFocus={onFocus} />
				</Grid>
				<Grid item xs="auto">
					<IconButton
						ref={anchorRef}
						onClick={toggleActionsMenu}
						size="large"
					>
						<MoreVert />
					</IconButton>
					<Menu
						anchorEl={anchorRef.current}
						open={showActionsMenu}
						onClose={() => setActionsMenu(false)}
					>
						<MenuItem onClick={onDrawClick}>
							<IconButton size="small" color="info">
								<Brush size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem onClick={onRefresh}>
							<IconButton size="small" color="info">
								<Refresh size="small" />
							</IconButton>
						</MenuItem>
					</Menu>
				</Grid>
			</Grid>
		</ListItem>
	);
};

const InvoiceColumns = () => {
	const { fields, append, remove } = useFieldArray({
		name: 'layout.columns',
	});

	const onAdd = React.useCallback(() => {
		append({
			id: uniqueId(),
			label: '',
			baseline: { x1: 0, x2: 0, y1: 0, y2: 0 },
			alignment: 'LEFT',
		});
	}, [append]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<RowBaseLine />
			<RowsBlock />
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new column
			</Button>
			<Grid item>
				<InputWrapper name={`layout.rowRegex`} label="Row Regex" />
			</Grid>
			{fields.map((field, index) => (
				<InvoiceColumn
					key={field?.id ?? index}
					field={field}
					index={index}
					remove={remove}
				/>
			))}
		</List>
	);
};

const InvoiceRows = () => {
	const { fields, append, remove } = useFieldArray({
		name: 'rows',
	});

	const { setValue } = useFormContext();

	const rowRegex = useWatch({ name: `layout.rowRegex` });
	const fullPage = useWatch({ name: `layout.fullPage` });

	useEffect(() => {
		if (!fullPage) {
			return;
		}

		const regex = new RegExp(rowRegex, 'gm');
		// const result = regex.exec(fullPage);

		if (!regex) {
			return;
		}

		let rows = [];
		let extra = [];
		for (let i = 0; i < fullPage.length; i += 1) {
			if (!fullPage[i]) {
				continue;
			}
			const result = regex.exec(fullPage[i]);

			if (!result) {
				extra.push(fullPage[i]);
			} else {
				if (extra.length > 0 && rows.length > 0) {
					rows[rows.length - 1].extra = [
						...(rows[rows.length - 1].extra ?? []),
						...extra,
					];
				}

				rows.push({
					...Object.fromEntries(
						Object.entries(result.groups).map(([key, value]) => [
							key,
							value?.trim?.(),
						]),
					),
				});

				if (rows.length !== 1) {
					extra = [];
				}
			}

			regex.lastIndex = 0;
		}

		if (extra.length > 0 && rows.length > 0) {
			rows[rows.length - 1].extra = [
				...(rows[rows.length - 1].extra ?? []),
				...extra,
			];
		}

		setValue('rows', rows);
	}, [fullPage, setValue, rowRegex]);

	const onAdd = React.useCallback(() => {
		append({
			id: uniqueId(),
			productId: '',
			description: '',
			count: 0,
			countType: '',
			discount: '',
			price: '',
			total: '',
		});
	}, [append]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<InvoiceColumns />
			<Grid item>
				<InputWrapper name={`layout.fullPage`} label="Full page" />
			</Grid>
			<ul>
				{(fullPage || []).map((_, index) => (
					<InputWrapper
						key={index}
						name={`layout.fullPage.${index}`}
						label="Full page"
					/>
				))}
			</ul>
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new row
			</Button>
			{fields.map((field, index) => (
				<InvoiceRow
					key={field?.id ?? index}
					field={field}
					index={index}
					remove={remove}
				/>
			))}
		</List>
	);
};

const DeliveryInfo = () => {
	const anchorRef = React.useRef(null);
	const { setValue, getValues } = useFormContext();
	const { canvas, scheduler } = React.useContext(FormContext);
	const { fields, append, remove } = useFieldArray({
		name: 'invoice.delivery.extra',
	});

	const onAdd = React.useCallback(() => {
		append('');
	}, [append]);

	const onRefresh = React.useCallback(async () => {
		const area = getValues('layout.$area');
		const fields = [
			'invoice.delivery.method',
			'invoice.delivery.terms',
			'invoice.meta.termsOfPayment',
			'invoice.meta.sellerOrderId',
			'invoice.meta.buyerOrderId',
			'invoice.meta.buyerReference',
			'invoice.meta.sellerReference',
			'invoice.contact.shipping.street2',
			'invoice.contact.shipping.street',
			'invoice.contact.shipping.zipcode',
			'invoice.contact.shipping.city',
			'invoice.contact.shipping.country',
		];

		for (let i = 0; i < fields.length; i += 1) {
			const fieldArea = get(area, fields[i]);
			if (!fieldArea) {
				continue;
			}

			const value = await readValueWithRules(
				{ name: fields[i], area: fieldArea },
				{
					canvas: canvas.current,
					scheduler: scheduler,
					getValues,
				},
			);

			setValue(fields[i], value);
		}

		const extraFields = get(area, 'invoice.delivery.extra');
		if (extraFields && extraFields.length > 0) {
			const values = [];
			for (let i = 0; i < extraFields.length; i += 1) {
				const fieldArea = extraFields[i];

				if (!fieldArea) {
					continue;
				}

				const value = await readValueWithRules(
					{ name: `invoice.delivery.extra.${i}`, area: fieldArea },
					{
						canvas: canvas.current,
						scheduler: scheduler,
						getValues,
					},
				);

				values.push(value);
			}

			setValue('invoice.delivery.extra', values);
		}

		const vatFields = get(area, 'invoice.vats');
		if (vatFields && vatFields.length > 0) {
			const values = [];
			for (let i = 0; i < vatFields.length; i += 1) {
				const fieldArea = vatFields[i];

				if (
					!fieldArea &&
					fieldArea.value &&
					fieldArea.rate &&
					fieldArea.vat
				) {
					continue;
				}

				const total = await readValueWithRules(
					{ name: `invoice.vats.${i}.total`, area: fieldArea.total },
					{
						canvas: canvas.current,
						scheduler: scheduler,
						getValues,
					},
				);

				const rate = await readValueWithRules(
					{ name: `invoice.vats.${i}.rate`, area: fieldArea.rate },
					{
						canvas: canvas.current,
						scheduler: scheduler,
						getValues,
					},
				);

				const vat = await readValueWithRules(
					{ name: `invoice.vats.${i}.vat`, area: fieldArea.vat },
					{
						canvas: canvas.current,
						scheduler: scheduler,
						getValues,
					},
				);

				values.push({ total, rate, vat });
			}

			setValue('invoice.vats', values);
		}

		const columns = Object.values(get(area, 'columns', {}));

		for (let i = 0; i < columns.length; i += 1) {
			const { text } = await detectValue(
				columns[i],
				canvas.current,
				scheduler,
			);

			setValue(`layout.columns.${i}.label`, text);
		}

		const fullPage = get(area, 'layout.fullPage');
		if (fullPage) {
			const { lines } = await detectValue(
				fullPage,
				canvas.current,
				scheduler,
			);

			setValue(
				'layout.fullPage',
				lines.map(({ text }) => text),
			);
		}
	}, [canvas, scheduler, setValue, getValues]);

	return (
		<>
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onRefresh}
			>
				Fetch
			</Button>
			<Grid item>
				<InputWrapper
					name="invoice.delivery.method"
					label="Delivery Method"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.delivery.terms"
					label="Delivery terms"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.meta.termsOfPayment"
					label="Terms of payment"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.meta.sellerOrderId"
					label="Seller Order ID"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.meta.buyerOrderId"
					label="Buyer Order ID"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.meta.buyerReference"
					label="Buyer reference"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.meta.sellerReference"
					label="Seller reference"
				/>
			</Grid>
			<Grid item>
				<Typography>Address</Typography>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.contact.shipping.street2"
					label="Name"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.contact.shipping.street"
					label="Street"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.contact.shipping.zipcode"
					label="Zipcode"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.contact.shipping.city"
					label="City"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name="invoice.contact.shipping.country"
					label="Country"
				/>
			</Grid>
			<Grid item>
				<Typography>Extra texts</Typography>
			</Grid>
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new text
			</Button>
			{fields.map((field, index) => (
				<Grid item>
					<InputWrapper
						name={`invoice.delivery.extra.${index}`}
						label="Extra Text Field"
					/>
				</Grid>
			))}
		</>
	);
};

const InvoiceVatRow = (props) => {
	const { index } = props;

	return (
		<>
			<Grid item>
				<Typography>VAT Rate</Typography>
			</Grid>

			<Grid item>
				<InputWrapper
					name={`invoice.vats.${index}.rate`}
					label="Rate"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name={`invoice.vats.${index}.total`}
					label="Total"
				/>
			</Grid>
			<Grid item>
				<InputWrapper name={`invoice.vats.${index}.vat`} label="VAT" />
			</Grid>
		</>
	);
};

const InvoiceSummaryRow = (props) => {
	const { index } = props;

	return (
		<>
			<Grid item>
				<Typography>Summary Row</Typography>
			</Grid>

			<Grid item>
				<InputWrapper
					name={`invoice.print.summary.${index}.label`}
					label="Label"
				/>
			</Grid>
			<Grid item>
				<InputWrapper
					name={`invoice.print.summary.${index}.value`}
					label="Value"
				/>
			</Grid>
		</>
	);
};

const InvoiceVATs = () => {
	const { fields, append, remove } = useFieldArray({
		name: 'invoice.vats',
	});

	const onAdd = React.useCallback(() => {
		append({
			total: 0,
			vat: 0,
			rate: 0,
		});
	}, [append]);

	const {
		fields: summaryFields,
		append: appendSummary,
		remove: removeSummary,
	} = useFieldArray({
		name: 'invoice.print.summary',
	});

	const onAddSummary = React.useCallback(() => {
		appendSummary({
			label: '',
			value: '',
		});
	}, [appendSummary]);

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAdd}
			>
				Add new vat
			</Button>
			{fields.map((field, index) => (
				<InvoiceVatRow
					key={field?.rate}
					field={field}
					index={index}
					remove={remove}
				/>
			))}
			<Button
				variant="outlined"
				color="success"
				fullWidth
				onClick={onAddSummary}
			>
				Add new summary row
			</Button>
			{summaryFields.map((field, index) => (
				<InvoiceSummaryRow
					key={field?.label}
					field={field}
					index={index}
					remove={remove}
				/>
			))}
		</List>
	);
};

const CommonContent = () => {
	const { setValue } = useFormContext();

	const companyId = useWatch({ name: 'companyId' });

	const { data, isLoading } = useQuery({
		queryKey: [`companies`, companyId, 'relative.companies'],
		queryFn: async () =>
			CompanyService.company
				.fetchOne({
					id: companyId,
					fields: `_id users(pageFilters: { first: 0 }) { edges { node { _id companies(pageFilters: { first: 0 }) { edges { node { _id name } } } } } }`,
				})
				.then((response) =>
					(response?.users?.edges ?? []).flatMap((object) =>
						(object?.node?.companies?.edges ?? []).map(
							(object) => object?.node,
						),
					),
				),
	});

	const onRelativeCompanyChange = (event) => {
		setValue('companyId', event?.target?.value);
	};

	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			<ListItem>
				<InputWrapper name="companyId" label="Company ID" />
			</ListItem>

			{!isLoading && data?.length ? (
				<ListItem>
					<InputWrapper name="relative-companyId">
						<TextField
							id="relative-companyId"
							label="Relative CompanyID"
							select
							value={companyId}
							onChange={onRelativeCompanyChange}
							InputLabelProps={{ shrink: true }}
							fullWidth
						>
							{data.map((object) => (
								<MenuItem key={object?._id} value={object?._id}>
									{object?.name ?? object?._id}
								</MenuItem>
							))}
						</TextField>
					</InputWrapper>
				</ListItem>
			) : null}
		</List>
	);
};

const Wrapper = (props) => {
	const [active, setActive] = React.useState('invoice');

	const fields = useFields();

	const onChange = React.useCallback((event, value) => {
		setActive(value);
	}, []);

	return (
		<Box sx={{ width: '100%', maxWidth: 450 }}>
			<Tabs
				value={active}
				onChange={onChange}
				variant="scrollable"
				scrollButtons="auto"
				sx={{ maxWidth: 520 }}
			>
				<Tab label="Common" value="common" />
				{Object.values(fields).map(({ name, label }) => (
					<Tab key={name} label={label} value={name} />
				))}
				<Tab label="Delivery" value="delivery" />
				<Tab label="VAT" value="vats" />
				<Tab label="Rows" value="rows" />
				<Tab label="Anchors" value="anchors" />
				<Tab label="Key Points" value="keypoints" />
			</Tabs>
			<Content value={active} name="common">
				<CommonContent />
			</Content>
			{Object.values(fields).map((object) => (
				<Content key={object?.name} value={active} {...object}>
					<Fields {...object} />
				</Content>
			))}
			<Content value={active} name="vats">
				<InvoiceVATs />
			</Content>
			<Content value={active} name="delivery">
				<DeliveryInfo />
			</Content>
			<Content value={active} name="rows">
				<InvoiceRows />
			</Content>
			<Content value={active} name="anchors">
				<Anchors />
			</Content>
			<Content value={active} name="keypoints">
				<KeyPoints />
			</Content>
		</Box>
	);
};

export default Wrapper;
