import React from 'react';

import { useParams } from 'react-router-dom';

import { Paper } from '@mui/material';

import Form from '../../../../components/form';

import Footer from './footer';
import Header from './header';
import { useCreateMutation, useSubscription } from './hooks';
import Messages from './messages';

function ChatDetailsPage() {
	const { id } = useParams();

	const ref = React.useRef(null);

	const mutation = useCreateMutation({ id });

	useSubscription({ id });

	return (
		<Form onSubmit={mutation.mutate} className="grow flex flex-col gap-4">
			<Header id={id} />
			<Paper
				className="grow p-4 overflow-auto h-0 flex flex-col gap-8"
				ref={ref}
			>
				<Messages id={id} wrapperRef={ref} />
			</Paper>
			<Footer id={id} success={mutation.isSuccess} />
		</Form>
	);
}

export default ChatDetailsPage;
