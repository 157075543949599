import React, { useEffect, useMemo } from 'react';

import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import {
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import TableItem from './item';

const Table = ({
	data,
	columns,
	search,
	rowProps,
	firstRow,
	headerProps = () => {},
	update,
	getSelectedRows = () => {},
}) => {
	const filteredColumns = useMemo(() => {
		return columns.filter((item) => item.show !== false);
	}, [columns]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		setGlobalFilter,
		selectedFlatRows,

		page,

		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		state: { pageIndex },
	} = useTable(
		{
			columns: filteredColumns,
			data: data,
			update: update,
			initialState: { pageSize: 15 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	);

	useEffect(() => {
		function onKeyDown(event) {
			if (event.code === 'ArrowRight') {
				nextPage?.();
			}

			if (event.code === 'ArrowLeft') {
				previousPage?.();
			}
		}

		document.addEventListener('keydown', onKeyDown);

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	useEffect(() => {
		setGlobalFilter(search);
	}, [search, setGlobalFilter, data]);

	useEffect(() => {
		const selectedData = selectedFlatRows.map((item) => item.original);
		getSelectedRows(selectedData);
	}, [getSelectedRows, selectedFlatRows]);

	const rowsData = useMemo(() => {
		if (!page.length) {
			return (
				<tr>
					<td colSpan="100%">
						<div className="h-full w-full p-10 text-center text-xl">
							No data
						</div>
					</td>
				</tr>
			);
		}

		const rows = page.map((row) => {
			prepareRow(row);
			return <TableItem key={row.id} row={row} rowProps={rowProps} />;
		});

		return (
			<>
				{firstRow ? (
					<tr>
						<td colSpan="100%">
							<div>{firstRow}</div>
						</td>
					</tr>
				) : null}
				{rows}
			</>
		);
	}, [firstRow, page, prepareRow, rowProps, selectedFlatRows]);

	return (
		<>
			<table className="table-auto w-full" {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr
							key={headerGroup.id}
							{...headerGroup.getHeaderGroupProps(
								headerProps(headerGroup),
							)}
						>
							{headerGroup.headers.map((column) => (
								<th
									key={column.id}
									className={column.class}
									{...column.getHeaderProps(
										column.getSortByToggleProps(),
									)}
								>
									<div className="flex flex-row">
										{column.isSorted ? (
											column.isSortedDesc ? (
												<MdArrowDropDown className="my-auto" />
											) : (
												<MdArrowDropUp className="my-auto" />
											)
										) : (
											<div className="w-4" />
										)}
										{column.render('Header')}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>{rowsData}</tbody>
			</table>
			<div
				className="pagination"
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					margin: 5,
				}}
			>
				<Button
					variant="primary"
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
					icon="chevron-left"
				/>
				<Text weight="bold" style={{ margin: 10 }}>
					{pageIndex + 1} of {pageOptions.length}
				</Text>
				<Button
					variant="primary"
					onClick={() => nextPage()}
					disabled={!canNextPage}
					icon="chevron-right"
				/>
			</div>
		</>
	);
};

export default Table;
