import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRef } from 'react';

import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { DataGrid, useGridApiContext, useGridApiRef } from '@mui/x-data-grid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { GridColumnMenu } from '@mui/x-data-grid';
import { without } from 'lodash';
import { useSnackbar } from 'notistack';

import { FeatureService } from '@asteria/backend-utils-services';

import PartnerSelector from '../../components/partner/selector';
import * as Context from '../../context';
import { useRequest } from '../../hooks/request';
import { selectToken } from '../../store/auth';

import './features.scss';

function EditToolbar(props) {
	const { addFeature, partners, setActivePartners } = props;
	const context = useGridApiContext();

	const handleAdd = () => {
		const featureName =
			context?.current?.exportState()?.filter?.filterModel
				?.quickFilterValues?.[0] ?? '';
		if (featureName.trim() !== '') {
			addFeature(featureName);
		}
	};

	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter />
			<Button color="primary" startIcon={<AddIcon />} onClick={handleAdd}>
				Add feature
			</Button>
			<PartnerSelector
				sx={{
					ml: 'auto',
					flexGrow: 1,
				}}
				partners={partners}
				onChange={setActivePartners}
				multiple
			/>
		</GridToolbarContainer>
	);
}

function CustomUserItem(props) {
	const { myCustomHandler, myCustomValue } = props;
	return (
		<MenuItem onClick={myCustomHandler}>
			<ListItemIcon>
				<SettingsApplicationsIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>{myCustomValue}</ListItemText>
		</MenuItem>
	);
}

function CustomColumnMenu(props) {
	const { onAction, ...restProps } = props;
	return (
		<GridColumnMenu
			{...restProps}
			slots={{
				columnMenuExport: CustomUserItem,
				columnMenuImport: CustomUserItem,
			}}
			slotProps={{
				columnMenuExport: {
					displayOrder: 15,
					myCustomValue: 'Export',
					myCustomHandler: () => onAction('export', restProps.colDef),
				},
				columnMenuImport: {
					displayOrder: 16,
					myCustomValue: 'Import',
					myCustomHandler: () => onAction('import', restProps.colDef),
				},
			}}
		/>
	);
}

const FeatureCell = (props) => {
	const { enableFeature, disableFeature, partner, params } = props;
	const [checked, isChecked] = useState(params?.value);

	useEffect(() => {
		isChecked(params?.value);
	}, [params?.value]);

	return (
		<Checkbox
			checked={checked}
			onChange={(event) => {
				isChecked(event.target.checked);
				if (event.target.checked) {
					enableFeature(params.row, partner._id);
				} else {
					disableFeature(params.row, partner._id);
				}
			}}
		/>
	);
};

const FeaturePage = () => {
	const accessToken = useSelector(selectToken);
	const [isActive, setIsActive] = useState([]);
	const [activeFeatures, setActiveFeatures] = useState({});
	const [features, setFeatures] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [shouldFetch, setShouldFetch] = useState(true);
	const apiRef = useGridApiRef();

	const {
		state: { partners },
	} = useContext(Context.partner.Context);

	const reFetch = useCallback(() => {
		setShouldFetch((value) => !value);
	}, []);

	const options = React.useMemo(() => ({}), [shouldFetch]);
	const context = React.useMemo(
		() => ({ token: accessToken }),
		[accessToken],
	);

	const { isLoading, data, error } = useRequest({
		handler: FeatureService.feature.fetch,
		options: options,
		context: context,
	});

	useEffect(() => {
		setFeatures(data);
	}, [data]);

	const disableFeature = useCallback(
		(feature, partnerId) => {
			FeatureService.feature.sendRequest(
				{
					query: `
				mutation DisableFeature($key: String, $input: FeatureDisableInput!) {
					disableFeature(key: $key, input: $input) {ok}
				}
			`,
					variables: {
						key: feature.key,
						input: {
							type: 'PARTNER',
							entityId: partnerId,
						},
					},
				},
				context,
			);
		},
		[context],
	);

	const enableFeature = useCallback(
		(feature, partnerId) => {
			FeatureService.feature.sendRequest(
				{
					query: `
				mutation EnableFeature($key: String, $input: FeatureEnableInput!) {
					enableFeature(key: $key, input: $input) {ok}
				}
			`,
					variables: {
						key: feature.key,
						input: {
							type: 'PARTNER',
							entityId: partnerId,
							version: 1,
						},
					},
				},
				context,
			);
		},
		[context],
	);

	const enableFeatures = useCallback(
		async (features, partnerId) => {
			return await FeatureService.feature.sendRequest(
				{
					query: `
					mutation EnableFeatures(
						$keys: [String]
						$input: FeatureEnableInput!
					) {
						enableFeatures(keys: $keys, input: $input) {
							ok
							error
						}
					}
			`,
					variables: {
						keys: features,
						input: {
							type: 'PARTNER',
							entityId: partnerId,
						},
					},
				},
				context,
			);
		},
		[context],
	);

	const disableFeatures = useCallback(
		async (features, partnerId) => {
			return await FeatureService.feature.sendRequest(
				{
					query: `
					mutation DisableFeatures($ids: [ID!]!, $input: FeatureDisableInput!) {
						disableFeatures(ids: $ids, input: $input) {
							ok
							error
						}
					}
			`,
					variables: {
						ids: features,
						input: {
							type: 'PARTNER',
							entityId: partnerId,
						},
					},
				},
				context,
			);
		},
		[context],
	);

	const deleteFeature = useCallback(
		(feature) => {
			const remove = async () => {
				await FeatureService.feature.sendRequest(
					{
						query: `
						mutation RemoveFeature($id: ID!){
							removeFeature(id: $id) {
							  	ok
							}
						}
					`,
						variables: {
							id: feature._id,
						},
					},
					context,
				);

				const newFeatures = {
					...features,
				};

				delete newFeatures[feature.key];

				setFeatures(newFeatures);
			};

			remove();
		},
		[context, features],
	);

	const columns = useMemo(() => {
		return [
			{
				field: 'key',
				headerName: 'Feature',
				flex: 1,
				editable: true,
			},
			...partners
				.filter((partner) => isActive.includes(partner._id))
				.map((partner) => ({
					field: partner._id,
					headerName: partner.name,
					flex: 1,
					renderCell: (params) => (
						<FeatureCell
							params={params}
							enableFeature={enableFeature}
							disableFeature={disableFeature}
							partner={partner}
						/>
					),
				})),
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				getActions: (row) => {
					return [
						<GridActionsCellItem
							icon={<DeleteIcon />}
							label="Delete"
							color="inherit"
							onClick={() => deleteFeature(row.row)}
						/>,
					];
				},
			},
			{
				field: 'createdAt',
				headerName: 'Created At',
				flex: 1,
				editable: true,
			},
		];
	}, [partners, isActive, disableFeature, enableFeature, deleteFeature]);

	useEffect(() => {
		const fetch = async () => {
			Promise.all(
				isActive.map((partner) =>
					FeatureService.feature.fetchByPartnerId({
						id: partner,
						...options,
					}),
				),
			).then((data) => {
				setActiveFeatures(
					isActive.reduce((acc, partner, index) => {
						acc[partner] = data[index];
						return acc;
					}, {}),
				);
			});
		};

		if (isActive && isActive.length > 0) {
			fetch();
		}
	}, [isActive, options]);

	const addFeature = useCallback(
		(data) => {
			const add = async () => {
				const resp = await FeatureService.feature.sendRequest(
					{
						query: `
						mutation AddFeature($input: FeatureAddInput!){
							addFeature(input: $input) {
								ok
								data {
									id
									key
									name
									description
								}
							}
						}
					`,
						variables: {
							input: {
								name: data,
								description: '',
								key: data,
							},
						},
					},
					context,
				);

				const feature = resp?.addFeature?.data;

				if (feature) {
					setFeatures({
						...features,
						[feature.key]: feature,
					});
				}
			};

			add();
		},
		[context, features],
	);

	const rows = useMemo(() => {
		if (!features) {
			return [];
		}

		if (apiRef.current && apiRef.current.forceUpdate) {
			apiRef.current.forceUpdate();
		}

		return Object.values(features).map((feature) => ({
			...feature,
			...partners.reduce((acc, partner, index) => {
				acc[partner._id] =
					!!activeFeatures?.[partner._id]?.[feature.key] || false;
				return acc;
			}, {}),
		}));
	}, [features, partners, activeFeatures]);

	const [showImport, setShowImport] = useState(false);

	const importExportData = useCallback(
		(action, params) => {
			if (action === 'export') {
				const { field: partnerId, headerName: partnerName } = params;

				if (partnerId === 'key') {
					return;
				}

				navigator.clipboard.writeText(
					Object.keys(activeFeatures?.[partnerId]).join(','),
				);

				enqueueSnackbar(
					`Copied ${partnerName} feature flags to clipboard`,
					{
						variant: 'success',
					},
				);
			} else if (action === 'import') {
				const { field: partnerId, headerName: partnerName } = params;

				if (partnerId === 'key') {
					return;
				}

				setShowImport({ partnerId, partnerName });
			}
		},
		[activeFeatures, enqueueSnackbar],
	);

	const importFormRef = useRef(null);
	const handleCloseImport = useCallback(() => setShowImport(false), []);
	const handleImport = useCallback(async () => {
		const { partnerId, partnerName } = showImport;

		const importKeys = (importFormRef?.current?.value || '')
			.split(',')
			.map((key) => key.trim());
		const activeKeys = Object.keys(activeFeatures?.[partnerId] || {});

		const disableKeys = without(activeKeys, ...importKeys)
			.map((key) => features[key]._id)
			.filter((key) => key);
		const enableKeys = without(importKeys, ...activeKeys);

		await enableFeatures(enableKeys, partnerId);
		await disableFeatures(disableKeys, partnerId);

		enqueueSnackbar(`Imported ${partnerName} feature flags successfully`, {
			variant: 'success',
		});
		reFetch();
		handleCloseImport();
	}, [
		handleCloseImport,
		showImport,
		enqueueSnackbar,
		activeFeatures,
		enableFeatures,
		disableFeatures,
		reFetch,
		features,
	]);

	const onSetActive = useCallback(
		(e, values) => {
			const [newPartner] = values.filter(
				(item) => !isActive.includes(item._id),
			);

			if (newPartner) {
				FeatureService.feature
					.fetchByPartnerId({
						id: newPartner._id,
						...options,
					})
					.then((data) => {
						setActiveFeatures((active) => {
							setActiveFeatures({
								...active,
								[newPartner._id]: data,
							});
						});
					});
			}

			setIsActive(values.map(({ _id }) => _id));
		},
		[isActive, options],
	);

	if (error) {
		return error;
	}

	return (
		<Paper
			elevation={0}
			sx={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Dialog
				open={!!showImport}
				onClose={handleCloseImport}
				maxWidth="md"
				fullWidth
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Paste feature flag string
				</DialogTitle>
				<DialogContent>
					<TextField
						multiline
						rows={10}
						fullWidth
						inputRef={importFormRef}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseImport}>Close</Button>
					<Button onClick={handleImport} autoFocus>
						Import
					</Button>
				</DialogActions>
			</Dialog>
			<Stack spacing={3} sx={{ height: '100%' }}>
				<DataGrid
					apiRef={apiRef}
					columns={columns}
					rows={rows}
					loading={isLoading}
					slots={{
						loadingOverlay: LinearProgress,
						toolbar: EditToolbar,
						columnMenu: CustomColumnMenu,
					}}
					slotProps={{
						toolbar: {
							addFeature,
							partners,
							setActivePartners: onSetActive,
						},
						columnMenu: { onAction: importExportData },
					}}
				/>
			</Stack>
		</Paper>
	);
};

export default FeaturePage;
