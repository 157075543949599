import { ClientService } from '@asteria/backend-utils-services';

ClientService.client.extend({
	key: `remove`,
	getQuery: () => `
		mutation DeleteClients($ids: [ObjectId!]!, $companyId: ObjectId) {
			deleteClients(ids: $ids, companyId: $companyId) {
				ok
				error
			}
		}
	`,
	loggerMethod: `services.clientService.remove`,
});
