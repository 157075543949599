import React from 'react';

import { Divider, List, ListItem, ListSubheader } from '@mui/material';

import { Field } from './field';
import { Input } from './input';

const Fields = [
	{
		name: 'invoice.meta.invoiceNumber',
		label: 'Invoice Number',
		required: true,
		order: 2,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		type: 'numberString',
	},
	{
		name: 'invoice.type',
		label: 'Invoice Type',
	},
	{},
	{
		label: 'Invoice Dates',
	},
	{
		name: 'invoice.dates.invoiceSent',
		label: 'Invoice Sent Date',
		order: 3,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		required: true,
	},
	{
		name: 'invoice.dates.invoiceDue',
		label: 'Invoice Due Date',
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		required: true,
	},
	{},
	{
		label: 'Invoice Sums',
	},
	{
		name: 'invoice.sums.total',
		label: 'Invoice Total',
		type: 'number',
		required: true,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		rules: [
			{
				type: 'number',
			},
		],
	},
	{
		name: 'invoice.sums.tax',
		label: 'VAT',
		type: 'number',
		required: true,
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		rules: [
			{
				type: 'number',
			},
		],
	},
	{
		name: 'invoice.sums.currency',
		label: 'Currency',
		group: {
			name: 'invoice',
			label: 'Invoice',
		},
		required: true,
	},
];

export const InvoiceFields = (props) => {
	return (
		<List
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
			}}
		>
			{Fields.map(({ name, label, type }, index) => {
				if (!label) {
					return <Divider key={index} />;
				}

				if (!name) {
					return <ListSubheader key={index}>{label}</ListSubheader>;
				}

				return (
					<ListItem key={name}>
						<Field
							name={name}
							label={label}
							fieldType={type}
							edit
							{...props}
						/>
					</ListItem>
				);
			})}
		</List>
	);
};
