const lookupAll = ({ op, node, pdfData }) => {
	if (!op.pattern) {
		return [null, null];
	}

	const regex = new RegExp(op.pattern, 'i');
	let operationResults = null;
	let texts = [];

	if (op.pages === 'first') {
		texts = pdfData?.pdfData?.Pages?.[0]?.Texts ?? [];
	} else if (op.pages === 'last') {
		texts =
			pdfData?.pdfData?.Pages?.[pdfData?.pdfData?.Pages?.length - 1]
				?.Texts ?? [];
	} else {
		texts = (pdfData?.pdfData?.Pages ?? []).flatMap(({ Texts }) => Texts);
	}

	if (op.direction === 'before') {
		const index = texts.indexOf(node);
		if (index !== 0) {
			texts = texts.slice(0, index);
		}

		texts = texts.toReversed();
	} else if (op.direction === 'after' && node) {
		texts = texts.slice(texts.indexOf(node) + 1);
	}

	const matches = texts.filter(({ text }) => regex.test(text));

	if (matches && matches.length > 0) {
		return [operationResults, matches];
	} else {
		return [operationResults, null];
	}
};

export default lookupAll;
