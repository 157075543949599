import { env } from './constants';

export const group = (data = [], key) => {
	return data.reduce(
		(acc, { [key]: $key, ...object }) => ({
			...acc,
			[$key]: object,
		}),
		{},
	);
};

export const getEnv = (path) => {
	const link = path || window.location.href;

	if (link.includes('dev.') || link.includes('dev-')) {
		return env.DEV;
	} else if (link.includes('stage.') || link.includes('stage-')) {
		return env.STAGE;
	} else if (link.includes('localhost')) {
		return env.LOCAL;
	} else if (link.includes('local.')) {
		return env.DEV;
	} else {
		return env.PROD;
	}
};

export const getAnalyticsByRange = (companies) => {
	return companies.reduce((acc, { analytics }) => {
		for (const key in analytics) {
			if (key === 'integration.created') {
				for (const selector in analytics[key].dataByRange) {
					analytics[key].dataByRange[selector].data.forEach(
						({ data }) => {
							if (!data) {
								return;
							}

							const integrationKey = `${key}.${data.type}`;

							if (
								analytics[integrationKey] === undefined ||
								analytics[integrationKey][selector] ===
									undefined
							) {
								acc[integrationKey] = {
									...acc[integrationKey],
									[selector]: 0,
								};
							}

							acc[integrationKey][selector] += 1;
						},
					);
				}
			} else {
				if (acc[key] === undefined) {
					acc[key] = {};
				}

				for (const selector in analytics[key].dataByRange) {
					if (acc[key][selector] === undefined) {
						acc[key][selector] = 0;
					}

					acc[key][selector] +=
						analytics[key].dataByRange[selector]?.count || 0;
				}
			}
		}

		return acc;
	}, {});
};

export const average = (arr = []) => {
	if (!arr.length) {
		return null;
	}

	const avg = arr.reduce((acc, value) => acc + value) / arr.length;
	return Math.round(avg * 100) / 100;
};

export const getNodes = (object) => {
	if (!object) {
		return [];
	}

	return object.edges.map((object) => object.node);
};

export const countItems = (items) => {
	const data = {};

	items.forEach((item) => {
		if (data[item] === undefined) {
			data[item] = 0;
		}

		data[item] = data[item] + 1;
	});

	return Object.keys(data).map((key) => ({
		action: key,
		value: data[key],
	}));
};

export const countActions = (items) => {
	const data = {};

	items.forEach((item) => {
		if (data[item] === undefined) {
			data[item] = 0;
		}

		data[item] = data[item] + 1;
	});

	return Object.keys(data).map((key) => ({
		action: key,
		value: data[key],
	}));
};
