import React, { useCallback } from 'react';

import { formatISO } from 'date-fns';

import { UncontrolledDatePicker } from '@asteria/component-form/datepicker';
import { Option, UncontrolledSelect } from '@asteria/component-form/select';

import { rangeType } from '../utils/constants';

const UsageAnalyticsSettings = ({ settings, setSettings }) => {
	const setOptions = useCallback(
		({ name, value }) => {
			setSettings({ ...settings, [name]: value });
		},
		[settings],
	);

	const onDateSelect = useCallback(
		({ value }) => {
			const date = new Date(value);
			setSettings({ ...settings, date: date });
		},
		[settings],
	);

	return (
		<div className="ml-auto flex flex-row gap-2 my-2">
			<UncontrolledSelect
				size="small"
				name="range"
				label="Range: "
				value={settings.range}
				onChange={setOptions}
			>
				<Option value={rangeType.DAY}>Daily</Option>
				<Option value={rangeType.WEEK}>Weekly</Option>
				<Option value={rangeType.MONTH}>Monthly</Option>
				<Option value={rangeType.YEAR}>Yearly</Option>
			</UncontrolledSelect>
			<UncontrolledDatePicker
				className="w-40"
				icon="date"
				iconPosition="last"
				locale="en"
				name="date"
				type={settings.range.toLowerCase()}
				value={formatISO(new Date(settings.date))}
				onChange={onDateSelect}
				size="small"
				placement="bottom"
			/>
		</div>
	);
};

export default UsageAnalyticsSettings;
