export function getAvatarLabel(data, type) {
	const name = [data?.firstName, data?.lastName].filter(Boolean);

	if (name.length) {
		return name
			.map(([char]) => char)
			.join('')
			.toUpperCase();
	}

	if (type === 'UserToken') {
		return 'U';
	}

	return 'A';
}

export function getUnreadMessages(messages) {
	if (!messages?.length) {
		return [];
	}

	return messages?.filter(
		(message) =>
			message?.read === false &&
			message?.createdBy?.type !== 'AdminToken',
	);
}
