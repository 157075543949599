import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AuthService } from '@asteria/backend-utils-services';

import Logo from '../components/Logo';
import * as Context from '../context';
import * as Ducks from '../ducks';
import { login } from '../store/auth';

const Login = () => {
	const navigate = useNavigate();
	const [requireTwoFactor, setRequireTwoFactor] = useState(false);
	const authContext = React.useContext(Context.auth.Context);

	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm();

	const onSubmit = (form) => {
		AuthService.auth.extension
			.loginAdmin({
				useTokenFromContext: false,
				...form,
				fields: `ok
						error
						data {
							userId
							accessToken
							refreshToken
							accessTokenExpires
							refreshTokenExpires
							disabled
							revoked
    						hasTwoFactor
						}`,
			})
			.then(({ data }) => data)
			.then((data) => {
				if (!data.hasTwoFactor) {
					// authContext.dispatch(Ducks.auth.login(data));
					dispatch(login(data));
					authContext.dispatch(Ducks.auth.login(data));
					navigate('/registerTwoFactor');
				} else if (data.hasTwoFactor && !data?.accessToken) {
					setRequireTwoFactor(true);
					if (requireTwoFactor) {
						setError('global', {
							type: 'manual',
							message: 'Incorrect code',
						});
					}
				} else {
					dispatch(login(data));
					authContext.dispatch(Ducks.auth.login(data));
					navigate('/');
				}
			})
			.catch((err) => {
				let message = err.message;

				if (err.response) {
					message = err.response.data.errors[0].message;
				}

				setError('global', { type: 'manual', message: message });
			});
	};

	return (
		<div className="flex flex-col h-screen items-center justify-center">
			<Logo className="mb-4" />
			<div>
				<div>{errors.global ? errors.global.message : null}</div>
				<div>
					{errors.username
						? errors.username.message ||
						  'Username should be provided'
						: null}
				</div>
				<div>
					{errors.password
						? errors.password.message ||
						  'Password should be provided'
						: null}
				</div>
			</div>
			<form
				className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				{!requireTwoFactor && (
					<div>
						<div className="mb-4">
							<label className="block text-gray-700 text-sm font-bold mb-2">
								Email
							</label>
							<input
								className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								type="email"
								name="email"
								{...register('username', { required: true })}
							/>
						</div>
						<div className="mb-4">
							<label className="block text-gray-700 text-sm font-bold mb-2">
								Password
							</label>
							<input
								className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								type="password"
								name="password"
								{...register('password', { required: true })}
							/>
						</div>
					</div>
				)}
				{requireTwoFactor && (
					<div className="mb-4">
						<label className="block text-gray-700 text-sm font-bold mb-2">
							Twofactor Code
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="text"
							name="code"
							{...register('code', { required: true })}
						/>
					</div>
				)}
				<button
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
					onClick={() => {
						clearErrors('global');
					}}
				>
					Login
				</button>
			</form>
		</div>
	);
};

export default Login;
