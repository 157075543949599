/* Constants */

export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_TRANSLATONS = 'UPDATE_TRANSLATONS';

/* Reducer */

export const initialState = {
	settings: {
		originalCode: 'sv',
		code: 'en',
		partnerId: undefined,
		partnerName: '',
		themeId: undefined,
		showMissing: false,
		search: '',
	},
	translations: {},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SETTINGS: {
			const data = action.payload;

			return {
				...state,
				settings: { ...state.settings, ...data },
			};
		}

		case UPDATE_TRANSLATONS: {
			const data = action.payload;

			return {
				...state,
				translations: { ...state.translations, ...data },
			};
		}
	}
};

/* Actions */

export const setSettings = (data) => ({ type: SET_SETTINGS, payload: data });
export const updateTranslations = (data) => ({
	type: UPDATE_TRANSLATONS,
	payload: data,
});
