import AsteriaCore from '@asteria/core';

import { CompanyService } from '@asteria/backend-utils-services';

CompanyService.company.extend({
	getQuery: () => `
		query List(
			$pageFilters: PageInput = {}
			$userPageFilters: PageInput = {}
			$filters: CompanyFiltersInputType = {}
		) {
			response: companies(pageFilters: $pageFilters, filters: $filters) {
				pageInfo {
					hasNextPage
					hasPreviousPage
					count
					currentCursor
					startCursor
					endCursor
				}
				edges {
					node {
						_id
						id
						active
						name
						orgnumber
						createdAt
						deletedAt
						rawPartners

						users(pageFilters: $userPageFilters) {
							edges {
								node {
									_id
									username
									rawCompanies
								}
							}
						}
						partners {
							edges {
								node {
									_id
									name
								}
							}
						}
						integrations {
							_id
							type
							key
							status {
								state
							}
						}
					}
				}
			}
		}
	`,
	key: `fetchTable`,
	loggerMethod: `services.companyService.company`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.companyService.company.fetchTable`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

CompanyService.company.extend({
	getQuery: () => `
		query List(
			$pageFilters: PageInput = {}
			$userPageFilters: PageInput = {}
			$filters: CompanyFiltersInputType = {}
			$userAnalyticsFilter: AnalyticsFilters = {}
			$analyticsRange: ANALYTICS_RANGE = MONTH
		) {
			response: companies(pageFilters: $pageFilters, filters: $filters) {
				pageInfo {
					hasNextPage
					hasPreviousPage
					count
					currentCursor
					startCursor
					endCursor
				}
				edges {
					cursor
					node {
						name
						_id
						id
						createdAt
						deletedAt
						active
						orgnumber
						rawPartners

						partners {
							edges {
								node {
									_id
									name
								}
							}
						}
						users(pageFilters: $userPageFilters) {
							edges {
								node {
									_id
									username
									rawCompanies
									analytics(filters: $userAnalyticsFilter) {
										data {
											type
											dataByRange(
												range: $analyticsRange
											) {
												selector
												data {
													type
													data
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	key: `fetchUIAnalytics`,
	loggerMethod: `services.companyService.company`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.companyService.company.fetchUIAnalytics`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

CompanyService.company.extend({
	getQuery: () => `
		query List(
			$pageFilters: PageInput = {}
			$userPageFilters: PageInput = {}
			$filters: CompanyFiltersInputType = {}
			$userAnalyticsFilter: AnalyticsFilters = {}
			$analyticsFilters: AnalyticsFilters = {}
			$analyticsRange: ANALYTICS_RANGE = MONTH
		) {
			response: companies(pageFilters: $pageFilters, filters: $filters) {
				pageInfo {
					hasNextPage
					hasPreviousPage
					count
					currentCursor
					startCursor
					endCursor
				}
				edges {
					cursor
					node {
						name
						_id
						id
						createdAt
						deletedAt
						active
						orgnumber
						rawPartners

						partners {
							edges {
								node {
									_id
									name
								}
							}
						}
						users(pageFilters: $userPageFilters) {
							edges {
								node {
									_id
									username
									rawCompanies

									analytics(filters: $userAnalyticsFilter) {
										data {
											type
											dataByRange(
												range: $analyticsRange
											) {
												selector
												data {
													type
													data
												}
											}
										}
									}
								}
							}
						}

						analytics(filters: $analyticsFilters) {
							_id
							count
							data {
								type
								count
								dataByRange(range: $analyticsRange) {
									selector
									count
									data {
										_id
										data
										createdAt
									}
								}
							}
						}
					}
				}
			}
		}
	`,
	key: `fetchAnalytics`,
	loggerMethod: `services.companyService.company`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.companyService.company.fetchAnalytics`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

CompanyService.company.extend({
	getQuery: () => `
		mutation Request(
			$filters: [CompanyFilters!]!
			$input: [CleanCompanyInputType!]
		) {
			response: cleanCompanies(filters: $filters, input: $input) {
				ok
				error
			}
		}
	`,
	key: `clean`,
	loggerMethod: `services.companyService.clean`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.companyService.clean`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});

CompanyService.company.extend({
	getQuery: () => `
		mutation Request(
			$id: ID
			$input: CompanyUpdateInputType!
		) {
			response: updatePartialCompany(id: $id, input: $input) {
				ok
				error
			}
		}
	`,
	key: `updatePartialCompany`,
	loggerMethod: `services.companyService.updatePartialCompany`,
	onError: (err, { context }) => {
		const { token, tokenData: { sessionId = null } = {} } = context;

		AsteriaCore.Logger.warn(err, {
			method: `services.companyService.updatePartialCompany`,
			sessionId: sessionId,
			token: token,
		});

		throw err;
	},
});
