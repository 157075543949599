import React from 'react';

const CircleMarker = ({ size = 3, color = '#78716c' }) => {
	const sizeInRem = `${size / 4}rem`;
	return (
		<span
			className={`flex label rounded-full block my-auto`}
			style={{
				backgroundColor: color,
				width: sizeInRem,
				height: sizeInRem,
			}}
		/>
	);
};

export default CircleMarker;
