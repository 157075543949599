import React, { useCallback, useMemo } from 'react';

import Box from '../../../components/Stats/Box';
import Arrow from '../../../components/core/Arrow';
import { formatInterval, moveDate } from '../../../utils/dateUtils';
import { getAvgTime } from '../utils/usageUtils';

const UsageAnalyticsStats = ({
	data = [],
	settings,
	pageOptions,
	setPageOptions = () => {},
}) => {
	const stats = useMemo(() => {
		if (!data.length) {
			return [];
		}

		const selector = formatInterval({
			date: new Date(pageOptions.selector),
			range: settings.range,
		});

		const prevDate = moveDate({
			range: settings.range,
			date: new Date(pageOptions.selector),
			diff: -1,
		});

		const prevSelector = formatInterval({
			date: prevDate,
			range: settings.range,
		});

		const timeInInterface = getAvgTime({
			data: data,
			type: 'timeInInterface',
			selector: selector,
			prevSelector: prevSelector,
		});

		const firstAction = getAvgTime({
			data: data,
			type: 'userFirstAction',
			selector: selector,
			prevSelector: prevSelector,
		});

		return [
			{
				id: 'timeInInterface',
				name: 'timeInInterface',
				color: '#7dd3fc',
				value: `${timeInInterface.value}s`,
				desc: 'Time in interface',
				category: <Arrow value={timeInInterface.diff} ending="s" />,
				editable: false,
			},
			{
				id: 'firstAction',
				name: 'firstAction',
				color: '#86efac',
				value: `${firstAction.value}s`,
				desc: 'Time to first action',
				category: <Arrow value={firstAction.diff} ending="s" />,
				editable: false,
			},
		];
	}, [data, settings, pageOptions.selector]);

	const setSelected = useCallback(
		(id) => setPageOptions({ ...pageOptions, selectedStats: id }),
		[pageOptions],
	);

	const statBoxes = useMemo(() => {
		return stats.map((item, itemIndex) => {
			return (
				<Box
					id={itemIndex}
					key={item.id}
					item={item}
					selected={pageOptions.selectedStats === item.id}
					setSelected={setSelected}
				/>
			);
		});
	}, [stats, pageOptions]);

	return (
		<div className="flex flex-row basis-full bg-white ml-0 p-4 pb-8 space-x-4">
			{statBoxes}
		</div>
	);
};

export default UsageAnalyticsStats;
