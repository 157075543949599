import React from 'react';

import { useFormContext } from 'react-hook-form';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Paper,
} from '@mui/material';

const Footer = React.memo(function Footer({ id, success }) {
	const { register, resetField } = useFormContext();

	React.useEffect(() => {
		resetField('message', {
			keepDirty: false,
			keepError: false,
			keepTouched: false,
		});
	}, [resetField, success]);

	return (
		<Paper className="p-4">
			<FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
				<InputLabel htmlFor="message">Message</InputLabel>
				<OutlinedInput
					id="message"
					{...register('message', { required: true })}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="send a message"
								edge="end"
								type="submit"
							>
								<ChevronRightIcon />
							</IconButton>
						</InputAdornment>
					}
					label="Message"
				/>
			</FormControl>
		</Paper>
	);
});

export default Footer;
