import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import {
	Autocomplete,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	Input,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Slider,
	Stack,
	TextField,
} from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';

export const Append = (props) => {
	const { name, operation } = props;
	const { register } = useFormContext();

	return (
		<Stack>
			<Input
				value={operation.text ?? ''}
				size="small"
				{...register(`${name}.text`)}
			/>
		</Stack>
	);
};
