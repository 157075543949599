export const QUERY_CHATS = `
	query Request(
		$chatPageFilters: PageInput = {}
		$chatFilters: ChatFilters = {}
		$chatCompanyId: ObjectId
		$messagePageFilters: PageInput = {}
		$messageFilters: ChatMessageFilters = {}
		$messageCompanyId: ObjectId
	) {
		response: chats(
			pageFilters: $chatPageFilters
			filters: $chatFilters
			companyId: $chatCompanyId
		) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				count
				currentCursor
				startCursor
				endCursor
			}
			edges {
				cursor
				node {
					id
					_id
					companyId
					name
					status
					createdBy {
						type
						id
					}
					createdAt
					updatedAt
					deletedAt
					messages(
						pageFilters: $messagePageFilters
						filters: $messageFilters
						companyId: $messageCompanyId
					) {
						pageInfo {
							hasNextPage
							hasPreviousPage
							count
							currentCursor
							startCursor
							endCursor
						}
						edges {
							cursor
							node {
								id
								_id
								companyId
								chatId
								content
								read
								createdBy {
									type
									id
								}
								createdAt
								updatedAt
								deletedAt
							}
						}
					}
				}
			}
		}
	}
`;

export const QUERY_CHAT = `
	query Request(
		$chatId: ID!
		$chatCompanyId: ObjectId
		$messagePageFilters: PageInput = {}
		$messageFilters: ChatMessageFilters = {}
		$messageCompanyId: ObjectId
	) {
		response: chat(id: $chatId, companyId: $chatCompanyId) {
			id
			_id
			companyId
			name
			status
			createdBy {
				type
				id
			}
			createdAt
			updatedAt
			deletedAt
			messages(
				pageFilters: $messagePageFilters
				filters: $messageFilters
				companyId: $messageCompanyId
			) {
				pageInfo {
					hasNextPage
					hasPreviousPage
					count
					currentCursor
					startCursor
					endCursor
				}
				edges {
					cursor
					node {
						id
						_id
						companyId
						chatId
						content
						read
						createdBy {
							type
							id
						}
						createdAt
						updatedAt
						deletedAt
						chat {
							_id
							createdAt
						}
					}
				}
			}
		}
	}
`;
