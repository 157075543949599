import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PartnerService } from '@asteria/backend-utils-services';

import { selectToken } from '../../../store/auth';

export default function PartnerEdit() {
	const { id } = useParams();
	const accessToken = useSelector(selectToken);
	const [partner, setPartner] = useState(null);

	useEffect(() => {
		const fetch = async () => {
			const partner = await PartnerService.partner.fetchOne(
				{
					id: id,
					fields: `id name`,
				},
				{ token: accessToken },
			);
			setPartner(partner);
		};

		fetch();
	}, [accessToken, id]);

	if (partner === null) {
		return null;
	}

	return <div>{partner.name}</div>;
}
