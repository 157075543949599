import React from 'react';

import { useSelector } from 'react-redux';

import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { CompanyService } from '@asteria/backend-utils-services';

import { selectToken } from '../../../store/auth';

const PartnerTabContent = (props) => {
	const { id } = props;

	const accessToken = useSelector(selectToken);

	const { data } = useQuery({
		queryKey: ['companies', id, 'partners'],
		queryFn: () =>
			CompanyService.company
				.fetchOne(
					{
						fields: `
							_id
							partners(pageFilters: { first: 0 }) {
								edges {
									node {
										_id
										name
										settings {
											hosting {
												domain
												widget
											}
											themeId
										}
										createdAt
										updatedAt
									}
								}
							}
						`,
						id: id,
					},
					{ token: accessToken },
				)
				.then((response) =>
					(response?.partners?.edges ?? []).map(({ node }) => node),
				),
	});

	return (
		<Stack spacing={2}>
			{(data ?? []).map((object) => (
				<Accordion key={object?._id} variant="outlined">
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography>{object?.name}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableHead>
									<TableCell>Key</TableCell>
									<TableCell>Value</TableCell>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>{object?._id}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>{object?.name}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Domain</TableCell>
										<TableCell>
											{object?.settings?.hosting?.domain}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Widget</TableCell>
										<TableCell>
											{object?.settings?.hosting?.widget}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Theme ID</TableCell>
										<TableCell>
											{object?.settings?.themeId}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Created at</TableCell>
										<TableCell>
											{object?.createdAt &&
												object?.createdAt
													.split('T')
													.join(' ')}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Updated at</TableCell>
										<TableCell>
											{object?.updatedAt &&
												object?.updatedAt
													.split('T')
													.join(' ')}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			))}
		</Stack>
	);
};

export default PartnerTabContent;
