import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import {
	Autocomplete,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	Input,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Slider,
	Stack,
	TextField,
} from '@mui/material';

import { FormContext } from '../../../../../../components/pdf/context';

export const Filter = (props) => {
	const { name, operation } = props;
	const { register } = useFormContext();

	return (
		<Stack>
			<Input
				value={operation.filter ?? 0}
				size="small"
				{...register(`${name}.filter`, {
					value: 0,
				})}
			/>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">
					Include / Exclude
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={operation.action ?? 'include'}
					label="Include / Exclude"
					{...register(`${name}.action`)}
				>
					<MenuItem value="include">Include</MenuItem>
					<MenuItem value="exclude">Exclude</MenuItem>
				</Select>
			</FormControl>
		</Stack>
	);
};
