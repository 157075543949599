import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { CompanyService } from '@asteria/backend-utils-services';

import CompanyInfo from '../../components/dialogs/CompanyInfo';
import { selectToken } from '../../store/auth';

export default function CompanyView() {
	const { id } = useParams();
	const accessToken = useSelector(selectToken);
	const [company, setCompany] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		const fetch = async () => {
			const company = await CompanyService.company.fetchOne(
				{
					id: id,
					fields: `
                        _id
                        id
                        active
                        name
                        orgnumber
                        createdAt
                        deletedAt
                        rawPartners

                        users(pageFilters: {first: 0}) {
                            edges {
                                node {
                                    _id
                                    username
                                    rawCompanies
                                }
                            }
                        }
                        partners {
                            edges {
                                node {
                                    _id
                                    name
                                }
                            }
                        }
                        integrations {
                            _id
                            type
                            key
                            status {
                                state
                            }
                        }
                    `,
				},
				{ token: accessToken },
			);
			setCompany(company);
		};

		fetch();
	}, [accessToken, id]);

	const onClose = useCallback(() => {
		navigate(`/companies`);
	}, [navigate]);

	if (company === null) {
		return null;
	}

	return <CompanyInfo companyData={company} onClose={onClose} />;
}
