import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button } from '@mui/material';
import {
	DataGrid,
	GridActionsCellItem,
	GridRowModes,
	GridToolbarContainer,
	useGridApiRef,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';

import { useBatch, useBatchUpdate } from './hooks';

function EditToolbar(props) {
	const { setRows, setRowModesModel } = props;

	const handleClick = () => {
		const id = randomId();
		setRows((oldRows) => [
			...oldRows,
			{
				id,

				code: '',
				type: '',
				customerNo: '',
				sellerOrderID: '',
				originalData: '',
				message: '',

				isNew: true,
			},
		]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: 'code' },
		}));
	};

	return (
		<GridToolbarContainer>
			<Button
				color="primary"
				startIcon={<AddIcon />}
				onClick={handleClick}
			>
				Add error
			</Button>
		</GridToolbarContainer>
	);
}

function BatchErrors({ id, companyId }) {
	const apiRef = useGridApiRef();

	const query = useBatch({
		id,
		companyId,
		select: (data) =>
			(data?.response?.data?.errors ?? []).map((value, index) => ({
				...value,
				id: index,
			})),
	});

	const mutation = useBatchUpdate({ id, companyId });

	const [rows, setRows] = React.useState(query.data);
	const [rowModesModel, setRowModesModel] = React.useState({});

	React.useEffect(() => {
		setRows(query.data);
	}, [query.data]);

	const handleEditClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.Edit },
		});
	};

	const handleSaveClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleDeleteClick = (id) => () => {
		setRows(rows.filter((row) => row.id !== id));
	};

	const handleCancelClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	};

	const processRowUpdate = (newRow) => {
		const updatedRow = { ...newRow, isNew: false };
		setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const handleSave = async () => {
		await mutation.mutate({
			data: {
				errors: apiRef.current
					.getSortedRows()
					.filter(
						(object) =>
							object?.code ||
							object?.type ||
							object?.customerNo ||
							object?.sellerOrderID ||
							object?.originalData ||
							object?.message,
					)
					.map(
						({
							code,
							type,
							customerNo,
							sellerOrderID,
							originalData,
							message,
						}) => ({
							code,
							type,
							customerNo,
							sellerOrderID,
							originalData,
							message,
						}),
					),
			},
		});
	};

	if (query.error) {
		return <Alert severity="error">{query.error?.message}</Alert>;
	}

	return (
		<>
			<DataGrid
				columns={[
					{
						field: 'code',
						headerName: 'Code',
						editable: true,
						width: 140,
					},
					{
						field: 'type',
						headerName: 'Type',
						editable: true,
						width: 140,
					},
					{
						field: 'customerNo',
						headerName: 'CustomerNo',
						editable: true,
						width: 140,
					},
					{
						field: 'sellerOrderID',
						headerName: 'SellerOrderID',
						editable: true,
						width: 140,
					},
					{
						field: 'originalData',
						headerName: 'OriginalData',
						editable: true,
						width: 140,
					},
					{
						field: 'message',
						headerName: 'Message',
						editable: true,
						flex: 1,
					},
					{
						field: 'actions',
						type: 'actions',
						headerName: 'Actions',
						width: 100,
						cellClassName: 'actions',
						getActions: ({ id }) => {
							const isInEditMode =
								rowModesModel[id]?.mode === GridRowModes.Edit;

							if (isInEditMode) {
								return [
									<GridActionsCellItem
										icon={<SaveIcon />}
										label="Save"
										sx={{
											color: 'primary.main',
										}}
										onClick={handleSaveClick(id)}
									/>,
									<GridActionsCellItem
										icon={<CancelIcon />}
										label="Cancel"
										className="textPrimary"
										onClick={handleCancelClick(id)}
										color="inherit"
									/>,
								];
							}

							return [
								<GridActionsCellItem
									icon={<EditIcon />}
									label="Edit"
									className="textPrimary"
									onClick={handleEditClick(id)}
									color="inherit"
								/>,
								<GridActionsCellItem
									icon={<DeleteIcon />}
									label="Delete"
									onClick={handleDeleteClick(id)}
									color="inherit"
								/>,
							];
						},
					},
				]}
				rows={rows}
				loading={query.isFetching || mutation.isPending}
				apiRef={apiRef}
				editMode="row"
				rowModesModel={rowModesModel}
				onRowModesModelChange={handleRowModesModelChange}
				processRowUpdate={processRowUpdate}
				slots={{ toolbar: EditToolbar }}
				slotProps={{ toolbar: { setRows, setRowModesModel } }}
			/>
			<Box
				alignItems="center"
				justifyContent="flex-end"
				display="flex"
				py={2}
				gap={2}
			>
				<Button variant="contained" onClick={handleSave}>
					Save
				</Button>
			</Box>
		</>
	);
}

export default BatchErrors;
