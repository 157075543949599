import React from 'react';

import CircleMarker from '../../../components/core/CircleMarker';

const CustomLegend = (props) => {
	const { payload } = props;

	return (
		<div className="flex flex-row justify-center gap-4">
			{payload.map((entry, index) => (
				<div key={`item-${index}`} className="flex flex-row gap-1">
					<CircleMarker
						color={!entry.inactive ? entry.color : '#d1d5db'}
					/>
					<p className={entry.inactive ? 'text-gray-300' : ''}>
						{entry.value}
					</p>
				</div>
			))}
		</div>
	);
};

export default CustomLegend;
