import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { Brush, Delete, Refresh, Settings } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	ButtonGroup,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	TextField,
} from '@mui/material';

import { FormContext } from '../context';
import { readValueWithRules } from '../utils';

import InputSettings from './Settings';

import '../styles.scss';

export const Input = (props) => {
	const { name, required, label, onFocus } = props;

	const { register, setValue } = useFormContext();
	const value = useWatch({ name: name });

	const { onChange, ...input } = register(name, { required: required });

	const handleChange = (event) => {
		const name = event?.target?.name;
		const value = event?.target?.value;

		setValue(`layout.$manual.${name}`, value);

		return onChange?.(event);
	};

	return (
		<TextField
			label={label}
			variant="outlined"
			fullWidth
			value={value ?? ''}
			required={required}
			InputLabelProps={{ shrink: !!value }}
			onFocus={onFocus}
			onChange={handleChange}
			size="small"
			{...input}
		/>
	);
};

export const InputWrapper = (props) => {
	const { name, label, required, children } = props;

	const { setValue, getValues } = useFormContext();
	const anchorRef = React.useRef(null);
	const [showActionsMenu, setActionsMenu] = React.useState(false);

	const { canvas, scheduler } = React.useContext(FormContext);
	const area = useWatch({ name: ['layout.$area', name].join('.') });
	const value = useWatch({ name: name });

	const { setActive, setHighlight } = React.useContext(FormContext);
	const [isSettingsOpen, setOpen] = React.useState(false);

	const onSettingsClose = React.useCallback(() => {
		setValue(`layout.$settings.${name}`, []);
		setOpen(false);
	}, [name, setValue]);

	const onSettingsOpen = React.useCallback(() => {
		setOpen(true);
	}, []);
	const onDrawClick = React.useCallback(() => {
		setActionsMenu(false);
		setActive({
			area: `layout.$area.${name}`,
			name: name,
			drawing: true,
		});
	}, [name, setActive]);

	const onRemoveClick = React.useCallback(() => {
		setActionsMenu(false);
		setValue(name, '');
		setValue(`layout.$area.${name}`, null);

		setActive({ area: null, name: null });
	}, [name, setActive, setValue]);

	const onFocus = React.useCallback(() => {
		setHighlight(`layout.$area.${name}`);
	}, [setHighlight, name]);

	const toggleActionsMenu = React.useCallback(() => {
		setActionsMenu(!showActionsMenu);
	}, [showActionsMenu]);

	const onRefresh = React.useCallback(async () => {
		setActionsMenu(false);
		const currentArea = getValues(['layout.$area', name].join('.'));
		const value = await readValueWithRules(
			{ name, area: currentArea },
			{
				canvas: canvas.current,
				scheduler: scheduler,
				getValues,
			},
		);

		setValue(name, value);
	}, [name, canvas, scheduler, setValue, getValues]);

	const onSettingsSubmit = React.useCallback(
		({ rules, area }) => {
			setValue(`layout.$settings.${name}`, rules);

			if (area) {
				Object.entries(area).forEach(([key, value]) =>
					setValue(`layout.$area.${name}.${key}`, value),
				);
			}

			onRefresh();
			setOpen(false);
		},
		[name, onRefresh, setValue],
	);

	return (
		<>
			<Grid container direction="row" alignItems="center" spacing={2}>
				<Grid item xs>
					{children ?? (
						<Input
							name={name}
							label={label ?? name}
							required={required}
							onFocus={onFocus}
						/>
					)}
				</Grid>
				<Grid item xs="auto">
					<IconButton
						ref={anchorRef}
						onClick={toggleActionsMenu}
						size="large"
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						anchorEl={anchorRef.current}
						open={showActionsMenu}
						onClose={() => setActionsMenu(false)}
					>
						<MenuItem onClick={onDrawClick}>
							<IconButton size="small" color="info">
								<Brush size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem onClick={onRefresh}>
							<IconButton size="small" color="info">
								<Refresh size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem onClick={onSettingsOpen}>
							<IconButton
								size="small"
								color="secondary"
								disabled={!value}
							>
								<Settings size="small" />
							</IconButton>
						</MenuItem>
						<MenuItem onClick={onRemoveClick}>
							<IconButton
								size="small"
								color="error"
								onClick={onRemoveClick}
							>
								<Delete size="small" />
							</IconButton>
						</MenuItem>
					</Menu>
					<ButtonGroup></ButtonGroup>
				</Grid>
			</Grid>
			<InputSettings
				name={name}
				open={isSettingsOpen}
				onClose={onSettingsClose}
				onSubmit={onSettingsSubmit}
			/>
		</>
	);
};
